import { Slider } from "antd";

const FieldSlider: React.FC<any> = (props) => {
  const { value, onChange } = props;
  const onChangeValue = (value: number) => {
    if (value < 0) onChange(0);
    else if (value > 100) onChange(100);
    else onChange(value);
  };
  return (
    <div className="field-slider">
      <Slider value={value} onChange={onChange} />
      <div className="number-box">
        <input
          className="number"
          type="number"
          value={value}
          onChange={(evt) => onChangeValue(Number(evt.target.value))}
          min="1"
          max="100"
        />
        <span>%</span>
      </div>
    </div>
  );
};

export default FieldSlider;
