import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import imgHenriqueJulianoWeb from "shared/assets/images/henrique-juliano-sem-texto.png";
import { formatNoSignObject, signByCurrency } from "shared/helpers/BRLFormat";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createNewCashAdvance } from "shared/service/StrmService";
import { SpecialCampaignEnum } from "shared/enums/SpecialCampaignEnum";
import "explorer/pages/new-estagio-artista/campanhas-especiais/CampanhasEspeciais.scss";

interface ModalCampaignHeJEligibleProps {
  open: boolean;
  onCancel: () => void;
}

const ModalCampaignHeJEligible: React.FC<ModalCampaignHeJEligibleProps> = (
  props
) => {
  const { open, onCancel } = props;
  const { t } = useTranslation();
  const { userArtist } = useSelector((state: any) => state.artists);
  const { loggedUser } = useSelector((state: any) => state.users);
  const history = useHistory();

  const acceptWithoutAdvance = () => {
    history.push("/distribua", {
      specialCampaign: SpecialCampaignEnum.HenriqueEJuliano,
    });
  };

  const acceptWithAdvance = async () => {
    await createNewCashAdvance(
      loggedUser.id,
      SpecialCampaignEnum.HenriqueEJuliano
    );
    history.push("/adiantamentos");
  };

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      width={700}
      title={null}
      className="modal-henrique-juliano"
    >
      <>
        <div
          style={{
            background: `url(${imgHenriqueJulianoWeb}) no-repeat`,
            backgroundSize: "cover",
            borderRadius: "8px 8px 0 0",
            minHeight: 330,
            width: "100%",
            position: "absolute",
          }}
        />
        <div style={{ position: "relative" }}>
          <div className="conteudo">
            <div style={{ background: "#0D0D0D", opacity: 0.7 }} />
            <div
              style={{
                paddingTop: 112,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 48px",
              }}
            >
              <span className="chamada">
                {t("pages.CampanhasEspeciais.HenriqueJuliano.quaseLa")}
              </span>
              <h1 className="titulo">
                {t("pages.CampanhasEspeciais.HenriqueJuliano.temosUmBeneficio")}
              </h1>
              <span>
                {t(
                  "pages.CampanhasEspeciais.HenriqueJuliano.adiantamentoEstimado"
                )}
              </span>
              <p className="valor linha-paragrafo">
                <b>
                  {signByCurrency(userArtist?.cashAdvanceEstimated?.currency)}
                </b>
                {formatNoSignObject(userArtist?.cashAdvanceEstimated)}
              </p>
              <span className="divider" />
            </div>
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(25, 25, 25, 0.00) 0%, #1A1A1A 100%)",
                height: 54,
                width: "100%",
                marginTop: -12,
              }}
            />
            <div style={{ background: "#1A1A1A", paddingTop: 12 }}>
              <ul>
                <li className="circle-checkmark azul">
                  {t(
                    "pages.CampanhasEspeciais.HenriqueJuliano.henriqueJulianoBeneficio1"
                  )}
                </li>
                <li className="circle-checkmark azul">
                  {t(
                    "pages.CampanhasEspeciais.HenriqueJuliano.henriqueJulianoBeneficio2"
                  )}
                </li>
                <li className="circle-checkmark azul">
                  {t(
                    "pages.CampanhasEspeciais.HenriqueJuliano.henriqueJulianoBeneficio3"
                  )}
                </li>
              </ul>
              <div className="buttons">
                <button className="botao-roxo" onClick={acceptWithAdvance}>
                  {t("pages.CampanhasEspeciais.queroSolicitar")}
                </button>
                <button className="botao-link" onClick={acceptWithoutAdvance}>
                  {t("pages.CampanhasEspeciais.continuarSemAdiantamento")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ModalCampaignHeJEligible;
