export enum ArtistRelationshipEnum {
  CareerManager = 0,
  Marketing = 1,
  ComercialAgent = 2,
  Producer = 3,
  Investor = 4,
  Other = 5,
  ArtistMember = 6,
  Label = 7,
  Distributor = 8,
  PublishingCompany = 9,
}

export const artistRelationshipList = [
  { label: "ArtistMember", value: ArtistRelationshipEnum.ArtistMember },
  { label: "CareerManager", value: ArtistRelationshipEnum.CareerManager },
  { label: "Investor", value: ArtistRelationshipEnum.Investor },
  { label: "Label", value: ArtistRelationshipEnum.Label },
  { label: "ComercialAgent", value: ArtistRelationshipEnum.ComercialAgent },
  { label: "Marketing", value: ArtistRelationshipEnum.Marketing },
  { label: "Producer", value: ArtistRelationshipEnum.Producer },
  { label: "Distributor", value: ArtistRelationshipEnum.Distributor },
  {
    label: "PublishingCompany",
    value: ArtistRelationshipEnum.PublishingCompany,
  },
  { label: "Other", value: ArtistRelationshipEnum.Other },
];
