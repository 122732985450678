import { Form } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined } from "@ant-design/icons";
import FieldSlider from "./FieldSlider";

const FieldTrack: React.FC<any> = (props) => {
  const { form, field } = props;
  const { t } = useTranslation();
  const tracks = form.getFieldValue("tracks");
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const track = tracks[field.name];
  if (showEdit)
    return (
      <div className="field-track">
        <Form.Item {...field} noStyle name={[field.name, "royaltyShare"]}>
          <FieldSlider />
        </Form.Item>
        <button className="botao-roxo" onClick={() => setshowEdit(false)}>
          {t("pages.Adiantamentos.salvar")}
        </button>
      </div>
    );
  return (
    <div className="linha-track">
      <span
        style={{
          flex: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {track.trackName}
      </span>
      <div style={{ width: 30, display: "flex", alignItems: "center" }}>
        <div
          style={{
            height: 2,
            width: `${track.royaltyShare}%`,
            backgroundColor: "#5779FF",
            borderRadius: "3px 0px 0px 3px",
          }}
        />
        <div
          style={{
            height: 2,
            width: `${100 - track.royaltyShare}%`,
            backgroundColor: "#2D334E",
            borderRadius: "0px 3px 3px 0px",
          }}
        />
      </div>
      <span
        style={{
          width: 50,
          textAlign: "end",
        }}
        className="ellipsis"
      >
        {`${track.royaltyShare} `}
      </span>
      <span
        style={{
          marginLeft: -8,
        }}
      >
        %
      </span>
      <button className="botao-cinza" onClick={() => setshowEdit(true)}>
        <EditOutlined />
        {t("pages.Adiantamentos.editar")}
      </button>
    </div>
  );
};

export default FieldTrack;
