import { autenticado } from "shared/helpers/auth-functions";
import { useSelector } from "react-redux";
import { RouteProps, Redirect, Route } from "react-router";
import AlternativeAutentication from "./AlternativeAutentication";
import { UserTypeEnum } from "shared/enums/UserTypeEnum";
import I18nLayout from "shared/layout/I18nLayout";
import { setRefPageLocalStorage } from "shared/helpers/ref-page";

interface RotaProps extends RouteProps {
  autenticacaoNecessaria?: boolean;
  isAdminStrmRoute?: boolean;
  component: any;
  layout: React.FC;
  path?: string;
  [x: string]: any;
}

const RotaCustomizada: React.FC<RotaProps> = (props) => {
  const {
    autenticacaoNecessaria = true,
    isAdminStrmRoute = false,
    children,
    component: Component,
    layout: Layout,
    path,
    ...rest
  } = props;
  const { loggedUser, loading } = useSelector((state: any) => state.users);
  const urlSearch = new URLSearchParams(props.location?.search);
  const referencePage: string | null = urlSearch.get("ref");

  if (referencePage) setRefPageLocalStorage(referencePage);

  if (urlSearch.has("code") && !urlSearch.has("state")) {
    return <AlternativeAutentication {...props} />;
  }

  if (autenticacaoNecessaria && !autenticado()) {
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/"
    )
      sessionStorage.setItem("returnURL", window.location.pathname);

    return <Redirect to="/login" />;
  }

  const claimGuard = loggedUser.typeAccess === UserTypeEnum.AdminStrm;

  if (!loading && isAdminStrmRoute && !claimGuard) return <Redirect to="/" />;

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <I18nLayout>
          <Layout>
            <Component {...routeProps} />
          </Layout>
        </I18nLayout>
      )}
    />
  );
};

export default RotaCustomizada;
