import { useSelector } from "react-redux";

const NewUserNotification: React.FC<any> = () => {
  const novoUsuario = useSelector((state: any) => state.auth.novoUsuario);
  if (novoUsuario)
    return (
      <iframe
        title="iframe"
        src={`${window.document.location.origin}/novo-usuario`}
        style={{ width: 0, height: 0, display: "none", visibility: "hidden" }}
      />
    );
  return null;
};
export default NewUserNotification;
