import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "antd";
import { useEffect } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ReactComponent as MusicNote } from "shared/assets/images/music-note.svg";
import FieldTrack from "./FieldTrack";

const FormTrackList: React.FC<any> = (props) => {
  const { release } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  }, [release, form]);

  return (
    <div style={{ display: release ? "block" : "none" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          color="#fff"
          onClick={() => form.submit()}
          className="icone-voltar"
          style={{ margin: 12, cursor: "pointer", alignSelf: "start" }}
        />
        <span
          onClick={() => form.resetFields()}
          style={{
            padding: "0 16px",
            color: "#5779ff",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: 6,
          }}
        >
          <SyncOutlined />
          {t("pages.Adiantamentos.desfazerEdicoes")}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 16,
          color: "#8E8E8E",
        }}
      >
        <MusicNote />
        {t("pages.Adiantamentos.porcentagemFaturamento")}
      </div>
      <Form initialValues={release} name="formTrack" form={form}>
        <Form.List name="tracks">
          {(fields) => (
            <>
              {fields.map((field: any) => (
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.tracks !== curValues.tracks
                  }
                  key={`track_${field.name}`}
                >
                  {() => {
                    return <FieldTrack field={field} form={form} />;
                  }}
                </Form.Item>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default FormTrackList;
