import { Layout } from "antd";
import { MenuLateral } from "shared/components";
import "./Layout.scss";

const { Content } = Layout;

const LayoutAdiantamento: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout className="layout-mobile layout-adiantamento">
      <MenuLateral floating />
      <Content className="mobile-content">
        <div className="mobile-content-centralizado">{children}</div>
      </Content>
    </Layout>
  );
};

export default LayoutAdiantamento;
