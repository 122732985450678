export enum AdvanceStatus {
  ReleasesSelection = 0,
  AcceptedAdvance = 1,
  SubmissionOfReport = 2,
  ReportUnderReview = 3,
  AdvanceChanged = 4,
  DocumentationForContract = 5,
  WaitingContract = 6,
  WaitingSignature = 7,
  CatalogMigration = 8,
  Disbursement = 9,
  Canceled = 10,
  InProgress = 11,
  Finished = 12,
  WaitingTakedown = 13,
  WaitingTakedownConfirmation = 14,
  DocumentationForDisbursement = 15,
  MissingMinimumRequirements = 16,
  IdentityValidation = 17,
  WaitingList = 18,
}

export const advanceStatusList = [
  { label: "Escolha dos Lançamentos", value: AdvanceStatus.ReleasesSelection },
  { label: "Aceite Adiantamento", value: AdvanceStatus.AcceptedAdvance },
  { label: "Envio do Relatório", value: AdvanceStatus.SubmissionOfReport },
  { label: "Relatório em análise", value: AdvanceStatus.ReportUnderReview },
  { label: "Alteração Adiantamento", value: AdvanceStatus.AdvanceChanged },
  {
    label: "Dados para Contrato",
    value: AdvanceStatus.DocumentationForContract,
  },
  { label: "Aguardando Contrato", value: AdvanceStatus.WaitingContract },
  { label: "Aguardando Assinatura", value: AdvanceStatus.WaitingSignature },
  { label: "Migração Catálogo", value: AdvanceStatus.CatalogMigration },
  { label: "Realizar Desembolso", value: AdvanceStatus.Disbursement },
  { label: "Cancelado", value: AdvanceStatus.Canceled },
  { label: "Adiantamento vigente", value: AdvanceStatus.InProgress },
  { label: "Finalizado", value: AdvanceStatus.Finished },
  { label: "Aguardando takedown", value: AdvanceStatus.WaitingTakedown },
  {
    label: "Aguardando confirmação do takedown",
    value: AdvanceStatus.WaitingTakedownConfirmation,
  },
  {
    label: "Dados Desembolso",
    value: AdvanceStatus.DocumentationForDisbursement,
  },
  {
    label: "Não tem os requisitos mínimos",
    value: AdvanceStatus.MissingMinimumRequirements,
  },
];
