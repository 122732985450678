import { Layout } from "antd";
import "./Layout.scss";

const { Content } = Layout;

const LayoutNaoAutenticado: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout className="layout-escuro">
      <Content>{children}</Content>
    </Layout>
  );
};

export default LayoutNaoAutenticado;
