import { useTranslation } from "react-i18next";
import "./LanguageChange.scss";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { setGenreOptionsI18n } from "explorer/reducers/generos";
import { setCountryOptionsI18n } from "explorer/reducers/countries";
import { setLoggedUserLanguage } from "explorer/reducers/users";
import { LinguagemEnum } from "shared/enums/LinguagemEnum";

interface SelectLanguageChangeProps {
  placement?: "bottomLeft" | "bottomRight" | "topLeft" | "topRight";
  dropdownStyle?: React.CSSProperties;
  width?: number;
}

const SelectLanguageChange: React.FC<SelectLanguageChangeProps> = (props) => {
  const { placement, dropdownStyle, width } = props;
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { countries } = useSelector((state: any) => state.countries);

  const setCountryOptions = useCallback(async () => {
    if (countries) {
      dispatch(setCountryOptionsI18n(i18n.language));
    }
  }, [dispatch, i18n.language, countries]);

  const setGenreOptions = useCallback(async () => {
    dispatch(setGenreOptionsI18n(i18n.language));
  }, [dispatch, i18n.language]);

  const onChangeLanguage = (lang: string) => {
    dispatch(setLoggedUserLanguage(lang));
    i18n.changeLanguage(lang);
    setGenreOptions();
    setCountryOptions();
  };

  useEffect(() => {
    setGenreOptions();
    setCountryOptions();
  }, [setGenreOptions, setCountryOptions]);

  return (
    <Select
      className="select-language"
      style={{ width: width ?? 60 }}
      onChange={onChangeLanguage}
      value={i18n?.language}
      dropdownMatchSelectWidth={false}
      placement={placement}
      dropdownStyle={dropdownStyle}
    >
      <Select.Option value={LinguagemEnum.Ingles}>EN</Select.Option>
      <Select.Option value={LinguagemEnum.Portugues}>PT</Select.Option>
      <Select.Option value={LinguagemEnum.Espanhol}>ES</Select.Option>
    </Select>
  );
};
export default SelectLanguageChange;
