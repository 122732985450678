import { ReleaseTypeEnum } from "shared/enums/ReleaseTypeEnum";

export interface Step {
  content: any;
  releaseType?: ReleaseTypeEnum;
  formListIndex?: number;
  title?: string;
}
export interface StepOrdered extends Step {
  step: number;
}

export interface FormValues {
  singles: FormValuesRelease[];
  eps: FormValuesRelease[];
  albums: FormValuesRelease[];
  aboutCreation: string;
}

export interface FormValuesRelease {
  tracksCount: number;
  tracks: FormValuesReleaseTrack[];
  releaseForecastMonth: number;
  name: string;
  context: string;
  genre: string;
  urlMusic: string;
  label: string;
  existsVideoClipe: boolean;
  urlPhotos: string;
  urlCover: string;
  urlMarketingPlaning: string;
}

export interface FormValuesReleaseTrack {
  name: string;
  isFocus: boolean;
}

export const releaseTypeFieldName: { [key in ReleaseTypeEnum]: string } = {
  [ReleaseTypeEnum.Single]: "singles",
  [ReleaseTypeEnum.EP]: "eps",
  [ReleaseTypeEnum.Album]: "albums",
};
