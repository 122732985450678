import { Col, Row, Skeleton } from "antd";

const FieldReleaseSkeleton: React.FC<any> = () => {
  return (
    <div style={{ margin: "0 16px", width: "100%" }}>
      <Row className="card-album" gutter={16}>
        <Col xs={8} className="album-imagem">
          <Skeleton.Image active />
        </Col>
        <Col xs={16}>
          <Skeleton paragraph={{ rows: 1 }} />
          <Skeleton.Button active style={{ width: 180, borderRadius: 36 }} />
        </Col>
      </Row>
    </div>
  );
};

export default FieldReleaseSkeleton;
