import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { StepProps } from "./StepProps";

const FirstStep: React.FC<StepProps> = () => {
  const { t } = useTranslation();

  return (
    <Row justify="center" gutter={16}>
      <Col span={24}>
        <p>{t("pages.Pitching.steps.first.texto")}</p>
      </Col>
    </Row>
  );
};

export default FirstStep;
