import { Button, Col, Form, InputNumber, Row } from "antd";
import {
  PlusOutlined,
  PlusCircleOutlined,
  MinusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ReactComponent as NotaIcon } from "shared/assets/images/nota-icon.svg";
import { useTranslation } from "react-i18next";
import { releaseTypeFieldName } from "../interfaces";
import { ReleaseTypeEnum } from "shared/enums/ReleaseTypeEnum";

interface FormListTypeReleaseProps {
  formListName: string;
  btnText: string;
  rangeValues: [number, number];
}
const FormListTypeRelease: React.FC<FormListTypeReleaseProps> = ({
  formListName,
  btnText,
  rangeValues,
}) => {
  return (
    <Form.List name={formListName}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name }) => (
            <div key={key}>
              <QuantitySelector
                key={key}
                name={name}
                label={btnText}
                quantityRange={rangeValues}
                remove={() => remove(name)}
              />
            </div>
          ))}
          <button
            className="botao-branco-vazado btn-add-item"
            onClick={() => {
              add({
                tracksCount: rangeValues[0],
              });
            }}
            type="button"
          >
            {btnText} <PlusCircleOutlined style={{ color: "#5A57FF" }} />
          </button>
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  );
};

interface IncrementalInputNumberProps {
  value?: number;
  onChange?: any;
  min: number;
  max: number;
}

const IncrementalInputNumber: React.FC<IncrementalInputNumberProps> = ({
  value,
  onChange,
  min,
  max,
}) => {
  const currentValue = value ?? min;

  const decrement = () => {
    if (currentValue > min) onChange(currentValue - 1);
  };

  const increment = () => {
    if (currentValue < max) onChange(currentValue + 1);
  };

  return (
    <InputNumber
      className="input-number-with-buttons"
      addonBefore={
        <Button
          onClick={decrement}
          icon={<MinusOutlined />}
          disabled={currentValue <= min}
        />
      }
      addonAfter={
        <Button
          onClick={increment}
          icon={<PlusOutlined />}
          disabled={currentValue >= max}
        />
      }
      controls={false}
      min={min}
      maxLength={max}
      value={currentValue}
      disabled
    />
  );
};

interface QuantitySelectorProps {
  name: number;
  label: string;
  remove: any;
  quantityRange: [number, number];
}

const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  name,
  label,
  quantityRange,
  remove,
}) => {
  const [minQuantity, maxQuantity] = quantityRange;

  return (
    <div className="wrapper-input-incrimental">
      <div className="label box">
        <div>
          <NotaIcon />
          {label && <span style={{ marginLeft: ".5rem" }}>{label}</span>}
        </div>
      </div>
      <div className="wrapper-input box">
        <Form.Item name={[name, "tracksCount"]} noStyle>
          <IncrementalInputNumber min={minQuantity} max={maxQuantity} />
        </Form.Item>
      </div>
      <div className="label box">
        <div>
          <DeleteOutlined className="trash-icon" onClick={() => remove()} />
        </div>
      </div>
    </div>
  );
};

const FirstStep: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row justify="center" gutter={[24, 24]}>
      <Col span={24} className="header">
        <p className="title">{t("pages.ReleasesSchedule.steps.first.title")}</p>
        <p className="subtitle">
          {t("pages.ReleasesSchedule.steps.first.subtitle")}
        </p>
      </Col>
      <Col span={24}>
        <FormListTypeRelease
          formListName={releaseTypeFieldName[ReleaseTypeEnum.Single]}
          btnText={t("pages.ReleasesSchedule.single")}
          rangeValues={[1, 1]}
        />
      </Col>
      <Col span={24}>
        <FormListTypeRelease
          formListName={releaseTypeFieldName[ReleaseTypeEnum.EP]}
          btnText={t("pages.ReleasesSchedule.ep")}
          rangeValues={[4, 50]}
        />
      </Col>
      <Col span={24}>
        <FormListTypeRelease
          formListName={releaseTypeFieldName[ReleaseTypeEnum.Album]}
          btnText={t("pages.ReleasesSchedule.album")}
          rangeValues={[7, 50]}
        />
      </Col>
    </Row>
  );
};

export default FirstStep;
