import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import languagePT from "antd/es/date-picker/locale/pt_BR";
import languageEN from "antd/es/date-picker/locale/en_US";
import languageES from "antd/es/date-picker/locale/es_ES";

export const getLocaleDatePickerByI18n = (language: string): PickerLocale => {
  switch (language) {
    case "en":
      return languageEN;

    case "pt":
      return languagePT;

    case "es":
      return languageES;
    default:
      return languagePT;
  }
};
