import { useEffect, useState } from "react";
import { Divider, Row } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import { ReactComponent as Logo } from "shared/assets/images/contrato-icon.svg";
import { ReactComponent as Clock } from "shared/assets/images/clock-eight.svg";
import { ReactComponent as ErrorIcon } from "shared/assets/images/icon-alert-triangle.svg";
import MeuAdiantamentoHeader from "./MeuAdiantamentoHeader";
import MeuAdiantamento from "./MeuAdiantamento";
import Duvidas from "./Duvidas";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { SpecialCampaignEnum } from "shared/enums/SpecialCampaignEnum";

const AdiantamentoMensagemGeral: React.FC<any> = (props) => {
  const { advanceStatus, advance, reloadCashAdvance } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [showMeuAdiantamento, setShowMeuAdiantamento] =
    useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const onClickAssinar = () => {
    if (advance?.contractTobeSignedUrl) {
      setIsActive(true);
      const newWindow = window.open(
        advance?.contractTobeSignedUrl,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    } else {
      toast.error(t("pages.Adiantamentos.erroAssinarContrato"));
    }
  };

  const onClickHome = () => {
    history.push("/");
  };

  useEffect(() => {
    let intervalId: any;

    if (isActive) {
      intervalId = setInterval(() => {
        reloadCashAdvance();
      }, 15000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, reloadCashAdvance]);

  return (
    <div
      className="flex-column-between"
      style={{
        minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
        paddingLeft: isMobile ? 0 : 94,
      }}
    >
      <div
        className="migracao"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MeuAdiantamentoHeader
          setShowMeuAdiantamento={setShowMeuAdiantamento}
          showMeuAdiantamento={showMeuAdiantamento}
        />
        <Divider />
        {showMeuAdiantamento ? (
          <MeuAdiantamento advance={advance} />
        ) : (
          <>
            <div className="confirma-adiantamento-intro">
              {advanceStatus === AdvanceStatus.MissingMinimumRequirements ||
              advanceStatus === AdvanceStatus.IdentityValidation ? (
                <ErrorIcon className="svg-amarelo" />
              ) : (
                <Logo />
              )}
              <h1>
                {advanceStatus === AdvanceStatus.WaitingContract &&
                  t("pages.Adiantamentos.confeccaoContratoTitulo")}

                {advanceStatus === AdvanceStatus.WaitingSignature &&
                  t("pages.Adiantamentos.contratoEnviadoTitulo")}

                {advanceStatus === AdvanceStatus.ReportUnderReview &&
                  t("pages.Adiantamentos.relatorioAnaliseTitulo")}

                {advanceStatus === AdvanceStatus.MissingMinimumRequirements &&
                  t(
                    "pages.Adiantamentos.adiantamentoSemRequisitosMinimosTitulo"
                  )}
                {advanceStatus === AdvanceStatus.IdentityValidation &&
                  t("pages.Adiantamentos.verificacaoIdentidadeTitulo")}
              </h1>
              <p style={{ maxWidth: isMobile ? "unset" : 650 }}>
                {advanceStatus === AdvanceStatus.WaitingContract && (
                  <Trans
                    i18nKey={t("pages.Adiantamentos.confeccaoContratoTexto")}
                  />
                )}

                {advanceStatus === AdvanceStatus.WaitingSignature && (
                  <>
                    {advance.specialCampaign ===
                      SpecialCampaignEnum.HenriqueEJuliano && (
                      <p style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <Trans
                          i18nKey={t(
                            "pages.CampanhasEspeciais.HenriqueJuliano.contratoAssinaturaDisclaimer"
                          )}
                        />
                      </p>
                    )}

                    <Trans
                      i18nKey={t("pages.Adiantamentos.contratoEnviadoTexto")}
                    />
                  </>
                )}

                {advanceStatus === AdvanceStatus.ReportUnderReview && (
                  <Trans
                    i18nKey={t("pages.Adiantamentos.relatorioAnaliseTexto")}
                  />
                )}

                {advanceStatus === AdvanceStatus.IdentityValidation && (
                  <Trans
                    i18nKey={t(
                      "pages.Adiantamentos.verificacaoIdentidadeTexto"
                    )}
                  />
                )}
                {advanceStatus === AdvanceStatus.MissingMinimumRequirements && (
                  <Trans
                    i18nKey={t(
                      "pages.Adiantamentos.adiantamentoSemRequisitosMinimosTexto"
                    )}
                  />
                )}
              </p>
              {advanceStatus === AdvanceStatus.ReportUnderReview && (
                <span className="prazo-tag">
                  <Clock />
                  {t("pages.Adiantamentos.prazoEstimado", { dias: 2 })}
                </span>
              )}
            </div>
            <Row
              justify="center"
              style={{
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
                gap: 12,
              }}
            >
              {advanceStatus === AdvanceStatus.WaitingSignature && (
                <button className="botao-azul" onClick={onClickAssinar}>
                  {t("pages.Adiantamentos.assinar")}
                </button>
              )}
              <button className="botao-borda-roxa" onClick={onClickHome}>
                {t("pages.Adiantamentos.botaoHome")}
              </button>
            </Row>
          </>
        )}
      </div>

      <div className="footer" style={{ paddingLeft: 8 }}>
        <Duvidas />
      </div>
    </div>
  );
};

export default AdiantamentoMensagemGeral;
