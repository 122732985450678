import { updatePassword } from "@aws-amplify/auth";
import { PageHeader } from "@ant-design/pro-layout";
import { Col, Form, Input, message, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const FormAlterarSenha: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish = async () => {
    const values = await form.validateFields();
    try {
      await updatePassword({
        oldPassword: values.senhaAntiga,
        newPassword: values.password,
      });

      message.success(t("pages.MeusDados.sucesso"));
      form.resetFields();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <div>
      <PageHeader title={t("pages.MeusDados.titulo")} />
      <Form
        form={form}
        onFinish={onFinish}
        style={{ color: "#fff", marginTop: 44 }}
        layout="vertical"
      >
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item
              name="senhaAntiga"
              label={t("pages.MeusDados.senhaAntiga")}
              rules={[
                {
                  required: true,
                  message: t("pages.MeusDados.senhaAntigaObrigatoria"),
                },
              ]}
            >
              <Input.Password placeholder={t("pages.MeusDados.senhaAntiga")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item
              name="password"
              label={t("pages.MeusDados.senha")}
              rules={[
                {
                  required: true,
                  message: t("pages.MeusDados.senhaObrigatoria"),
                },
              ]}
            >
              <Input.Password placeholder={t("pages.MeusDados.senha")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item
              name="confirm"
              label={t("pages.MeusDados.confirmacaoSenha")}
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t("pages.MeusDados.confirmacaoSenhaObrigatoria"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("pages.MeusDados.senhaNaoCombina"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t("pages.MeusDados.confirmacaoSenha")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12} offset={6}>
            <button className="botao-roxo" style={{ width: "100%" }}>
              {t("pages.MeusDados.alterar")}
            </button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormAlterarSenha;
