import { getRefPageLocalStorage } from "shared/helpers/ref-page";

export const dataLayerEventAcesso = (user: any) => {
  if (user) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      email: user.login,
      userId: user.cognitoUsername,
      time:
        user.userTeam?.length > 0
          ? user.userTeam[0].team?.displayName
          : "Sem time associado",

      event: "acesso",
    });
  }
};

export const dataLayerEvent = (event: string, params: any) => {
  const ref = getRefPageLocalStorage();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...params,
    ref,
  });
};
