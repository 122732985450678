import { signUp } from "@aws-amplify/auth";
import { Form, Input, Typography, Row, Col, Divider } from "antd";
import { Button, LanguageChange } from "shared/components";
import { Trans, useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import { ReactComponent as MailIcon } from "shared/assets/images/icon-mail.svg";
import { ReactComponent as LockIcon } from "shared/assets/images/icon-lock.svg";
import { ReactComponent as UserIcon } from "shared/assets/images/icon-meus-dados.svg";
import { ReactComponent as DiagnosticoIcon } from "shared/assets/images/icon-diagnostico-ready.svg";
import DrumGirl from "shared/assets/images/drum-girl-purple-tall.png";
import { registerUser } from "shared/service/StrmService";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CadastreSe.scss";
import { isMobile } from "react-device-detect";
import LoginTheme from "shared/themes/LoginTheme";
import { LinguagemEnum } from "shared/enums/LinguagemEnum";
import { toast } from "react-toastify";
import LoadingButton from "shared/components/button/LoadingButton";
import { UserEvent, saveUserEvent } from "event-tracker/track-user-helpers";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";

const CadastreSe: React.FC<RouteComponentProps> = (props) => {
  const { history, location } = props;
  const { state }: any = location;
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [urlTermsUser, setUrlTermsUser] = useState<string>("");
  const [urlPrivacyPolicy, setUrlPrivacyPolicy] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (i18n.language === LinguagemEnum.Portugues) {
      setUrlTermsUser("termos-de-uso.html");
      setUrlPrivacyPolicy("politica-de-privacidade.html");
    } else {
      setUrlTermsUser("terms-of-use.html");
      setUrlPrivacyPolicy("privacy-policy.html");
    }
  }, [i18n.language]);

  const onFinish = async () => {
    setLoading(true);
    const values = await form.validateFields();
    try {
      const user = await signUp({
        username: values.email,
        password: values.password,
        options: {
          userAttributes: {
            name: values.name,
          },
          autoSignIn: true,
        },
      });

      await registerUser({
        cognitoUsername: user.userId,
        name: values.name,
        login: values.email,
        currentLanguage: i18n.language,
      });

      toast.success(t("pages.CadastreSe.mensagemSucesso"), {
        autoClose: 10000,
      });
      history.push("/verificacao", {
        login: values.email,
      });
    } catch (error: any) {
      saveUserEvent({ name: UserEvent.SIGNUP_ERROR });
      if (error.code === "UsernameExistsException")
        toast.error(t("pages.CadastreSe.usuarioExistente"), {
          autoClose: 10000,
        });
      else if (error.code === "InvalidPasswordException")
        toast.error(t("pages.CadastreSe.criterioSenha"), { autoClose: 10000 });
      else toast.error(error.message, { autoClose: 10000 });
    } finally {
      setLoading(false);
    }
  };

  const instagramWebView = window.navigator.userAgent
    .toLowerCase()
    .includes("instagram");

  useEffect(() => {
    const urlSearch = new URLSearchParams(location.search);
    if (urlSearch.has("email")) {
      setEmail(urlSearch.get("email") || "");
      urlSearch.delete("email");
    }
  }, [setEmail, location.search]);

  useEffect(() => {
    form.resetFields();
  }, [email, form]);

  useEffect(() => {
    saveUserEvent({ name: UserEvent.S4A_SIGN_UP });
    dataLayerEvent("ScreenViewed", {
      ...quizEvents.S4A_SIGN_UP,
    });
  }, []);

  return (
    <LoginTheme>
      <Row className="login cadastre-se" style={{ paddingTop: 32 }}>
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <LanguageChange.SelectLanguageChange />
          </div>
        )}
        <Col
          xs={isMobile ? 24 : 10}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="container" style={{ padding: isMobile ? 12 : 0 }}>
            <Row className="fedarated-buttons" gutter={18}>
              <Col xs={24}>
                <div className="logo-region">
                  <Logo
                    className="logo"
                    style={{ width: isMobile ? 80 : 100 }}
                  />
                  <span style={{ fontSize: isMobile ? 14 : 28 }}>
                    for artists
                  </span>
                </div>
              </Col>
              {isMobile && (
                <p className="mobile-copy">
                  <Trans i18nKey="pages.Login.exploreSuaCarreiraMobile" />
                </p>
              )}
              <Col span={24}>
                {email || state?.quiz ? (
                  <>
                    <DiagnosticoIcon />
                    <Typography.Title
                      className="text login-title"
                      style={{ fontSize: isMobile ? 18 : 26, marginTop: 16 }}
                    >
                      {t("pages.CadastreSe.tituloCampanha")}
                    </Typography.Title>
                    <Typography.Title
                      className="text login-subtitle"
                      style={{
                        fontSize: isMobile ? 16 : 18,
                        margin: 0,
                        marginBottom: 16,
                      }}
                    >
                      {t("pages.CadastreSe.textoCampanha")}
                    </Typography.Title>
                  </>
                ) : (
                  <>
                    <Typography.Title className="text login-title">
                      {t("pages.CadastreSe.titulo")}
                    </Typography.Title>
                    <Typography.Text className="text login-subtitle">
                      {t("pages.Login.texto")}
                    </Typography.Text>
                  </>
                )}
                <Typography.Text className="text social">
                  {t("pages.CadastreSe.socialText")}
                </Typography.Text>
              </Col>
              <Col xs={12} hidden={instagramWebView}>
                <Button.Google
                  onClick={() => {
                    dataLayerEvent("ClickButton", {
                      ...quizEvents.S4A_SIGN_UP,
                      buttonName: "LOGIN_GOOGLE",
                    });
                  }}
                />
              </Col>
              <Col span={instagramWebView ? 24 : 12}>
                <Button.Facebook
                  onClick={() => {
                    dataLayerEvent("ClickButton", {
                      ...quizEvents.S4A_SIGN_UP,
                      buttonName: "LOGIN_FACEBOOK",
                    });
                  }}
                />
              </Col>
            </Row>
            <Divider className="divider" style={{ margin: "12px 0" }}>
              {t("pages.Login.ou")}
            </Divider>
            <Row>
              <Col span={24}>
                <Form
                  layout={"vertical"}
                  form={form}
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{ email }}
                >
                  <div className="content" style={{ marginBottom: 12 }}>
                    <div className="input">
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t("pages.CadastreSe.nameRequired"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("pages.CadastreSe.nome")}
                          prefix={<UserIcon className="icon-color" />}
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t("pages.CadastreSe.emailRequired"),
                          },
                          {
                            type: "email",
                            message: t("pages.CadastreSe.emailValido"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("pages.CadastreSe.email")}
                          disabled={!!email}
                          prefix={<MailIcon />}
                          size="large"
                        />
                      </Form.Item>

                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t("pages.CadastreSe.passwordRequired"),
                          },
                        ]}
                        extra={t("pages.CadastreSe.criterioSenha")}
                      >
                        <Input.Password
                          placeholder={t("pages.CadastreSe.senha")}
                          size="large"
                          prefix={<LockIcon />}
                        />
                      </Form.Item>
                      <Form.Item
                        name="confirm"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: t("pages.CadastreSe.confirmRequired"),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(t("pages.CadastreSe.senhaNaoCombina"))
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder={t("pages.CadastreSe.confirmaSenha")}
                          prefix={<LockIcon />}
                          size="large"
                        />
                      </Form.Item>
                    </div>
                    <div className="paragrafo-termos">
                      <Trans
                        i18nKey="pages.CadastreSe.mensagemTermos"
                        components={{
                          a: (
                            <a
                              href={`/${urlTermsUser}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                            </a>
                          ),
                          ab: (
                            <a
                              href={`/${urlPrivacyPolicy}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                            </a>
                          ),
                        }}
                      />
                    </div>
                    <div className="enter-button">
                      <LoadingButton
                        className="botao-roxo"
                        loading={loading}
                        onClick={() => {
                          dataLayerEvent("ClickButton", {
                            ...quizEvents.S4A_SIGN_UP,
                            buttonName: "REGISTER",
                          });
                        }}
                      >
                        {t("pages.CadastreSe.cadastrar")}
                      </LoadingButton>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
            <Row className="row-register">
              <Col span={24}>
                <div className="text-register back-login">
                  {t("pages.CadastreSe.possuiCadastro")}
                  <Link
                    style={{ marginLeft: 6 }}
                    to="/login"
                    onClick={() => {
                      saveUserEvent({ name: UserEvent.SIGNUP_HAS_ACCOUNT });
                      dataLayerEvent("ClickButton", {
                        ...quizEvents.S4A_SIGN_UP,
                        buttonName: "LOGIN",
                      });
                    }}
                  >
                    Login
                  </Link>
                </div>
              </Col>
            </Row>
            <span
              style={{
                display: "flex",
                borderBottom: "1px solid #272727",
                marginTop: 22,
              }}
            />

            <span className="outras-plataformas-titulo">
              {t("pages.Login.acesseOutraPlataforma")}
            </span>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href={process.env.REACT_APP_STRM_INVESTOR_URL}
                className="outras-plataformas"
                onClick={() =>
                  saveUserEvent({ name: UserEvent.SIGNUP_LEAVE_LABELS })
                }
              >
                <span>
                  <Logo style={{ width: 67 }} />
                  for Labels
                </span>
              </a>
            </div>
          </div>
        </Col>
        <Col xs={14} hidden={isMobile}>
          <div className="caixa-copy">
            <div className="copy-content">
              {!isMobile && <LanguageChange.SelectLanguageChange />}
              <img src={DrumGirl} alt="girl playing drum" />
              <p className="copy">{t("pages.Login.exploreSuaCarreira")}</p>
            </div>
          </div>
        </Col>
      </Row>
    </LoginTheme>
  );
};
export default CadastreSe;
