import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CashAdvanceAvailableEnum } from "shared/enums/CashAdvanceAvailable";
import { formatNoSignObject, signByCurrency } from "shared/helpers/BRLFormat";
import { ReactComponent as DistroIcon } from "shared/assets/images/icon-distro-colorido.svg";
import { ReactComponent as SpotifyIcon } from "shared/assets/images/icon-spotify.svg";
import { ReactComponent as AppleMusicIcon } from "shared/assets/images/icon-apple-music.svg";
import { ReactComponent as AmazonMusicIcon } from "shared/assets/images/icon-amazon-music.svg";
import { ReactComponent as YoutubeIcon } from "shared/assets/images/icon-youtube.svg";
import { ReactComponent as DeezerIcon } from "shared/assets/images/icon-deezer.svg";
import { ReactComponent as TidalIcon } from "shared/assets/images/icon-tidal.svg";
import { ReactComponent as SpikeArrow } from "shared/assets/images/spike-arrow.svg";
import {
  WhatsAppContactAdvance,
  WhatsAppContactDistro,
} from "shared/helpers/whatsapp-contact";
import { canAskAdvance } from "explorer/reducers/adiantamentos";

const CardCTAPrincipal: React.FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { userArtist } = useSelector((state: any) => state.artists);
  const { cashAdvanceAvailable } = useSelector((state: any) => state.advances);
  const { loggedUser } = useSelector((state: any) => state.users);

  const goRouteScrollTop = (route: string) => {
    history.push(route);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onOkModalDistro = async () => {
    history.push("/distribua");
  };

  const mensagemSemAdiantamento = () => {
    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotAvailable)
      return t("pages.Adiantamentos.naoCumpreRequisitosMensagemSimples");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NeedRetry)
      return t("pages.Adiantamentos.tentarNovamenteEmbreve");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotFound)
      return t("pages.Adiantamentos.spotifyNaoEncontrado");
  };

  if (canAskAdvance(cashAdvanceAvailable) || loggedUser?.isCashAdvanceAvailable)
    return (
      <div className="caixa-adiantamento padding">
        <div className="background" />
        <div className="container-linhas">
          <div className="linha linha-paragrafo">
            <p className="branco">
              <Trans
                i18nKey="pages.Adiantamentos.adiante5Anos"
                components={{ span: <span className="cor-destaque" /> }}
              />
            </p>
          </div>
          <div className="linha linha-paragrafo">
            <p className="valor">
              <b>
                {signByCurrency(userArtist?.cashAdvanceEstimated?.currency)}
              </b>
              {formatNoSignObject({
                ...userArtist?.cashAdvanceEstimated,
                amount:
                  userArtist?.cashAdvanceEstimated?.amount /
                  userArtist?.cashAdvanceEstimated?.trancheFactor,
              })}
              {userArtist?.cashAdvanceEstimated?.trancheFactor > 1 && (
                <>
                  {t("pages.Adiantamentos.ate")}
                  <b>
                    {signByCurrency(userArtist?.cashAdvanceEstimated?.currency)}
                  </b>
                  {formatNoSignObject(userArtist?.cashAdvanceEstimated)}
                </>
              )}
              <span className="disclaimer-dot">*</span>
            </p>
          </div>
          <div className={`linha actions`}>
            <button
              className="botao-roxo botao-adiantamento-desktop"
              onClick={() => goRouteScrollTop("/adiantamentos")}
            >
              {t("pages.Adiantamentos.queroSolicitar")}
            </button>
            <button className="botao-link botao-tirar-duvidas">
              <a
                target="_blank"
                href={WhatsAppContactAdvance}
                rel="noopener noreferrer"
              >
                {t("pages.Adiantamentos.tirarDuvidas")}
              </a>
            </button>
          </div>

          <div className={`linha disclaimer`}>
            <p className="roxo">
              {t("pages.Adiantamentos.valoresSujeitoAlteracoes")}
            </p>
          </div>
        </div>
      </div>
    );

  if (userArtist?.distributionAvailable && !userArtist?.distributionSigned)
    return (
      <div className="caixa-distro">
        <span className="branco">
          <DistroIcon /> {t("pages.EstagioArtista.distoLiberadoTitulo")}
        </span>
        <div className="botoes-icones">
          <div className="icones">
            <AmazonMusicIcon />
            <SpotifyIcon />
            <YoutubeIcon />
            <TidalIcon />
            <AppleMusicIcon />
            <DeezerIcon />
          </div>
        </div>
        <div className="content-text">
          <SpikeArrow
            style={{
              position: "absolute",
              opacity: 0.5,
              top: -116,
              right: -29,
              width: "100%",
            }}
          />
          <ul>
            <li className="circle-checkmark azul">
              {t("pages.EstagioArtista.distroAcessoAoLabels")}
            </li>
            <li className="circle-checkmark azul">
              {t("pages.EstagioArtista.distroIsencaoTaxaUpload")}
            </li>
            <li className="circle-checkmark azul">
              {t("pages.EstagioArtista.distrosMigracaoCatalogo")}
            </li>
          </ul>
          <div className="background" />
        </div>
        <div
          style={{
            margin: "24px 60px",
            display: "flex",
            flexDirection: "column",
            gap: 12,
            zIndex: 10,
            position: "relative",
          }}
        >
          <button className="botao-roxo" onClick={onOkModalDistro}>
            {t("pages.EstagioArtista.distribuaComStrm")}
          </button>
          <button className="botao-borda-roxa">
            <a
              target="_blank"
              href={WhatsAppContactDistro}
              rel="noopener noreferrer"
            >
              {t("pages.EstagioArtista.saibaMais")}
            </a>
          </button>
        </div>
      </div>
    );

  return (
    <div className="caixa-adiantamento padding">
      <div className="background" />
      <span className="branco">
        {t("pages.EstagioArtista.adiantamentoNaoLiberadoTitulo")}
      </span>
      <p className="roxo">
        <Trans i18nKey={mensagemSemAdiantamento()} />
      </p>
      <button
        className="botao-roxo"
        onClick={() => goRouteScrollTop("/adiantamentos")}
      >
        {t("pages.EstagioArtista.saibaMais")}
      </button>
    </div>
  );
};

export default CardCTAPrincipal;
