import { Col, Collapse, Row } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { moneyFormatObject } from "shared/helpers/BRLFormat";
import LogoStrm from "shared/assets/images/strm-logo-verde-gradient.png";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Clock } from "shared/assets/images/clock-eight.svg";
import Duvidas from "./Duvidas";
import { WhatsAppContactConsultor } from "shared/helpers/whatsapp-contact";
import { CashAdvanceAvailableEnum } from "shared/enums/CashAdvanceAvailable";
import { getSpecialCampaign } from "shared/helpers/special-campaign-helpers";
import { SpecialCampaignEnum } from "shared/enums/SpecialCampaignEnum";
import { ClientSpecialCampaignApprovalEnum } from "shared/enums/ClientSpecialCampaignApprovalEnum";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";
import { canAskAdvance } from "explorer/reducers/adiantamentos";

interface AdiantamentoInicioProps {
  createAdvance: any;
}
const AdiantamentoInicio: React.FC<AdiantamentoInicioProps> = (props) => {
  const { createAdvance } = props;
  const { t } = useTranslation();
  const { loggedUser } = useSelector((state: any) => state.users);
  const noQuiz = loggedUser?.userArtists?.length < 1;

  const { userArtist, loading } = useSelector((state: any) => state.artists);
  const { cashAdvanceAvailable, loading: advanceLoading } = useSelector(
    (state: any) => state.advances
  );
  const specialCampaign = getSpecialCampaign(
    userArtist?.specialCampaign,
    SpecialCampaignEnum.HenriqueEJuliano
  );

  const onClickIniciaProcesso = async () => {
    createAdvance(true);
    dataLayerEvent("ClickButton", {
      ...quizEvents.S4A_ADVANCE_INDEX,
      buttonName: "REQUEST",
      carreerStage: userArtist?.stage,
      approvedLimit: userArtist?.cashAdvanceEstimated?.amountUsd,
      alreadyCalculated:
        userArtist?.cashAdvanceEstimated?.successfullyEstimated,
      country: userArtist?.country,
    });
  };

  const mensagemSemAcesso = () => {
    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotAvailable)
      return t("pages.Adiantamentos.naoCumpreRequisitosMensagem");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NeedRetry)
      return t("pages.Adiantamentos.tentarNovamenteEmbreve");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotFound)
      return t("pages.Adiantamentos.spotifyNaoEncontrado");

    if (!loggedUser?.cashAdvanceFeatureAvailable)
      return t("pages.Adiantamentos.funcionalidadeNaoDisponivel");

    if (noQuiz) return t("pages.Adiantamentos.semArtista");

    if (!userArtist?.spotifyId)
      return t("pages.Adiantamentos.artistaSemSpotify");

    if (specialCampaign?.approval === ClientSpecialCampaignApprovalEnum.Pending)
      return t("pages.Adiantamentos.campanhaEspecialPendente");
  };

  const carregando = () => loading || advanceLoading;

  const permissaoBotaoCTA = () => {
    const byPassAdiantamentoUsuario = !!loggedUser?.isCashAdvanceAvailable;
    const funcionalideDisponivel = !!loggedUser?.cashAdvanceFeatureAvailable;
    const artistaElegivel = canAskAdvance(cashAdvanceAvailable);
    const campanhaEspecialBloqueada =
      specialCampaign?.approval === ClientSpecialCampaignApprovalEnum.Pending;

    return (
      byPassAdiantamentoUsuario ||
      (funcionalideDisponivel && artistaElegivel && !campanhaEspecialBloqueada)
    );
  };

  const faqList: any[] = t("pages.Adiantamentos.faqInicio");

  return (
    <div className={`adiantamentos inicio ${!isMobile && "web"}`}>
      <div className="header">
        <div className="logo-container">
          <img src={LogoStrm} alt="" />
        </div>
        <h1 className="title">{t("pages.Adiantamentos.titulo")}</h1>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: isMobile ? "unset" : 650,
          }}
        >
          <Row gutter={[0, 0]}>
            <Col xs={20} className={`branco`} style={{ marginTop: 48 }}>
              <h3 className="apresentacao">
                <b>
                  <Trans i18nKey={t(`pages.Adiantamentos.apresentacao`)} />
                </b>
              </h3>
              <p className="paragrafo-com-verde">
                <Trans i18nKey={t(`pages.Adiantamentos.texto`)} />
              </p>
            </Col>
            {(loggedUser?.isCashAdvanceAvailable ||
              canAskAdvance(cashAdvanceAvailable) ||
              carregando()) && (
              <Col xs={20} className="branco">
                <h2
                  style={{
                    fontWeight: 400,
                    margin: 0,
                    fontSize: 14,
                    marginBottom: ".6rem",
                  }}
                >
                  <Trans
                    i18nKey={
                      loading || advanceLoading
                        ? t("pages.Adiantamentos.calculandoValores")
                        : t("pages.Adiantamentos.valorEstimado")
                    }
                  />
                </h2>

                <div className={`box-money ${noQuiz && "blur"}`}>
                  <span
                    className={`green money-format ${
                      loading || advanceLoading ? "loading" : ""
                    }`}
                  >
                    {moneyFormatObject({
                      ...userArtist?.cashAdvanceEstimated,
                      amount:
                        userArtist?.cashAdvanceEstimated?.amount /
                        userArtist?.cashAdvanceEstimated?.trancheFactor,
                    })}
                  </span>
                </div>

                <h2
                  style={{
                    fontWeight: 100,
                    margin: 0,
                    fontSize: 14,
                    marginTop: ".6rem",
                  }}
                >
                  {t("pages.Adiantamentos.disponibilizadoAvista")}
                </h2>
                {userArtist?.cashAdvanceEstimated?.trancheFactor > 1 && (
                  <>
                    <h2
                      style={{
                        fontWeight: 900,
                        margin: 0,
                        fontSize: 14,
                        marginTop: "1.3rem",
                      }}
                    >
                      {t("pages.Adiantamentos.preAprovadoAte")}
                    </h2>
                    {(loggedUser?.isCashAdvanceAvailable ||
                      canAskAdvance(cashAdvanceAvailable) ||
                      carregando()) && (
                      <div
                        className={`box-money ${noQuiz && "blur"}`}
                        style={{ marginTop: ".6rem" }}
                      >
                        <span
                          className={`green money-format ${
                            loading || advanceLoading ? "loading" : ""
                          }`}
                        >
                          {moneyFormatObject(userArtist?.cashAdvanceEstimated)}
                        </span>
                      </div>
                    )}
                    <h2
                      style={{
                        fontWeight: 400,
                        margin: 0,
                        fontSize: 14,
                        marginTop: ".6rem",
                      }}
                    >
                      <Trans
                        i18nKey={t("pages.EstagioArtista.recebaEsteValorAnual")}
                      />
                    </h2>
                  </>
                )}
                {permissaoBotaoCTA() && (
                  <div style={{ marginTop: "1.3rem" }}>
                    <button
                      className="botao-roxo"
                      disabled={loading || advanceLoading}
                      onClick={onClickIniciaProcesso}
                    >
                      {t("pages.Adiantamentos.cta")}
                    </button>
                  </div>
                )}
              </Col>
            )}
          </Row>
          <Row>
            <Col
              xs={24}
              style={{
                margin: ".6rem 0",
              }}
            >
              {permissaoBotaoCTA() ? (
                <>
                  <p className="paragrafo-com-verde">
                    {t("pages.Adiantamentos.outroAdiantamento")}
                  </p>
                  <a
                    target="_blank"
                    href={WhatsAppContactConsultor}
                    rel="noopener noreferrer"
                    style={{
                      color: "#00BDDC",
                      textDecorationLine: "underline",
                    }}
                  >
                    {t("pages.Adiantamentos.faleConsultores")}
                  </a>
                </>
              ) : (
                <div className="pending-info">
                  <Clock style={{ flex: 2 }} />
                  <span style={{ flex: 20 }}>
                    <Trans i18nKey={mensagemSemAcesso()} />
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row className="perks">
            <ul>
              <li
                className="circle-checkmark roxo"
                style={{ color: "#CECDFF" }}
              >
                {t("pages.Adiantamentos.adiantamentoInicioHighlightUm")}
              </li>
              <li
                className="circle-checkmark roxo"
                style={{ color: "#CECDFF" }}
              >
                {t("pages.Adiantamentos.adiantamentoInicioHighlightDois")}
              </li>
              <li
                className="circle-checkmark roxo"
                style={{ color: "#CECDFF" }}
              >
                {t("pages.Adiantamentos.adiantamentoInicioHighlightTres")}
              </li>
            </ul>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{
                marginTop: 32,
              }}
            >
              <h3 className="apresentacao">
                <Trans i18nKey={"pages.Adiantamentos.stepsTitle"} />
              </h3>
              <ol className="lista-passos">
                <li>
                  <span>
                    <Trans i18nKey={t("pages.Adiantamentos.stepUm")} />
                  </span>
                </li>
                <li>
                  <span>
                    <Trans i18nKey={t("pages.Adiantamentos.stepDois")} />
                  </span>
                </li>
                <li>
                  <span>
                    <Trans i18nKey={t("pages.Adiantamentos.stepTres")} />
                  </span>
                </li>
                <li>
                  <span>
                    <Trans i18nKey={t("pages.Adiantamentos.stepQuatro")} />
                  </span>
                </li>
                <li>
                  <span>
                    <Trans i18nKey={t("pages.Adiantamentos.stepCinco")} />
                  </span>
                </li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{
                marginTop: 26,
                marginBottom: 50,
              }}
            >
              {permissaoBotaoCTA() && (
                <button
                  className="botao-roxo"
                  disabled={loading}
                  onClick={onClickIniciaProcesso}
                >
                  {t("pages.Adiantamentos.cta")}
                </button>
              )}
            </Col>
          </Row>
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{ display: "block", marginBottom: 24, color: "#F2F2F2" }}
              >
                {t("pages.Adiantamentos.aindaComDuvidasFAQ")}
              </span>
              <span
                style={{ display: "blick", marginBottom: 12, color: "#F2F2F2" }}
              >
                {t("pages.Adiantamentos.faqTitle")}
              </span>
            </div>
            <Collapse
              bordered={false}
              className="faq"
              expandIcon={() => <FontAwesomeIcon icon={faChevronDown} />}
              expandIconPosition="end"
            >
              {faqList?.map((faq, i) => (
                <Collapse.Panel
                  header={t(`pages.Adiantamentos.faq.${faq}.pergunta`)}
                  key={i}
                >
                  <p>
                    <Trans
                      i18nKey={t(`pages.Adiantamentos.faq.${faq}.resposta`)}
                    />
                  </p>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{
                margin: "45px 0",
              }}
            >
              {permissaoBotaoCTA() && (
                <button
                  className="botao-roxo"
                  disabled={loading}
                  onClick={onClickIniciaProcesso}
                >
                  {t("pages.Adiantamentos.cta")}
                </button>
              )}
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: 32 }} className="footer">
            <Duvidas />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AdiantamentoInicio;
