import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Artist } from "model/Artist";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as EstrelaIcon } from "shared/assets/images/icon-estrela.svg";
import ButtonRemakeQuiz from "shared/components/button-remake-quiz/ButtonRemakeQuiz";

interface EstagioArtistaMenuProps {
  setSecao: (secao: "inicio" | "carreira" | "estrategico") => void;
  secao: "inicio" | "carreira" | "estrategico";
  artist?: Artist;
}

const sectionMap = {
  inicio: "inicio",
  carreira: "minhaCarreira",
  estrategico: "planoEstrategico",
};

const EstagioArtistaMenu: React.FC<EstagioArtistaMenuProps> = (props) => {
  const { secao, setSecao } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [opened, setOpened] = useState<boolean>(false);

  const getButtonClass = (value: "inicio" | "carreira" | "estrategico") => {
    return secao === value ? "active" : "";
  };

  const renderButton = (key: "inicio" | "carreira" | "estrategico") => (
    <button
      key={key}
      className={`${getButtonClass(key)} botao-link`}
      onClick={() => {
        setSecao(key);
        setOpened(false);
      }}
    >
      {t(`pages.EstagioArtista.${sectionMap[key]}`)}
    </button>
  );

  if (isMobile)
    return (
      <div className="menu-adiantamento">
        <div className={`selected ${opened && "opened"}`}>
          {renderButton(secao)}
          <FontAwesomeIcon
            className="icon-open-menu"
            icon={opened ? faChevronUp : faChevronDown}
            onClick={() => setOpened(!opened)}
          />
        </div>

        {opened && (
          <div className="list">
            {Object.keys(sectionMap)
              .filter((key) => key !== secao)
              .map((key) =>
                renderButton(key as "inicio" | "carreira" | "estrategico")
              )}

            <div className="diagnostico">
              <div
                style={{ display: "flex", gap: 12 }}
                onClick={() => history.push("/diagnosticos")}
              >
                {t("pages.EstagioArtista.diagnosticoCompleto")} <EstrelaIcon />
              </div>
              <ButtonRemakeQuiz />
            </div>
          </div>
        )}
      </div>
    );

  return (
    <div className="menu-adiantamento">
      <button
        className={`${getButtonClass("inicio")} button`}
        onClick={() => setSecao("inicio")}
      >
        {t("pages.EstagioArtista.inicio")}
      </button>
      <button
        className={`${getButtonClass("carreira")} button`}
        onClick={() => setSecao("carreira")}
      >
        {t("pages.EstagioArtista.minhaCarreira")}
      </button>
      <button
        className={`${getButtonClass("estrategico")} button`}
        onClick={() => setSecao("estrategico")}
      >
        {t("pages.EstagioArtista.planoEstrategico")}
      </button>
      <button className="button" onClick={() => history.push("/diagnosticos")}>
        {t("pages.EstagioArtista.diagnosticoCompleto")} <EstrelaIcon />
      </button>
      <div className="buttons-end">
        <ButtonRemakeQuiz />
      </div>
    </div>
  );
};
export default EstagioArtistaMenu;
