import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import { ReactComponent as CashUp } from "shared/assets/images/cash-up.svg";
import { Trans, useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { changeStatusCashAdvanceProcess } from "shared/service/StrmService";
import { useHistory } from "react-router-dom";

interface Props {
  advance: any;
  reloadCashAdvance: () => void;
}

const AdiantamentoTakeDownPagamento: React.FC<Props> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const onClickTakeDownSolicitado = async () => {
    await changeStatusCashAdvanceProcess(
      advance.id,
      AdvanceStatus.WaitingTakedownConfirmation
    );
    reloadCashAdvance();
  };
  return (
    <div className="confirma-adiantamento-intro">
      {advance?.status === AdvanceStatus.Disbursement && <CashUp />}

      {advance?.status === AdvanceStatus.WaitingTakedown && (
        <>
          <h1 style={{ margin: "32px 32px 0 32px" }}>
            <Trans i18nKey="pages.Adiantamentos.adiantamentoAguardandoTakeDownTitulo" />
          </h1>
          <p style={{ color: "#F2F2F2" }} className="italic-roxo">
            <Trans i18nKey="pages.Adiantamentos.adiantamentoAguardandoTakeDownText" />
          </p>
          <div style={{ marginTop: isMobile ? 20 : 100 }}>
            <button className="botao-roxo" onClick={onClickTakeDownSolicitado}>
              {t("pages.Adiantamentos.soliciteTakeDown")}
            </button>
          </div>
          <div>
            <p
              className="italic-roxo"
              style={{
                color: "#E6E6E6",
                marginTop: isMobile ? 20 : 100,
                fontSize: 14,
              }}
            >
              <i>*</i>
              {t("pages.Adiantamentos.takedownDisclaimer")}
            </p>
          </div>
        </>
      )}

      {advance?.status === AdvanceStatus.WaitingTakedownConfirmation && (
        <>
          <h1 style={{ margin: "32px 32px 0 32px" }}>
            <Trans i18nKey="pages.Adiantamentos.adiantamentoConfirmacaoTakeDownTitulo" />
          </h1>
          <p>
            <Trans i18nKey="pages.Adiantamentos.adiantamentoConfirmacaoTakeDownText" />
          </p>
        </>
      )}

      {advance?.status === AdvanceStatus.Disbursement && (
        <>
          <h1 style={{ margin: "32px 32px 0 32px" }}>
            <Trans i18nKey="pages.Adiantamentos.tudoProntoTitulo" />
          </h1>
          <p>{t("pages.Adiantamentos.tudoProntoText")}</p>
          <div style={{ marginTop: 100 }}>
            <button
              className="botao-borda-roxa"
              onClick={() => history.push("/")}
            >
              {t("pages.Adiantamentos.botaoHome")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AdiantamentoTakeDownPagamento;
