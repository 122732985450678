import Back from "./Back";
import Color from "./Color";
import Default from "./Default";
import DefaultImage from "./DefaultImage";
import Facebook from "./Facebook";
import Google from "./Google";
import Instagram from "./Instagram";
import LoadingButton from "./LoadingButton";

const pack = {
  Default,
  DefaultImage,
  Color,
  Facebook,
  Google,
  Back,
  Instagram,
  LoadingButton,
};
export default pack;
