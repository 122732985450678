const revelatorGenres = [
  { label: "African", value: "outro-african" },
  { label: "Afro House", value: "outro-afro-house" },
  { label: "Afro Soul", value: "outro-afro-soul" },
  { label: "Benga", value: "outro-benga" },
  { label: "Bongo-Flava", value: "outro-bongo-flava" },
  { label: "Coupé-Décalé", value: "outro-coup-dcal" },
  { label: "Gqom", value: "outro-gqom" },
  { label: "Highlife", value: "outro-highlife" },
  { label: "Kizomba", value: "outro-kizomba" },
  { label: "Kuduro", value: "outro-kuduro" },
  { label: "Kwaito", value: "outro-kwaito" },
  { label: "Maskandi", value: "outro-maskandi" },
  { label: "Mbalax", value: "outro-mbalax" },
  { label: "Ndombolo", value: "outro-ndombolo" },
  { label: "Shangaan Electro", value: "outro-shangaan-electro" },
  { label: "Soukous", value: "outro-soukous" },
  { label: "Taarab", value: "outro-taarab" },
  { label: "Zouglou", value: "outro-zouglou" },
  { label: "College Rock", value: "outro-college-rock" },
  { label: "EMO", value: "outro-emo" },
  { label: "Goth Rock", value: "outro-goth-rock" },
  { label: "Grunge", value: "outro-grunge" },
  { label: "Indie Pop", value: "outro-indie-pop" },
  { label: "Indie Rock", value: "outro-indie-rock" },
  { label: "New Wave", value: "outro-new-wave" },
  { label: "Pop Punk", value: "outro-pop-punk" },
  { label: "Punk", value: "outro-punk" },
  { label: "Anime", value: "outro-anime" },
  { label: "Arabic", value: "outro-arabic" },
  { label: "Arabic Pop", value: "outro-arabic-pop" },
  { label: "Islamic", value: "outro-islamic" },
  { label: "Khaleeji", value: "outro-khaleeji" },
  { label: "North African", value: "outro-north-african" },
  { label: "Acoustic Blues", value: "outro-acoustic-blues" },
  { label: "Chicago Blues", value: "outro-chicago-blues" },
  { label: "Classic Blues", value: "outro-classic-blues" },
  { label: "Contemporary Blues", value: "outro-contemporary-blues" },
  { label: "Country Blues", value: "outro-country-blues" },
  { label: "Delta Blues", value: "outro-delta-blues" },
  { label: "Electric Blues", value: "outro-electric-blues" },
  { label: "Brazilian", value: "outro-brazilian" },
  { label: "Baile Funk", value: "outro-baile-funk" },
  { label: "Bossa Nova", value: "outro-bossa-nova" },
  { label: "Choro", value: "outro-choro" },
  { label: "Frevo", value: "outro-frevo" },
  { label: "Pagode", value: "outro-pagode" },
  { label: "Children's Music", value: "outro-childrens-music" },
  { label: "Lullabies", value: "outro-lullabies" },
  { label: "Sing-Along", value: "outro-sing-along" },
  { label: "Stories", value: "outro-stories" },
  { label: "Chinese", value: "outro-chinese" },
  { label: "Cantopop", value: "outro-cantopop" },
  { label: "Chinese Alt", value: "outro-chinese-alt" },
  { label: "Chinese Classical", value: "outro-chinese-classical" },
  { label: "Chinese Flute", value: "outro-chinese-flute" },
  { label: "Chinese Hip-Hop", value: "outro-chinese-hip-hop" },
  { label: "Chinese Opera", value: "outro-chinese-opera" },
  { label: "Chinese Orchestral", value: "outro-chinese-orchestral" },
  { label: "Chinese Regional Folk", value: "outro-chinese-regional-folk" },
  { label: "Chinese Rock", value: "outro-chinese-rock" },
  { label: "Chinese Strings", value: "outro-chinese-strings" },
  { label: "Taiwanese Folk", value: "outro-taiwanese-folk" },
  { label: "Tibetan Native Music", value: "outro-tibetan-native-music" },
  { label: "CCM", value: "outro-ccm" },
  { label: "Christian Metal", value: "outro-christian-metal" },
  { label: "Christian Pop", value: "outro-christian-pop" },
  { label: "Christian Rap", value: "outro-christian-rap" },
  { label: "Christian Rock", value: "outro-christian-rock" },
  { label: "Classic Christian", value: "outro-classic-christian" },
  { label: "Praise & Worship", value: "outro-praise-worship" },
  { label: "Art Song", value: "outro-art-song" },
  { label: "Avant-Garde", value: "outro-avant-garde" },
  { label: "Baroque Era", value: "outro-baroque-era" },
  { label: "Brass & Woodwinds", value: "outro-brass-woodwinds" },
  { label: "Cantata", value: "outro-cantata" },
  { label: "Cello", value: "outro-cello" },
  { label: "Chamber Music", value: "outro-chamber-music" },
  { label: "Chant", value: "outro-chant" },
  { label: "Choral", value: "outro-choral" },
  { label: "Classical Crossover", value: "outro-classical-crossover" },
  { label: "Classical Era", value: "outro-classical-era" },
  { label: "(Classical) Electronic", value: "outro-classical-electronic" },
  { label: "(Classical) Opera", value: "outro-classical-opera" },
  { label: "(Classical) Orchestral", value: "outro-classical-orchestral" },
  { label: "Contemporary Era", value: "outro-contemporary-era" },
  { label: "Early Music", value: "outro-early-music" },
  { label: "Guitar", value: "outro-guitar" },
  { label: "Impressionist", value: "outro-impressionist" },
  { label: "Medieval Era", value: "outro-medieval-era" },
  { label: "Minimalism", value: "outro-minimalism" },
  { label: "Modern Era", value: "outro-modern-era" },
  { label: "Oratorio", value: "outro-oratorio" },
  { label: "Percussion", value: "outro-percussion" },
  { label: "Piano", value: "outro-piano" },
  { label: "Renaissance", value: "outro-renaissance" },
  { label: "Romantic Era", value: "outro-romantic-era" },
  { label: "Sacred", value: "outro-sacred" },
  { label: "Solo Instrumental", value: "outro-solo-instrumental" },
  { label: "Violin", value: "outro-violin" },
  { label: "Wedding Music", value: "outro-wedding-music" },
  { label: "Comedy", value: "outro-comedy" },
  { label: "Novelty", value: "outro-novelty" },
  { label: "Standup Comedy", value: "outro-standup-comedy" },
  { label: "Alternative Country", value: "outro-alternative-country" },
  { label: "Americana", value: "outro-americana" },
  { label: "Bluegrass", value: "outro-bluegrass" },
  { label: "Contemporary Bluegrass", value: "outro-contemporary-bluegrass" },
  { label: "Contemporary Country", value: "outro-contemporary-country" },
  { label: "Honky Tonk", value: "outro-honky-tonk" },
  { label: "Outlaw Country", value: "outro-outlaw-country" },
  { label: "Thai Country", value: "outro-thai-country" },
  { label: "Traditional Bluegrass", value: "outro-traditional-bluegrass" },
  { label: "Traditional Country", value: "outro-traditional-country" },
  { label: "Urban Cowboy", value: "outro-urban-cowboy" },
  { label: "Cuban", value: "outro-cuban" },
  { label: "Chachacha", value: "outro-chachacha" },
  { label: "Guajira", value: "outro-guajira" },
  { label: "Guaracha", value: "outro-guaracha" },
  { label: "Mambo", value: "outro-mambo" },
  { label: "Son", value: "outro-son" },
  { label: "Timba", value: "outro-timba" },
  { label: "Dance", value: "outro-dance" },
  { label: "Breakbeat", value: "outro-breakbeat" },
  { label: "Garage", value: "outro-garage" },
  { label: "Hardcore", value: "outro-hardcore" },
  { label: "House", value: "outro-house" },
  { label: "Jungle/Drum'n'bass", value: "outro-jungledrumnbass" },
  { label: "Techno", value: "outro-techno" },
  { label: "Trance", value: "outro-trance" },
  { label: "Disney", value: "outro-disney" },
  { label: "Easy Listening", value: "outro-easy-listening" },
  { label: "Lounge", value: "outro-lounge" },
  { label: "Swing", value: "outro-swing" },
  { label: "Ambient", value: "outro-ambient" },
  { label: "Bass", value: "outro-bass" },
  { label: "Downtempo", value: "outro-downtempo" },
  { label: "Dubstep", value: "outro-dubstep" },
  { label: "Electronica", value: "outro-electronica" },
  { label: "IDM/Experimental", value: "outro-idmexperimental" },
  { label: "Industrial", value: "outro-industrial" },
  { label: "Fitness & Workout", value: "outro-fitness-workout" },
  { label: "Alternative Rap", value: "outro-alternative-rap" },
  { label: "Dirty South", value: "outro-dirty-south" },
  { label: "East Coast Rap", value: "outro-east-coast-rap" },
  { label: "Gangsta Rap", value: "outro-gangsta-rap" },
  { label: "Hardcore Rap", value: "outro-hardcore-rap" },
  { label: "Hip-Hop", value: "outro-hip-hop" },
  { label: "Latin Rap", value: "outro-latin-rap" },
  { label: "Old School Rap", value: "outro-old-school-rap" },
  { label: "Rap", value: "outro-rap" },
  { label: "Turkish Hip-Hop/Rap", value: "outro-turkish-hip-hoprap" },
  { label: "Underground Rap", value: "outro-underground-rap" },
  { label: "West Coast Rap", value: "outro-west-coast-rap" },
  { label: "Holiday", value: "outro-holiday" },
  { label: "Chanukah", value: "outro-chanukah" },
  { label: "Christmas", value: "outro-christmas" },
  { label: "Christmas: Children's", value: "outro-christmas-childrens" },
  { label: "Christmas: Classic", value: "outro-christmas-classic" },
  { label: "Christmas: Classical", value: "outro-christmas-classical" },
  { label: "Christmas: Modern", value: "outro-christmas-modern" },
  { label: "Christmas: Pop", value: "outro-christmas-pop" },
  { label: "Christmas: R&B", value: "outro-christmas-rb" },
  { label: "Christmas: Religious", value: "outro-christmas-religious" },
  { label: "Christmas: Rock", value: "outro-christmas-rock" },
  { label: "Easter", value: "outro-easter" },
  { label: "Halloween", value: "outro-halloween" },
  { label: "Thanksgiving", value: "outro-thanksgiving" },
  { label: "Indian", value: "outro-indian" },
  { label: "Bollywood", value: "outro-bollywood" },
  { label: "Ghazals", value: "outro-ghazals" },
  { label: "Indian Folk", value: "outro-indian-folk" },
  { label: "Indian Pop", value: "outro-indian-pop" },
  { label: "Sufi", value: "outro-sufi" },
  { label: "Tamil", value: "outro-tamil" },
  { label: "Telugu", value: "outro-telugu" },
  { label: "Indian Classical", value: "outro-indian-classical" },
  { label: "Carnatic Classical", value: "outro-carnatic-classical" },
  { label: "Hindustani Classical", value: "outro-hindustani-classical" },
  { label: "Inspirational", value: "outro-inspirational" },
  { label: "Instrumental", value: "outro-instrumental" },
  { label: "Bebop", value: "outro-bebop" },
  { label: "Big Band", value: "outro-big-band" },
  { label: "Cool", value: "outro-cool" },
  { label: "Dixieland", value: "outro-dixieland" },
  { label: "Fusion", value: "outro-fusion" },
  { label: "Hard Bop", value: "outro-hard-bop" },
  { label: "Ragtime", value: "outro-ragtime" },
  { label: "Karaoke", value: "outro-karaoke" },
  { label: "Korean", value: "outro-korean" },
  { label: "Korean Folk-Pop", value: "outro-korean-folk-pop" },
  { label: "Korean Hip-Hop", value: "outro-korean-hip-hop" },
  { label: "Korean Indie", value: "outro-korean-indie" },
  { label: "Korean Rock", value: "outro-korean-rock" },
  { label: "Korean Traditional", value: "outro-korean-traditional" },
  { label: "Latin", value: "outro-latin" },
  {
    label: "Alternative & Rock in Spanish",
    value: "outro-alternative-rock-in-spanish",
  },
  { label: "Baladas y Boleros", value: "outro-baladas-y-boleros" },
  { label: "Contemporary Latin", value: "outro-contemporary-latin" },
  { label: "Pop in Spanish", value: "outro-pop-in-spanish" },
  { label: "Raices", value: "outro-raices" },
  { label: "Regional Mexicano", value: "outro-regional-mexicano" },
  { label: "Marching Bands", value: "outro-marching-bands" },
  { label: "New Age", value: "outro-new-age" },
  { label: "Healing", value: "outro-healing" },
  { label: "Meditation", value: "outro-meditation" },
  { label: "Nature", value: "outro-nature" },
  { label: "Relaxation", value: "outro-relaxation" },
  { label: "Travel", value: "outro-travel" },
  { label: "Yoga", value: "outro-yoga" },
  { label: "Opera", value: "outro-opera" },
  { label: "Orchestral", value: "outro-orchestral" },
  { label: "Pop", value: "outro-pop" },
  { label: "Adult Contemporary", value: "outro-adult-contemporary" },
  { label: "Britpop", value: "outro-britpop" },
  { label: "French Pop", value: "outro-french-pop" },
  { label: "German Pop", value: "outro-german-pop" },
  { label: "Indo Pop", value: "outro-indo-pop" },
  { label: "Kayokyoku", value: "outro-kayokyoku" },
  { label: "Malaysian Pop", value: "outro-malaysian-pop" },
  { label: "Mandopop", value: "outro-mandopop" },
  { label: "Manilla Sound", value: "outro-manilla-sound" },
  { label: "Oldies", value: "outro-oldies" },
  { label: "Original Pilipino Music", value: "outro-original-pilipino-music" },
  { label: "Pinoy Pop", value: "outro-pinoy-pop" },
  { label: "Pop/Rock", value: "outro-poprock" },
  { label: "Shows", value: "outro-shows" },
  { label: "Soft Rock", value: "outro-soft-rock" },
  { label: "Tai-Pop", value: "outro-tai-pop" },
  { label: "Teen Pop", value: "outro-teen-pop" },
  { label: "Thai Pop", value: "outro-thai-pop" },
  { label: "R&B/Soul", value: "outro-rbsoul" },
  { label: "Contemporary R&B", value: "outro-contemporary-rb" },
  { label: "Disco", value: "outro-disco" },
  { label: "Doo Wop", value: "outro-doo-wop" },
  { label: "Motown", value: "outro-motown" },
  { label: "Neo-Soul", value: "outro-neo-soul" },
  { label: "Classic/Modern Dancehall", value: "outro-classicmodern-dancehall" },
  { label: "Dub", value: "outro-dub" },
  { label: "Lovers Rock", value: "outro-lovers-rock" },
  { label: "Roots Reggae", value: "outro-roots-reggae" },
  { label: "Ska", value: "outro-ska" },
  { label: "Regional Indian", value: "outro-regional-indian" },
  { label: "Assamese", value: "outro-assamese" },
  { label: "Bengali", value: "outro-bengali" },
  { label: "Bhojpuri", value: "outro-bhojpuri" },
  { label: "Gujarati", value: "outro-gujarati" },
  { label: "Haryanvi", value: "outro-haryanvi" },
  { label: "Kannada", value: "outro-kannada" },
  { label: "Malayalam", value: "outro-malayalam" },
  { label: "Marathi", value: "outro-marathi" },
  { label: "Odia", value: "outro-odia" },
  { label: "Punjabi", value: "outro-punjabi" },
  { label: "Punjabi Pop", value: "outro-punjabi-pop" },
  { label: "Rabindra Sangeet", value: "outro-rabindra-sangeet" },
  { label: "Rajasthani", value: "outro-rajasthani" },
  { label: "Urdu", value: "outro-urdu" },
  { label: "Adult Alternative", value: "outro-adult-alternative" },
  { label: "American Trad Rock", value: "outro-american-trad-rock" },
  { label: "Arena Rock", value: "outro-arena-rock" },
  { label: "Blues-Rock", value: "outro-blues-rock" },
  { label: "British Invasion", value: "outro-british-invasion" },
  { label: "Death Metal/Black Metal", value: "outro-death-metalblack-metal" },
  { label: "Glam Rock", value: "outro-glam-rock" },
  { label: "Hair Metal", value: "outro-hair-metal" },
  { label: "Hard Rock", value: "outro-hard-rock" },
  { label: "Heavy Metal", value: "outro-heavy-metal" },
  { label: "Jam Bands", value: "outro-jam-bands" },
  { label: "Prog-Rock/Art Rock", value: "outro-prog-rockart-rock" },
  { label: "Psychedelic", value: "outro-psychedelic" },
  { label: "Rock & Roll", value: "outro-rock-roll" },
  { label: "(Rock) Singer/Songwriter", value: "outro-rock-singersongwriter" },
  { label: "Rockabilly", value: "outro-rockabilly" },
  { label: "Roots Rock", value: "outro-roots-rock" },
  { label: "Southern Rock", value: "outro-southern-rock" },
  { label: "Surf", value: "outro-surf" },
  { label: "Tex-Mex", value: "outro-tex-mex" },
  { label: "Singer/Songwriter", value: "outro-singersongwriter" },
  { label: "Alternative Folk", value: "outro-alternative-folk" },
  { label: "Contemporary Folk", value: "outro-contemporary-folk" },
  {
    label: "Contemporary Singer/Songwriter",
    value: "outro-contemporary-singersongwriter",
  },
  { label: "Folk-Rock", value: "outro-folk-rock" },
  { label: "New Acoustic", value: "outro-new-acoustic" },
  { label: "Traditional Folk", value: "outro-traditional-folk" },
  { label: "Soundtrack", value: "outro-soundtrack" },
  { label: "Film Soundtrack", value: "outro-film-soundtrack" },
  { label: "Foreign Cinema", value: "outro-foreign-cinema" },
  { label: "Musicals", value: "outro-musicals" },
  { label: "Original Score", value: "outro-original-score" },
  { label: "Sound Effects", value: "outro-sound-effects" },
  { label: "TV Soundtrack", value: "outro-tv-soundtrack" },
  { label: "Video Game", value: "outro-video-game" },
  { label: "Spoken Word", value: "outro-spoken-word" },
  { label: "Turkish", value: "outro-turkish" },
  { label: "Fantezi", value: "outro-fantezi" },
  { label: "Özgün", value: "outro-zgn" },
  { label: "Religious", value: "outro-religious" },
  { label: "Turkish Alternative", value: "outro-turkish-alternative" },
  { label: "Turkish Pop", value: "outro-turkish-pop" },
  { label: "Turkish Rock", value: "outro-turkish-rock" },
  { label: "Vocal", value: "outro-vocal" },
  { label: "Standards", value: "outro-standards" },
  { label: "Traditional Pop", value: "outro-traditional-pop" },
  { label: "Trot", value: "outro-trot" },
  { label: "Vocal Pop", value: "outro-vocal-pop" },
  { label: "World", value: "outro-world" },
  { label: "Afrikaans", value: "outro-afrikaans" },
  { label: "Afro-Beat", value: "outro-afro-beat" },
  { label: "Afro-Pop", value: "outro-afro-pop" },
  { label: "Arabesque", value: "outro-arabesque" },
  { label: "Asia", value: "outro-asia" },
  { label: "Australia", value: "outro-australia" },
  { label: "Cajun", value: "outro-cajun" },
  { label: "Calypso", value: "outro-calypso" },
  { label: "Caribbean", value: "outro-caribbean" },
  { label: "Celtic", value: "outro-celtic" },
  { label: "Celtic Folk", value: "outro-celtic-folk" },
  { label: "Contemporary Celtic", value: "outro-contemporary-celtic" },
  { label: "Dangdut", value: "outro-dangdut" },
  { label: "Dini", value: "outro-dini" },
  { label: "Enka", value: "outro-enka" },
  { label: "Europe", value: "outro-europe" },
  { label: "Fado", value: "outro-fado" },
  { label: "Farsi", value: "outro-farsi" },
  { label: "Flamenco", value: "outro-flamenco" },
  { label: "France", value: "outro-france" },
  { label: "German Folk", value: "outro-german-folk" },
  { label: "Halk", value: "outro-halk" },
  { label: "Hawaii", value: "outro-hawaii" },
  { label: "Iberia", value: "outro-iberia" },
  { label: "Indonesian Religious", value: "outro-indonesian-religious" },
  { label: "Israeli", value: "outro-israeli" },
  { label: "Japan", value: "outro-japan" },
  { label: "Klezmer", value: "outro-klezmer" },
  { label: "North America", value: "outro-north-america" },
  { label: "Polka", value: "outro-polka" },
  { label: "Russian", value: "outro-russian" },
  { label: "Russian Chanson", value: "outro-russian-chanson" },
  { label: "Sanat", value: "outro-sanat" },
  { label: "Soca", value: "outro-soca" },
  { label: "South Africa", value: "outro-south-africa" },
  { label: "South America", value: "outro-south-america" },
  { label: "Traditional Celtic", value: "outro-traditional-celtic" },
  { label: "Worldbeat", value: "outro-worldbeat" },
  { label: "Zydeco", value: "outro-zydeco" },
];

export default revelatorGenres;
