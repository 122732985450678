import { Carousel, Collapse } from "antd";
import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Wallet } from "shared/assets/images/wallet-circle.svg";
import { ReactComponent as Music } from "shared/assets/images/music.svg";
import { changeStatusCashAdvanceProcess } from "shared/service/StrmService";
import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import MeuAdiantamento from "./MeuAdiantamento";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";
import { useSelector } from "react-redux";

export enum StepAceite {
  Assinatura = 0,
  Migracao = 1,
  Recebimento = 2,
}

const AdiantamentoAceite: React.FC<any> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const [step, setStep] = useState<number>();

  const carouselRef = useRef<any>(null);
  const { t } = useTranslation();
  const contentStyle = { color: "white", fontSize: 32 };
  const faqList: any[] = t("pages.Adiantamentos.faqAceite");
  const { userArtist } = useSelector((state: any) => state.artists);

  const moveStep = async () => {
    dataLayerEvent("ClickButton", {
      ...quizEvents.S4A_ADVANCE_CONFIRMATION,
      buttonName: "NEXT",
      carreerStage: userArtist?.stage,
      proposalValue: advance?.totalAmountUsd,
      approvedLimit: userArtist?.cashAdvanceEstimated?.amountUsd,
      alreadyCalculated:
        userArtist?.cashAdvanceEstimated?.successfullyEstimated,
      country: userArtist?.country,
    });
    await changeStatusCashAdvanceProcess(
      advance.id,
      AdvanceStatus.DocumentationForContract
    );
    reloadCashAdvance();
  };

  const moveNext = () => {
    const { innerSlider, prev } = carouselRef.current;
    if (innerSlider.state.currentSlide === 0) setShowSteps(false);
    else prev();
  };

  const movePrev = () => {
    const { innerSlider, next } = carouselRef.current;
    if (innerSlider.state.currentSlide === 2) moveStep();
    else next();
  };

  const onClickBackStep = async () => {
    await changeStatusCashAdvanceProcess(
      advance.id,
      AdvanceStatus.ReleasesSelection
    );
    reloadCashAdvance();
  };

  if (showSteps)
    return (
      <>
        <div className="aceite-adiantamento">
          <div
            className="flex-column-between"
            style={{ maxWidth: isMobile ? "unset" : 650 }}
          >
            <div style={{ marginTop: 56, flex: 1 }}>
              <h1 style={{ color: "#fff", textAlign: "center" }}>
                {t("pages.Adiantamentos.proximosPassos")}
              </h1>
              <Carousel
                ref={(ref) => (carouselRef.current = ref)}
                style={{ width: "100%" }}
                afterChange={(current) => setStep(current)}
              >
                <div>
                  <div className="carousel-content">
                    <h1 style={contentStyle}>1</h1>
                    <h1>{t("pages.Adiantamentos.assinarDigitalmente")}</h1>
                    <div className="instrucoes">
                      <span style={{ flex: 1 }}>
                        {t("pages.Adiantamentos.distribuidoraAtual")}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowRight} color="#455FE4" />
                        <Music
                          className="svg-stroke-branco bg-roxo-escuro"
                          style={{ width: 52, height: 52 }}
                        />
                        <FontAwesomeIcon icon={faArrowRight} color="#455FE4" />
                      </span>
                      <span style={{ flex: 1 }}>
                        <b>Strm</b> {t("pages.Adiantamentos.distribuidora")}
                        <b style={{ fontSize: 8, position: "absolute" }}>®</b>
                      </span>
                    </div>
                    <p style={{ marginTop: 40, fontSize: 20 }}>
                      <Trans
                        i18nKey={t(
                          "pages.Adiantamentos.enviarRelatorioReceitaPassoDois"
                        )}
                      />
                    </p>
                  </div>
                </div>
                <div>
                  <div className="carousel-content">
                    <h1 style={contentStyle}>2</h1>
                    <h1>{t("pages.Adiantamentos.migrarCatalogo")}</h1>
                    <div className="instrucoes">
                      <span style={{ flex: 1 }}>
                        {t("pages.Adiantamentos.distribuidoraAtual")}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowRight} color="#455FE4" />
                        <Music
                          className="svg-stroke-branco bg-roxo-escuro"
                          style={{ width: 52, height: 52 }}
                        />
                        <FontAwesomeIcon icon={faArrowRight} color="#455FE4" />
                      </span>
                      <span style={{ flex: 1 }}>
                        <b>Strm</b> {t("pages.Adiantamentos.distribuidora")}
                        <b style={{ fontSize: 8, position: "absolute" }}>®</b>
                      </span>
                    </div>
                    <p style={{ marginTop: 40, fontSize: 20 }}>
                      <Trans
                        i18nKey={t(
                          "pages.Adiantamentos.enviarRelatorioReceitaPassoTres"
                        )}
                      />
                    </p>
                  </div>
                </div>
                <div>
                  <div className="carousel-content">
                    <h1 style={contentStyle}>3</h1>
                    <h1>{t("pages.Adiantamentos.receberAdiantamento")}</h1>
                    <div className="instrucoes">
                      <span style={{ fontSize: 20, flex: 1 }}>
                        <b>Strm</b>
                        <b style={{ fontSize: 10, position: "absolute" }}>®</b>
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          color="#455FE4"
                          style={{ flex: 1 }}
                        />
                        <Wallet className="svg-stroke-branco bg-roxo-escuro" />
                        <FontAwesomeIcon icon={faArrowRight} color="#455FE4" />
                      </span>
                      <span style={{ flex: 1 }}>
                        {t("pages.Adiantamentos.contaRecebimento")}
                      </span>
                    </div>
                    <p style={{ marginTop: 40, fontSize: 20 }}>
                      {t(
                        "pages.Adiantamentos.enviarRelatorioReceitaPassoQuatro"
                      )}
                    </p>
                  </div>
                </div>
              </Carousel>
            </div>
            {step === StepAceite.Migracao && (
              <div
                style={{
                  flex: 1,
                  marginTop: 50,
                  margin: isMobile ? "50px 18px 0 18px" : "50px 0 75px 0",
                }}
              >
                <p>
                  <strong style={{ color: "#5A57FF" }}>*</strong>{" "}
                  {t("pages.Adiantamentos.migracaoDisclaimer")}
                </p>
                <span
                  style={{
                    display: "block",
                    marginBottom: 12,
                    color: "#F2F2F2",
                  }}
                >
                  {t("pages.Adiantamentos.faqTitle")}
                </span>
                <Collapse
                  bordered={false}
                  className="faq"
                  expandIcon={() => <FontAwesomeIcon icon={faChevronDown} />}
                  expandIconPosition="end"
                >
                  {faqList?.map((faq, i) => (
                    <Collapse.Panel
                      header={t(`pages.Adiantamentos.faq.${faq}.pergunta`)}
                      key={i}
                    >
                      <p>
                        <Trans
                          i18nKey={t(`pages.Adiantamentos.faq.${faq}.resposta`)}
                        />
                      </p>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
            )}
            {step === StepAceite.Recebimento && (
              <div
                style={{
                  flex: 1,
                  marginTop: 50,
                  margin: isMobile ? "50px 18px 0 18px" : "50px 0 0 0",
                  textAlign: "center",
                }}
              >
                <h3>{t(`pages.Adiantamentos.faq.takedown.pergunta`)}</h3>
                <p>
                  {" "}
                  <strong style={{ color: "#5A57FF" }}>*</strong>{" "}
                  {t(`pages.Adiantamentos.faq.takedown.resposta`)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="footer black">
          <button className="botao-borda-roxa " onClick={moveNext}>
            {t("pages.Adiantamentos.voltar")}
          </button>
          <button className="botao-roxo continuar" onClick={movePrev}>
            {t("pages.Adiantamentos.continuar")}
          </button>
        </div>
      </>
    );
  return (
    <>
      <div className="aceite-adiantamento">
        <div
          className="flex-column-between"
          style={{
            maxWidth: isMobile ? "unset" : 650,
            paddingTop: isMobile ? 0 : 60,
          }}
        >
          <div style={{ paddingBottom: isMobile ? 0 : 64 }}>
            <div className="confirma-adiantamento-intro">
              <h1>{t("pages.Adiantamentos.valoresAlteradosTitulo")}</h1>
              <p
                style={{ maxWidth: isMobile ? "unset" : 550, marginBottom: 48 }}
              >
                <Trans
                  i18nKey={t("pages.Adiantamentos.valoresAlteradosTexto")}
                />
              </p>
            </div>
            <MeuAdiantamento advance={advance} />
            <div
              style={{
                margin: isMobile ? "0 18px" : 0,
              }}
            >
              <span
                style={{ color: "#F2F2F2", display: "block", marginBottom: 12 }}
              >
                {t("pages.Adiantamentos.valoresAte")}{" "}
                <span style={{ color: "#B3B3B3" }}>
                  {moment(advance.validUntil).format("DD/MM/YYYY")}
                </span>
              </span>

              {/* <p className="disclaimer">
                <Trans
                  i18nKey={t("pages.Adiantamentos.valoresAlteradosTexto")}
                />
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer black">
        <button className="botao-borda-roxa " onClick={onClickBackStep}>
          {t("pages.Adiantamentos.voltar")}
        </button>
        <button
          className="botao-roxo continuar"
          onClick={() => setShowSteps(true)}
        >
          {t("pages.Adiantamentos.continuar")}
        </button>
      </div>
    </>
  );
};

export default AdiantamentoAceite;
