import { RouteComponentProps } from "react-router";
import { isMobile } from "react-device-detect";
import "./Diagnostico.scss";

const Diagnostico: React.FC<RouteComponentProps> = (props) => {
  const { location, match } = props;
  const url: any = location.state;

  const {
    params: { id },
  }: any = match;
  const { REACT_APP_STRM_INVESTOR_URL } = process.env;

  const finalUrl = id
    ? `${REACT_APP_STRM_INVESTOR_URL}/diagnostico-carreira/${id}?token=${localStorage.getItem(
        "token"
      )}`
    : `${url}?token=${localStorage.getItem("token")}`;

  if (!finalUrl) return null;
  return (
    <div
      style={{ width: "100%", height: "100%", minHeight: "100vh" }}
      className={`diagnostico ${isMobile ? "mobile" : ""}`}
    >
      <iframe
        style={{ width: "100%", height: "100vh" }}
        src={finalUrl}
        title="diagnostico"
        id="diag-iframe"
      />
    </div>
  );
};
export default Diagnostico;
