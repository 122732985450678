import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { TIPO_DIAGNOSTICO_UTILIZADO } from "shared/constants/StorageConsts";
import { TipoDiagnostico } from "shared/enums/TipoDiagnostico";
import {
  generateUserDiagnoseTokenUser,
  generateUserDiagnoseTokenTeam,
} from "shared/service/StrmService";

const NovoDiagnostico: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const [formParams, setFormParams] = useState<any>();
  const { loggedUser, loading } = useSelector((state: any) => state.users);
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;

    if (!loading) {
      if (loggedUser?.userArtists?.length === 0)
        history.push("/quiz", { diagnose: true });
      else {
        const tipoDiagnostico = localStorage.getItem(
          TIPO_DIAGNOSTICO_UTILIZADO
        );
        if (tipoDiagnostico === TipoDiagnostico.User) {
          getFormTokenUser();
        }
        if (tipoDiagnostico === TipoDiagnostico.Team) {
          getFormTokenTeam();
        }
      }
    }

    async function getFormTokenUser() {
      const result: any = await generateUserDiagnoseTokenUser();
      if (mounted) setFormParams(result);
    }

    async function getFormTokenTeam() {
      const result: any = await generateUserDiagnoseTokenTeam();
      if (mounted) setFormParams(result);
    }

    // localStorage.removeItem(TIPO_DIAGNOSTICO_UTILIZADO);
    return () => {
      mounted = false;
    };
  }, [loading, loggedUser, history]);

  if (loading) return null;
  if (
    !formParams?.formStrmUserId ||
    !formParams?.formToken ||
    !formParams?.formName
  )
    return t("pages.diagnosticos.error");
  const paperformUrl = `${process.env.REACT_APP_PAPERFORM_URL}?strm_user_id=${formParams?.formStrmUserId}&form_token=${formParams?.formToken}&form_name=${formParams?.formName}`;

  return (
    <div
      style={{ height: "100%", width: "100%", minHeight: "100vh" }}
      key={formParams?.formToken}
    >
      <iframe
        style={{ minHeight: "100vh" }}
        key={formParams?.formStrmUserId}
        src={paperformUrl}
        title="Novo Diagnósticos"
        id="paper-form"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export default NovoDiagnostico;
