import { Tooltip } from "antd";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getKeyTranslation,
  QuizDisabledReasonEnum,
} from "shared/enums/QuizDisabledReasonEnum";

const ButtonRemakeQuiz: React.FC = () => {
  const { t } = useTranslation();
  const { userArtist } = useSelector((state: any) => state.artists);
  const history = useHistory();

  const onClickRemakeQuiz = () => {
    dataLayerEvent("ClickButton", {
      ...quizEvents.S4A_HOME_DIAGNOSED,
      buttonName: "RERUN_DIAGNOSYS",
    });
    history.push("/quiz", { id: userArtist?.id });
  };

  const reasonKey = getKeyTranslation(
    userArtist?.quizDisabledReason as QuizDisabledReasonEnum
  );

  return (
    <Tooltip placement="bottom" title={reasonKey && t(reasonKey)}>
      <div>
        <button
          className="botao-branco"
          onClick={onClickRemakeQuiz}
          disabled={!userArtist?.quizRedoAvailable}
        >
          {t("pages.EstagioArtista.refazerDiagnosticoGratuito")}
        </button>
      </div>
    </Tooltip>
  );
};

export default ButtonRemakeQuiz;
