import { PageHeader } from "@ant-design/pro-layout";
import { FormInstance } from "antd";
import { UpcomingRelease } from "shared/interfaces/UpcomingRelease";

interface StepComponentProps {
  title: string;
  StepContent: any;
  form: FormInstance<UpcomingRelease>;
}

const StepContainer: React.FC<StepComponentProps> = ({
  title,
  StepContent,
  form,
}) => {
  return (
    <div>
      <PageHeader title={title} className="title-step" />
      {StepContent(form)}
    </div>
  );
};

export default StepContainer;
