import { PageHeader } from "@ant-design/pro-layout";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BRLFormat } from "shared/helpers/BRLFormat";

const HistoricoCompras: React.FC<any> = () => {
  const { t } = useTranslation();

  const loggedUser = useSelector((state: any) => state.users.loggedUser);
  const columns: ColumnsType<any> = [
    {
      title: t("pages.MeusDados.quantidade"),
      dataIndex: "quantity",
      align: "center",
    },
    {
      title: t("pages.MeusDados.dataCompra"),
      dataIndex: "createdOn",
      align: "center",
      render: (createdOn: string) =>
        moment(createdOn).format("DD/MM/YYYY - HH:mm"),
    },
    {
      title: t("pages.MeusDados.formaPagamento"),
      align: "center",
      render: () => "Mercado Pago",
    },
    {
      title: t("pages.MeusDados.valor"),
      dataIndex: "unitPrice",
      align: "center",
      render: (valorTotal: number) => BRLFormat(valorTotal),
    },
  ];
  return (
    <div>
      <PageHeader title={t("pages.MeusDados.shopHistory")} />
      <Table
        columns={columns}
        dataSource={loggedUser?.mercadoPagoTransactions || []}
        pagination={{
          hideOnSinglePage: true,
        }}
        scroll={{ x: 600 }}
      />
    </div>
  );
};

export default HistoricoCompras;
