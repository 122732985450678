import React, { useState } from "react";
import "./Collapse.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

interface CollapseProps {
  content: React.ReactNode;
  header?: React.ReactNode;
}

const Collapse: React.FC<CollapseProps> = (props) => {
  const { content, header } = props;
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <div className="collapse-container">
      <div className="collapse-header" onClick={toggleCollapse}>
        {header}{" "}
        {open ? (
          <FontAwesomeIcon icon={faAngleUp} />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} />
        )}
      </div>
      <div className={`collapse-content ${open ? "open" : ""}`}>{content}</div>
    </div>
  );
};

export default Collapse;
