import { Modal } from "antd";
import { Trans, useTranslation } from "react-i18next";
import imgHenriqueJulianoWeb from "shared/assets/images/henrique-juliano-sem-texto.png";
import "explorer/pages/new-estagio-artista/campanhas-especiais/CampanhasEspeciais.scss";

interface ModalCampaignHeJRequestProps {
  open: boolean;
  onCancel: () => void;
}

const ModalCampaignHeJRequest: React.FC<ModalCampaignHeJRequestProps> = (
  props
) => {
  const { open, onCancel } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      width={700}
      title={null}
      className="modal-henrique-juliano"
    >
      <>
        <div
          style={{
            background: `url(${imgHenriqueJulianoWeb}) no-repeat`,
            backgroundSize: "cover",
            borderRadius: "8px 8px 0 0",
            minHeight: 330,
            width: "100%",
            position: "absolute",
          }}
        />
        <div style={{ position: "relative" }}>
          <div className="conteudo">
            <div style={{ background: "#0D0D0D", opacity: 0.7 }} />
            <div
              style={{
                paddingTop: 112,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 48px",
              }}
            >
              <span className="chamada">
                {t("pages.CampanhasEspeciais.HenriqueJuliano.curadoriaSelecao")}
              </span>
              <h1 className="titulo">
                {t(
                  "pages.CampanhasEspeciais.HenriqueJuliano.recebemosSoliciacaoTitulo"
                )}
              </h1>

              <span className="divider" />
            </div>

            <p
              style={{
                padding: "0 48px",
                textAlign: "center",
              }}
            >
              <Trans
                i18nKey={t(
                  "pages.CampanhasEspeciais.HenriqueJuliano.recebemosSoliciacaoTexto"
                )}
              />
            </p>
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(25, 25, 25, 0.00) 0%, #1A1A1A 100%)",
                height: 170,
                width: "100%",
                marginTop: -130,
              }}
            />
            <div style={{ background: "#1A1A1A", paddingTop: 12 }}>
              <ul>
                <li className="circle-checkmark azul">
                  {t("pages.CampanhasEspeciais.HenriqueJuliano.strmHelp1")}
                </li>
                <li className="circle-checkmark azul">
                  {t("pages.CampanhasEspeciais.HenriqueJuliano.strmHelp2")}
                </li>
                <li className="circle-checkmark azul">
                  {t("pages.CampanhasEspeciais.HenriqueJuliano.strmHelp3")}
                </li>
                <li className="circle-checkmark azul">
                  {t("pages.CampanhasEspeciais.HenriqueJuliano.strmHelp4")}
                </li>
              </ul>
              <div className="buttons"></div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ModalCampaignHeJRequest;
