import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import explorerEn from "./locales/en/explorer.json";
import explorerPt from "./locales/pt/explorer.json";
import explorerEs from "./locales/es/explorer.json";
import { LinguagemEnum } from "shared/enums/LinguagemEnum";

// const en = { ...explorerEn, ...radarEn };
// const pt = { ...radarPt, ...explorerPt };

const resources = {
  en: {
    explorer: explorerEn,
  },
  pt: {
    explorer: explorerPt,
  },
  es: {
    explorer: explorerEs,
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ["explorer"],
    defaultNS: "explorer",
    lng: LinguagemEnum.Portugues,
    returnObjects: true,
    resources,
    debug: false,
  });

export default i18n;
