import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";

const useFetchData = <T>(
  apiFunc: () => Promise<AxiosResponse<T>>,
  initialValue?: any,
  transformFunc?: (data: any) => any
) => {
  const [data, setData] = useState<T | null>(initialValue);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: any = await apiFunc();
        setData(transformFunc ? transformFunc(response) : response);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiFunc, transformFunc]);

  return [data, error, loading];
};

export default useFetchData;
