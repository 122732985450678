import { Spin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./Loader.scss";

interface LoaderProps extends SpinProps {
  hidden?: boolean;
  fontSize?: number;
  global?: boolean;
  backgroundColor?: string;
}

const Simple: React.FC<LoaderProps> = (props) => {
  const { fontSize = 48, hidden, backgroundColor = "#121212" } = props;
  if (hidden) return null;
  return (
    <div className="loader" style={{ backgroundColor }}>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize }} spin={false} />}
        {...props}
      />
    </div>
  );
};

export default Simple;
