import { useState, useEffect } from "react";

const minWidth = 600;
export const useWindowWidth = (mobileWidth: number = minWidth) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(width < mobileWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setIsMobile(width < mobileWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, mobileWidth]);

  return { width, isMobile };
};
