export enum CopyrightAssociationEnum {
  NotAffiliated = 0,
  Abramus = 1,
  Amar = 2,
  Assim = 3,
  Sbacem = 4,
  Sicam = 5,
  Socinpro = 6,
  UBC = 7,
}
