import { Row, Spin } from "antd";
import { isMobile } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import Duvidas from "./Duvidas";
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import { LoadingOutlined } from "@ant-design/icons";
import "../Adiantamentos.scss";
import { useEffect } from "react";
import { putReprocessAdvance } from "shared/service/StrmService";
import { CashAdvance } from "shared/interfaces/CashAdvance";

interface AdiantamentoProcessamentoErroProps {
  reloadCashAdvance: () => void;
  advance: CashAdvance;
}

const AdiantamentoProcessamentoErro: React.FC<
  AdiantamentoProcessamentoErroProps
> = ({ advance, reloadCashAdvance }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClickHome = () => {
    history.push("/");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (advance?.id) {
          await putReprocessAdvance(advance?.id);
          reloadCashAdvance();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, [advance?.id, reloadCashAdvance]);

  return (
    <div className={`adiantamentos ${!isMobile && "web"}`}>
      <div
        className="flex-column-between"
        style={{
          minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
          paddingLeft: isMobile ? 0 : 94,
          alignItems: "center",
        }}
      >
        <div
          className="migracao"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            maxWidth: isMobile ? "unset" : 650,
          }}
        >
          <div className="confirma-adiantamento-intro">
            <Logo style={{ width: 65 }} />
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 20, margin: 32 }}
                  spin={false}
                />
              }
            />
            <h1>{t("pages.Adiantamentos.processamentoErroTitulo")}</h1>
            <p>
              <Trans
                i18nKey={t("pages.Adiantamentos.processamentoErroTexto")}
              />
            </p>
          </div>
          <Row
            justify="center"
            style={{
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <button className="botao-roxo" onClick={onClickHome}>
              {t("pages.Adiantamentos.botaoHome")}
            </button>
          </Row>
        </div>

        <div className="footer" style={{ paddingLeft: 8 }}>
          <Duvidas />
        </div>
      </div>
    </div>
  );
};

export default AdiantamentoProcessamentoErro;
