import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { autenticado, logout } from "shared/helpers/auth-functions";
import { RootStateOrAny } from "react-redux";
import {
  putChangeLanguage,
  fetchLoggedUser,
  fetchPersonifiedUser,
} from "shared/service/StrmService";
import { getBrowserId } from "shared/helpers/browser-id";
import { dataLayerEventAcesso } from "event-tracker/gtm-events";
import { LinguagemEnum } from "shared/enums/LinguagemEnum";

export const getLoggedUser = createAsyncThunk("users/GetUser", async () => {
  const loggedUser: any = await fetchLoggedUser(getBrowserId());
  if (loggedUser.emailPersonified) return await fetchPersonifiedUser();
  return loggedUser;
});

export const setLoggedUserLanguage = createAsyncThunk(
  "users/setLanguage",
  async (language: string) => {
    if (autenticado()) await putChangeLanguage(language);
    return language;
  }
);

export const usersSlice: any = createSlice({
  name: "users",
  initialState: {
    loggedUser: {
      currentLanguage: LinguagemEnum.Portugues,
    },
    loading: true,
    firstAccess: false,
    firstAccessNotified: false,
  },
  reducers: {
    firstAccessNotified: (state) => {
      state.firstAccess = false;
      state.firstAccessNotified = true;
    },
  },
  extraReducers: {
    [getLoggedUser.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.loggedUser = action.payload;
        state.loading = false;
        const firstLoginArtistsAt = new Date(
          action.payload?.firstLoginArtistsAt
        );
        const lastLoginArtistsAt = new Date(action.payload?.lastLoginArtistsAt);

        firstLoginArtistsAt.setMilliseconds(0);
        lastLoginArtistsAt.setMilliseconds(0);

        state.firstAccess =
          firstLoginArtistsAt.getTime() === lastLoginArtistsAt.getTime();
        dataLayerEventAcesso(action.payload);
      } else {
        logout(false);
      }
    },
    [getLoggedUser.pending.type]: (state) => {
      state.loading = true;
    },
    [setLoggedUserLanguage.fulfilled.type]: (state, action) => {
      if (action.payload)
        state.loggedUser = {
          ...state.loggedUser,
          currentLanguage: action.payload,
        };
    },
  },
});

export const { firstAccessNotified } = usersSlice.actions;

export const selectLoggedUser = (state: RootStateOrAny) => state.loggedUser;

export default usersSlice.reducer;
