import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CashAdvanceAvailableEnum } from "shared/enums/CashAdvanceAvailable";
import {
  putVerifyAdvanceByLoggedUser,
  putVerifyAdvanceByLoggedUserForce,
} from "shared/service/StrmService";

export const getLoggedUserCashAdvanceAvailable = createAsyncThunk(
  "advances/getLoggedUserCashAdvanceAvailable",
  async (getMetadata: boolean = false) => {
    const cashAdvanceAvailable: any = getMetadata
      ? await putVerifyAdvanceByLoggedUserForce()
      : await putVerifyAdvanceByLoggedUser();
    return cashAdvanceAvailable;
  }
);

export const canAskAdvance = (cashAdvanceAvailable: CashAdvanceAvailableEnum) =>
  cashAdvanceAvailable === CashAdvanceAvailableEnum.Available ||
  cashAdvanceAvailable === CashAdvanceAvailableEnum.GettingReleasesMetadata ||
  cashAdvanceAvailable === CashAdvanceAvailableEnum.WaitingListAvailable;

export const adiantamentosSlice: any = createSlice({
  name: "advances",
  initialState: {
    loading: false,
    cashAdvanceAvailable: CashAdvanceAvailableEnum.NotAvailable,
  },
  reducers: {},
  extraReducers: {
    [getLoggedUserCashAdvanceAvailable.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.cashAdvanceAvailable = action.payload;
      }
      state.loading = false;
    },
    [getLoggedUserCashAdvanceAvailable.pending.type]: (state) => {
      state.loading = true;
    },
  },
});

export default adiantamentosSlice.reducer;
