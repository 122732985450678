import axios from "axios";
import { getLoggedUser } from "explorer/reducers/users";
import { getCountries } from "explorer/reducers/countries";
import { cadastroRealizado, loginReduced } from "explorer/reducers/auth";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DISTRO_PARAM, LANG, TOKEN } from "shared/constants/StorageConsts";
import { autenticado, hasQuizPending } from "shared/helpers/auth-functions";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { getLoggedUserArtist } from "explorer/reducers/artistas";

const AlternativeAutentication: React.FC<any> = (props) => {
  const { location } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { loggedUser, loading } = useSelector((state: any) => state.users);

  useEffect(() => {
    const urlSearch = new URLSearchParams(location.search);
    const getAuthToken = async (code: string) => {
      try {
        const response: any = await axios.post(
          `${process.env.REACT_APP_HOST_ENDPOINT}auth/oauth2/token`,
          {
            code,
            redirectUri: encodeURIComponent(window.location.origin),
          }
        );

        localStorage.setItem(TOKEN, response.data);
        dispatch(getLoggedUser());
        dispatch(getLoggedUserArtist());
      } catch {}
    };

    const handleLanguage = () => {
      const lang = Cookies.get(LANG);
      if (lang && i18n.hasResourceBundle(lang, "explorer")) {
        i18n.changeLanguage(lang);
      }
    };

    const handleRequests = async () => {
      dispatch(loginReduced());
      dispatch(getCountries());
      const distroFlow = sessionStorage.getItem(DISTRO_PARAM) === "true";
      if (hasQuizPending()) {
        history.push("/quiz");
      } else {
        if (loggedUser.firstLoginArtistsAt === loggedUser.lastLoginArtistsAt)
          dispatch(cadastroRealizado());

        handleLanguage();
        const returnURL = sessionStorage.getItem("returnURL");
        sessionStorage.removeItem("returnURL");

        if (distroFlow) {
          history.push("/distribua");
        } else if (returnURL) history.push(returnURL);
        else history.push("/", { login: true });
      }
    };

    if (loading && !autenticado()) getAuthToken(urlSearch.get("code") || "");
    if (!loading) handleRequests();
  }, [loading, location, loggedUser, dispatch, history, i18n]);

  return <div style={{ height: "100vh", backgroundColor: "#26272c" }} />;
};

export default AlternativeAutentication;
