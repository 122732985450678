import { configureStore } from "@reduxjs/toolkit";
import artistasReducer from "explorer/reducers/artistas";
import genresSlice from "explorer/reducers/generos";
import usersReducer from "explorer/reducers/users";
import authReducer from "explorer/reducers/auth";
import countriesReducer from "explorer/reducers/countries";
import advancesReducer from "explorer/reducers/adiantamentos";

export default configureStore({
  reducer: {
    users: usersReducer,
    artists: artistasReducer,
    genres: genresSlice,
    auth: authReducer,
    countries: countriesReducer,
    advances: advancesReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(cashAdvanceAvailableMiddleware),
});
