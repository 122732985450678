import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Artist } from "model/Artist";
import { Trans, useTranslation } from "react-i18next";
import { getCountryByKey } from "shared/helpers/country-helper";
import {
  Button,
  Col,
  Collapse,
  Form,
  Progress,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import GenreTagList from "shared/components/genre-tag-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Meta from "./Meta";
import { Goal, Goals } from "model/Goals";
import { useHistory } from "react-router-dom";
import { fetchArtistMilestones } from "shared/service/RadarService";
import CardCTAPrincipal from "../new-estagio-artista/CardCTAPrincipal";
import { isMobile } from "react-device-detect";
import {
  faInstagram,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  LoadingOutlined,
  TrophyOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "../../pages/new-estagio-artista/NewEstagioArtista.scss";
import "./Metas.scss";
import { getRatioTranslateKey } from "shared/enums/MilestoneRatio";
import { MilestoneRatioPeriod } from "shared/enums/MilestoneRatioPeriod";
import { getLoggedUserCashAdvanceAvailable } from "explorer/reducers/adiantamentos";

interface MetasProps {}

const Metas: React.FC<MetasProps> = (_) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [metas, setMetas] = useState<Goals>();
  const [milestoneRatioPeriod, setMilestoneRatioPeriod] =
    useState<MilestoneRatioPeriod>(MilestoneRatioPeriod.OneWeek);
  const [loading, setLoading] = useState<boolean>(false);
  const scrollContainerRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState<
    "spotify" | "youtube" | "instagram" | "achievements"
  >("spotify");
  const { countryByKeys } = useSelector((state: any) => state.countries);
  const { userArtist } = useSelector((state: any) => state.artists) as {
    userArtist: Artist;
  };
  const noData =
    !metas?.achievements &&
    !metas?.instagram &&
    !metas?.spotify &&
    !metas?.youtube;

  const { loading: advanceLoading } = useSelector(
    (state: any) => state.advances
  );

  useEffect(() => {
    dispatch(getLoggedUserCashAdvanceAvailable(true));
  }, [dispatch]);

  const isArtistMaxStage = userArtist?.stage === 10;

  const { titulo } = t(
    `pages.EstagioArtista.estagio.${userArtist?.stage || 0}`
  ) as any;

  const milestoneRatioPeriodOptions = [
    {
      value: MilestoneRatioPeriod.OneWeek,
      label: t(`pages.Metas.periodos.1w`),
    },
    {
      value: MilestoneRatioPeriod.OneMonth,
      label: t(`pages.Metas.periodos.1m`),
    },
  ];

  const getPropBySelectedTab = () => {
    if (!metas) return [];
    return metas[selectedTab];
  };

  const getRatioTranslation = (meta: Goal) => {
    const prefixTranslate = "pages.Metas.ratioDisclaimers";
    const { ratioSelected, ratioLastWeek, completed } = meta;

    if (completed) return `${prefixTranslate}.metaConcluida`;

    const ratioSelectedTranslated = getRatioTranslateKey(ratioSelected);
    const ratioLastWeekTranslated = getRatioTranslateKey(ratioLastWeek);

    return `${prefixTranslate}.${ratioSelectedTranslated}.${ratioLastWeekTranslated}`;
  };

  const selectTabAndScroll = (
    tab: "spotify" | "youtube" | "instagram" | "achievements",
    element: any
  ) => {
    setSelectedTab(tab);
    element.target.scrollIntoView({ behavior: "smooth", inline: "start" });
  };

  const getMeta = useCallback(async () => {
    if (isArtistMaxStage) return;

    if (userArtist.id) {
      setLoading(true);
      const response: any = await fetchArtistMilestones(
        userArtist.id,
        milestoneRatioPeriod
      );
      setMetas(response);
      setLoading(false);
    }
  }, [userArtist.id, milestoneRatioPeriod, isArtistMaxStage]);

  useEffect(() => {
    getMeta();
  }, [getMeta]);

  const RenderNoData = () => {
    return (
      <>
        {isArtistMaxStage ? (
          <div className="meta-container">
            <div className="meta-card ">
              <p className="max-stage-text">
                <Trans i18nKey={"pages.Metas.maxStageMessage"} />
              </p>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: 32,
            }}
          >
            {t(`pages.Metas.noData`)}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="wrapper-metas">
      <div
        className={`background-page ${isMobile ? "mobile" : ""}`}
        style={{ backgroundImage: `url(${userArtist?.profileImage})` }}
      />
      <div className={`metas ${isMobile ? "mobile" : ""}`}>
        <Row>
          <Col xs={24} md={12}>
            <div className="perfil-card">
              <div
                className="background"
                style={{ backgroundImage: `url(${userArtist?.profileImage})` }}
              />
              <div className="perfil-detalhes default-padding ">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="perfil-stats">
                    <span className="pais">
                      {getCountryByKey(
                        userArtist?.country,
                        countryByKeys,
                        i18n
                      )?.toUpperCase()}
                    </span>
                    <div className="nome-genero">
                      <span className="nome">{userArtist?.name}</span>
                      <GenreTagList genreList={userArtist.genresStrm} />
                    </div>

                    <button
                      className="botao-branco-vazado"
                      onClick={() => history.push("/estagio")}
                    >
                      {t(`pages.Metas.diagnostico`)}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                  <div>
                    <Progress
                      type="circle"
                      percent={userArtist?.stagePercentual}
                      width={110}
                      strokeColor="#00D88A"
                      trailColor="#004129"
                      format={(value) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 4,
                          }}
                        >
                          <span style={{ fontSize: 36, color: "#f2f2f2" }}>
                            {userArtist?.stage}
                          </span>
                          <span
                            style={{
                              fontSize: 14,
                              width: 80,
                              color: "#f2f2f2",
                            }}
                          >
                            {titulo}
                          </span>
                          <span style={{ fontSize: 11, color: "#00D88A" }}>
                            {value}%
                          </span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Col
              xs={0}
              sm={24}
              className="estagio-novo mobile"
              style={{ marginTop: 16 }}
            >
              <CardCTAPrincipal />
            </Col>
          </Col>
          <Col
            xs={24}
            md={12}
            className={`${!isMobile && "right-side-desktop"}`}
          >
            <Spin
              spinning={loading || advanceLoading}
              indicator={
                <LoadingOutlined style={{ fontSize: 72 }} spin={false} />
              }
            >
              <div className="default-padding">
                <h1>{t(`pages.Metas.titulo`)}</h1>
                <div
                  className="scrollmenu"
                  ref={scrollContainerRef}
                  hidden={noData}
                >
                  <div className="scroll-box">
                    <Tooltip title="Spotify">
                      <button
                        className={selectedTab === "spotify" ? "selected" : ""}
                        onClick={(element) =>
                          selectTabAndScroll("spotify", element)
                        }
                      >
                        <FontAwesomeIcon icon={faSpotify} />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="scroll-box">
                    <Tooltip title="Youtube">
                      <button
                        className={selectedTab === "youtube" ? "selected" : ""}
                        onClick={(element) =>
                          selectTabAndScroll("youtube", element)
                        }
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="scroll-box">
                    <Tooltip title="Instagram">
                      <button
                        className={
                          selectedTab === "instagram" ? "selected" : ""
                        }
                        onClick={(element) =>
                          selectTabAndScroll("instagram", element)
                        }
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="scroll-box">
                    <Tooltip title={t(`pages.Metas.conquistas`)}>
                      <button
                        className={
                          selectedTab === "achievements" ? "selected" : ""
                        }
                        onClick={(element) =>
                          selectTabAndScroll("achievements", element)
                        }
                      >
                        <TrophyOutlined />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                {noData && !loading ? (
                  <RenderNoData />
                ) : (
                  <>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", marginTop: "1rem" }}
                      hidden={isArtistMaxStage}
                    >
                      <span>{t(`pages.Metas.periodo`)}</span>
                      <Space.Compact block>
                        <Button
                          icon={<CalendarOutlined />}
                          style={{
                            border: "none",
                          }}
                        />
                        <Form.Item
                          style={{ width: "100%" }}
                          className="form-item-periodo-metas"
                        >
                          <Select
                            className="select-periodo-metas"
                            onChange={(val) => setMilestoneRatioPeriod(val)}
                            options={milestoneRatioPeriodOptions}
                            defaultValue={milestoneRatioPeriod}
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Space>
                    <div
                      className="meta-container"
                      style={{ minHeight: loading ? 200 : 0 }}
                    >
                      <Collapse
                        className="collapse-container"
                        expandIconPosition="end"
                        expandIcon={({ isActive }) => (
                          <button className="botao-collapse">
                            {isActive ? <UpOutlined /> : <DownOutlined />}
                          </button>
                        )}
                      >
                        {getPropBySelectedTab()?.map((meta, index) => (
                          <Collapse.Panel
                            key={meta.name}
                            header={<Meta {...meta} />}
                            className="header-meta"
                          >
                            <p className="paragraph-disclaimer">
                              <InfoCircleOutlined />
                              {t("pages.Metas.insight")}
                            </p>
                            <p>
                              <Trans i18nKey={getRatioTranslation(meta)} />
                            </p>
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    </div>
                  </>
                )}
                <Col
                  xs={24}
                  sm={0}
                  className="estagio-novo mobile"
                  style={{ marginTop: 16 }}
                >
                  <CardCTAPrincipal />
                </Col>
              </div>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Metas;
