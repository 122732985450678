import { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { setRefPageLocalStorage } from "shared/helpers/ref-page";

const { REACT_APP_SITE_URL } = process.env;

const Afiliates: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;

  const { id }: any = match.params;
  useEffect(() => {
    setRefPageLocalStorage(id);
    const urlRedirect = `${REACT_APP_SITE_URL}${id}`;

    window.location.href = urlRedirect;
  }, [id]);

  return null;
};

export default Afiliates;
