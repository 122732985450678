import { ButtonHTMLAttributes } from "react";
import "./Button.scss";

const Back: React.FC<ButtonHTMLAttributes<any>> = (props: any) => {
  const { children, ...rest } = props;
  return (
    <button {...rest} className="button back">
      {children}
    </button>
  );
};

export default Back;
