import React, { Component, ErrorInfo, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import "./Error.scss";

function ErrorFallback() {
  const { t } = useTranslation();
  return (
    <div className="conteudo-centralizado componente-erro">
      <div
        className="direction"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 50,
        }}
      >
        <h1>{t("pages.Error.message")}</h1>
        <button
          className="botao-roxo"
          onClick={() => (window.location.href = "/")}
        >
          {t("pages.Error.button")}
        </button>
      </div>
    </div>
  );
}

interface ErrorBoundaryProps {
  children: ReactNode;
  fallbackComponent?: React.ComponentType;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });

    Sentry.withScope((scope) => {
      const errorDetails = {
        componentStack: errorInfo.componentStack,
        message: "Houve um erro na aplicação.",
      };

      scope.setExtras(errorDetails);
      Sentry.captureException(error);
    });

    console.error(error);
    console.error(errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, fallbackComponent: FallbackComponent } = this.props;

    if (hasError) {
      if (FallbackComponent) {
        return <FallbackComponent />;
      }
      return <ErrorFallback />;
    }

    return children;
  }
}

export default ErrorBoundary;
