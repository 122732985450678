import { Layout } from "antd";
import { MenuLateral } from "shared/components";
import { useWindowWidth } from "shared/hooks/UseWindowWidth";
import "./Layout.scss";

const { Content } = Layout;
const NewLayout: React.FC = (props) => {
  const { children } = props;
  const { isMobile } = useWindowWidth();

  return (
    <Layout className="new-layout">
      <MenuLateral />
      <Content className={isMobile ? "mobile-content" : ""}>
        <div className="mobile-content-centralizado">{children}</div>
      </Content>
    </Layout>
  );
};

export default NewLayout;
