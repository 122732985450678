import { ResourcesConfig } from "aws-amplify";
const {
  REACT_APP_COGNITO_USER_POOL,
  REACT_APP_CLIENT_ID,
  REACT_APP_IDENTITY_POOL_ID,
} = process.env;

export const poolData: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: REACT_APP_COGNITO_USER_POOL || "",
      userPoolClientId: REACT_APP_CLIENT_ID || "",
      identityPoolId: REACT_APP_IDENTITY_POOL_ID || "",
    },
  },
};
