import { Select } from "antd";
import { useState } from "react";
import { removeDiacritics } from "shared/helpers/remove-diacritics";

interface CountryOption {
  value: string;
  label: string;
}

export const filterOptionDiacritics: any = (
  input: string,
  option: CountryOption
) => {
  const normalizedInput = removeDiacritics(input.toLowerCase());
  const normalizedOptionLabel = removeDiacritics(option.label.toLowerCase());
  return normalizedOptionLabel.includes(normalizedInput);
};

const SingleMultiselect: React.FC<any> = (props) => {
  const { onChange, mode = "multiple" } = props;
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Select
      {...props}
      mode={mode}
      open={visible}
      filterOption={filterOptionDiacritics}
      onDropdownVisibleChange={(open) => setVisible(open)}
      onChange={(value: any) => {
        if (value?.length > 1) {
          onChange(value.slice(1));
        } else onChange(value);
        setVisible(false);
      }}
    />
  );
};
export default SingleMultiselect;
