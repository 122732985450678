export enum MonthsEnum {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export const monthsData = [
  {
    label: "components.Months.janeiro",
    value: MonthsEnum.January,
  },
  {
    label: "components.Months.fevereiro",
    value: MonthsEnum.February,
  },
  {
    label: "components.Months.março",
    value: MonthsEnum.March,
  },
  {
    label: "components.Months.abril",
    value: MonthsEnum.April,
  },
  {
    label: "components.Months.maio",
    value: MonthsEnum.May,
  },
  {
    label: "components.Months.junho",
    value: MonthsEnum.June,
  },
  {
    label: "components.Months.julho",
    value: MonthsEnum.July,
  },
  {
    label: "components.Months.agosto",
    value: MonthsEnum.August,
  },
  {
    label: "components.Months.setembro",
    value: MonthsEnum.September,
  },
  {
    label: "components.Months.outubro",
    value: MonthsEnum.October,
  },
  {
    label: "components.Months.novembro",
    value: MonthsEnum.November,
  },
  {
    label: "components.Months.dezembro",
    value: MonthsEnum.December,
  },
];
