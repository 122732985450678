import { Trans, useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Col, Form, Progress, Row } from "antd";
import { toast } from "react-toastify";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import "./releases-schedule.scss";
import { ReleaseTypeEnum } from "shared/enums/ReleaseTypeEnum";
import {
  AboutArtistStep,
  FirstStep,
  ReleaseDataStep,
  StepContainer,
  SuccessfulStep,
} from "./steps";
import { useState } from "react";
import { ReleasesSchedule as ReleasesScheduleRequest } from "shared/interfaces/ReleasesSchedule";
import { createUserArtistSchedule } from "shared/service/StrmService";
import {
  Step,
  FormValuesRelease,
  StepOrdered,
  FormValues,
  releaseTypeFieldName,
} from "./interfaces";

const firstStep: Step = {
  content: () => <FirstStep />,
  title: "pages.ReleasesSchedule.tipoLancamento",
};

const aboutArtistStep: Step = {
  content: (isVisible: boolean) => <AboutArtistStep isVisible={isVisible} />,
  title: "pages.ReleasesSchedule.sobreArtista",
};

const buildOrderedSteps = (releaseDataSteps: Step[]) => {
  const appendNewSteps = [firstStep, ...releaseDataSteps, aboutArtistStep];

  return appendNewSteps.map((x: any, index: number) => {
    return {
      ...x,
      step: index + 1,
    };
  });
};

const formatReleasesToRequest = (
  type: ReleaseTypeEnum,
  releases: FormValuesRelease[]
) => {
  return releases?.map((x) => ({
    type,
    ...x,
    tracks: type === ReleaseTypeEnum.Single ? [] : x.tracks,
  }));
};

const ReleasesSchedule = () => {
  const { t } = useTranslation();

  const [steps, setSteps] = useState<StepOrdered[]>(buildOrderedSteps([]));
  const [currentStep, setCurrentStep] = useState<StepOrdered>(steps[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [successful, setSuccessful] = useState<boolean>(false);

  const [form] = Form.useForm<FormValues>();
  const eps = Form.useWatch(["eps", "0", "tracksCount"], form);
  const albums = Form.useWatch(["albums", "0", "tracksCount"], form);
  const singles = Form.useWatch(["singles", "0", "tracksCount"], form);
  const isFirstStepInvalid = !albums && !eps && !singles;

  const getProgressPercent = () => (100 / steps.length) * currentStep.step;
  const isFirstStep = () => currentStep.step === 1;
  const isLastStep = () => currentStep.step === steps.length;

  const navigateToNextStep = () => {
    setCurrentStep(steps.find((step) => step.step === currentStep.step + 1)!);
  };
  const navigateToPreviousStep = () => {
    setCurrentStep(steps.find((step) => step.step === currentStep.step - 1)!);
  };

  const submitForm = async () => {
    try {
      setLoading(true);

      const formValues = form.getFieldsValue();

      const request: ReleasesScheduleRequest = {
        releases: [
          ...formatReleasesToRequest(
            ReleaseTypeEnum.Single,
            formValues.singles
          ),
          ...formatReleasesToRequest(ReleaseTypeEnum.Album, formValues.albums),
          ...formatReleasesToRequest(ReleaseTypeEnum.EP, formValues.eps),
        ],
        aboutCreation: formValues.aboutCreation,
      };

      await createUserArtistSchedule(request);
      setSuccessful(true);
      toast.success(t("pages.ReleasesSchedule.salvoSucesso"));
    } catch (error) {
      console.log(error);
      toast.error(t("pages.ReleasesSchedule.erroAoSalvar"));
    } finally {
      setLoading(false);
    }
  };

  const createStepByReleaseType = (
    releaseType: ReleaseTypeEnum,
    releases: any[]
  ): Step[] => {
    const releaseTypeTitle: { [key in ReleaseTypeEnum]: string } = {
      [ReleaseTypeEnum.Single]: "titleSingle",
      [ReleaseTypeEnum.EP]: "titleEp",
      [ReleaseTypeEnum.Album]: "titleAlbum",
    };

    return releases?.map((_, index): Step => {
      const fieldName = releaseTypeFieldName[releaseType];
      return {
        title: "pages.ReleasesSchedule.sobreOsLancamentos",
        releaseType: releaseType,
        formListIndex: index,
        content: (isVisible: boolean) => (
          <ReleaseDataStep
            count={[index + 1, releases.length]}
            name={[fieldName, index.toString()]}
            title={`pages.ReleasesSchedule.steps.release.${releaseTypeTitle[releaseType]}`}
            isVisible={isVisible}
          />
        ),
      };
    });
  };

  const nextStep = async () => {
    if (isLastStep()) {
      return await submitForm();
    } else if (isFirstStep()) {
      const newSteps: Step[] = [];

      const releaseTypes = [
        ReleaseTypeEnum.Single,
        ReleaseTypeEnum.EP,
        ReleaseTypeEnum.Album,
      ];

      const releases = form.getFieldsValue(
        releaseTypes.map((type) => releaseTypeFieldName[type])
      );

      releaseTypes.forEach((type) => {
        const release = releases[releaseTypeFieldName[type].toLowerCase()];
        if (release) {
          newSteps.push(...createStepByReleaseType(type, release));
        }
      });

      const orderedSteps = buildOrderedSteps(newSteps);

      setSteps(orderedSteps);
      setCurrentStep(orderedSteps[1]);
    } else {
      navigateToNextStep();
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (successful) {
    return <SuccessfulStep />;
  }

  return (
    <div className={`container-releases-schedule ${isMobile && "mobile"}`}>
      <div className="content">
        <Row justify="space-between" className="wrapper-progress-bar">
          <div className="text">{t(currentStep?.title ?? "")}</div>
          <div className="text">
            <Trans
              i18nKey={t("pages.ReleasesSchedule.progressBar.progress", {
                currentStep: currentStep?.step,
                totalSteps: steps.length,
              })}
            />
          </div>
          <Progress
            percent={getProgressPercent()}
            showInfo={false}
            strokeColor="#CECDFF"
          />
        </Row>
        <Form
          layout="vertical"
          form={form}
          initialValues={{ singles: [], eps: [], albums: [] }}
        >
          {steps.map((item, index) => (
            <div
              className="steps-content"
              key={index}
              hidden={item.step !== currentStep.step}
            >
              <StepContainer
                isVisible={item.step === currentStep.step}
                StepContent={item.content}
              />
            </div>
          ))}
        </Form>

        <Row className="btn-move-steps">
          <Col xs={24}>
            <button
              className="botao-roxo"
              style={{ width: "100%" }}
              onClick={async () => {
                await form.validateFields();
                nextStep();
              }}
              disabled={loading || isFirstStepInvalid}
            >
              {loading && <LoadingOutlined />}
              {isLastStep()
                ? t("pages.ReleasesSchedule.enviar")
                : t("pages.ReleasesSchedule.avancar")}
              <RightOutlined />
            </button>
          </Col>
          {!isFirstStep() && (
            <Col xs={24}>
              <button
                className="botao-branco-vazado"
                style={{ width: "100%" }}
                onClick={() => navigateToPreviousStep()}
                disabled={loading}
              >
                {t("pages.ReleasesSchedule.voltar")}
              </button>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ReleasesSchedule;
