import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import "./Adiantamentos.scss";
import {
  createNewCashAdvance,
  fetchAdvanceDataLoggedUser,
} from "shared/service/StrmService";
import AdiantamentoInicio from "./etapas/AdiantamentoInicio";
import AdiantamentoContratoAssinatura from "./etapas/AdiantamentoContratoAssinatura";
import AdiantamentoMigracaoDesembolso from "./etapas/AdiantamentoMigracaoDesembolso";
import AdiantamentoEnvioRelatorio from "./etapas/AdiantamentoEnvioRelatorio";
import AdiantamentoSelecaoLancamento from "./etapas/AdiantamentoSelecaoLancamento";
import AdiantamentoMensagemGeral from "./etapas/AdiantamentoMensagemGeral";
import AdiantamentoAceite from "./etapas/AdiantamentoAceite";
import { CashAdvance } from "shared/interfaces/CashAdvance";
import { AdvanceStatus, advanceStatusList } from "shared/enums/AdvanceStatus";
import AdiantamentoProgressoQuitado from "./etapas/AdiantamentoProgressoQuitado";
import AdiantamentoAlteracao from "./etapas/AdiantamentoAlteracao";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { Loader } from "shared/components";
import { kebabCase } from "lodash";
import { CashAdvanceAvailableEnum } from "shared/enums/CashAdvanceAvailable";
import PendenteBuscaMetadados from "./etapas/selecao-lancamento/PendenteBuscaMetadados";
import { getLoggedUserCashAdvanceAvailable } from "explorer/reducers/adiantamentos";
import AdiantamentoProcessamentoErro from "./etapas/AdiantamentoProcessamentoErro";
import AdiantamentoListaEspera from "./etapas/AdiantamentoListaEspera";
import AdiantamentoMigracaoTakedownPagamento from "./etapas/AdiantamentoMigracaoTakedownPagamento";

const Adiantamentos: React.FC<RouteComponentProps> = () => {
  const [advanceInfo, setAdvanceInfo] = useState<CashAdvance>();
  const [loading, setLoading] = useState<boolean>(true);
  const { loggedUser } = useSelector((state: any) => state.users);
  const { cashAdvanceAvailable, loading: advanceLoading } = useSelector(
    (state: any) => state.advances
  );
  const history = useHistory<any>();
  const dispatch = useDispatch();

  const getStatusText = (status: AdvanceStatus) => {
    const plainText = advanceStatusList[status]?.label;

    return kebabCase(plainText);
  };

  const updateQueryParam = (param: string, value: string, history: any) => {
    if (window.URL && window.URLSearchParams) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(param, value);

      history.push({
        pathname: window.location.pathname,
        search: searchParams.toString(),
      });
    }
  };

  const clearQueryParam = (param: string, history: any) => {
    if (window.URL && window.URLSearchParams) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(param);

      history.push({
        pathname: window.location.pathname,
        search: searchParams.toString(),
      });
    }
  };

  const createAdvance = useCallback(async () => {
    const response: any = await createNewCashAdvance(loggedUser.id);
    setAdvanceInfo(response);
    setLoading(false);
  }, [loggedUser]);

  const getValorAdiantamento = useCallback(async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    clearQueryParam("status", history);
    const response: any = await fetchAdvanceDataLoggedUser();

    const param = getStatusText(response?.status);

    if (response?.status === AdvanceStatus.ReleasesSelection)
      dispatch(getLoggedUserCashAdvanceAvailable(true));

    if (param) updateQueryParam("status", param, history);
    setAdvanceInfo(response);
    setLoading(false);
  }, [history, dispatch]);

  useEffect(() => {
    getValorAdiantamento();
    dispatch(getLoggedUserCashAdvanceAvailable(false));
  }, [getValorAdiantamento, dispatch]);

  if (loading || advanceLoading) return <Loader.Simple />;

  if (
    (!advanceInfo && !loading) ||
    (!loggedUser?.cashAdvanceFeatureAvailable &&
      !loggedUser?.isCashAdvanceAvailable)
  )
    return <AdiantamentoInicio createAdvance={createAdvance} />;

  if (advanceInfo?.processingError)
    return (
      <AdiantamentoProcessamentoErro
        advance={advanceInfo}
        reloadCashAdvance={getValorAdiantamento}
      />
    );

  return (
    <div className={`adiantamentos ${!isMobile && "web"}`}>
      {advanceInfo?.status === AdvanceStatus.ReleasesSelection && (
        <>
          {cashAdvanceAvailable ===
          CashAdvanceAvailableEnum.GettingReleasesMetadata ? (
            <PendenteBuscaMetadados />
          ) : (
            <AdiantamentoSelecaoLancamento
              advance={advanceInfo}
              reloadCashAdvance={getValorAdiantamento}
            />
          )}
        </>
      )}
      {advanceInfo?.status === AdvanceStatus.WaitingList && (
        <AdiantamentoListaEspera />
      )}
      {advanceInfo?.status === AdvanceStatus.AcceptedAdvance && (
        <AdiantamentoAceite
          advance={advanceInfo}
          reloadCashAdvance={getValorAdiantamento}
        />
      )}

      {advanceInfo?.status === AdvanceStatus.SubmissionOfReport && (
        <AdiantamentoEnvioRelatorio
          advance={advanceInfo}
          reloadCashAdvance={getValorAdiantamento}
        />
      )}
      {advanceInfo?.status === AdvanceStatus.AdvanceChanged && (
        <AdiantamentoAlteracao
          advance={advanceInfo}
          reloadCashAdvance={getValorAdiantamento}
        />
      )}
      {advanceInfo?.status === AdvanceStatus.DocumentationForContract && (
        <AdiantamentoContratoAssinatura
          advance={advanceInfo}
          reloadCashAdvance={getValorAdiantamento}
        />
      )}
      {(advanceInfo?.status === AdvanceStatus.WaitingContract ||
        advanceInfo?.status === AdvanceStatus.WaitingSignature ||
        advanceInfo?.status === AdvanceStatus.ReportUnderReview ||
        advanceInfo?.status === AdvanceStatus.MissingMinimumRequirements ||
        advanceInfo?.status === AdvanceStatus.IdentityValidation) && (
        <AdiantamentoMensagemGeral
          advanceStatus={advanceInfo.status}
          advance={advanceInfo}
          reloadCashAdvance={getValorAdiantamento}
        />
      )}
      {advanceInfo?.status === AdvanceStatus.DocumentationForDisbursement && (
        <AdiantamentoMigracaoDesembolso
          advance={advanceInfo}
          reloadCashAdvance={getValorAdiantamento}
        />
        // Remover esse status pois não está sendo mais usado
      )}

      {(advanceInfo?.status === AdvanceStatus.CatalogMigration ||
        advanceInfo?.status === AdvanceStatus.WaitingTakedown ||
        advanceInfo?.status === AdvanceStatus.WaitingTakedownConfirmation ||
        advanceInfo?.status === AdvanceStatus.Disbursement) && (
        <AdiantamentoMigracaoTakedownPagamento
          advance={advanceInfo}
          reloadCashAdvance={getValorAdiantamento}
        />
      )}

      {(advanceInfo?.status === AdvanceStatus.Finished ||
        advanceInfo?.status === AdvanceStatus.InProgress) && (
        <AdiantamentoProgressoQuitado
          advance={advanceInfo}
          createAdvance={createAdvance}
        />
      )}
    </div>
  );
};

export default Adiantamentos;
