import moment from "moment";
import { PageHeader } from "@ant-design/pro-layout";
import { Col, message, Row, Typography } from "antd";
import { Diagnostico } from "shared/interfaces/Diagnostico";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import {
  fetchUserCredits,
  fetchUserDiagnostics,
} from "shared/service/StrmService";
import background from "shared/assets/images/banda-background.jpg";
import { useSelector } from "react-redux";
import { TIPO_DIAGNOSTICO_UTILIZADO } from "shared/constants/StorageConsts";
import { TipoDiagnostico } from "shared/enums/TipoDiagnostico";
import ApresentacaoDiagnostico from "./ApresentacaoDiagnostico";
import { useWindowWidth } from "shared/hooks/UseWindowWidth";
import { ReactComponent as Clock } from "shared/assets/images/clock-eight.svg";
import "./Diagnosticos.scss";

const { REACT_APP_DIAGNOSE_EXAMPLE } = process.env;
const Diagnosticos: React.FC<RouteComponentProps> = (props) => {
  const { history, location } = props;
  const [diagnosticos, setDiagnosticos] = useState<Diagnostico[]>([]);
  const { loggedUser, loading } = useSelector((state: any) => state.users);
  const [creditos, setCreditos] = useState<number>(0);
  const [convites, setConvites] = useState<number>(0);
  const [dataExpiracao, setDataExpiracao] = useState("");
  const { isMobile } = useWindowWidth();
  const { t } = useTranslation();

  const formatDate = (date: string = "") => moment(date).format("DD/MM/YYYY");

  const onClickComprar = () =>
    history.push("/checkout-diagnostico", { buy: true });

  const onClickUsarCredito = () => {
    localStorage.setItem(TIPO_DIAGNOSTICO_UTILIZADO, TipoDiagnostico.User);
    history.push("/novo-diagnostico");
  };

  const onClickUserConvite = () => {
    localStorage.setItem(TIPO_DIAGNOSTICO_UTILIZADO, TipoDiagnostico.Team);
    history.push("/novo-diagnostico");
  };

  useEffect(() => {
    async function getUserDiagnostics() {
      const response: any = await fetchUserDiagnostics();
      setDiagnosticos(response);
    }

    async function getUserCredits() {
      const response: any = await fetchUserCredits();
      setCreditos(response.remainingCredits);
      setConvites(response.remainingInviteCredits);
      if (response.daysUntilExpiresInvites)
        setDataExpiracao(
          moment(response.daysUntilExpiresInvites).format("DD/MM/YYYY")
        );
    }

    getUserDiagnostics();
    getUserCredits();
    const state: any = location.state;
    if (state?.error) {
      message.error(t("pages.Diagnosticos.erroCompra"));
    }
    if (state?.buySuccess)
      message.success(t("ages.Diagnosticos.sucessoCompra"));
  }, [location, t]);

  if (loading) return null;

  if (creditos === 0 && convites === 0 && diagnosticos?.length === 0)
    return (
      <div className="diagnosticos">
        <ApresentacaoDiagnostico
          showPageHeader
          title={t("pages.Diagnosticos.PreviaDiagnostico.acelereSuaCarreira")}
        />
      </div>
    );

  return (
    <div className="diagnosticos space">
      {!isMobile && (
        <PageHeader
          title={t("pages.Diagnosticos.titulo")}
          subTitle={t("pages.Diagnosticos.subTitulo")}
        />
      )}
      <div className="content">
        <div className="textos">
          {convites > 0 && dataExpiracao && (
            <div className="tempo-restante">
              <Clock />
              <span>
                <Trans
                  i18nKey={t("pages.Diagnosticos.tempoRestante", {
                    dias: dataExpiracao,
                  })}
                />
              </span>
            </div>
          )}
          <h1 className="titulo">{t("pages.Diagnosticos.tituloStrm")}</h1>
          <p className="mensagem">
            <Trans i18nKey={t("pages.Diagnosticos.explicacao")} />
          </p>
        </div>
        <div className="div-row-reverse-mobile">
          <div className="botoes">
            {creditos === 0 && (
              <button className="botao-roxo" onClick={onClickComprar}>
                {t("pages.Diagnosticos.comprar")}
              </button>
            )}
            {creditos > 0 && (
              <button className="botao-roxo" onClick={onClickUsarCredito}>
                {t("pages.Diagnosticos.usarCredito")}
              </button>
            )}
            {convites > 0 && (
              <button className="botao-borda-roxa" onClick={onClickUserConvite}>
                {t("pages.Diagnosticos.usarConvite")}
              </button>
            )}
            <a
              className="botao-exemplo"
              href={REACT_APP_DIAGNOSE_EXAMPLE}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("pages.Diagnosticos.exemplo")}
            </a>
          </div>
          <div className="caixa-credito-convite">
            <span>
              <Trans i18nKey={t("pages.Diagnosticos.creditos", { creditos })} />
            </span>

            <span>
              <Trans i18nKey={t("pages.Diagnosticos.convites", { convites })} />
            </span>
          </div>
        </div>

        <div style={{ height: 50 }}>
          {loggedUser?.paymentPending && (
            <div className="pending-investor">
              <Clock />
              <strong>{t("pages.Diagnosticos.pagamentoPendente")}</strong>
            </div>
          )}
        </div>
        <div className="diagnosticos">
          {diagnosticos?.length > 0 && (
            <div className="lista">
              <span className="title">
                {t("pages.Diagnosticos.tituloStrm")}
              </span>
              <Row gutter={[32, 32]} justify="center" style={{ width: "100%" }}>
                {diagnosticos.map((diagnostico) => (
                  <Col key={diagnostico?.url} xs={24} md={8}>
                    {diagnostico?.pendingInvestorAnswer && (
                      <div className="pending-investor">
                        <Clock />
                        <span>
                          {t("pages.Diagnosticos.pendenteInvestidor")}
                        </span>
                      </div>
                    )}
                    <div className="card-diagnostico">
                      <img
                        alt=""
                        src={background}
                        className="imagem-diagnostico"
                      />

                      <span className="conteudo-diagnostico">
                        {diagnostico?.artistName}
                      </span>
                      <button
                        className="botao-roxo"
                        disabled={diagnostico?.pendingInvestorAnswer}
                        onClick={() =>
                          !diagnostico?.pendingInvestorAnswer &&
                          history.push("/diagnostico", diagnostico?.url)
                        }
                      >
                        {t("pages.Diagnosticos.acessar")}
                      </button>
                      <span>{formatDate(diagnostico?.createdOn)}</span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
        <Row
          justify="center"
          className="row-video"
          hidden={
            creditos === 0 && convites === 0 && diagnosticos?.length === 0
          }
        >
          <Col xs={24}>
            <Typography.Title
              style={{
                marginTop: "3rem",
                fontSize: 16,
                color: "#E6E6E6",
              }}
            >
              {t(
                "pages.Diagnosticos.PreviaDiagnostico.entendaComoInterpretarDiagnostico"
              )}
            </Typography.Title>
          </Col>
          <Col xs={24} className="borda-bottom-colorida">
            <iframe
              className="embedded-video-16-9"
              src="https://www.youtube.com/embed/ukLu7BPLJ6s"
              allowFullScreen
              title="youtube video"
              style={{ border: 0 }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Diagnosticos;
