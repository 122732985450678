import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import "./NotFound.scss";

const NotFound: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  return (
    <div className="conteudo-centralizado not-found">
      <div
        className="direction"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 50,
        }}
      >
        <h1>{t("pages.NotFound.message")}</h1>
        <button className="botao-roxo" onClick={() => history.push("/")}>
          {t("pages.NotFound.button")}
        </button>
      </div>
    </div>
  );
};

export default NotFound;
