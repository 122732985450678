export enum MilestoneRatio {
  Stable = 1,
  Descending = 2,
  Ascending = 3,
}

export const getRatioTranslateKey = (ratio: MilestoneRatio): string => {
  let key = "";

  switch (ratio) {
    case MilestoneRatio.Stable:
      key = "estavel";
      break;
    case MilestoneRatio.Descending:
      key = "decrescendo";
      break;
    case MilestoneRatio.Ascending:
      key = "crescendo";
      break;
  }

  return key;
};
