import { ConfigProvider, ThemeConfig } from "antd";

const quizTheme: ThemeConfig = {
  components: {
    Input: {
      borderRadiusLG: 4,
      colorText: "#E6E6E6",
      colorBgContainer: "#1F1D38",
      colorTextPlaceholder: "#8A8A8A",
    },
    Typography: {
      colorText: "#888",
    },
    Select: {
      colorBgContainer: "#1F1D38",
    },
  },
};

const QuizTheme: React.FC<any> = (props) => {
  const { children } = props;
  return <ConfigProvider theme={quizTheme}>{children}</ConfigProvider>;
};

export default QuizTheme;
