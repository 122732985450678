import { Anchor, Checkbox, Form, FormInstance } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CashAdvanceRelease } from "shared/interfaces/CashAdvanceRelease";
import {
  fetchAdvanceReleases,
  putCalculateCashAdvanceRelease,
} from "shared/service/StrmService";
import { formatNoSign, signByCurrency } from "shared/helpers/BRLFormat";
import { LoadingOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import FormTrackList from "./selecao-lancamento/FormTrackList";
import FieldReleaseSkeleton from "./selecao-lancamento/FieldReleaseSkeleton";
import FieldRelease, {
  findAllConnectedIsRcs,
  updateSelectedForConnectedReleases,
} from "./selecao-lancamento/FieldRelease";
import { Release } from "model/Release";
import { AnchorLinkItemProps } from "antd/es/anchor/Anchor";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import EdicaoRoyalties from "./selecao-lancamento/EdicaoRoyalties";
import { useDispatch } from "react-redux";
import { getLoggedUserCashAdvanceAvailable } from "explorer/reducers/adiantamentos";

export enum StepLancamentos {
  Selecao = 0,
  Royalties = 1,
}

const AdiantamentoSelecaoLancamento: React.FC<any> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [releases, setReleases] = useState<any>();
  const [selectedReleases, setSelectedReleases] = useState<any>([]);
  const [loadingReleases, setLoadingReleases] = useState<boolean>(true);
  const [selectedRelease, setSelectedRelease] = useState<CashAdvanceRelease>();
  const [loadingTotal, setLoadingTotal] = useState<boolean>(false);
  const [valorTotal, setValorTotal] = useState<number>(0);
  const [bufferRequest, setBufferRequest] = useState<any>();
  const [step, setStep] = useState<StepLancamentos>(StepLancamentos.Selecao);
  const dispatch = useDispatch();

  const handleChange = useCallback(async () => {
    if (!loadingTotal) {
      setLoadingTotal(true);
      const values = form.getFieldsValue(true);
      const album = values.album.filter((release: any) => release.selected);
      const single = values.single.filter((release: any) => release.selected);
      const request = {
        album,
        single,
      };
      if (single.length > 0 || album.length > 0) {
        const response: any = await putCalculateCashAdvanceRelease(
          request,
          advance.userArtistId
        );
        setValorTotal(response);
      } else {
        setValorTotal(0);
      }
      setLoadingTotal(false);
    } else {
      const values = form.getFieldsValue(true);
      setBufferRequest(values);
    }
  }, [advance, form, loadingTotal]);

  const handleCalculoRoyalties = async (lancamentos: any) => {
    setLoadingTotal(true);
    const request = {
      album: lancamentos.filter((release: any) => release.selected),
      single: lancamentos.filter((release: any) => release.selected),
    };
    const response: any = await putCalculateCashAdvanceRelease(
      request,
      advance.userArtistId
    );
    setValorTotal(response);
    setLoadingTotal(false);
  };

  const showToastOutrosLancamentos = () => {
    toast.info(
      <div className="toast-info">
        <Trans i18nKey="pages.Adiantamentos.outrosLancamentosSelecionados" />
      </div>
    );
  };

  const onChangeSelectAll = (event: CheckboxChangeEvent, type: string) => {
    event.stopPropagation();
    const selected = event.target.checked;
    const data = form.getFieldsValue(true);
    const releasesByType: Release[] = data[type];
    const otherType = type === "album" ? "single" : "album";

    const updatedReleasesByType = releasesByType.map((release) => ({
      ...release,
      selected,
    }));

    const allReleases: Release[] = [
      ...updatedReleasesByType,
      ...data[otherType],
    ];

    const connectedIsRcs: string[] = updatedReleasesByType.flatMap((release) =>
      findAllConnectedIsRcs(release, allReleases)
    );
    const result = updateSelectedForConnectedReleases(
      data,
      connectedIsRcs,
      selected
    );

    if (selected) {
      const otherSelectedCount = data[otherType].filter(
        (release: any) => release.selected
      ).length;

      const newSelectedCount = result[otherType].filter(
        (release: any) => release.selected
      ).length;
      if (otherSelectedCount !== newSelectedCount) showToastOutrosLancamentos();
    }
    form.setFieldsValue(result);
    handleChange();
  };

  const hasAnySelected = () => {
    const { album, single } = form.getFieldsValue(true);
    return (
      album?.some((item: CashAdvanceRelease) => item.selected) ||
      single?.some((item: CashAdvanceRelease) => item.selected)
    );
  };

  const canMoveStep = () => {
    return valorTotal > 0 && hasAnySelected();
  };

  const onClickMoveForward = async () => {
    const values = form.getFieldsValue(true);
    const normalizeAgain = [...values.album, ...values.single];
    setSelectedReleases(normalizeAgain);
    setStep(StepLancamentos.Royalties);
  };

  const updateRelease = (
    selectedRelease: CashAdvanceRelease | undefined,
    formTrack: FormInstance,
    formRelease: FormInstance
  ) => {
    const type = selectedRelease?.releaseType || "";
    const releasesByType: CashAdvanceRelease[] =
      formRelease.getFieldValue(type);
    const index = releasesByType
      .map((val) => val.releaseId)
      .indexOf(selectedRelease?.releaseId);

    const updatedRelease = formTrack.getFieldsValue(true);
    releasesByType[index] = updatedRelease;
    formRelease.setFieldsValue({ [type]: [...releasesByType] });

    return updatedRelease;
  };

  const updateConnectedTracks = (
    updatedRelease: Release,
    formRelease: FormInstance
  ) => {
    const type: any = updatedRelease.releaseType;
    const otherType = type === "album" ? "single" : "album";
    const allReleases: Release[] = [
      ...formRelease.getFieldValue(type),
      ...formRelease.getFieldValue(otherType),
    ];

    updatedRelease.tracks?.forEach((updatedTrack: any) => {
      const connectedIsRc = updatedTrack.isRc;
      allReleases.forEach((release) => {
        if (release.upc !== updatedRelease.upc)
          release.tracks?.forEach((track) => {
            if (track.isRc === connectedIsRc) {
              track.royaltyShare = updatedTrack.royaltyShare;
            }
          });
      });
    });

    formRelease.setFieldsValue({
      album: allReleases.filter((release) => release.releaseType === "album"),
      single: allReleases.filter((release) => release.releaseType === "single"),
    });
  };

  const onFinish = (name: string, { forms }: any) => {
    const { formTrack, formRelease } = forms;
    if (name === "formTrack") {
      const updatedRelease = updateRelease(
        selectedRelease,
        formTrack,
        formRelease
      );
      updateConnectedTracks(updatedRelease, formRelease);
      setSelectedRelease(undefined);
      handleChange();
    }
  };

  useEffect(() => {
    const getAdvanceReleases = async () => {
      const response: any = await fetchAdvanceReleases(advance.id);
      if (!response?.album?.length && !response?.single?.length)
        dispatch(getLoggedUserCashAdvanceAvailable(false));
      setReleases(response);
      const releases = [...response.album, ...response.single];
      setSelectedReleases(releases);
      setBufferRequest(response);
      setLoadingReleases(false);
    };
    if (advance) getAdvanceReleases();
  }, [advance, dispatch]);

  useEffect(() => {
    form.resetFields();
  }, [releases, form]);

  useEffect(() => {
    const calculate = async () => {
      setLoadingTotal(true);
      const response: any = await putCalculateCashAdvanceRelease(
        bufferRequest,
        advance.userArtistId
      );
      setValorTotal(response);
      setBufferRequest(undefined);
      setLoadingTotal(false);
    };
    if (!loadingTotal && bufferRequest) calculate();
  }, [bufferRequest, loadingTotal, advance, handleChange]);

  const getAnchorItens = (): AnchorLinkItemProps[] => {
    const result: AnchorLinkItemProps[] = [];
    if ((loadingReleases || releases?.album?.length) > 0)
      result.push({
        href: "#release",
        key: "release",
        title: t("pages.Adiantamentos.albuns"),
      });

    if ((loadingReleases || releases?.single?.length) > 0)
      result.push({
        href: "#single",
        key: "single",
        title: t("pages.Adiantamentos.singles"),
      });

    return result;
  };

  if (step === StepLancamentos.Royalties)
    return (
      <EdicaoRoyalties
        selectedReleases={selectedReleases}
        setSelectedReleases={setSelectedReleases}
        handleCalculoRoyalties={handleCalculoRoyalties}
        reloadCashAdvance={reloadCashAdvance}
        setStep={setStep}
        valorTotal={valorTotal}
        advance={advance}
        loadingTotal={loadingTotal}
      />
    );

  return (
    <div className="selecao-lancamentos">
      <div
        className="flex-column-between"
        style={{
          justifyContent: "inherit",
          maxWidth: isMobile ? "unset" : 650,
        }}
      >
        <Form.Provider onFormFinish={onFinish}>
          <FormTrackList release={selectedRelease} />
          <div
            className="migracao selecao-lancamento"
            style={{ display: selectedRelease ? "none" : "block" }}
          >
            <div className="texto">
              <div className="inline">
                <h1>{t("pages.Adiantamentos.selecaoLancamentoTitulo")}</h1>
              </div>
              <div className="inline">
                <span className="passos-selecao">
                  <Trans
                    i18nKey={t(`pages.Adiantamentos.selecaoLancamentoTexto`)}
                  />
                </span>
              </div>
              <div className="inline">
                <span className="passos-selecao">
                  <Trans
                    i18nKey={t(
                      `pages.Adiantamentos.selecaoLancamentoTextoDois`
                    )}
                  />
                </span>
              </div>
            </div>
          </div>
          <Form
            initialValues={releases}
            form={form}
            name="formRelease"
            onChange={handleChange}
          >
            <Anchor
              onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
              targetOffset={65}
              items={getAnchorItens()}
              style={{ display: selectedRelease ? "none" : "block" }}
            />

            <div
              style={{
                display: selectedRelease ? "none" : "block",
              }}
            >
              {loadingReleases && (
                <div id="release">
                  <div className="lista-album">
                    <FieldReleaseSkeleton />
                    <FieldReleaseSkeleton />
                    <FieldReleaseSkeleton />
                  </div>
                </div>
              )}
              {releases?.album?.length > 0 && (
                <div id="release" style={{ marginBottom: 72 }}>
                  <div className="select-todos">
                    <span>{t("pages.Adiantamentos.selecionarTodos")}</span>
                    <Checkbox onChange={(e) => onChangeSelectAll(e, "album")} />
                  </div>

                  <div className="lista-album">
                    <Form.List name="album">
                      {(fields) => (
                        <>
                          {fields.map((field: any) => (
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.album !== curValues.album
                              }
                              key={`album_${field.name}`}
                            >
                              {() => {
                                const releases = form.getFieldValue("album");
                                const release: CashAdvanceRelease =
                                  releases[field.key];
                                return (
                                  <FieldRelease
                                    field={field}
                                    release={release}
                                    type={t("pages.Adiantamentos.album")}
                                    setSelectedRelease={setSelectedRelease}
                                  />
                                );
                              }}
                            </Form.Item>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>
                </div>
              )}
              {loadingReleases && (
                <div id="single">
                  <div className="lista-album">
                    <FieldReleaseSkeleton />
                    <FieldReleaseSkeleton />
                    <FieldReleaseSkeleton />
                  </div>
                </div>
              )}
              {releases?.single?.length > 0 && (
                <div id="single" style={{ marginBottom: 132 }}>
                  <div className="select-todos">
                    <span>{t("pages.Adiantamentos.selecionarTodos")}</span>
                    <Checkbox
                      onChange={(e) => onChangeSelectAll(e, "single")}
                    />
                  </div>

                  <div className="lista-album">
                    <Form.List name="single">
                      {(fields) => (
                        <>
                          {fields.map((field: any) => (
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.single !== curValues.single
                              }
                              key={`single_${field.name}`}
                            >
                              {() => {
                                const releases = form.getFieldValue("single");
                                const release: CashAdvanceRelease =
                                  releases[field.key];
                                return (
                                  <FieldRelease
                                    field={field}
                                    release={release}
                                    type={t("pages.Adiantamentos.single")}
                                    setSelectedRelease={setSelectedRelease}
                                  />
                                );
                              }}
                            </Form.Item>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>
                </div>
              )}
            </div>
            <div
              className="caixa-total"
              style={{
                display: selectedRelease ? "none" : "flex",
                flexWrap: "wrap",
              }}
            >
              {loadingTotal ? (
                <>
                  <span>{t("pages.Adiantamentos.calculandoValores")}</span>
                  <span
                    style={{ minWidth: 100, textAlign: "right", height: 30 }}
                  >
                    <LoadingOutlined size={36} />
                  </span>
                  <button
                    className="botao-branco continuar"
                    style={{
                      flex: 1,
                      flexBasis: "auto",
                      justifyContent: "space-between",
                      marginTop: 6,
                      WebkitFlexBasis: "100%",
                    }}
                    type="button"
                    disabled
                  >
                    {t("pages.Adiantamentos.solicitarAdiantamento")}
                  </button>
                </>
              ) : (
                <>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.single !== curValues.single &&
                      prevValues.album !== curValues.album
                    }
                  >
                    {() => {
                      const showButton = canMoveStep();
                      const anySelected = hasAnySelected();
                      return (
                        <>
                          <span>
                            {showButton ? (
                              <>
                                {t("pages.Adiantamentos.selecioneLancamentos")}{" "}
                                <b style={{ fontSize: 10 }}>
                                  {t("pages.Adiantamentos.sujeitoAprovacao")}
                                </b>
                              </>
                            ) : anySelected ? (
                              t("pages.Adiantamentos.valorAbaixoMinimo")
                            ) : (
                              t(
                                "pages.Adiantamentos.nenhumLancamentoSelecionado"
                              )
                            )}
                          </span>
                          {showButton && (
                            <span className="valor-total">
                              <b> {signByCurrency(advance?.currency)}</b>{" "}
                              {formatNoSign(valorTotal, advance?.currency)}
                            </span>
                          )}
                          {showButton && (
                            <button
                              className="botao-branco continuar"
                              style={{
                                flex: 1,
                                flexBasis: "auto",
                                justifyContent: "space-between",
                                WebkitFlexBasis: "100%",
                              }}
                              type="button"
                              onClick={onClickMoveForward}
                            >
                              {t("pages.Adiantamentos.solicitarAdiantamento")}
                            </button>
                          )}
                        </>
                      );
                    }}
                  </Form.Item>
                </>
              )}
            </div>
          </Form>
        </Form.Provider>
      </div>
    </div>
  );
};

export default AdiantamentoSelecaoLancamento;
