import { Form, Radio, Space, Input, DatePicker, RadioChangeEvent } from "antd";
import moment from "moment";
import { ReleaseTypeEnum } from "shared/enums/ReleaseTypeEnum";
import { ReactComponent as ExclamationTriangleOutlined } from "shared/assets/images/exclamation-triangle.svg";
import { getLocaleDatePickerByI18n } from "shared/helpers/getLocaleDatePickerByI18n";
import { StepProps } from "./StepProps";
import { useTranslation } from "react-i18next";

const SecondStep: React.FC<StepProps> = ({ form }) => {
  const { t } = useTranslation();
  const type = Form.useWatch("type", form);
  const releaseOn = Form.useWatch("releaseOn", form);

  const isReleaseOnInvalid = () => {
    if (!releaseOn) return false;

    return !releaseOn?.isAfter(moment().add(20, "days"));
  };

  const onChangeType = (ev: RadioChangeEvent) => {
    if (ev?.target?.value === ReleaseTypeEnum.Single)
      form?.setFieldValue("focusTrack", null);
  };

  return (
    <div>
      <Form.Item
        name="type"
        label={t("pages.Pitching.steps.second.type")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group onChange={onChangeType}>
          <Space direction="vertical">
            <Radio value={ReleaseTypeEnum.Single}>Single</Radio>
            <Radio value={ReleaseTypeEnum.EP}>EP</Radio>
            <Radio value={ReleaseTypeEnum.Album}>Álbum</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="name"
        label={t("pages.Pitching.steps.second.name")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        hidden={type === ReleaseTypeEnum.Single}
        name="focusTrack"
        label={t("pages.Pitching.steps.second.focusTrack")}
        rules={[
          {
            required: type !== ReleaseTypeEnum.Single,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="genres"
        label={t("pages.Pitching.steps.second.genres")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="releaseOn"
        label={t("pages.Pitching.steps.second.releaseOn")}
        rules={[
          {
            required: true,
          },
          {
            validator(rule, value, callback) {
              if (isReleaseOnInvalid()) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <DatePicker
          locale={getLocaleDatePickerByI18n("pt")}
          style={{ width: "100%" }}
          placeholder="dd/mm/aaaa"
          format="DD/MM/YYYY"
        />
      </Form.Item>
      {isReleaseOnInvalid() && (
        <div className="pending-info">
          <span className="col-icon">
            <ExclamationTriangleOutlined />
          </span>
          <span className="col-text">
            <p className="title">
              {t("pages.Pitching.steps.second.releaseOnInvalid")}
            </p>
            <p>{t("pages.Pitching.steps.second.releaseOnInvalidMsg")}</p>
          </span>
        </div>
      )}
      <Form.Item
        name="labelName"
        label={t("pages.Pitching.steps.second.labelName")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="feats"
        label={t("pages.Pitching.steps.second.feats")}
        style={{ marginBottom: 0 }}
      >
        <Input />
      </Form.Item>
      <span className="info-small-text">{t("pages.Pitching.opcional")}</span>
    </div>
  );
};

export default SecondStep;
