import { setCountryOptionsI18n } from "explorer/reducers/countries";
import { setGenreOptionsI18n } from "explorer/reducers/generos";
import { setLoggedUserLanguage } from "explorer/reducers/users";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function I18nLayout({ children }: { children: React.ReactNode }) {
  const { lang } = useParams<{ lang: string }>();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (lang && i18n.hasResourceBundle(lang, "explorer")) {
      i18n.changeLanguage(lang);
      dispatch(setLoggedUserLanguage(lang));
    }
  }, [lang, i18n, dispatch]);

  useEffect(() => {
    dispatch(setGenreOptionsI18n(i18n.language));
    dispatch(setCountryOptionsI18n(i18n.language));
  }, [i18n, dispatch]);

  return <>{children}</>;
}

export default I18nLayout;
