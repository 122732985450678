import { Divider, Form, Input } from "antd";
import { Trans, useTranslation } from "react-i18next";
import DistroLogo from "shared/assets/images/strm-distro-logo.png";
import { useState } from "react";
import MeuAdiantamentoHeader from "./MeuAdiantamentoHeader";
import MeuAdiantamento from "./MeuAdiantamento";
import Duvidas from "./Duvidas";
import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import { putDisbursementCashAdvance } from "shared/service/StrmService";
import { isMobile } from "react-device-detect";

const AdiantamentoMigracaoDesembolso: React.FC<any> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [showAdiantamento, setShowAdiantamento] = useState<boolean>(false);

  const onFinishFormPix = async () => {
    const values = form.getFieldsValue();
    await putDisbursementCashAdvance({
      cashAdvanceId: advance.id,
      ...values,
    });
    reloadCashAdvance();
  };

  return (
    <div
      className="migracao-catalogo-desembolso"
      style={{ marginLeft: isMobile ? "unset" : 80 }}
    >
      <div className="flex-column-between">
        <div className="migracao">
          <MeuAdiantamentoHeader
            setShowMeuAdiantamento={setShowAdiantamento}
            showMeuAdiantamento={showAdiantamento}
          />
          <Divider />
          {showAdiantamento ? (
            <MeuAdiantamento advance={advance} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                className="confirma-adiantamento-intro"
                style={{ maxWidth: isMobile ? "unset" : 500 }}
              >
                <img src={DistroLogo} alt="logo" />

                {advance?.status ===
                  AdvanceStatus.DocumentationForDisbursement && (
                  <>
                    <h1 style={{ margin: "32px 32px 0 32px" }}>
                      <Trans i18nKey="pages.Adiantamentos.adiantamentoDadosDesembolsoTitulo" />
                    </h1>
                    <p>
                      {t("pages.Adiantamentos.adiantamentoDadosDesembolsoText")}
                    </p>
                    <div style={{ marginTop: 100, width: "100%" }}>
                      <Form
                        form={form}
                        onFinish={onFinishFormPix}
                        layout="vertical"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Form.Item
                          name="pixKey"
                          label={t("pages.Adiantamentos.pix")}
                          rules={[
                            {
                              required: true,
                              message: t("pages.Adiantamentos.pixObrigatorio"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "pages.Adiantamentos.pixPlaceholder"
                            )}
                          />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                          {() => (
                            <button
                              className="botao-roxo continuar"
                              style={{ marginTop: 100, alignSelf: "center" }}
                              disabled={
                                !form.isFieldsTouched(true) ||
                                !!form
                                  .getFieldsError()
                                  .filter(({ errors }) => errors.length).length
                              }
                            >
                              {t("pages.Adiantamentos.continuar")}
                            </button>
                          )}
                        </Form.Item>
                      </Form>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="footer">
          <Duvidas />
        </div>
      </div>
    </div>
  );
};

export default AdiantamentoMigracaoDesembolso;
