import { HENRIQUE_E_JULIANO } from "shared/constants/SpecialCampaignConst";
import { SpecialCampaignEnum } from "shared/enums/SpecialCampaignEnum";

const campaignConstByEnum = (campaign: string) => {
  switch (campaign) {
    case SpecialCampaignEnum.HenriqueEJuliano:
      return HENRIQUE_E_JULIANO;
  }
  return null;
};

export const saveSessionSpecialCampaign = (specialCampaign: string | null) => {
  if (!specialCampaign || specialCampaign === null) return;
  const campaign = campaignConstByEnum(specialCampaign);
  if (campaign) {
    sessionStorage.setItem("specialCampaign", campaign);
  }
};

export const artistOriginSpecialCampaign = (specialCampaign: string) => {
  const campaign = campaignConstByEnum(specialCampaign);
  if (!campaign) return false;
  const sessionCampaign = sessionStorage.getItem("specialCampaign");
  return campaign === sessionCampaign;
};

export const removeSessionSpecialCampaign = (specialCampaign: string) => {
  const campaign = campaignConstByEnum(specialCampaign);
  if (!campaign) return false;
  sessionStorage.removeItem("specialCampaign");

  return true;
};

export const findSpecialCampaign = (listCampaign: any[], campaign: string) => {
  return listCampaign?.some((item: any) => item.name === campaign) ?? false;
};

export const getSpecialCampaign = (listCampaign: any[], campaign: string) => {
  return listCampaign?.find((item: any) => item.name === campaign) ?? null;
};
