import { Layout } from "antd";

const { Content } = Layout;
const LayoutQuiz: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout className="layout-quiz">
      <Content> {children}</Content>
    </Layout>
  );
};

export default LayoutQuiz;
