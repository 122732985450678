import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Input, Row, Select, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MaskInput, MenuHeader } from "shared/components";
import { artistRelationshipList } from "shared/enums/ArtistRelationshipEnum";
import { CopyrightAssociationEnum } from "shared/enums/CopyrightAssociationEnum";
import {
  YoutubeFilled,
  InstagramOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import SocialList from "./SocialList";
import {
  fetchArtistQuiz,
  fetchInstagramUser,
  fetchSpotifyArtists,
  fetchYoutubeArtists,
  saveQuiz,
} from "shared/service/StrmService";
import snakeToCamelCase from "shared/helpers/snakeToCamelCase";
import { DISTRO_PARAM, QUIZ_INFO } from "shared/constants/StorageConsts";
import {
  autenticado,
  handlePendingQuiz,
  hasQuizPending,
} from "shared/helpers/auth-functions";
import { getLoggedUser } from "explorer/reducers/users";
import "./Quiz.scss";
import SingleMultiselect, {
  filterOptionDiacritics,
} from "shared/components/single-multiselect/SingleMultiselect";
import { getLoggedUserArtist } from "explorer/reducers/artistas";
import QuizTheme from "shared/themes/QuizTheme";
import { isMobile } from "react-device-detect";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { validatePhone } from "shared/helpers/phone-helpers";
import { LinguagemEnum } from "shared/enums/LinguagemEnum";
import { toast } from "react-toastify";
import { saveSessionSpecialCampaign } from "shared/helpers/special-campaign-helpers";
import { UserEvent, saveUserEvent } from "event-tracker/track-user-helpers";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";
import { DefaultOptionType } from "antd/es/select";

const hasURL = (value: string) => value.includes("http") || value.includes("/");

const getQuizValueIfPendingQuiz = (lang: string) => {
  const quizInfo = JSON.parse(sessionStorage.getItem(QUIZ_INFO) || "{}");
  return hasQuizPending()
    ? quizInfo
    : {
        countryCode:
          lang === LinguagemEnum.Portugues
            ? "BR"
            : lang === LinguagemEnum.Ingles
            ? "US"
            : "",
      };
};

enum Steps {
  PERSONAL_DATA = 0,
  PLATFORMS = 1,
  FINISH = 2,
}

const Quiz: React.FC<any> = (props) => {
  const { history, location } = props;
  const urlSearch = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const id = (location.state as any)?.id;
  const [form] = Form.useForm();
  const countryCodeValue = Form.useWatch("countryCode", form);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { genreOptions, revelatorGenres } = useSelector(
    (state: any) => state.genres
  );
  const { countryOptions } = useSelector((state: any) => state.countries);
  const [simpleForm] = useState<boolean>(urlSearch.get("simple") === "true");
  const [copyrightAssociationList, setCopyrightAssociationList] = useState<
    DefaultOptionType[]
  >([]);
  const [specialCampaign] = useState<boolean>(
    urlSearch.get("special-campaign") !== null
  );
  const [distroFlow] = useState(urlSearch.get("distro") === "true");
  const [current, setCurrent] = useState<Steps>(Steps.PERSONAL_DATA);

  const [artistQuiz, setArtistQuiz] = useState<any>(
    getQuizValueIfPendingQuiz(i18n.language)
  );

  const isInternational = countryCodeValue !== "BR";
  const backgroundCurrent =
    current === 0 ? "zero" : current === 1 ? "um" : "tres";

  const handleBack = () => {
    if (current === Steps.PLATFORMS) setCurrent(Steps.PERSONAL_DATA);
    else history.push("/");
  };

  function splitStringAtFirstHyphen(str: string) {
    const index = str.indexOf("-");
    let genreCodes, genreAux;

    if (index !== -1) {
      genreCodes = str.slice(0, index);
      genreAux = str;
    } else {
      genreCodes = str;
      genreAux = "";
    }

    return { genreCodes: [genreCodes], genreAux };
  }

  const getQuizInfo = useCallback(async () => {
    const getCompoundGenre = (genre: string, genreOptions: any[]) => {
      const genreOptionValuesSet = new Set(
        genreOptions.map((item) => item.value)
      );

      if (genreOptionValuesSet.has(genre)) {
        return { genreCodes: [genre], genreAux: "" };
      } else {
        return splitStringAtFirstHyphen(genre);
      }
    };
    if (id) {
      const values = await fetchArtistQuiz(id);
      const genreCodes = values.genreCodes && values.genreCodes[0];
      const genres = getCompoundGenre(genreCodes, genreOptions);
      setArtistQuiz({ ...values, ...genres });
    }
  }, [id, genreOptions]);

  const handleNext = async () => {
    if (current === Steps.PERSONAL_DATA) {
      dataLayerEvent("ClickButton", {
        ...quizEvents.S4A_QUIZ_GENERAL_DATA,
        buttonName: "NEXT",
        specialCampaign: urlSearch.get("special-campaign"),
      });
      try {
        await form.validateFields([
          "name",
          "genreCodes",
          "countryCode",
          "phone",
        ]);
        setCurrent(Steps.PLATFORMS);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch {}
    } else {
      dataLayerEvent("ClickButton", {
        ...quizEvents.S4A_QUIZ_PLATFORMS_CONNECT,
        buttonName: "NEXT",
        specialCampaign: urlSearch.get("special-campaign"),
      });
      form.submit();
    }
  };

  const getSpotifyArtists = async (term: string, offset: number = 0) => {
    if (term) {
      if (hasURL(term)) {
        return [];
      } else {
        dataLayerEvent("ClickButton", {
          ...quizEvents.S4A_QUIZ_PLATFORMS_CONNECT,
          buttonName: "SEARCH_SPOTIFY_PROFILE",
          specialCampaign: urlSearch.get("special-campaign"),
        });
        saveUserEvent({ name: UserEvent.QUIZ_SEARCH_SPOTIFY });
        const artists: any = await fetchSpotifyArtists(
          term,
          countryCodeValue,
          offset
        );
        const artistsOption = artists.map(({ name, id, ...artist }: any) => ({
          name,
          id,
          avatarUrl: artist?.images[0]?.url,
        }));
        if (artistsOption.length === 0) {
          saveUserEvent({ name: UserEvent.QUIZ_SEARCH_ERROR_SPOTIFY });
          return [
            {
              name: t("quiz.pages.Questionario.spotifyNotFound"),
            },
          ];
        } else return artistsOption;
      }
    } else {
      return [];
    }
  };

  const getYoutubeArtists = async (term: string) => {
    if (term) {
      if (hasURL(term)) {
        return [];
      } else {
        dataLayerEvent("ClickButton", {
          ...quizEvents.S4A_QUIZ_PLATFORMS_CONNECT,
          buttonName: "SEARCH_YOUTUBE_PROFILE",
          specialCampaign: urlSearch.get("special-campaign"),
        });
        saveUserEvent({ name: UserEvent.QUIZ_SEARCH_YOUTUBE });
        const artists: any = await fetchYoutubeArtists(term);
        const artistsOption = artists.map((artist: any) => {
          return {
            id: artist.snippet?.channelId,
            name: artist.snippet?.title,
            avatarUrl: artist.snippet?.thumbnails?.medium?.url,
          };
        });
        if (artistsOption.length === 0) {
          saveUserEvent({ name: UserEvent.QUIZ_SEARCH_ERROR_YOUTUBE });
          return [
            {
              name: t("quiz.pages.Questionario.youtubeNotFound"),
            },
          ];
        } else return artistsOption;
      }
    } else {
      return [];
    }
  };

  const getInstagramArtists = async (term: string) => {
    if (term) {
      var regexp1 = new RegExp("^[0-9A-Za-z_.-]+$");
      const termSemArroba = term.replace(/@/g, "");
      if (regexp1.test(termSemArroba)) {
        saveUserEvent({ name: UserEvent.QUIZ_SEARCH_INSTAGRAM });
        dataLayerEvent("ClickButton", {
          ...quizEvents.S4A_QUIZ_PLATFORMS_CONNECT,
          buttonName: "SEARCH_INSTAGRAM_PROFILE",
          specialCampaign: urlSearch.get("special-campaign"),
        });
        const respose = await fetchInstagramUser(termSemArroba);
        const service = snakeToCamelCase(respose);
        if (service.serviceAvailable) {
          if (service.profileFound)
            return [
              {
                id: termSemArroba,
                name: service.profile?.artistName,
                avatarUrl: service.profile?.pictureUrl,
              },
            ];
          else {
            saveUserEvent({ name: UserEvent.QUIZ_SEARCH_ERROR_INSTAGRAM });
            return [
              {
                id: "no-data",
                name: t("quiz.pages.Questionario.instagramUserNotFound"),
              },
            ];
          }
        } else return [{ name: termSemArroba, username: termSemArroba }];
      } else
        return [
          {
            id: "no-data",
            name: t("quiz.pages.Questionario.instagramUserNotFound"),
          },
        ];
    } else return [];
  };

  const filterGenreOptions = (
    options: any[],
    countryCode: string,
    valueToHide: string
  ) => {
    if (countryCode === "BR") {
      return options.filter((option) => option.value !== valueToHide);
    }
    return options;
  };

  const processGenreOther: any = () => {
    const values = form.getFieldsValue(true);

    const genre = values.genreCodes && values.genreCodes[0];
    if (genre === "outro") {
      const genreAux = values.genreAux;
      return {
        ...values,
        genreCodes: [genreAux],
      };
    } else return values;
  };

  const pendingQuizHandler = useCallback(async () => {
    if (autenticado() && hasQuizPending()) {
      saveUserEvent({ name: UserEvent.QUIZ_SAVE_OPEN });
      setLoading(true);
      setCurrent(Steps.FINISH);
      await handlePendingQuiz((success: boolean) => {
        if (success) {
          dispatch(getLoggedUser());
          dispatch(getLoggedUserArtist());
          history.push("/estagio");
        } else {
          toast.error(t("quiz.pages.Questionario.errorSaveQuiz"));
          dispatch(getLoggedUser());
          dispatch(getLoggedUserArtist());
          history.push("/estagio");
        }
      });
    }
  }, [dispatch, history, t]);

  const onFinish = async () => {
    try {
      if (!loading) {
        setLoading(true);
        const values = processGenreOther();

        const request = { ...values };
        if (
          values.instagram.id === "no-data" &&
          values.spotify.id === "no-data" &&
          values.youtube.id === "no-data"
        ) {
          toast.error(t("quiz.pages.Questionario.atLeastOnePlatform"));
          setCurrent(Steps.PLATFORMS);
          setLoading(false);
          return;
        }

        if (autenticado()) {
          setCurrent(Steps.FINISH);
          try {
            await saveQuiz({ ...request });
            dispatch(getLoggedUser());
            dispatch(getLoggedUserArtist());
            if ((location.state as any)?.diagnose)
              history.push("/novo-diagnostico");
            else history.push("/estagio");
          } catch {
            dispatch(getLoggedUser());
            dispatch(getLoggedUserArtist());
            history.push("/estagio");
          }
        } else {
          sessionStorage.setItem(
            QUIZ_INFO,
            JSON.stringify({
              ...request,
            })
          );
          if (distroFlow) sessionStorage.setItem(DISTRO_PARAM, "true");
          history.push("/cadastre", { quiz: true });
        }
      }
    } catch {
      setCurrent(Steps.PLATFORMS);
      setLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [form, artistQuiz]);

  useEffect(() => {
    getQuizInfo();
  }, [getQuizInfo]);

  useEffect(() => {
    if (!form.isFieldsTouched()) {
      setArtistQuiz(getQuizValueIfPendingQuiz(i18n.language));
      form.resetFields();
    }
  }, [form, i18n.language]);

  useEffect(() => {
    setCopyrightAssociationList([
      {
        label: t("quiz.pages.Questionario.notAffiliated"),
        value: CopyrightAssociationEnum.NotAffiliated,
      },
      { label: "Abramus", value: CopyrightAssociationEnum.Abramus },
      { label: "Amar", value: CopyrightAssociationEnum.Amar },
      { label: "Assim", value: CopyrightAssociationEnum.Assim },
      { label: "Sbacem", value: CopyrightAssociationEnum.Sbacem },
      { label: "Sicam", value: CopyrightAssociationEnum.Sicam },
      { label: "Socinpro", value: CopyrightAssociationEnum.Socinpro },
      { label: "UBC", value: CopyrightAssociationEnum.UBC },
    ]);
  }, [t]);

  useEffect(() => {
    pendingQuizHandler();
  }, [pendingQuizHandler]);

  useEffect(() => {
    if (specialCampaign) {
      saveSessionSpecialCampaign(urlSearch.get("special-campaign"));
    }
  }, [specialCampaign, urlSearch]);

  useEffect(() => {
    if (current === Steps.PERSONAL_DATA) {
      saveUserEvent({ name: UserEvent.S4A_QUIZ_GENERAL_DATA });
      dataLayerEvent("ScreenViewed", {
        ...quizEvents.S4A_QUIZ_GENERAL_DATA,
        specialCampaign: urlSearch.get("special-campaign"),
      });
    }
    if (current === Steps.PLATFORMS) {
      saveUserEvent({ name: UserEvent.S4A_QUIZ_PLATFORMS_CONNECT });
      dataLayerEvent("ScreenViewed", {
        ...quizEvents.S4A_QUIZ_PLATFORMS_CONNECT,
        specialCampaign: urlSearch.get("special-campaign"),
      });
    }
    if (current === Steps.FINISH) {
      saveUserEvent({ name: UserEvent.S4A_QUIZ_ANALYSIS_LOADING });
      dataLayerEvent("ScreenViewed", {
        ...quizEvents.S4A_QUIZ_ANALYSIS_LOADING,
        specialCampaign: urlSearch.get("special-campaign"),
      });
    }
  }, [current, urlSearch]);

  if (loading)
    return (
      <div className={`quiz ${backgroundCurrent}`}>
        <MenuHeader current={current} />
        <div className="loading" style={{ marginTop: isMobile ? 30 : 120 }}>
          <div className="spacer"></div>
          <Spin
            indicator={
              <LoadingOutlined spin={false} style={{ color: "#F2F2F2" }} />
            }
            size="large"
          />
          <span className="aguarde">
            {t("quiz.pages.Questionario.loadingTitulo")}
          </span>
          <p className="texto" style={{ margin: isMobile ? "0 44px" : 0 }}>
            <Trans i18nKey={t("quiz.pages.Questionario.loadingTexto")} />
          </p>
        </div>
      </div>
    );

  return (
    <div className={`quiz ${backgroundCurrent}`}>
      <QuizTheme>
        <MenuHeader current={current} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div className="content">
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              initialValues={artistQuiz}
            >
              <Row hidden={current !== Steps.PERSONAL_DATA} gutter={[18, 18]}>
                <>
                  {autenticado() && (
                    <span
                      onClick={handleBack}
                      className="voltar"
                      style={{
                        position: isMobile ? "relative" : "absolute",
                        left: isMobile ? 0 : 30,
                        top: 20,
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                      {t("quiz.pages.Questionario.back")}
                    </span>
                  )}
                  <h3 className="texto-passo">
                    {t("quiz.pages.Questionario.passoUm")}
                  </h3>
                  <div className="caixa-input">
                    <Col xs={24}>
                      <Form.Item
                        label={t("quiz.pages.Questionario.nameLabel")}
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t("quiz.pages.Questionario.nameRequired"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t(
                            "quiz.pages.Questionario.typeHerePlaceholder"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.countryCode !== curValues.countryCode
                        }
                      >
                        {() => {
                          const country = form.getFieldValue("countryCode");
                          const filteredGenreOptions = filterGenreOptions(
                            genreOptions,
                            country,
                            "outro"
                          );
                          return (
                            <Form.Item
                              label={t("quiz.pages.Questionario.genreLabel")}
                              name="genreCodes"
                              colon={false}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "quiz.pages.Questionario.genreRequired"
                                  ),
                                },
                              ]}
                            >
                              <SingleMultiselect
                                options={filteredGenreOptions}
                                optionFilterProp="label"
                                placeholder={t(
                                  "quiz.pages.Questionario.selectHerePlaceholder"
                                )}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.genreCodes !== curValues.genreCodes
                      }
                    >
                      {() => {
                        const genre = form.getFieldValue("genreCodes");
                        return (
                          <Col xs={24} hidden={!genre || genre[0] !== "outro"}>
                            <Form.Item
                              label={t("quiz.pages.Questionario.genreAuxLabel")}
                              name="genreAux"
                            >
                              <Select
                                options={revelatorGenres}
                                filterOption={filterOptionDiacritics}
                                showSearch
                                placeholder={t(
                                  "quiz.pages.Questionario.selectHerePlaceholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        );
                      }}
                    </Form.Item>
                    <Col xs={24} hidden={simpleForm}>
                      <Form.Item
                        label={t("quiz.pages.Questionario.relationLabel")}
                        colon={false}
                        name="artistRelationship"
                      >
                        <Select
                          options={artistRelationshipList.map(
                            ({ label, value }: any) => ({
                              label: t(`quiz.pages.Questionario.${label}`),
                              value,
                            })
                          )}
                          placeholder={t(
                            "quiz.pages.Questionario.selectHerePlaceholder"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} hidden={simpleForm || isInternational}>
                      <Form.Item
                        label={t(
                          "quiz.pages.Questionario.copyrightAssociationLabel"
                        )}
                        colon={false}
                        name="copyrightAssociation"
                      >
                        <Select
                          options={copyrightAssociationList}
                          placeholder={t(
                            "quiz.pages.Questionario.selectHerePlaceholder"
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  <div className="caixa-input">
                    <Col xs={12}>
                      <Form.Item
                        label={t("quiz.pages.Questionario.countryLabel")}
                        colon={false}
                        name="countryCode"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "quiz.pages.Questionario.countryRequired"
                            ),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          options={countryOptions}
                          filterOption={filterOptionDiacritics}
                          notFoundContent={t("quiz.pages.Questionario.noItem")}
                          placeholder={t(
                            "quiz.pages.Questionario.selectHerePlaceholder"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} hidden={simpleForm}>
                      <Form.Item
                        label={t("quiz.pages.Questionario.stateLabel")}
                        name="state"
                        colon={false}
                      >
                        <Input
                          placeholder={t(
                            "quiz.pages.Questionario.typeHerePlaceholder"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} hidden={simpleForm}>
                      <Form.Item
                        label={t("quiz.pages.Questionario.cityLabel")}
                        name="city"
                        colon={false}
                      >
                        <Input
                          placeholder={t(
                            "quiz.pages.Questionario.typeHerePlaceholder"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} hidden={simpleForm || isInternational}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.countryCode !== curValues.countryCode
                        }
                      >
                        {() => {
                          const country = form.getFieldValue("countryCode");
                          return (
                            <Form.Item
                              label={t("quiz.pages.Questionario.phoneLabel")}
                              name="phone"
                              colon={false}
                              rules={[
                                {
                                  required:
                                    countryCodeValue === "BR" && !simpleForm,
                                  message: t(
                                    "quiz.pages.Questionario.phoneRequired"
                                  ),
                                },
                                {
                                  validator(_, value) {
                                    const { countryCode } =
                                      form.getFieldsValue();
                                    return validatePhone(
                                      countryCode,
                                      value,
                                      t("pages.MeusDados.telefoneInvalido")
                                    );
                                  },
                                },
                              ]}
                            >
                              <MaskInput
                                mask={
                                  country === "BR"
                                    ? "(99) 9999-999999"
                                    : "+99 (99) 9999-999999"
                                }
                                maskChar=" "
                                placeholder={t(
                                  "quiz.pages.Questionario.typeHerePlaceholder"
                                )}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </div>
                </>
              </Row>
              <Row hidden={current !== Steps.PLATFORMS} gutter={[18, 18]}>
                <>
                  <span
                    onClick={handleBack}
                    className="voltar"
                    style={{
                      position: isMobile ? "relative" : "absolute",
                      left: isMobile ? 0 : 30,
                      top: 20,
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    {t("quiz.pages.Questionario.back")}
                  </span>
                  <h3 className="texto-passo">
                    {t("quiz.pages.Questionario.passoDois")}
                  </h3>
                  <ol className="lista-instrucoes">
                    <li>
                      <Trans
                        i18nKey={t("quiz.pages.Questionario.intrucoesUm")}
                      />
                    </li>
                    <li>
                      <Trans
                        i18nKey={t("quiz.pages.Questionario.intrucoesDois")}
                      />
                    </li>
                    <li>
                      <Trans
                        i18nKey={t("quiz.pages.Questionario.intrucoesTres")}
                      />
                    </li>
                  </ol>
                  <Col xs={24}>
                    <Form.Item
                      name="spotify"
                      rules={[
                        {
                          required: true,
                          message: t("quiz.pages.Questionario.spotifyRequired"),
                        },
                      ]}
                    >
                      <SocialList
                        label={t("quiz.pages.Questionario.spotifyLabel")}
                        logo={
                          <FontAwesomeIcon
                            icon={faSpotify}
                            className="label-icon"
                          />
                        }
                        placeholder={t(
                          "quiz.pages.Questionario.searchPlaceholder"
                        )}
                        checkboxLabel={t("quiz.pages.Questionario.noSpotify")}
                        getListOptions={getSpotifyArtists}
                        onSelectCallback={(value: boolean) => {
                          saveUserEvent({
                            name: value
                              ? UserEvent.QUIZ_SPOTIFY_SELECTED
                              : UserEvent.QUIZ_SPOTIFY_NO_VALUE,
                          });
                        }}
                        showMore
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="youtube"
                      rules={[
                        {
                          required: true,
                          message: t("quiz.pages.Questionario.youtubeRequired"),
                        },
                      ]}
                    >
                      <SocialList
                        label={t("quiz.pages.Questionario.youtubeLabel")}
                        logo={<YoutubeFilled className="label-icon" />}
                        placeholder={t(
                          "quiz.pages.Questionario.searchPlaceholder"
                        )}
                        checkboxLabel={t("quiz.pages.Questionario.noYoutube")}
                        getListOptions={getYoutubeArtists}
                        onSelectCallback={(value: boolean) => {
                          saveUserEvent({
                            name: value
                              ? UserEvent.QUIZ_YOUTUBE_SELECTED
                              : UserEvent.QUIZ_YOUTUBE_NO_VALUE,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="instagram"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "quiz.pages.Questionario.instagramRequired"
                          ),
                        },
                      ]}
                    >
                      <SocialList
                        label={t("quiz.pages.Questionario.instagramLabel")}
                        logo={<InstagramOutlined className="label-icon" />}
                        placeholder={t(
                          "quiz.pages.Questionario.searchPlaceholder"
                        )}
                        checkboxLabel={t("quiz.pages.Questionario.noInstagram")}
                        getListOptions={getInstagramArtists}
                        onSelectCallback={(value: boolean) => {
                          saveUserEvent({
                            name: value
                              ? UserEvent.QUIZ_INSTAGRAM_SELECTED
                              : UserEvent.QUIZ_INSTAGRAM_NO_VALUE,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <div className="aviso-social">
                      <InfoCircleOutlined style={{ marginTop: 6 }} />
                      <p>{t("quiz.pages.Questionario.avisoLinkSocial")}</p>
                    </div>
                  </Col>
                </>
              </Row>
            </Form>
            <Row>
              <Col
                xs={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "32px 0",
                }}
              >
                {/* {autenticado() || current === 1 ? (
                  <button
                    className="botao-borda-roxa"
                    onClick={handleBack}
                    disabled={loading}
                  >
                    {t("quiz.pages.Questionario.back")}
                  </button>
                ) : (
                  <span></span>
                )} */}
                <button
                  className="botao-roxo continuar"
                  onClick={handleNext}
                  type="button"
                  disabled={loading}
                >
                  {t("quiz.pages.Questionario.confirm")}
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </QuizTheme>
    </div>
  );
};

export default Quiz;
