import { Modal as AntModal } from "antd";
import { useTranslation } from "react-i18next";
import "./Modal.scss";

interface ModalProps {
  onOk: () => void;
  onCancel?: () => void;
  okText?: string;
  okClass?: string;
  cancelText?: string;
  visible: boolean;
  footer?: any;
  closable?: boolean;
  maskClosable?: boolean;
  showCancelButton?: boolean;
  width?: string;
  okButtonDisabled?: boolean;
}

const Modal: React.FC<ModalProps> = (props) => {
  const {
    children,
    onCancel,
    onOk,
    okText,
    closable,
    okClass,
    cancelText,
    width,
    showCancelButton = true,
    okButtonDisabled,
    ...rest
  } = props;
  const { t } = useTranslation();
  return (
    <AntModal
      {...rest}
      closable={closable}
      maskClosable={false}
      footer={null}
      onCancel={onCancel}
      width={width}
    >
      <div className="custom-modal-content">
        {children}
        <div className="footer">
          {onCancel && showCancelButton && (
            <button className="botao-vazado" onClick={onCancel}>
              {cancelText ? cancelText : t("components.Modal.nao")}
            </button>
          )}
          <button
            className={okClass ? okClass : "botao-verde"}
            onClick={onOk}
            disabled={okButtonDisabled}
          >
            {okText ? okText : t("components.Modal.sim")}
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default Modal;
