import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as StrmMusicTierIcon } from "shared/assets/images/icon-strm-music-tier.svg";
import { ReactComponent as StarsIcon } from "shared/assets/images/icon-stars-tier.svg";
import { ReactComponent as LegendsIcon } from "shared/assets/images/icon-legends-tier.svg";
import { isMobile } from "react-device-detect";
//icon-legends-tier

const Card: React.FC<any> = (props) => {
  const { icon, tier } = props;
  const [opened, setOpened] = useState<boolean>(true);

  const { spec, titulo } = tier;
  return (
    <div className="tier">
      <div className="header">
        <h1>{titulo}</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 10,
          }}
        >
          {icon}
          {isMobile && (
            <FontAwesomeIcon
              icon={faChevronDown}
              onClick={() => setOpened(!opened)}
            />
          )}
        </div>
      </div>
      {opened && (
        <div className="content">
          <ul>
            {spec.map((spec: string, index: number) => (
              <li
                key={`STRM_TIER_SPEC_${index}`}
                className="circle-checkmark roxo"
              >
                <Trans i18nKey={spec} />
              </li>
            ))}
          </ul>{" "}
        </div>
      )}
    </div>
  );
};

const StrmMusic: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Card
      tier={t("pages.EstagioArtista.tiers.strmMusic")}
      icon={<StrmMusicTierIcon className="tier-icon" />}
    />
  );
};

const Stars: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      tier={t("pages.EstagioArtista.tiers.stars")}
      icon={<StarsIcon className="tier-icon" />}
    />
  );
};

const Legends: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Card
      tier={t("pages.EstagioArtista.tiers.legends")}
      icon={<LegendsIcon className="tier-icon" />}
    />
  );
};

const mod = { StrmMusic, Stars, Legends };
export default mod;
