import { PlayCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";

const { success } = Modal;
const EstagioArtistaCTADiagnostico: React.FC = () => {
  const showModalVideo = () => {
    success({
      width: 800,
      closable: true,
      icon: null,
      okButtonProps: {
        className: "botao-roxo",
      },
      content: (
        <iframe
          className="embedded-video-16-9"
          src="https://www.youtube.com/embed/pIXGRHuWhog"
          allowFullScreen
          title="video"
          style={{ width: "100%", border: 0, height: 400 }}
        />
      ),
    });
  };
  return (
    <div className="video-diagnostico">
      {" "}
      <button className="play-button" onClick={showModalVideo}>
        <PlayCircleFilled />
      </button>
    </div>
  );
};

export default EstagioArtistaCTADiagnostico;
