import { Collapse, Divider, Select, Spin } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as RelatorioIcon } from "shared/assets/images/icon-relatorios.svg";
import { ReactComponent as ErrorIcon } from "shared/assets/images/icon-alert-triangle.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import MeuAdiantamento from "./MeuAdiantamento";
import MeuAdiantamentoHeader from "./MeuAdiantamentoHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { putAssignDocumentsCashAdvance } from "shared/service/StrmService";
import DistroControl, { distroList } from "./envio-relatorio/DistroControl";
import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import Duvidas from "./Duvidas";
import { camelCase } from "lodash";
import { fetchSuportedDistros } from "shared/service/RadarService";
import { isMobile } from "react-device-detect";

const getKeyByName = (name: string) => {
  return distroList.find((distro: any) => distro.name === name);
};

const otherDistro = {
  displayName: "Outra distribuidora",
  name: "other",
};

const AdiantamentoEnvioRelatorio: React.FC<any> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const { t, i18n } = useTranslation();
  const [showMeuAdiantamento, setShowMeuAdiantamento] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSelecao, setShowSelecao] = useState<boolean>(false);
  const [suportedDistros, setSuportedDistros] = useState<any>([]);
  const [distroOptions, setDistroOptions] = useState<any[]>([]);
  const reportConsolidationIssues = useMemo(
    () =>
      advance?.reportConsolidationIssues?.filter(
        (issue: any) => issue?.level === "error"
      ),
    [advance]
  );
  const [distribuidoras, setDistribuidoras] = useState<any>(
    advance?.distros?.map((distro: any) => ({
      selectedDistro: {
        name: distro.distroName,
        key: getKeyByName(distro.distroName),
      },
      fileList: distro.selectedDocuments,
    })) || []
  );
  const closeSelecao = () => {
    setShowSelecao(false);
    const distribuidorasFiltradas = distribuidoras
      .map((item: any) => ({
        ...item,
        fileList: item.fileList
          ? item.fileList.filter((file: any) => file.status !== "error")
          : [],
      }))
      .filter((item: any) => item.fileList.length > 0);
    setDistribuidoras(distribuidorasFiltradas);
  };

  const onAddDistro = () => {
    if (!distribuidoras.some((distro: any) => !distro?.selectedDistro))
      setDistribuidoras([...distribuidoras, {}]);
  };

  const onChangeDistro = useCallback(
    (distro: any, index: number) => {
      const copy = [...distribuidoras];
      copy[index] = distro;
      setDistribuidoras(copy);
    },
    [distribuidoras]
  );

  const onDeleteDistro = (index: number) => {
    const distribuidorasFiltradas = distribuidoras.filter(
      (_: any, ind: number) => ind !== index
    );
    setDistribuidoras(distribuidorasFiltradas);
  };

  const getError = (error: any) => {
    const code = camelCase(error?.code);
    if (code === "timeGapGreaterThan1M") {
      return t("pages.Adiantamentos.ValidationErrors.timeGapGreaterThan1M", {
        gap: error?.additionalMessage,
        interpolation: {
          escapeValue: false,
        },
      });
    }
    if (!code || !i18n.exists(`pages.Adiantamentos.ValidationErrors.${code}`))
      return t("pages.Adiantamentos.erroValidacaoArquivoGeral");
    return t(`pages.Adiantamentos.ValidationErrors.${code}`);
  };

  const onClickAdvanceStep = async () => {
    const request = {
      id: advance.id,
      distros: distribuidoras.map((distro: any) => ({
        distroName: distro.selectedDistro.name,
        selectedDocuments: distro.fileList.map((file: any) => ({
          ...file,
          ...file.response,
        })),
      })),
    };
    setLoading(true);
    await putAssignDocumentsCashAdvance(request);
    setLoading(false);
    reloadCashAdvance();
    setShowSelecao(true);
  };

  const onAddDistroMostraSelecao = () => {
    setShowSelecao(true);
    onAddDistro();
  };

  const getDistroNameByI18 = (name: string) => {
    return i18n.exists(`pages.Adiantamentos.${name}`)
      ? t(`pages.Adiantamentos.${name}`)
      : name;
  };

  useEffect(() => {
    setDistroOptions(
      suportedDistros.filter(
        (item: any) =>
          !distribuidoras
            .map((distro: any) => distro?.selectedDistro?.key)
            .includes(item?.name)
      )
    );
  }, [distribuidoras, suportedDistros]);

  useEffect(() => {
    const getSuportedDistros = async () => {
      const response: any = await fetchSuportedDistros();
      setSuportedDistros([...response, otherDistro]);
    };
    getSuportedDistros();
  }, []);

  if (loading) {
    return (
      <div className="envio-relatorios">
        <div
          className="flex-column-between"
          style={{ padding: isMobile ? 0 : "48px 170px" }}
        >
          <div
            className="migracao"
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
          >
            <div className="confirma-adiantamento-intro" style={{ gap: 28 }}>
              <Logo style={{ width: 65 }} />
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: 24 }} spin={false} />
                }
              />
              <h1>{t("pages.Adiantamentos.analisandoRelatorioTitulo")}</h1>
              <p>
                <Trans
                  i18nKey={t("pages.Adiantamentos.analisandoRelatorioTexto")}
                />
              </p>
            </div>
          </div>

          <div className="footer">
            <Duvidas />
          </div>
        </div>
      </div>
    );
  }

  if (showSelecao)
    return (
      <div
        className="envio-relatorios"
        style={{
          display: isMobile ? "block" : "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: 0,
            maxWidth: isMobile ? "unset" : 650,
          }}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            color="#fff"
            onClick={closeSelecao}
            className="icone-voltar"
            style={{ margin: 12, cursor: "pointer", alignSelf: "start" }}
          />
          <div className="selecao-distro">
            {reportConsolidationIssues?.length ? (
              <>
                <ErrorIcon />
                <h3 style={{ fontSize: 22 }}>
                  {t("pages.Adiantamentos.consolidacaoErroTitle")}
                </h3>
                <ol className="lista-erro-consolidacao">
                  {reportConsolidationIssues.map((error: any) => (
                    <li key={error.code}>{getError(error)}</li>
                  ))}
                </ol>
              </>
            ) : (
              <>
                <h3>
                  {t("pages.Adiantamentos.relatoriosReceitasDigitaisTitulo")}
                </h3>
                <p>
                  {t("pages.Adiantamentos.relatoriosReceitasDigitaisTexto")}
                </p>
              </>
            )}
            {distribuidoras.map((distro: any, index: number) => (
              <DistroControl
                key={distro.selectedDistro?.key}
                advance={advance}
                distro={distro}
                onChange={(distro: any) => onChangeDistro(distro, index)}
                onDelete={() => onDeleteDistro(index)}
                suportedDistros={suportedDistros}
                distroOptions={distroOptions.map((distro) => (
                  <Select.Option
                    key={`OPTION_${distro.name}`}
                    value={distro.name}
                  >
                    {distro.displayName}
                  </Select.Option>
                ))}
              />
            ))}
            <div className="adicionar-distro">
              <button
                className="botao-borda-roxa"
                style={{ flex: 1 }}
                onClick={onAddDistro}
              >
                {t("pages.Adiantamentos.adicionarOutraDistribuidora")}
              </button>
            </div>

            {distribuidoras.some(
              (distro: any) => distro.fileList?.length > 0
            ) && (
              <div className="adicionar-distro">
                <button
                  onClick={closeSelecao}
                  className="botao-roxo continuar"
                  style={{ flex: 1, marginBottom: 40 }}
                >
                  {t("pages.Adiantamentos.continuar")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );

  return (
    <div className="envio-relatorios">
      <div className="flex-column-between">
        <div
          className="migracao envio-relatorio"
          style={{ paddingLeft: isMobile ? 24 : 94 }}
        >
          <MeuAdiantamentoHeader
            setShowMeuAdiantamento={setShowMeuAdiantamento}
            showMeuAdiantamento={showMeuAdiantamento}
          />

          <Divider />

          {showMeuAdiantamento ? (
            <div style={{ minHeight: "100vh" }}>
              <MeuAdiantamento advance={advance} />
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="texto">
                <div className="inline">
                  <h1> {t("pages.Adiantamentos.relatoriosTitulo")}</h1>
                </div>

                <p>
                  <Trans i18nKey={t("pages.Adiantamentos.relatorioText")} />
                </p>
              </div>
            </div>
          )}
        </div>
        {!showMeuAdiantamento && (
          <div
            className="relatorios-digitais"
            style={{
              marginLeft: isMobile ? "unset" : 80,
              marginBottom: isMobile ? "unset" : 80,
            }}
          >
            <div style={{ maxWidth: isMobile ? "unset" : 650 }}>
              <div className="texto" style={{ marginBottom: 64 }}>
                <h1 style={{ color: "#5779ff" }}>
                  {t("pages.Adiantamentos.relatorioInstrucaoTitulo")}
                </h1>

                <div className="instrucoes-relatorios">
                  <div>
                    <span>
                      <Trans
                        i18nKey={t(
                          "pages.Adiantamentos.relatorioInstrucaoPassoUm"
                        )}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <Trans
                        i18nKey={t(
                          "pages.Adiantamentos.relatorioInstrucaoPassoDois"
                        )}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <Trans
                        i18nKey={t(
                          "pages.Adiantamentos.relatorioInstrucaoPassoTres"
                        )}
                      />
                    </span>
                  </div>
                  <div>
                    <span>
                      <Trans
                        i18nKey="pages.Adiantamentos.relatorioInstrucaoPassoQuatro"
                        components={{
                          a: <a href="#faq-relatorio"> </a>,
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <h3>
                {t("pages.Adiantamentos.relatoriosReceitasDigitaisTitulo")}
              </h3>
              <p>{t("pages.Adiantamentos.relatoriosReceitasDigitaisTexto")}</p>
              {distribuidoras.length > 0 ? (
                <>
                  {distribuidoras.map((relatorio: any, index: number) => (
                    <div
                      key={`RELATORIO_${relatorio.file?.fileKey}_${index}`}
                      className="distro-container"
                    >
                      <div className="upload">
                        <div style={{ textAlign: "end" }}>
                          <DeleteOutlined
                            onClick={() => onDeleteDistro(index)}
                          />
                        </div>
                        <div className="selecionados">
                          <span>
                            {getDistroNameByI18(relatorio.selectedDistro.name)}
                          </span>
                        </div>
                        {relatorio.fileList.map((file: any, index: number) => (
                          <span
                            key={`${file.uuid}_${file.fileKey}_${index}`}
                            className="relatorio-arquivo"
                          >
                            <RelatorioIcon /> {file.name}{" "}
                          </span>
                        ))}
                        <button
                          className="botao-cinza"
                          onClick={() => setShowSelecao(true)}
                          style={{ width: "100%" }}
                        >
                          <EditOutlined />
                          {t("pages.Adiantamentos.editar")}
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="adicionar-distro">
                    <button
                      className="botao-borda-roxa"
                      style={{ flex: 1 }}
                      onClick={onAddDistroMostraSelecao}
                    >
                      {t("pages.Adiantamentos.adicionarOutraDistribuidora")}
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="botao-borda-roxa"
                  onClick={onAddDistroMostraSelecao}
                >
                  {t("pages.Adiantamentos.enviarRelatorio")}
                </button>
              )}

              <div className="passos-distribuidoras" id="faq-relatorio">
                <span className="titulo">
                  {t("pages.Adiantamentos.passoApassoDistribuidora")}
                </span>
                <Collapse
                  bordered={false}
                  className="faq"
                  expandIcon={() => <FontAwesomeIcon icon={faChevronDown} />}
                  expandIconPosition="end"
                >
                  {distroList?.map((distro, i) => (
                    <Collapse.Panel
                      header={t(`pages.Adiantamentos.distroExportarRelatorio`, {
                        distroName: distro.name,
                      })}
                      key={i}
                    >
                      <div className="instrucoes">
                        {new Array(distro.steps).fill(null).map((_, index) => (
                          <div className="instrucao">
                            <span className="numero">{index + 1}</span>
                            <p>
                              <Trans
                                i18nKey={t(
                                  `pages.Adiantamentos.instrucoesDistribuidora.${distro.i18key}.${index}`,
                                  {
                                    interpolation: {
                                      escapeValue: false,
                                      useRawValueToEscape: true,
                                    },
                                  }
                                )}
                              />
                            </p>
                          </div>
                        ))}
                      </div>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
            </div>
          </div>
        )}
        {!showMeuAdiantamento && (
          <div className={`footer ${distribuidoras.length && "black"}`}>
            {distribuidoras.length ? (
              <button
                className="botao-roxo continuar"
                onClick={onClickAdvanceStep}
              >
                {t("pages.Adiantamentos.continuar")}
              </button>
            ) : (
              <Duvidas />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdiantamentoEnvioRelatorio;
