import { Trans, useTranslation } from "react-i18next";
import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import Duvidas from "./Duvidas";
import MeuAdiantamento from "./MeuAdiantamento";
import { isMobile } from "react-device-detect";

const AdiantamentoProgressoQuitado: React.FC<any> = (props) => {
  const { advance, createAdvance } = props;
  const { t } = useTranslation();

  const onNovoAdiantamentoClick = async () => {
    createAdvance();
  };

  return (
    <div
      className="progresso-quitado"
      style={{ marginLeft: isMobile ? "unset" : 80 }}
    >
      <div className="flex-column-between">
        <div
          className="migracao selecao-lancamento"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="texto"
            style={{
              maxWidth: isMobile ? "unset" : 650,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div className="inline">
              {advance.status === AdvanceStatus.Finished && (
                <h1 style={{ color: "#39D68D" }}>
                  {t("pages.Adiantamentos.adiantamentoQuitadoTitulo")}
                </h1>
              )}
              {advance.status === AdvanceStatus.InProgress && (
                <h1 style={{ color: "#5779FF" }}>
                  {t("pages.Adiantamentos.adiantamentoProgressoTitulo")}
                </h1>
              )}
            </div>
            <p>
              {advance.status === AdvanceStatus.Finished && (
                <Trans
                  i18nKey={t(`pages.Adiantamentos.adiantamentoQuitadoTexto`)}
                />
              )}
              {advance.status === AdvanceStatus.InProgress && (
                <Trans
                  i18nKey={t(`pages.Adiantamentos.adiantamentoProgressoTexto`)}
                />
              )}
            </p>
            {advance.status === AdvanceStatus.Finished && (
              <button
                className="botao-roxo"
                style={{
                  width: isMobile ? "100%" : "unset",
                  fontSize: 16,
                  color: "#E6E6E6",
                  alignSelf: "center",
                }}
                onClick={onNovoAdiantamentoClick}
              >
                {t("pages.Adiantamentos.solicitarNovoAdiantamento")}
              </button>
            )}
          </div>
        </div>
        <div
          style={{
            background: isMobile ? "#121212" : "",
            marginTop: -32,
            flex: 1,
            borderRadius: "32px 32px 0px 0px",
          }}
        >
          <MeuAdiantamento
            advance={advance}
            showPaid
            showToBePaid
            showContract
          />
          <p
            style={{
              color: "#B3B3B3",
              margin: "24px 60px",
              textAlign: "center",
            }}
          >
            {t(`pages.Adiantamentos.adiantamentoProgressoDisclaimer`)}
          </p>
        </div>
        <div className="footer">
          <Duvidas />
        </div>
      </div>
    </div>
  );
};

export default AdiantamentoProgressoQuitado;
