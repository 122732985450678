import { useContext } from "react";
import { SecaoContext } from "../NewEstagioArtista";
import { Trans, useTranslation } from "react-i18next";

const CardMinhaCarreira: React.FC = () => {
  const { t } = useTranslation();
  const setSecao = useContext(SecaoContext);
  const onClick = () => {
    setSecao("carreira");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="card-outra-sessao">
      <span className="titulo">{t("pages.EstagioArtista.seuEstagio")}</span>
      <span className="divider" />
      <h1>{t("pages.EstagioArtista.minhaCarreira")}</h1>
      <p className="strong-escuro">
        <Trans i18nKey={t("pages.EstagioArtista.minhaCarreiraCTA")} />
      </p>
      <button className="botao-roxo" onClick={onClick}>
        {t("pages.EstagioArtista.saibaMais")}
      </button>
    </div>
  );
};

export default CardMinhaCarreira;
