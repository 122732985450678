import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import imgHenriqueJulianoWeb from "shared/assets/images/henrique-juliano-web.png";
import imgHenriqueJulianoMobile from "shared/assets/images/henrique-juliano-mobile.png";
import { useTranslation } from "react-i18next";
import "./CTACampanhasEspeciais.scss";
import { useSelector } from "react-redux";
import { CashAdvanceAvailableEnum } from "shared/enums/CashAdvanceAvailable";
import { useHistory } from "react-router-dom";
import { SpecialCampaignEnum } from "shared/enums/SpecialCampaignEnum";
import {
  artistOriginSpecialCampaign,
  findSpecialCampaign,
  getSpecialCampaign,
  removeSessionSpecialCampaign,
} from "shared/helpers/special-campaign-helpers";
import { ClientSpecialCampaignApprovalEnum } from "shared/enums/ClientSpecialCampaignApprovalEnum";
import { postSpecialCampaignRequest } from "shared/service/StrmService";
import { toast } from "react-toastify";
import Modais from "shared/components/modais";

interface CTAEspecialProps {
  mobile?: boolean;
}

const CardCTAHenriqueJuliano: React.FC<CTAEspecialProps> = (props) => {
  const { mobile } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [isSpecialCampaignRequestSent, setIsSpecialCampaignRequestSent] =
    useState(false);
  const [openModalElegible, setOpenModalElegible] = useState<boolean>(false);
  const [openModalNotElegible, setOpenModalNotElegible] =
    useState<boolean>(false);
  const [openModalRequest, setOpenModalRequest] = useState<boolean>(false);
  const [openModalResultRequest, setOpenModalResultRequest] =
    useState<boolean>(false);
  const { cashAdvanceAvailable } = useSelector((state: any) => state.advances);
  const { userArtist } = useSelector((state: any) => state.artists);

  const specialCampaign = getSpecialCampaign(
    userArtist?.specialCampaign,
    SpecialCampaignEnum.HenriqueEJuliano
  );

  const campanhaEspecialHenriqueJuliano = findSpecialCampaign(
    userArtist?.specialCampaign,
    SpecialCampaignEnum.HenriqueEJuliano
  );

  useEffect(() => {
    setOpenModalResultRequest(specialCampaign?.shouldNotifyArtist);
  }, [specialCampaign]);

  useEffect(() => {
    const userComFromCampaign = artistOriginSpecialCampaign(
      SpecialCampaignEnum.HenriqueEJuliano
    );

    if (userComFromCampaign && !campanhaEspecialHenriqueJuliano)
      setOpenModalNotElegible(true);
  }, [campanhaEspecialHenriqueJuliano]);

  const onClickIniciaProcesso = async () => {
    if (isSpecialCampaignRequestSent) {
      setOpenModalRequest(true);
      return;
    }

    if (
      !isSpecialCampaignRequestSent &&
      specialCampaign?.approval ===
        ClientSpecialCampaignApprovalEnum.NotRequested
    ) {
      const response: any = await postSpecialCampaignRequest({
        specialCampaign: SpecialCampaignEnum.HenriqueEJuliano,
      });
      if (response?.response?.success) {
        setIsSpecialCampaignRequestSent(true);
        setOpenModalRequest(true);
        return;
      } else {
        toast.error(
          t("pages.CampanhasEspeciais.HenriqueJuliano.erroAoSolicitar")
        );
        return;
      }
    }

    if (
      specialCampaign?.approval === ClientSpecialCampaignApprovalEnum.Approved
    ) {
      if (
        cashAdvanceAvailable === CashAdvanceAvailableEnum.Available ||
        cashAdvanceAvailable ===
          CashAdvanceAvailableEnum.GettingReleasesMetadata ||
        cashAdvanceAvailable !== CashAdvanceAvailableEnum.WaitingListAvailable
      ) {
        setOpenModalElegible(true);
        return;
      } else {
        history.push("/distribua", {
          specialCampaign: SpecialCampaignEnum.HenriqueEJuliano,
        });
        return;
      }
    }

    if (
      specialCampaign?.approval === ClientSpecialCampaignApprovalEnum.Pending
    ) {
      setOpenModalRequest(true);
      return;
    }
  };

  const ModaisRender = () => (
    <>
      <Modais.ModalCampaignHeJEligible
        open={openModalElegible}
        onCancel={() => setOpenModalElegible(false)}
      />
      <Modais.ModalCampaignHeJNotEligible
        open={openModalNotElegible}
        onCancel={() => {
          removeSessionSpecialCampaign(SpecialCampaignEnum.HenriqueEJuliano);
          setOpenModalNotElegible(false);
        }}
      />
      <Modais.ModalCampaignHeJRequest
        open={openModalRequest}
        onCancel={() => setOpenModalRequest(false)}
      />
      <Modais.ModalCampaignHeJResponse
        open={openModalResultRequest}
        onCancel={() => setOpenModalResultRequest(false)}
      />
    </>
  );

  const Content = () => (
    <div className="content">
      <h1>
        {specialCampaign?.isParticipating
          ? t("pages.EstagioArtista.parabensSeloTitulo")
          : t("pages.EstagioArtista.facaParteSeloTitulo")}
      </h1>
      <h1 className="nome">Henrique & Juliano</h1>
      <p>
        {specialCampaign?.isParticipating
          ? t("pages.EstagioArtista.parabensSeloTexto")
          : t("pages.EstagioArtista.facaParteSeloTexto")}
      </p>
      {!specialCampaign?.isParticipating && (
        <div className="botoes">
          <button className="botao-roxo" onClick={onClickIniciaProcesso}>
            {t("pages.EstagioArtista.queroFazerParte")}
          </button>
          <button className="botao-borda-roxa">
            <a
              href="https://henriqueejuliano.strmmusic.ai/"
              target="_blank"
              rel="noreferrer"
            >
              {t("pages.EstagioArtista.saibaMais")}
            </a>
          </button>
        </div>
      )}
    </div>
  );

  if (!specialCampaign) return <ModaisRender />;

  if (mobile && isMobile) {
    return (
      <div className="cta-henrique-juliano mobile">
        <ModaisRender />
        <img
          src={imgHenriqueJulianoMobile}
          alt="Henrique & Juliano"
          className="image"
        />
        {Content()}
      </div>
    );
  } else if (!mobile && !isMobile) {
    return (
      <div className="cta-henrique-juliano web">
        <ModaisRender />
        {Content()}
        <img
          src={imgHenriqueJulianoWeb}
          alt="Henrique & Juliano"
          className="image"
        />
      </div>
    );
  } else {
    return null;
  }
};

export default CardCTAHenriqueJuliano;
