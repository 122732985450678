import { message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Modal } from "shared/components";
import { cancelSubscription } from "shared/service/StrmService";

const Unsubscribe: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;
  const [userId, setUserId] = useState("");
  const { t } = useTranslation();

  const onUnsubscribeClick = async () => {
    try {
      await cancelSubscription({ userId });
      message.success("Descadastramento realizado com sucesso!");
    } catch (error) {
      // message.error("Ocorreu um erro ao desinscrever-se!");
    }
  };

  useEffect(() => {
    window.history.pushState({}, document.title, "/unsubscribe");
  }, []);

  useEffect(() => {
    if (match.params) {
      const { id }: any = match.params;
      setUserId(id);
    }
  }, [match]);

  return (
    <>
      <Modal
        visible={true}
        onOk={onUnsubscribeClick}
        okClass="botao-vermelho"
        okText={t("Home.unsubscribeButton")}
      >
        {t("Home.unsubscribe")}
      </Modal>
    </>
  );
};

export default Unsubscribe;
