import { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Music } from "shared/assets/images/music.svg";
import { ReactComponent as MusicNote } from "shared/assets/images/music-note.svg";
import { useTranslation } from "react-i18next";
import { BRLFormat, moneyFormat, ptBRFormat } from "shared/helpers/BRLFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { CashAdvanceRelease } from "shared/interfaces/CashAdvanceRelease";
import { Col, Modal, Row, Tooltip } from "antd";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { ReactComponent as RelatorioIcon } from "shared/assets/images/icon-relatorios.svg";
import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import {
  changeStatusCashAdvanceProcess,
  fetchAdvanceSignedContractUrl,
} from "shared/service/StrmService";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { openNewTab } from "shared/helpers/window-helpers";

const { confirm } = Modal;

const MeuAdiantamento: React.FC<any> = (props) => {
  const { advance, showToBePaid, showPaid } = props;
  const history = useHistory();
  const [exibeLancamentos, setExibeLancamentos] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const calculaPorcentagem = (valor: number) => {
    return valor * 100;
  };

  const cancelarAdiantamento = async () => {
    setLoading(true);
    const response: any = await changeStatusCashAdvanceProcess(
      advance.id,
      AdvanceStatus.Canceled
    );
    if (response.id)
      toast.success(t("pages.Adiantamentos.cancelarAdiantamentoSucesso"));
    else toast.error(t("pages.Adiantamentos.cancelarAdiantamentoErro"));

    setLoading(false);
    history.push("/");
  };

  const onClickCancelarAdiantamento = () => {
    confirm({
      okText: t("pages.Adiantamentos.sim"),
      okButtonProps: { className: "botao-vermelho", type: "default" },
      cancelText: t("pages.Adiantamentos.nao"),
      cancelButtonProps: { className: "botao-verde", type: "default" },
      title: t("pages.Adiantamentos.cancelarAdiantamentoMensagem"),
      className: "flex-buttons",
      onOk: () => cancelarAdiantamento(),
      onCancel: () => {},
    });
  };

  const onClickVerContrato = async () => {
    setLoading(true);
    const response: any = await fetchAdvanceSignedContractUrl();
    setLoading(false);
    openNewTab(response);
  };

  const mostraBotaoCancelar = () => {
    return (
      (advance.status < AdvanceStatus.CatalogMigration &&
        advance.status !== AdvanceStatus.AdvanceChanged &&
        advance.status !== AdvanceStatus.AcceptedAdvance) ||
      advance.status === AdvanceStatus.MissingMinimumRequirements
    );
  };

  const mostrarBotaoContrato = () => {
    return (
      advance.status > AdvanceStatus.CatalogMigration &&
      advance.status < AdvanceStatus.DocumentationForDisbursement
    );
  };

  return (
    <div>
      <div className="meu-adiantamento">
        <div style={{ maxWidth: isMobile ? "unset" : 650, width: "100%" }}>
          <div className="resumo">
            <div className="linha borda">
              <span className="valor">
                {t("pages.Adiantamentos.totalAdiantamento")}
                <br />
                <b style={{ fontSize: 12, fontWeight: 400, color: "#b3b3b3" }}>
                  {t("pages.Adiantamentos.valoresEmTranches")}
                </b>
              </span>
              <span className="valor-total">
                {moneyFormat(advance.totalAmount, advance?.currency)}
              </span>
            </div>
            <div className="linha borda">
              <span>{t("pages.Adiantamentos.estimativaQuitacao")}</span>
              <span className="valor" style={{ flex: 1, textAlign: "right" }}>
                {t("pages.Adiantamentos.quantidadeMesesQuitacao", {
                  meses: advance.expectedPaymentMonths,
                })}
              </span>
            </div>

            <div className="percentual-disclaimer">
              <span>{t("pages.Adiantamentos.aceitePercentual")}</span>
              <Tooltip
                title={t("pages.Adiantamentos.aceitePercentualDisclaimer")}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <div className="acordo">
              <div>
                <span className="cinza">
                  {t("pages.Adiantamentos.ateQuitacao")}
                </span>
                <span className="branco">
                  {t("pages.Adiantamentos.percentualAritsta", {
                    percentual: calculaPorcentagem(
                      advance.artistPercentageUntilDischarge
                    ),
                  })}
                </span>
                <span className="cinza" style={{ fontSize: 11 }}>
                  {t("pages.Adiantamentos.utilizadoParaQuitacao")}
                </span>
              </div>
              <div style={{ justifyContent: "flex-end" }}>
                <span className="branco">
                  {calculaPorcentagem(advance.strmPercentageUntilDischarge)}
                  {"% "}
                  Strm
                </span>
                <span className="cinza" style={{ fontSize: 11 }}>
                  {t("pages.Adiantamentos.feeDistribuicao")}
                </span>
              </div>
            </div>

            <div className="acordo">
              <div>
                <span className="cinza">
                  {t("pages.Adiantamentos.aposQuitacao")}
                </span>
                <span className="branco">
                  {t("pages.Adiantamentos.percentualAritsta", {
                    percentual: calculaPorcentagem(
                      advance.artistPercentageAfterDischarge
                    ),
                  })}
                </span>
                <span className="cinza" style={{ fontSize: 11 }}>
                  {t("pages.Adiantamentos.validoFinalContrato")}
                </span>
              </div>
              <div style={{ justifyContent: "flex-end" }}>
                <span className="branco">
                  {calculaPorcentagem(advance.strmPercentageAfterDischarge)}
                  {"% "}
                  Strm
                </span>
                <span className="cinza" style={{ fontSize: 11 }}>
                  {t("pages.Adiantamentos.feeDistribuicao")}
                </span>
              </div>
            </div>

            <div className="linha borda">
              <span>
                {t("pages.Adiantamentos.quantidadeMinimaLancamentos")}
              </span>
              <span className="valor" style={{ flex: 1, textAlign: "right" }}>
                {t("pages.Adiantamentos.quantidadeMinimaLancamentosCount", {
                  lancamentos: advance.minimumNumberOfReleasesRequired,
                })}
              </span>
              <Tooltip
                title={t(
                  "pages.Adiantamentos.quantidadeMinimaLancamentosDisclaimer"
                )}
              >
                <InfoCircleOutlined style={{ marginLeft: 6 }} />
              </Tooltip>
            </div>
            <div className="linha">
              <span>{t("pages.Adiantamentos.prazo")}</span>
              <span className="valor" style={{ flex: 1, textAlign: "right" }}>
                {t("pages.Adiantamentos.prazoUmAno")}
              </span>
            </div>
            {showPaid && (
              <div className="linha borda">
                <span>{t("pages.Adiantamentos.amortizado")}</span>
                <span className="valor">
                  {BRLFormat(advance.amortizedAmount || 0)}
                </span>
              </div>
            )}
            {showToBePaid && (
              <div className="linha borda">
                <span>{t("pages.Adiantamentos.valorAQuitar")}</span>
                <span className="valor">
                  {BRLFormat(advance.pendingToBePaidAmount)}
                </span>
              </div>
            )}

            {mostrarBotaoContrato() && (
              <button
                className="botao-cinza"
                style={{ width: "100%", marginTop: 24 }}
                onClick={onClickVerContrato}
                disabled={loading}
              >
                <RelatorioIcon />

                {t("pages.Adiantamentos.verContrato")}
              </button>
            )}
          </div>
          <div
            className={`lancamentos ${exibeLancamentos && "open"}`}
            onClick={() => setExibeLancamentos(!exibeLancamentos)}
          >
            <Music className="svg-stroke-roxo no-bg" />
            <span>
              {t("pages.Adiantamentos.lancamentosSelecionadosCount", {
                lancamentos: advance.selectedReleases?.length,
              })}
            </span>
            {exibeLancamentos ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </div>

          {exibeLancamentos &&
            advance.selectedReleases.map((release: CashAdvanceRelease) => (
              <div
                key={release.releaseId}
                className="card-album"
                style={{ marginBottom: 16 }}
              >
                <Row gutter={16}>
                  <Col
                    xs={8}
                    className="album-imagem"
                    style={{ marginLeft: 16 }}
                  >
                    <img src={release.releaseImageUrl} alt="" />
                  </Col>
                  <Col
                    xs={13}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                      color: "8E8E8E",
                    }}
                  >
                    {t(`pages.Adiantamentos.${release.releaseType}`)}

                    <span style={{ marginBottom: 4 }}>
                      {release.releaseName}
                    </span>
                    <div
                      style={{ display: "flex", gap: 16, alignItems: "center" }}
                    >
                      <div className="flex-gap-8">
                        <div className="flex-gap-8">
                          <FontAwesomeIcon
                            icon={faSpotify}
                            style={{ width: 20, height: 20 }}
                          />
                          <b>{ptBRFormat(release.playCount || 0)}</b>
                          <span className="play">
                            {t("pages.Adiantamentos.plays")}
                          </span>
                        </div>
                        <div className="flex-gap-8">
                          <MusicNote />

                          <b>{release?.tracks?.length}</b>
                          <span className="play">
                            {t("pages.Adiantamentos.faixas")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 16,
                  }}
                >
                  <MusicNote />
                  {t("pages.Adiantamentos.porcentagemFaturamento")}
                </div>

                {release.tracks.map((track) => (
                  <div className="linha-track" key={track.trackId}>
                    <span
                      style={{
                        flex: 1,
                      }}
                      className="ellipsis"
                    >
                      {track.trackName}
                    </span>
                    <div
                      style={{
                        width: 30,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          height: 2,
                          width: `${track.royaltyShare}%`,
                          backgroundColor: "#5779FF",
                          borderRadius: "3px 0px 0px 3px",
                        }}
                      />
                      <div
                        style={{
                          height: 2,
                          width: `${100 - (track.royaltyShare || 0)}%`,
                          backgroundColor: "#2D334E",
                          borderRadius: "0px 3px 3px 0px",
                        }}
                      />
                    </div>
                    <span
                      style={{ width: 50, textAlign: "end" }}
                      className="ellipsis"
                    >
                      {track.royaltyShare}
                    </span>
                    <span
                      style={{
                        marginLeft: -8,
                      }}
                    >
                      {" "}
                      %
                    </span>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
      {mostraBotaoCancelar() && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="botao-vermelho"
            style={{
              maxWidth: isMobile ? "unset" : 650,
              marginTop: 12,
            }}
            onClick={onClickCancelarAdiantamento}
            disabled={loading}
          >
            {t("pages.Adiantamentos.cancelarAdiantamento")}
          </button>
        </div>
      )}
    </div>
  );
};

export default MeuAdiantamento;
