import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import { ReactComponent as DistroIcon } from "shared/assets/images/icon-distro.svg";
import { ReactComponent as DiagnosticoIcon } from "shared/assets/images/icon-diagnostic.svg";
import { ReactComponent as RecomendacaoIcon } from "shared/assets/images/icon-recomendacao.svg";
import { ReactComponent as IconMeusDados } from "shared/assets/images/icon-meus-dados.svg";
import IconMeusDadosSrc from "shared/assets/images/icon-meus-dados.svg";
import { ReactComponent as IconLogout } from "shared/assets/images/icon-logout.svg";
import { ReactComponent as IconAdiantamentos } from "shared/assets/images/icon-wallet.svg";
import { ReactComponent as HomeIcon } from "shared/assets/images/icon-home.svg";
import { ReactComponent as IconMetas } from "shared/assets/images/icon-metas.svg";
import { ReactComponent as PitchinIcon } from "shared/assets/images/pitching-icon.svg";
import { ReactComponent as ReleasesScheduleIcon } from "shared/assets/images/release-schedule-icon.svg";
import { redirectDistro } from "shared/helpers/distro-helpers";
import { logout } from "shared/helpers/auth-functions";
import { LanguageChange } from "..";
import { UserTypeEnum } from "shared/enums/UserTypeEnum";
import { isMobile } from "react-device-detect";

interface ContentMenuProps {
  collapsed: boolean;
  hideElements?: boolean;
}
const ContentMenu: React.FC<ContentMenuProps> = ({
  hideElements,
  collapsed,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loggedUser } = useSelector((state: any) => state.users);
  const { userArtist } = useSelector((state: any) => state.artists);
  const isBrazilian = userArtist?.country === "BR";
  const acessoRevelator = loggedUser?.revelatorEnterpriseId;
  const isAdmin = loggedUser.typeAccess === UserTypeEnum.AdminStrm;

  let signOutClickHandler = () => logout(false);
  if (collapsed && hideElements) return <div style={{ height: 0 }}></div>;

  return (
    <>
      <div>
        {!hideElements && (
          <>
            <div className="language-select">
              <Logo style={{ width: 48 }} />
            </div>
            <div className="header-top">
              {userArtist ? (
                <div className="image">
                  <img src={userArtist?.profileImage} alt="" />
                  <span className="estagio">{userArtist?.stage}</span>
                </div>
              ) : (
                <div className="image">
                  <img src={IconMeusDadosSrc} alt="" />

                  <span className="estagio">-</span>
                </div>
              )}
              <span
                className="nome"
                style={{ display: collapsed ? "none" : "unset" }}
              >
                {userArtist?.name || loggedUser?.name}
              </span>
            </div>
          </>
        )}
        <Menu
          mode="vertical"
          selectedKeys={[history?.location?.pathname]}
          items={[
            {
              label: <Link to="/"> {t("components.MenuLateral.home")}</Link>,
              key: "/home",
              icon: <HomeIcon />,
            },
            {
              label: (
                <Link to="/metas">{t("components.MenuLateral.goals")}</Link>
              ),
              key: "/metas",
              icon: <IconMetas />,
            },
            {
              label: (
                <Link to="/recomendacoes">
                  {t("components.MenuLateral.recomendacoes")}
                </Link>
              ),
              key: "/recomendacoes",
              icon: <RecomendacaoIcon />,
              style: {
                display: isAdmin ? "block" : "none",
              },
            },
            {
              label: (
                <Link to="/adiantamentos">
                  {t("components.MenuLateral.advances")}
                </Link>
              ),
              key: "/adiantamentos",
              icon: <IconAdiantamentos />,
            },
            {
              label: t("components.MenuLateral.distro"),
              onClick: redirectDistro,
              key: "/distro",
              icon: <DistroIcon />,
              style: {
                display: acessoRevelator
                  ? collapsed
                    ? "block"
                    : "flex"
                  : "none",
              },
            },
            {
              label: (
                <Link to="/pitching">
                  {t("components.MenuLateral.pitching")}
                </Link>
              ),
              key: "/pitching",
              icon: <PitchinIcon />,
              style: {
                display:
                  (userArtist?.distributionSigned && isBrazilian) || isAdmin
                    ? "block"
                    : "none",
              },
            },
            {
              label: (
                <Link to="/releases-schedule">
                  {t("components.MenuLateral.releasesSchedule")}
                </Link>
              ),
              key: "/releases-schedule",
              icon: <ReleasesScheduleIcon />,
              style: {
                display:
                  (userArtist?.distributionSigned && isBrazilian) || isAdmin
                    ? "block"
                    : "none",
              },
            },
            {
              label: (
                <Link to="/diagnosticos">
                  {t("components.MenuLateral.diagnostico")}
                </Link>
              ),
              key: "/diagnosticos",
              icon: <DiagnosticoIcon />,
            },
          ]}
        />
      </div>
      <div>
        {!hideElements && (
          <>
            <span className="divider" />
            <Menu
              mode="vertical"
              selectedKeys={[history?.location?.pathname]}
              items={[
                {
                  label: (
                    <Link to="/meus-dados">
                      {t("components.MenuLateral.meusDados")}
                    </Link>
                  ),
                  key: "/meus-dados",
                  icon: <IconMeusDados />,
                },
                {
                  label: t("components.MenuLateral.sair"),
                  key: "logout",
                  icon: <IconLogout />,
                  onClick: signOutClickHandler,
                },
              ]}
            />
          </>
        )}
        {!isMobile && (
          <div className="language-select">
            <LanguageChange.SelectLanguageChange
              placement="topRight"
              dropdownStyle={{ zIndex: 999999, position: "fixed" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ContentMenu;
