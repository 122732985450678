import { ButtonHTMLAttributes } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./Button.scss";

interface LoadingButtonProps extends ButtonHTMLAttributes<any> {
  loading?: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = (props: any) => {
  const { loading = false, children, ...rest } = props;
  return (
    <button {...rest}>
      {loading ? <LoadingOutlined style={{ fontSize: 25 }} spin /> : children}
    </button>
  );
};

export default LoadingButton;
