import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import { ReactComponent as IconMenu } from "shared/assets/images/menu.svg";
import { ReactComponent as IconClose } from "shared/assets/images/icon-close.svg";
import { ReactComponent as IconMeusDados } from "shared/assets/images/icon-meus-dados.svg";
import IconMeusDadosSrc from "shared/assets/images/icon-meus-dados.svg";
import { ReactComponent as IconLogout } from "shared/assets/images/icon-logout.svg";
import { useState, useEffect } from "react";
import { Layout, Menu, Tooltip } from "antd";
import { isMobile } from "react-device-detect";
import "./MenuLateral.scss";
import ContentMenu from "./MenuContent";
import { Link, useHistory } from "react-router-dom";
import { logout } from "shared/helpers/auth-functions";
import { useSelector } from "react-redux";
import { LanguageChange } from "..";
const { Sider } = Layout;

declare global {
  interface Window {
    flutter_inappwebview: any;
  }
}

const MenuLateral: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { userArtist } = useSelector((state: any) => state.artists);
  const [, setMobileAppLoaded] = useState(false);
  const history = useHistory();

  let signOutClickHandler = () => logout(false);
  const handleMobileAppLoaded = () => {
    return setMobileAppLoaded(true); // useless, just to trigger a re-render
  };

  useEffect(() => {
    window.addEventListener(
      "flutterInAppWebViewPlatformReady",
      handleMobileAppLoaded
    );

    return () => {
      window.removeEventListener(
        "flutterInAppWebViewPlatformReady",
        handleMobileAppLoaded
      );
    };
  }, []);

  const handleMouseEnter = () => {
    setCollapsed(false);
  };

  const handleMouseLeave = () => {
    setCollapsed(true);
  };

  if (isMobile)
    return (
      <div className={`menu content-menu ${collapsed ? "collapsed" : ""}`}>
        <div className="menu-top">
          <div className="item">
            {collapsed ? (
              <IconMenu onClick={() => setCollapsed(false)} />
            ) : (
              <IconClose
                className="item"
                style={{ width: 32 }}
                onClick={() => setCollapsed(true)}
              />
            )}
          </div>
          <div className="item center">
            <Logo style={{ width: 48 }} />
          </div>

          <div className="item last">
            <div>
              <div className="language-select mobile">
                <LanguageChange.SelectLanguageChange
                  placement="bottomRight"
                  dropdownStyle={{ zIndex: 999999, position: "fixed" }}
                  width={45}
                />
              </div>
            </div>
            <Tooltip
              arrow={false}
              placement="bottomRight"
              rootClassName="menu-alt"
              title={
                <>
                  <span className="divider" />
                  <Menu
                    mode="vertical"
                    selectedKeys={[history?.location?.pathname]}
                    items={[
                      {
                        label: (
                          <Link to="/meus-dados">
                            {t("components.MenuLateral.meusDados")}
                          </Link>
                        ),
                        key: "/meus-dados",
                        icon: <IconMeusDados />,
                      },
                      {
                        label: t("components.MenuLateral.sair"),
                        key: "logout",
                        icon: <IconLogout />,
                        onClick: signOutClickHandler,
                      },
                    ]}
                  />
                </>
              }
            >
              {userArtist ? (
                <div className="image">
                  <img src={userArtist?.profileImage} alt="" />
                  <span className="estagio">{userArtist?.stage}</span>
                </div>
              ) : (
                <div className="image">
                  <img src={IconMeusDadosSrc} alt="" />
                  <span className="estagio">-</span>
                </div>
              )}
            </Tooltip>
          </div>
        </div>
        <div className="vertical-content">
          <ContentMenu hideElements collapsed={collapsed} />
        </div>
      </div>
    );

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      style={{ minHeight: "100vh" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="menu"
      trigger={null}
    >
      <ContentMenu collapsed={collapsed} />
    </Sider>
  );
};

export default MenuLateral;
