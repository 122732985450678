import {
  Col,
  Form,
  Input,
  Row,
  Typography,
  Button as AntButton,
  Divider,
} from "antd";
import { Button, LanguageChange } from "shared/components";
import { Trans, useTranslation } from "react-i18next";
import { signIn, fetchAuthSession } from "aws-amplify/auth";
import { RouterProps } from "react-router";
import { useDispatch } from "react-redux";
import { getLoggedUser, setLoggedUserLanguage } from "explorer/reducers/users";
import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import { ReactComponent as MailIcon } from "shared/assets/images/icon-mail.svg";
import { ReactComponent as LockIcon } from "shared/assets/images/icon-lock.svg";
import { ReactComponent as LoginIcon } from "shared/assets/images/icon-log-in.svg";
import DrumGirl from "shared/assets/images/drum-girl-purple-tall.png";
import { useEffect, useState } from "react";
import { DISTRO_PARAM, TOKEN } from "shared/constants/StorageConsts";
import { loginReduced } from "explorer/reducers/auth";
import { hasQuizPending } from "shared/helpers/auth-functions";
import { getCountries } from "explorer/reducers/countries";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import LoginTheme from "shared/themes/LoginTheme";
import { LoadingOutlined } from "@ant-design/icons";
import { getLoggedUserArtist } from "explorer/reducers/artistas";
import { toast } from "react-toastify";
import "./Login.scss";
import "../cadastre-se/CadastreSe.scss";
import { UserEvent, saveUserEvent } from "event-tracker/track-user-helpers";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";

const Login: React.FC<RouterProps> = (props) => {
  const { history } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation("explorer");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async () => {
    const values = await form.validateFields();
    setLoading(true);
    try {
      localStorage.clear();
      const { isSignedIn, nextStep } = await signIn({
        username: values.email,
        password: values.password,
      });
      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        history.push("/verificacao", {
          name: values.name,
          login: values.email,
        });
        return;
      }
      if (isSignedIn) {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};

        localStorage.setItem(TOKEN, idToken?.toString() ?? "");
        dispatch(setLoggedUserLanguage(i18n.language));
        dispatch(getLoggedUser());
        dispatch(getLoggedUserArtist());
        dispatch(loginReduced());
        dispatch(getCountries());
        const distroFlow = sessionStorage.getItem(DISTRO_PARAM) === "true";
        if (hasQuizPending()) return history.push("/quiz");
        const returnURL = sessionStorage.getItem("returnURL");
        sessionStorage.removeItem("returnURL");
        if (distroFlow) {
          history.push("/distribua");
        } else if (returnURL) history.push(returnURL);
        else history.push("/", { login: true });
      }
    } catch (error: any) {
      //redirect usuario nao verificado
      if (error.code === "UserNotConfirmedException") {
        history.push("/verificacao", {
          name: values.name,
          login: values.email,
        });
      }
      if (error.code === "NotAuthorizedException") {
        toast.error(t("pages.Login.naoAutorizado"), { autoClose: 10000 });
      } else toast.error(error.message);
    }

    setLoading(false);
  };

  const instagramWebView = window.navigator.userAgent
    .toLowerCase()
    .includes("instagram");

  useEffect(() => {
    saveUserEvent({ name: UserEvent.S4A_LOGIN });
    dataLayerEvent("ScreenViewed", {
      ...quizEvents.S4A_LOGIN,
    });
  }, []);

  return (
    <LoginTheme>
      <Row className="login">
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <LanguageChange.SelectLanguageChange />
          </div>
        )}
        <Col
          xs={isMobile ? 24 : 10}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="container" style={{ padding: isMobile ? 12 : 0 }}>
            <Row className="fedarated-buttons" gutter={18}>
              <Col xs={24}>
                <div className="logo-region">
                  <Logo
                    className="logo"
                    style={{ width: isMobile ? 80 : 100 }}
                  />
                  <span style={{ fontSize: isMobile ? 14 : 28 }}>
                    for artists
                  </span>
                </div>
              </Col>
              {isMobile && (
                <p className="mobile-copy">
                  <Trans i18nKey="pages.Login.exploreSuaCarreiraMobile" />
                </p>
              )}
              <Col span={24}>
                <Typography.Text className="text social">
                  {t("pages.CadastreSe.socialText")}
                </Typography.Text>
              </Col>
              <Col xs={12} hidden={instagramWebView}>
                <Button.Google
                  onClick={() => {
                    dataLayerEvent("ClickButton", {
                      ...quizEvents.S4A_LOGIN,
                      buttonName: "LOGIN_GOOGLE",
                    });
                  }}
                />
              </Col>
              <Col span={instagramWebView ? 24 : 12}>
                <Button.Facebook
                  onClick={() => {
                    dataLayerEvent("ClickButton", {
                      ...quizEvents.S4A_LOGIN,
                      buttonName: "LOGIN_FACEBOOK",
                    });
                  }}
                />
              </Col>
            </Row>
            <Divider className="divider">{t("pages.Login.ou")}</Divider>
            <Row>
              <Col span={24}>
                <Form
                  layout={"vertical"}
                  form={form}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="content">
                    <div className="input">
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t("pages.Login.emailObrigatorio"),
                          },
                          {
                            type: "email",
                            message: t("pages.CadastreSe.emailValido"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("pages.Login.email")}
                          size="large"
                          prefix={<MailIcon />}
                        />
                      </Form.Item>

                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t("pages.Login.senhaObrigatorio"),
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder={t("pages.Login.senha")}
                          size="large"
                          prefix={<LockIcon />}
                        />
                      </Form.Item>
                    </div>

                    <div className="enter-button">
                      <button
                        className="botao-roxo"
                        disabled={loading}
                        onClick={() => {
                          dataLayerEvent("ClickButton", {
                            ...quizEvents.S4A_LOGIN,
                            buttonName: "SIGN_IN",
                          });
                        }}
                      >
                        {loading ? (
                          <LoadingOutlined style={{ fontSize: 25 }} spin />
                        ) : (
                          <div>
                            {t("pages.Login.entrar")} <LoginIcon />
                          </div>
                        )}
                      </button>
                    </div>
                    <div className="esqueci-senha">
                      <AntButton
                        type="link"
                        htmlType="button"
                        onClick={() => {
                          saveUserEvent({
                            name: UserEvent.LOGIN_FORGOT_PASSWORD,
                          });
                          dataLayerEvent("ClickButton", {
                            ...quizEvents.S4A_LOGIN,
                            buttonName: "RECOVERY_PASSWORD",
                          });
                          history.push("/reset-senha");
                        }}
                        className="button-reset-senha"
                      >
                        {t("pages.Login.esqueciSenha")}
                      </AntButton>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
            <Row className="row-register">
              <Col span={24}>
                <div className="text-register back-login">
                  {t("pages.Login.noAccountMessage")}
                  <Link
                    style={{ marginLeft: 6 }}
                    to="/cadastre"
                    onClick={() => {
                      dataLayerEvent("ClickButton", {
                        ...quizEvents.S4A_LOGIN,
                        buttonName: "SIGN_UP",
                      });
                    }}
                  >
                    {t("pages.Login.cadastre")}
                  </Link>
                </div>
              </Col>
            </Row>
            <span
              style={{
                display: "flex",
                borderBottom: "1px solid #272727",
                marginTop: 22,
              }}
            />

            <span className="outras-plataformas-titulo">
              {t("pages.Login.acesseOutraPlataforma")}
            </span>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href={process.env.REACT_APP_STRM_INVESTOR_URL}
                className="outras-plataformas"
              >
                <span>
                  <Logo style={{ width: 67 }} />
                  for Labels
                </span>
              </a>
            </div>
          </div>
        </Col>
        <Col xs={14} hidden={isMobile}>
          <div className="caixa-copy">
            <div className="copy-content">
              {!isMobile && <LanguageChange.SelectLanguageChange />}
              <img src={DrumGirl} alt="girl playing drum" />
              <p className="copy">{t("pages.Login.exploreSuaCarreira")}</p>
            </div>
          </div>
        </Col>
      </Row>
    </LoginTheme>
  );
};

export default Login;
