import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./ClusterChart.scss";
import { Avatar } from "antd";

interface IconData {
  id: number;
  component: React.ReactNode;
  angle: number;
}

interface ClusterChartProps {
  components: React.ReactNode[];
  mainImage: string;
  width?: number;
}

const ClusterChart: React.FC<ClusterChartProps> = ({
  components,
  mainImage,
  width = 400,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [positions, setPositions] = useState<{ x: number; y: number }[]>([]);

  const radius = width / 2.5; // Ajuste o raio conforme a largura
  const iconSize = width / 2.5; // Ajuste o tamanho dos ícones conforme a largura
  const mainImageSize = width / 3; // Ajuste o tamanho da imagem principal conforme a largura

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const height = width;

    const icons: IconData[] = components.map((component, index) => ({
      id: index,
      component,
      angle: index * (360 / components.length),
    }));

    const initialPositions = icons.map((d) => {
      const x = radius * Math.cos((d.angle * Math.PI) / 180);
      const y = radius * Math.sin((d.angle * Math.PI) / 180);
      return { x, y };
    });

    setPositions(initialPositions);

    const container = svg
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    const animateIcons = () => {
      const newPositions = icons.map((d) => {
        const x =
          radius * Math.cos((d.angle * Math.PI) / 180) +
          (Math.random() - 0.5) * 40; // Aumenta a variação aleatória
        const y =
          radius * Math.sin((d.angle * Math.PI) / 180) +
          (Math.random() - 0.5) * 40; // Aumenta a variação aleatória
        return { x, y };
      });
      setPositions(newPositions);

      container
        .selectAll(".icon-group")
        .data(icons)
        .transition()
        .duration(3000)
        .attr(
          "transform",
          (d, i) => `translate(${newPositions[i].x}, ${newPositions[i].y})`
        );
    };

    const interval = setInterval(animateIcons, 3000);

    // Limpar o intervalo anterior quando o efeito é executado novamente
    return () => {
      clearInterval(interval);
      svg.selectAll("*").remove();
    };
  }, [components, width, radius]);

  return (
    <div className="cluster-container" style={{ width, height: width }}>
      {components.map((component, index) => {
        const position = positions[index];
        return (
          <div
            key={index}
            className="icon"
            style={{
              position: "absolute",
              transform: `translate(${
                width / 2 + (position?.x || 0) - iconSize / 2
              }px, ${width / 2 + (position?.y || 0) - iconSize / 2}px)`,
              width: iconSize,
              height: iconSize,
              transition: "transform 2s",
            }}
          >
            {component}
          </div>
        );
      })}
      <div
        className="main-image pulse"
        style={{ width: mainImageSize, height: mainImageSize }}
      >
        <Avatar src={mainImage} size={mainImageSize} />
      </div>
      <svg ref={svgRef} width={width} height={width}></svg>
    </div>
  );
};

export default ClusterChart;
