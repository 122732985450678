import { PageHeader } from "@ant-design/pro-layout";
import { Col, Divider, Form, Input, Popover, Row, Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { MaskInput } from "shared/components";
import { BRLFormat } from "shared/helpers/BRLFormat";
import { formCPFValidator } from "shared/helpers/cpf-validator";
import { parse } from "shared/helpers/serialization";
import { isMobile } from "react-device-detect";
import {
  fetchCheckoutDiagnosticURL,
  fetchCheckoutRegularPrice,
  validateCoupon,
  validateDiagnosePurchase,
} from "shared/service/StrmService";
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { TipoPagamento } from "shared/enums/TipoPagamento";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import siteSeguroLogo from "shared/assets/images/site-seguro-logo.png";
import boletoLogo from "shared/assets/images/boleto.png";
import caixaLogo from "shared/assets/images/caixa.png";
import pixLogo from "shared/assets/images/pix.png";
import visaLogo from "shared/assets/images/visa.png";
import masterCardLogo from "shared/assets/images/master-card.png";
import dinersClubLogo from "shared/assets/images/diners-club.png";
import americanExpressLogo from "shared/assets/images/american-express.png";
import hipercardLogo from "shared/assets/images/hipercard.png";
import "./CheckoutDiagnostico.scss";

const { REACT_APP_APPLICATION_URL } = process.env;
const { success } = Modal;
const CheckoutDiagnostico: React.FC<RouteComponentProps> = (props) => {
  const { location, history } = props;
  const state: any = location?.state || {};
  const { checkoutURL } = state;
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { loggedUser } = useSelector((state: any) => state.users);
  const [quantity, setQuantity] = useState<number>(1);
  const [maxQuantity, setMaxQuantity] = useState<number>(999);
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [codigoCoupon, setCodigoCoupon] = useState<string>("");
  const [coupon, setCoupon] = useState<any>({ valid: false });
  const [selectedPayment, setSelectedPayment] = useState<
    "" | "in-cash" | "installments" | "customer-service"
  >("");
  const [paymentType, setPaymentType] = useState<TipoPagamento | "">(
    TipoPagamento.Externo
  );
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    if (quantity < maxQuantity) setQuantity(quantity + 1);
  };

  const onApplyClick = async () => {
    const response: any = await validateCoupon(codigoCoupon);
    if (response) {
      const percentage: number = response;
      const fullDiscount = percentage === 100;
      const discountUnitPrice = unitPrice * ((100 - percentage) / 100);
      const newMaxQuantity = fullDiscount ? 1 : 10;
      setMaxQuantity(newMaxQuantity);
      setQuantity(quantity > newMaxQuantity ? newMaxQuantity : quantity);
      setCoupon({
        valid: true,
        price: discountUnitPrice,
        fullDiscount,
        percentage,
      });
    } else {
      setCoupon({ valid: false, validationError: true });
    }
  };

  const onFinish = async () => {
    const values = form.getFieldsValue(true);

    const request = {
      ...values,
      paymentType,
      returnURL: `${REACT_APP_APPLICATION_URL}/checkout-diagnostico`,
      quantityofDiag: quantity,
      userCurrentLanguage: i18n.language,
      isPromo: false,
      couponCode: coupon.valid ? codigoCoupon : "",
    };
    const response: any = await fetchCheckoutDiagnosticURL(request);

    if (coupon.fullDiscount) {
      history.push("/diagnosticos");
    } else {
      success({
        width: 800,
        closable: false,
        title:
          paymentType === TipoPagamento.Externo
            ? t("pages.EasyCheckout.redirectExternal")
            : t("pages.EasyCheckout.redirectAtendente"),
        okButtonProps: {
          className: "botao-verde",
          style: { padding: 0 },
        },
        onOk: () => {
          window.location.href = response.purchaseRedirectUrl;
        },
      });
    }
  };

  useEffect(() => {
    async function validatePurchase() {
      const returnState = parse(location.search.replace("?", ""));
      if (
        returnState.status === "approved" ||
        returnState.status === "in_process"
      ) {
        await validateDiagnosePurchase(returnState.payment_id);

        history.push("/confirmacao", { buySuccess: true });
      } else {
        if (returnState.status === "null")
          history.push("/diagnosticos", { cancel: true });
        else history.push("/diagnosticos", { error: true });
      }
    }

    if (location.search) {
      validatePurchase();
    }
  }, [location, history]);

  useEffect(() => {
    form.resetFields();
  }, [loggedUser, form]);

  useEffect(() => {
    const getDiagnossePrice = async () => {
      const response: any = await fetchCheckoutRegularPrice();
      setUnitPrice(response);
    };

    getDiagnossePrice();
  }, []);

  return (
    <div className="checkout-diagnostic">
      <PageHeader
        title={t("pages.CheckoutDiagnostico.back")}
        onBack={() => history.push("/diagnosticos")}
        style={{ position: "absolute", color: "#000" }}
      />
      <div className="easy-checkout">
        <div className="container">
          <Row>
            <Col span={12}>
              <span className="tab-like-compoment">
                {t("pages.EasyCheckout.chart")}
              </span>
            </Col>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={siteSeguroLogo}
                  alt="site-seguro"
                  style={{ width: isMobile ? 100 : 150 }}
                />
              </div>
            </Col>
            <Col xs={24} md={checkoutURL ? 24 : 12}>
              <Row
                style={{
                  borderRight: checkoutURL ? 0 : "1px solid #ececec",
                  marginTop: 24,
                }}
              >
                <Col xs={24}>
                  <span className="section-name">
                    {t("pages.EasyCheckout.personalData")}
                  </span>
                </Col>
                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="vertical"
                  style={{ width: "100%", marginRight: 32, marginTop: 24 }}
                  initialValues={{
                    userEmail: loggedUser?.login,
                    userFullName: loggedUser?.name,
                    userCpf: loggedUser?.cpf,
                    userPhone: loggedUser?.phone,
                  }}
                >
                  <Col xs={24}>
                    <Form.Item
                      name="userFullName"
                      label={t("pages.EasyCheckout.name")}
                      rules={[
                        {
                          required: true,
                          message: t("pages.EasyCheckout.nameRequired"),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("pages.EasyCheckout.namePlaceholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="userCpf"
                      label={t("pages.EasyCheckout.cpf")}
                      extra={t("pages.EasyCheckout.cpfExtra")}
                      rules={[
                        {
                          required: true,
                          message: t("pages.EasyCheckout.cpfRequired"),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            return formCPFValidator(
                              value,
                              getFieldValue,
                              "userCpf",
                              t
                            );
                          },
                        }),
                      ]}
                    >
                      <MaskInput
                        mask="999.999.999-99"
                        placeholder={t("pages.EasyCheckout.cpfPlaceholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="userPhone"
                      label={t("pages.EasyCheckout.phone")}
                      extra={t("pages.EasyCheckout.phoneExtra")}
                      rules={[
                        {
                          required: true,
                          message: t("pages.EasyCheckout.phoneRequired"),
                        },
                      ]}
                    >
                      <MaskInput
                        mask="(99) 99999-9999"
                        placeholder={t("pages.EasyCheckout.phonePlaceholder")}
                      />
                    </Form.Item>
                  </Col>
                  {checkoutURL && (
                    <div style={{ textAlign: "end", padding: "72px 52px" }}>
                      <button
                        className="botao-colorido"
                        onClick={() => form.submit()}
                      >
                        {t("pages.EasyCheckout.next")}
                      </button>
                    </div>
                  )}
                </Form>
              </Row>
            </Col>
            <Col xs={24} md={12} hidden={checkoutURL}>
              <Row style={{ marginLeft: 32 }}>
                <Col xs={24}>
                  <Row
                    justify="space-between"
                    style={{ marginTop: 32, userSelect: "none" }}
                  >
                    <Col xs={24}>
                      <span className="section-name">
                        {t("pages.EasyCheckout.buyData")}
                      </span>
                    </Col>
                    <div
                      style={{ display: "flex", gap: 6, alignItems: "center" }}
                    >
                      <span className="button-example">
                        {t("pages.EasyCheckout.credits")}
                      </span>
                      <Popover content={t("pages.EasyCheckout.creditsExplain")}>
                        <ExclamationCircleOutlined
                          color="#98A2B3"
                          style={{ color: "#98A2B3" }}
                        />
                      </Popover>
                    </div>
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <span
                        onClick={decreaseQuantity}
                        className={`icon-sign ${quantity === 0 && "disabled"}`}
                      >
                        <MinusCircleOutlined />
                      </span>
                      <span className="quantity">{` ${quantity} `}</span>
                      <span onClick={increaseQuantity} className="icon-sign">
                        <PlusCircleOutlined />
                      </span>
                    </div>
                    <div
                      style={{
                        minWidth: 100,
                        textAlign: "right",
                        fontSize: 18,
                        color: "#E4BC5C",
                        height: 55,
                      }}
                    >
                      <span>{BRLFormat(unitPrice)}</span>
                    </div>
                    <Divider />
                  </Row>
                  <Row style={{ gap: 12 }}>
                    <Form.Item
                      style={{ width: "50%" }}
                      help={
                        coupon.validationError
                          ? t("pages.EasyCheckout.invalidCoupon")
                          : ""
                      }
                      validateStatus={coupon.validationError ? "error" : ""}
                    >
                      <Input
                        placeholder={t("pages.EasyCheckout.couponPlaceholder")}
                        value={codigoCoupon}
                        onChange={(e) =>
                          setCodigoCoupon(e.target.value?.toUpperCase())
                        }
                        disabled={coupon?.valid}
                      />
                    </Form.Item>
                    <div>
                      <button
                        className="botao-azul-vazado"
                        onClick={onApplyClick}
                        disabled={coupon?.valid || !codigoCoupon}
                      >
                        {t("pages.EasyCheckout.apply")}
                      </button>
                    </div>
                    {coupon.valid && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flex: 1,
                        }}
                      >
                        <b style={{ fontSize: 16 }}>{coupon.percentage}% OFF</b>
                      </div>
                    )}
                  </Row>
                  <Row justify="space-between" style={{ marginTop: 32 }}>
                    <div>
                      <span className="button-example">
                        {t("pages.EasyCheckout.total")}
                      </span>
                    </div>
                    <div>
                      <b
                        style={{ fontSize: 18, color: "#475467" }}
                        className={coupon.valid && "coupon-applied"}
                      >
                        {BRLFormat(unitPrice * quantity)}
                      </b>
                      {coupon?.valid && (
                        <b
                          style={{
                            fontSize: 18,
                            color: "#0086c9",
                            display: "block",
                            textAlign: "end",
                          }}
                        >
                          {BRLFormat(coupon.price * quantity)}
                        </b>
                      )}
                    </div>
                  </Row>
                  <span
                    style={{ textAlign: "end", display: "block", fontSize: 14 }}
                  >
                    {t("pages.EasyCheckout.paymentConditions")}
                  </span>
                  <span
                    style={{ margin: "32px 0", display: "block", fontSize: 18 }}
                  >
                    {t("pages.EasyCheckout.comoPagar")}
                  </span>
                  <Row>
                    <div
                      onClick={() => {
                        setSelectedPayment("in-cash");
                        setPaymentType(TipoPagamento.Externo);
                      }}
                    >
                      {t("pages.EasyCheckout.inCash")}
                      <div style={{ display: "flex", gap: 6 }}>
                        <div
                          className={`fake-radio-button ${
                            selectedPayment === "in-cash" ? "selected" : ""
                          }`}
                        />

                        <button className="botao-mercado-pago">
                          <img
                            className="image-vendor"
                            src={boletoLogo}
                            alt="boletoLogo"
                          />
                          <img
                            className="image-vendor"
                            src={caixaLogo}
                            alt="caixaLogo"
                          />
                          <img
                            className="image-vendor"
                            src={pixLogo}
                            alt="pixLogo"
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: isMobile ? 12 : 0,
                        marginLeft: isMobile ? 0 : 12,
                      }}
                      onClick={() => {
                        setSelectedPayment("installments");
                        setPaymentType(TipoPagamento.Externo);
                      }}
                    >
                      {t("pages.EasyCheckout.installments")}
                      <div style={{ display: "flex", gap: 6 }}>
                        <div
                          className={`fake-radio-button ${
                            selectedPayment === "installments" ? "selected" : ""
                          }`}
                        />

                        <button className="botao-mercado-pago">
                          <img
                            className="image-vendor"
                            src={visaLogo}
                            alt="visaLogo"
                          />
                          <img
                            className="image-vendor"
                            src={masterCardLogo}
                            alt="masterCardLogo"
                          />
                          <img
                            className="image-vendor"
                            src={dinersClubLogo}
                            alt="dinersClubLogo"
                          />
                          <img
                            className="image-vendor"
                            src={americanExpressLogo}
                            alt="americanExpressLogo"
                          />
                          <img
                            className="image-vendor"
                            src={hipercardLogo}
                            alt="hipercardLogo"
                          />
                        </button>
                      </div>
                    </div>
                  </Row>
                  <span
                    style={{
                      marginTop: 32,
                      marginBottom: 12,
                      display: "block",
                      fontSize: 12,
                    }}
                  >
                    {t("pages.EasyCheckout.duvias")}
                  </span>
                  <Row>
                    <div
                      style={{ display: "flex", gap: 6 }}
                      onClick={() =>
                        setPaymentType(TipoPagamento.AtendenteSTRM)
                      }
                    >
                      <div
                        className={`fake-radio-button ${
                          paymentType === TipoPagamento.AtendenteSTRM
                            ? "selected"
                            : ""
                        }`}
                      />
                      <button className="botao-mercado-pago">
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          color="#23B25A"
                          style={{ width: 25, height: 25 }}
                        />
                        {t("pages.EasyCheckout.StrmContact")}
                      </button>
                    </div>
                  </Row>
                  <div style={{ textAlign: "end", padding: "72px 52px" }}>
                    <button
                      className="botao-colorido"
                      onClick={() => form.submit()}
                    >
                      {t("pages.EasyCheckout.next")}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDiagnostico;
