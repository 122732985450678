import { trackUserEvent } from "shared/service/StrmService";
import { getBrowserId } from "../shared/helpers/browser-id";

export enum UserEvent {
  S4A_QUIZ_GENERAL_DATA = "S4A_QUIZ_GENERAL_DATA",
  S4A_QUIZ_PLATFORMS_CONNECT = "S4A_QUIZ_PLATFORMS_CONNECT",
  QUIZ_SEARCH_ERROR_SPOTIFY = "QUIZ_SEARCH_ERROR_SPOTIFY",
  QUIZ_SEARCH_ERROR_YOUTUBE = "QUIZ_SEARCH_ERROR_YOUTUBE",
  QUIZ_SEARCH_ERROR_INSTAGRAM = "QUIZ_SEARCH_ERROR_INSTAGRAM",
  QUIZ_SEARCH_SPOTIFY = "QUIZ_SEARCH_SPOTIFY",
  QUIZ_SEARCH_YOUTUBE = "QUIZ_SEARCH_YOUTUBE",
  QUIZ_SEARCH_INSTAGRAM = "QUIZ_SEARCH_INSTAGRAM",
  QUIZ_SPOTIFY_SELECTED = "QUIZ_SPOTIFY_SELECTED",
  QUIZ_YOUTUBE_SELECTED = "QUIZ_YOUTUBE_SELECTED",
  QUIZ_INSTAGRAM_SELECTED = "QUIZ_INSTAGRAM_SELECTED",
  QUIZ_SPOTIFY_NO_VALUE = "QUIZ_SPOTIFY_NO_VALUE",
  QUIZ_YOUTUBE_NO_VALUE = "QUIZ_YOUTUBE_NO_VALUE",
  QUIZ_INSTAGRAM_NO_VALUE = "QUIZ_INSTAGRAM_NO_VALUE",
  S4A_QUIZ_ANALYSIS_LOADING = "S4A_QUIZ_ANALYSIS_LOADING",
  S4A_SIGN_UP = "S4A_SIGN_UP",
  SIGNUP_HAS_ACCOUNT = "SIGNUP_HAS_ACCOUNT",
  SIGNUP_LEAVE_LABELS = "SIGNUP_LEAVE_LABELS",
  SIGNUP_ERROR = "SIGNUP_ERROR",
  SIGNIN_SOCIAL_GOOGLE = "SIGNIN_SOCIAL_GOOGLE",
  SIGNIN_SOCIAL_FACEBOOK = "SIGNIN_SOCIAL_FACEBOOK",
  VERIFICATION_OPEN = "VERIFICATION_OPEN",
  VERIFICATION_SEND = "VERIFICATION_SEND",
  VERIFICATION_RESEND = "VERIFICATION_RESEND",
  VERIFICATION_ERROR = "VERIFICATION_ERROR",
  S4A_LOGIN = "S4A_LOGIN",
  LOGIN_FORGOT_PASSWORD = "LOGIN_FORGOT_PASSWORD",
  QUIZ_SAVE_OPEN = "QUIZ_SAVE_OPEN",
  S4A_HOME_DIAGNOSED = "S4A_HOME_DIAGNOSED",
  S4A_HOME_UNDIAGNOSED = "S4A_HOME_UNDIAGNOSED",
  S4A_SIGN_UP_VERIFICATION = "S4A_SIGN_UP_VERIFICATION",

  S4A_DISTRO_FORM = "S4A_DISTRO_FORM",
  S4A_DISTRO_SIGN = "S4A_DISTRO_SIGN",

  S4A_ADVANCE_INDEX = "S4A_ADVANCE_INDEX",
  S4A_ADVANCE_CONFIRMATION = "S4A_ADVANCE_CONFIRMATION",
  S4A_ADVANCE_WAITING_MERGE = "S4A_ADVANCE_WAITING_MERGE",
}

export const saveUserEvent = async (event: any) => {
  if (!event || event === null) return;

  const param = {
    action: event.name,
    deviceId: getBrowserId(),
  };
  trackUserEvent(param);
};
