import revelatorGenres from "./revelator-genres";

export const getI18nGenre = (genre: string, i18n: any, genres: any) => {
  if (genre) {
    const genreOptionValuesSet = new Set(
      revelatorGenres.map((item: any) => item.value)
    );

    const genreI18n = genres.find((gen: any) => gen.code === genre);
    if (genreI18n) return genreI18n[`${i18n.language}`];

    if (genreOptionValuesSet.has(genre)) {
      const revelatorGenre = revelatorGenres.find(
        (item: any) => item.value === genre
      );
      return revelatorGenre ? revelatorGenre.label : genre;
    }

    return genre;
  }
  return "";
};
