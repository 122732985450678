import { ButtonHTMLAttributes } from "react";
import "./Button.scss";

const Color: React.FC<ButtonHTMLAttributes<any>> = (props: any) => {
  const { children, className = "", ...rest } = props;
  return (
    <button {...rest} className={`${className} button color`}>
      {children}
    </button>
  );
};

export default Color;
