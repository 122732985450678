import { ButtonHTMLAttributes } from "react";
import "./Button.scss";

const Default: React.FC<ButtonHTMLAttributes<any>> = (props: any) => {
  const { className = "", children, ...rest } = props;
  return (
    <button {...rest} className={`${className} button default`}>
      {children}
    </button>
  );
};

export default Default;
