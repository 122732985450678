import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import LanguageChange from "../language-change";
import "./MenuHeader.scss";
import { Slider } from "antd";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const MenuHeader: React.FC<any> = (props) => {
  const { current } = props;
  const { t } = useTranslation();
  const value = current === 2 ? 100 : current === 1 ? 50 : 0;
  return (
    <div className="menu-header">
      <div className="logo-container" style={{ flex: 1, order: 1 }}>
        <Logo className="logo" />
        <span>for Artists</span>
      </div>
      <Slider
        disabled
        value={value}
        marks={{
          0: t("quiz.pages.Questionario.dadosGerais"),
          50: t("quiz.pages.Questionario.plataformas"),
          100: t("quiz.pages.Questionario.analises"),
        }}
        style={{
          flex: 1,
          maxWidth: 500,
          minWidth: 250,
          order: isMobile ? 3 : 2,
        }}
      />
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          order: 2,
        }}
      >
        <LanguageChange.SelectLanguageChange />
      </div>
    </div>
  );
};

export default MenuHeader;
