import { useEffect, useState } from "react";
import { Col, Divider, Form, Input, message, Modal, Popover, Row } from "antd";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { MaskInput } from "shared/components";
import "./EasyCheckout.scss";
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { BRLFormat } from "shared/helpers/BRLFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";
import {
  fetchCheckoutDiagnosticURL,
  fetchCheckoutDiscountPrice,
  fetchCheckoutRegularPrice,
  validateCoupon,
  validateDiagnosePurchaceEmail,
} from "shared/service/StrmService";
import { TipoPagamento } from "shared/enums/TipoPagamento";
import { parse } from "shared/helpers/serialization";
import { NOVO_USUARIO } from "shared/constants/StorageConsts";
import { autenticado } from "shared/helpers/auth-functions";
import { formCPFValidator } from "shared/helpers/cpf-validator";
import siteSeguroLogo from "shared/assets/images/site-seguro-logo.png";
import boletoLogo from "shared/assets/images/boleto.png";
import caixaLogo from "shared/assets/images/caixa.png";
import pixLogo from "shared/assets/images/pix.png";
import visaLogo from "shared/assets/images/visa.png";
import masterCardLogo from "shared/assets/images/master-card.png";
import dinersClubLogo from "shared/assets/images/diners-club.png";
import americanExpressLogo from "shared/assets/images/american-express.png";
import hipercardLogo from "shared/assets/images/hipercard.png";

const { REACT_APP_APPLICATION_URL } = process.env;
const { success } = Modal;
const EasyCheckout: React.FC<RouteComponentProps> = (props) => {
  const { history, location } = props;
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const loggedUser = useSelector((state: any) => state.users.loggedUser);
  const urlSearch = new URLSearchParams(location.search);
  const [codigoCoupon, setCodigoCoupon] = useState<string>("");
  const [coupon, setCoupon] = useState<any>({ valid: false });
  const [quantity, setQuantity] = useState<number>(1);
  const [maxQuantity, setMaxQuantity] = useState<number>(999);
  const [discount] = useState(urlSearch.has("special-discount"));
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<TipoPagamento | "">(
    TipoPagamento.Externo
  );
  const [selectedPayment, setSelectedPayment] = useState<
    "" | "in-cash" | "installments" | "customer-service"
  >("");

  const onClickLogin = () => {
    sessionStorage.setItem("returnURL", window.location.pathname);
    history.push("/login");
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    if (quantity < maxQuantity) setQuantity(quantity + 1);
  };

  const onApplyClick = async () => {
    const response: any = await validateCoupon(codigoCoupon);
    if (response) {
      const percentage: number = response;
      const fullDiscount = percentage === 100;
      const discountUnitPrice = unitPrice * ((100 - percentage) / 100);
      const newMaxQuantity = fullDiscount ? 1 : 10;
      setMaxQuantity(newMaxQuantity);
      setQuantity(quantity > newMaxQuantity ? newMaxQuantity : quantity);
      setCoupon({
        valid: true,
        price: discountUnitPrice,
        fullDiscount,
        percentage,
      });
    } else {
      setCoupon({ valid: false, validationError: true });
    }
  };

  const onFinish = async () => {
    const values = form.getFieldsValue();

    const request = {
      ...values,
      paymentType,
      returnURL: `${REACT_APP_APPLICATION_URL}/easy-checkout`,
      quantityofDiag: quantity,
      userCurrentLanguage: i18n.language,
      isPromo: discount,
      couponCode: coupon.valid ? codigoCoupon : "",
    };
    const response: any = await fetchCheckoutDiagnosticURL(request);

    if (coupon.fullDiscount) {
      if (response.newUser) {
        history.push(`/cadastre?email=${values.userEmail}`);
      } else {
        if (!autenticado())
          message.success(t("pages.EasyCheckout.loginToContinue"));
        history.push("/diagnosticos");
      }
    } else {
      success({
        width: 800,
        closable: false,
        title:
          paymentType === TipoPagamento.Externo
            ? t("pages.EasyCheckout.redirectExternal")
            : t("pages.EasyCheckout.redirectAtendente"),
        okButtonProps: {
          className: "botao-verde",
          style: { padding: 0 },
        },
        onOk: () => {
          window.location.href = response.purchaseRedirectUrl;
        },
      });
    }
    localStorage.setItem(
      NOVO_USUARIO,
      JSON.stringify({
        email: values.userEmail,
        newUser: response.userIsNew,
      })
    );
  };

  useEffect(() => {
    form.resetFields();
  }, [loggedUser, form]);

  useEffect(() => {
    async function validatePurchase() {
      const returnState = parse(location.search.replace("?", ""));
      const newUser = JSON.parse(localStorage.getItem(NOVO_USUARIO) || "{}");
      if (
        returnState.status === "approved" ||
        returnState.status === "in_process"
      ) {
        await validateDiagnosePurchaceEmail(
          returnState.payment_id,
          newUser.userEmail
        );

        if (newUser.newUser) {
          history.push(`/cadastre?email=${newUser.email}`);
        } else {
          if (!autenticado())
            message.success(t("pages.EasyCheckout.loginToContinue"));
          history.push("/confirmacao", { buySuccess: true });
        }
      } else {
        if (returnState.status === "pending") {
          message.success(t("pages.EasyCheckout.pending"));
          if (newUser.newUser) {
            history.push(`/cadastre?email=${newUser.email}`);
          } else {
            history.push("/confirmacao", { buySuccess: true });
          }
        } else message.error(t("pages.EasyCheckout.error"));
      }
    }

    const localSearch = new URLSearchParams(location.search);
    if (localSearch.has("status")) {
      validatePurchase();
    }
  }, [location, history, t]);

  useEffect(() => {
    const getDiagnossePrice = async () => {
      const response: any = discount
        ? await fetchCheckoutDiscountPrice()
        : await fetchCheckoutRegularPrice();
      setUnitPrice(response);
    };

    getDiagnossePrice();
  }, [discount]);

  return (
    <div className="easy-checkout">
      <div className="container">
        <Row>
          <Col span={12}>
            <span className="tab-like-compoment">
              {t("pages.EasyCheckout.chart")}
            </span>
          </Col>
          <Col xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={siteSeguroLogo}
                alt="site-seguro"
                style={{ width: isMobile ? 100 : 150 }}
              />
            </div>
          </Col>
          <Col xs={24} md={12}>
            <Row
              style={{
                borderRight: isMobile ? 0 : "1px solid #ececec",
                marginTop: 24,
              }}
            >
              <Col
                xs={24}
                style={{
                  textAlign: "center",
                }}
                hidden={autenticado()}
              >
                <span className="login-call">
                  {t("pages.EasyCheckout.loginCall")}
                </span>{" "}
                <span onClick={onClickLogin} className="login-ref">
                  {t("pages.EasyCheckout.login")}
                </span>
                <span className="no-login-no-problem">
                  {t("pages.EasyCheckout.noLogin")}
                </span>
              </Col>
              <Col xs={24}>
                <span className="section-name">
                  {t("pages.EasyCheckout.personalData")}
                </span>
              </Col>
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                style={{
                  width: "100%",
                  marginRight: isMobile ? 0 : 32,
                  marginTop: 24,
                }}
                initialValues={{
                  userEmail: loggedUser?.login,
                  userFullName: loggedUser?.name,
                  userCpf: loggedUser?.cpf,
                  userPhone: loggedUser?.phone,
                }}
              >
                <Col xs={24}>
                  <Form.Item
                    name="userFullName"
                    label={t("pages.EasyCheckout.name")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.EasyCheckout.nameRequired"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("pages.EasyCheckout.namePlaceholder")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="userCpf"
                    label={t("pages.EasyCheckout.cpf")}
                    extra={t("pages.EasyCheckout.cpfExtra")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.EasyCheckout.cpfRequired"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          return formCPFValidator(
                            value,
                            getFieldValue,
                            "userCpf",
                            t
                          );
                        },
                      }),
                    ]}
                  >
                    <MaskInput
                      mask="999.999.999-99"
                      placeholder={t("pages.EasyCheckout.cpfPlaceholder")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="userPhone"
                    label={t("pages.EasyCheckout.phone")}
                    extra={t("pages.EasyCheckout.phoneExtra")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.EasyCheckout.phoneRequired"),
                      },
                    ]}
                  >
                    <MaskInput
                      mask="(99) 99999-9999"
                      placeholder={t("pages.EasyCheckout.phonePlaceholder")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="userEmail"
                    label={t("pages.EasyCheckout.email")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.EasyCheckout.emailRequired"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("pages.EasyCheckout.emailPlaceholder")}
                      disabled={loggedUser?.login}
                    />
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row style={{ marginLeft: isMobile ? 0 : 32 }}>
              <Col xs={24}>
                <Row
                  justify="space-between"
                  style={{ marginTop: 32, userSelect: "none" }}
                >
                  <Col xs={24}>
                    <span className="section-name">
                      {t("pages.EasyCheckout.buyData")}
                    </span>
                  </Col>
                  <div
                    style={{ display: "flex", gap: 6, alignItems: "center" }}
                  >
                    <span className="button-example">
                      {t("pages.EasyCheckout.credits")}
                    </span>
                    <Popover
                      content={<>{t("pages.EasyCheckout.creditsExplain")}</>}
                    >
                      <ExclamationCircleOutlined
                        color="#98A2B3"
                        style={{ color: "#98A2B3" }}
                      />
                    </Popover>
                  </div>
                  <div style={{ display: "flex", textAlign: "center" }}>
                    <span
                      onClick={decreaseQuantity}
                      className={`icon-sign ${quantity === 0 && "disabled"}`}
                    >
                      <MinusCircleOutlined />
                    </span>
                    <span className="quantity">{` ${quantity} `}</span>
                    <span onClick={increaseQuantity} className="icon-sign">
                      <PlusCircleOutlined />
                    </span>
                  </div>
                  <div
                    style={{
                      minWidth: 100,
                      textAlign: "right",
                      fontSize: 18,
                      color: "#E4BC5C",
                      height: 55,
                    }}
                  >
                    <span>{BRLFormat(unitPrice)}</span>
                  </div>
                  <Divider />
                </Row>
                <Row style={{ gap: 12 }}>
                  <Form.Item
                    style={{ width: "50%" }}
                    help={
                      coupon.validationError
                        ? t("pages.EasyCheckout.invalidCoupon")
                        : ""
                    }
                    validateStatus={coupon.validationError ? "error" : ""}
                  >
                    <Input
                      placeholder={t("pages.EasyCheckout.couponPlaceholder")}
                      value={codigoCoupon}
                      onChange={(e) =>
                        setCodigoCoupon(e.target.value?.toUpperCase())
                      }
                      disabled={coupon?.valid}
                    />
                  </Form.Item>
                  <div>
                    <button
                      className="botao-azul-vazado"
                      onClick={onApplyClick}
                      disabled={coupon?.valid || !codigoCoupon}
                    >
                      {t("pages.EasyCheckout.apply")}
                    </button>
                  </div>
                  {coupon.valid && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flex: 1,
                      }}
                    >
                      <b style={{ fontSize: 16 }}>{coupon.percentage}% OFF</b>
                    </div>
                  )}
                </Row>
                <Row justify="space-between" style={{ marginTop: 32 }}>
                  <div>
                    <span className="button-example">
                      {t("pages.EasyCheckout.total")}
                    </span>
                  </div>
                  <div>
                    <b
                      style={{ fontSize: 18, color: "#475467" }}
                      className={coupon.valid && "coupon-applied"}
                    >
                      {BRLFormat(unitPrice * quantity)}
                    </b>
                    {coupon?.valid && (
                      <b
                        style={{
                          fontSize: 18,
                          color: "#0086c9",
                          display: "block",
                          textAlign: "right",
                        }}
                      >
                        {BRLFormat(coupon.price * quantity)}
                      </b>
                    )}
                  </div>
                </Row>
                <span
                  style={{
                    textAlign: "end",
                    display: "block",
                    fontSize: isMobile ? 12 : 14,
                  }}
                >
                  {t("pages.EasyCheckout.paymentConditions")}
                </span>
                <span
                  style={{
                    margin: "32px 0",
                    display: "block",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  {t("pages.EasyCheckout.comoPagar")}
                </span>
                <Row>
                  <div
                    onClick={() => {
                      setSelectedPayment("in-cash");
                      setPaymentType(TipoPagamento.Externo);
                    }}
                  >
                    {t("pages.EasyCheckout.inCash")}
                    <div style={{ display: "flex", gap: 6 }}>
                      <div
                        className={`fake-radio-button ${
                          selectedPayment === "in-cash" ? "selected" : ""
                        }`}
                      />

                      <button className="botao-mercado-pago">
                        <img
                          className="image-vendor"
                          src={boletoLogo}
                          alt="boletoLogo"
                        />
                        <img
                          className="image-vendor"
                          src={caixaLogo}
                          alt="caixaLogo"
                        />
                        <img
                          className="image-vendor"
                          src={pixLogo}
                          alt="pixLogo"
                        />
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: isMobile ? 12 : 0,
                      marginLeft: isMobile ? 0 : 12,
                    }}
                    onClick={() => {
                      setSelectedPayment("installments");
                      setPaymentType(TipoPagamento.Externo);
                    }}
                  >
                    {t("pages.EasyCheckout.installments")}
                    <div style={{ display: "flex", gap: 6 }}>
                      <div
                        className={`fake-radio-button ${
                          selectedPayment === "installments" ? "selected" : ""
                        }`}
                      />

                      <button className="botao-mercado-pago">
                        <img
                          className="image-vendor"
                          src={visaLogo}
                          alt="visaLogo"
                        />
                        <img
                          className="image-vendor"
                          src={masterCardLogo}
                          alt="masterCardLogo"
                        />
                        <img
                          className="image-vendor"
                          src={dinersClubLogo}
                          alt="dinersClubLogo"
                        />
                        <img
                          className="image-vendor"
                          src={americanExpressLogo}
                          alt="americanExpressLogo"
                        />
                        <img
                          className="image-vendor"
                          src={hipercardLogo}
                          alt="hipercardLogo"
                        />
                      </button>
                    </div>
                  </div>
                </Row>
                <span
                  style={{
                    marginTop: 32,
                    marginBottom: 12,
                    display: "block",
                    fontSize: isMobile ? 10 : 12,
                  }}
                >
                  {t("pages.EasyCheckout.duvias")}
                </span>
                <Row>
                  <div
                    style={{ display: "flex", gap: 6 }}
                    onClick={() => {
                      setSelectedPayment("customer-service");
                      setPaymentType(TipoPagamento.AtendenteSTRM);
                    }}
                  >
                    <div
                      className={`fake-radio-button ${
                        selectedPayment === "customer-service" ? "selected" : ""
                      }`}
                    />
                    <button className="botao-mercado-pago">
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        color="#23B25A"
                        style={{ width: 25, height: 25 }}
                      />
                      {t("pages.EasyCheckout.StrmContact")}
                    </button>
                  </div>
                </Row>
                <div style={{ textAlign: "end", padding: "72px 52px" }}>
                  <button
                    className="botao-colorido"
                    onClick={() => form.submit()}
                  >
                    {t("pages.EasyCheckout.next")}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EasyCheckout;
