import { REFERENCE_PAGE } from "shared/constants/StorageConsts";

export const getRefPageLocalStorage = (): string => {
  const refString = sessionStorage.getItem(REFERENCE_PAGE) || "";
  return refString;
};

export const setRefPageLocalStorage = (referencePage: string) => {
  sessionStorage.setItem(REFERENCE_PAGE, referencePage);
};

export const getRefPageLocalStorageArray = (): string[] => {
  const refString = sessionStorage.getItem(REFERENCE_PAGE);
  return refString?.split(",") || [];
};

export const setRefPageLocalStorageArray = (referencePage: string) => {
  const refPage = getRefPageLocalStorageArray();
  sessionStorage.setItem(
    REFERENCE_PAGE,
    Array.from(new Set([...refPage, referencePage])).join(",")
  );
};
