import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import diagnosticoCapaExemploImg from "shared/assets/images/exemplo-diagnostico/capa.jpg";
import diagnosticoIntroducao2ExemploImg from "shared/assets/images/exemplo-diagnostico/introducao-2.jpg";
import diagnosticoIntroducao3ExemploImg from "shared/assets/images/exemplo-diagnostico/introducao-3.jpg";
import diagnosticoJornada1ExemploImg from "shared/assets/images/exemplo-diagnostico/jornada-construcao-carreira-1.jpg";
import diagnosticoJornada2ExemploImg from "shared/assets/images/exemplo-diagnostico/jornada-construcao-carreira-2.jpg";
import diagnosticoJornada3ExemploImg from "shared/assets/images/exemplo-diagnostico/jornada-construcao-carreira-3.jpg";
import diagnosticoJornada4ExemploImg from "shared/assets/images/exemplo-diagnostico/jornada-construcao-carreira-4.jpg";
import diagnosticoJornada5ExemploImg from "shared/assets/images/exemplo-diagnostico/jornada-construcao-carreira-5.jpg";
import diagnosticoDadosPublicos1ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-publicos-1.jpg";
import diagnosticoDadosPublicos2ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-publicos-2.jpg";
import diagnosticoDadosPublicos3ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-publicos-3.jpg";
import diagnosticoDadosPublicos4ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-publicos-4.jpg";
import diagnosticoDadosPublicos5ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-publicos-5.jpg";
import diagnosticoDadosPrivados1ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-1.jpg";
import diagnosticoDadosPrivados2ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-2.jpg";
import diagnosticoDadosPrivados3ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-3.jpg";
import diagnosticoDadosPrivados4ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-4.jpg";
import diagnosticoDadosPrivados5ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-5.jpg";
import diagnosticoDadosPrivados6ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-6.jpg";
import diagnosticoDadosPrivados7ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-7.jpg";
import diagnosticoDadosPrivados8ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-8.jpg";
import diagnosticoDadosPrivados9ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-9.jpg";
import diagnosticoDadosPrivados10ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-10.jpg";
import diagnosticoDadosPrivados11ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-11.jpg";
import diagnosticoDadosPrivados12ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-12.jpg";
import diagnosticoDadosPrivados13ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-13.jpg";
import diagnosticoDadosPrivados14ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-14.jpg";
import diagnosticoDadosPrivados15ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-15.jpg";
import diagnosticoDadosPrivados16ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-16.jpg";
import diagnosticoDadosPrivados17ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-17.jpg";
import diagnosticoDadosPrivados18ExemploImg from "shared/assets/images/exemplo-diagnostico/dados-privados-18.jpg";
import diagnosticoConclucoes1ExemploImg from "shared/assets/images/exemplo-diagnostico/conclusao-1.jpg";
import diagnosticoConclucoes2ExemploImg from "shared/assets/images/exemplo-diagnostico/conclusao-2.jpg";
import diagnosticoConclucoes3ExemploImg from "shared/assets/images/exemplo-diagnostico/conclusao-3.jpg";
import diagnosticoConclucoes4ExemploImg from "shared/assets/images/exemplo-diagnostico/conclusao-4.jpg";

const SlickArrowLeft: React.FC<any> = ({
  currentSlide,
  slideCount,
  ...props
}) => (
  <button {...props} type="button">
    <LeftOutlined />
  </button>
);
const SlickArrowRight: React.FC<any> = ({
  currentSlide,
  slideCount,
  ...props
}) => (
  <button {...props} type="button">
    <RightOutlined />
  </button>
);

const CarouselExemploDiagnostico = () => {
  return (
    <Carousel
      variableWidth={false}
      className="carousel-diagnostico"
      dots={{
        className: "custom-dots",
      }}
      arrows
      prevArrow={<SlickArrowLeft />}
      nextArrow={<SlickArrowRight />}
    >
      <div>
        <img alt="" src={diagnosticoCapaExemploImg} className="img-slide" />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoIntroducao2ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoIntroducao3ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img alt="" src={diagnosticoJornada1ExemploImg} className="img-slide" />
      </div>
      <div>
        <img alt="" src={diagnosticoJornada2ExemploImg} className="img-slide" />
      </div>
      <div>
        <img alt="" src={diagnosticoJornada3ExemploImg} className="img-slide" />
      </div>
      <div>
        <img alt="" src={diagnosticoJornada4ExemploImg} className="img-slide" />
      </div>
      <div>
        <img alt="" src={diagnosticoJornada5ExemploImg} className="img-slide" />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPublicos1ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPublicos2ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPublicos3ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPublicos4ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPublicos5ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados1ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados2ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados3ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados4ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados5ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados6ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados7ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados8ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados9ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados10ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados11ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados12ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados13ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados14ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados15ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados16ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados17ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoDadosPrivados18ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoConclucoes1ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoConclucoes2ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoConclucoes3ExemploImg}
          className="img-slide"
        />
      </div>
      <div>
        <img
          alt=""
          src={diagnosticoConclucoes4ExemploImg}
          className="img-slide"
        />
      </div>
    </Carousel>
  );
};

export default CarouselExemploDiagnostico;
