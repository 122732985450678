import { Divider, Form, Input, Modal, Progress } from "antd";
import { Trans, useTranslation } from "react-i18next";
import modalTelefoneBg from "shared/assets/images/modal-telefone-bg.png";
import { ReactComponent as GiftIcon } from "shared/assets/images/gift-icon.svg";
import "./ModalEstagio.scss";
import { useDispatch, useSelector } from "react-redux";
import MaskInput from "shared/components/mask-input";
import { validatePhone } from "shared/helpers/phone-helpers";
import "antd/es/input/style/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { updateUserPhone } from "shared/service/StrmService";
import { useState } from "react";
import { getLoggedUser } from "explorer/reducers/users";
import { toast } from "react-toastify";
import { WhatsAppContactConsultor } from "shared/helpers/whatsapp-contact";
import { isMobile } from "react-device-detect";
import { canAskAdvance } from "explorer/reducers/adiantamentos";

const ModalTelefone: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { userArtist } = useSelector((state: any) => state.artists);
  const { loggedUser } = useSelector((state: any) => state.users);
  const { cashAdvanceAvailable } = useSelector((state: any) => state.advances);
  const advanceAvailability = canAskAdvance(cashAdvanceAvailable);

  const phone =
    (userArtist && userArtist?.phone) || (loggedUser && loggedUser?.phone);

  const [open, setOpen] = useState<boolean>(!phone && advanceAvailability);
  const country = userArtist.country;

  const onFinishForm = async () => {
    const values = form.getFieldsValue();
    const response: any = await updateUserPhone(values);
    if (!response?.error) {
      dispatch(getLoggedUser());
      toast.success(t("pages.EstagioArtista.atualizacaoTelefoneSucesso"));
      setOpen(false);
    } else {
      toast.error(t("pages.EstagioArtista.atualizacaoTelefoneErro"));
    }
  };

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => setOpen(false)}
      width={700}
      title={null}
      className="modal-adiantamento"
    >
      <>
        <div
          style={{
            background: `url(${modalTelefoneBg}) no-repeat`,
            backgroundSize: "cover",
            borderRadius: "8px 8px 0 0",
            minHeight: 330,
            width: "100%",
            position: "absolute",
          }}
        />
        <div style={{ position: "relative" }}>
          <div className="conteudo" style={{ paddingTop: 60 }}>
            {/* <div style={{ background: "#0D0D0D", opacity: 0.7 }} /> */}
            <div
              style={{
                paddingTop: 112,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 48px",
              }}
            >
              <Progress
                type="circle"
                percent={100}
                width={64}
                strokeColor="#00D88A"
                trailColor="#004129"
                format={() => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <GiftIcon />
                  </div>
                )}
              />
              <span className="chamada">
                {t("pages.EstagioArtista.novidade")}
              </span>
              <h1 className="titulo">
                {t("pages.EstagioArtista.oportunidadeParaVoce")}
              </h1>

              <Divider />
            </div>
            {/* <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(25, 25, 25, 0.00) 0%, #1A1A1A 100%)",
                height: 170,
                width: "100%",
                marginTop: -20,
                position: "absolute",
              }}
            /> */}
            <div
              style={{
                textAlign: "center",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                zIndex: 1,
              }}
            >
              <p style={{ maxWidth: 350 }}>
                <Trans
                  i18nKey={t("pages.EstagioArtista.solicitacaoTelefoneTexto")}
                />
              </p>
            </div>

            <div
              style={{
                // background: "#1A1A1A",
                paddingTop: 12,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <QuizTheme> */}
              <Form
                form={form}
                onFinish={onFinishForm}
                layout="vertical"
                style={{ width: isMobile ? "100%" : 350 }}
              >
                <Form.Item
                  label={t("quiz.pages.Questionario.phoneLabel")}
                  name="phone"
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: t("quiz.pages.Questionario.phoneRequired"),
                    },
                    {
                      validator(_, value) {
                        return validatePhone(
                          country,
                          value,
                          t("pages.MeusDados.telefoneInvalido")
                        );
                      },
                    },
                  ]}
                >
                  <MaskInput
                    mask={
                      country === "BR"
                        ? "(99) 9999-999999"
                        : "+99 (99) 9999-999999"
                    }
                    maskChar=" "
                    placeholder={t(
                      "quiz.pages.Questionario.typeHerePlaceholder"
                    )}
                  />
                </Form.Item>

                <Input style={{ display: "none" }} />
                <button className="botao-roxo" style={{ width: "100%" }}>
                  {t("pages.EstagioArtista.salvarESolicitarContato")}
                </button>
              </Form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 60,
                  width: isMobile ? "100%" : 350,
                }}
              >
                <span style={{ textAlign: "center", margin: "24px 0" }}>
                  {t("pages.EstagioArtista.ou")}
                </span>
                <button className="botao-branco" style={{ width: "100%" }}>
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    color="#00D88A"
                    style={{ width: 12, height: 12 }}
                  />
                  <a
                    target="_blank"
                    href={WhatsAppContactConsultor}
                    rel="noopener noreferrer"
                    style={{
                      color: "#1B1A4C",
                    }}
                  >
                    {t("pages.EstagioArtista.falarComAtendente")}
                  </a>
                </button>
              </div>
              {/* </QuizTheme> */}
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ModalTelefone;
