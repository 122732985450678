import { message, Select, Upload, UploadFile, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  getUrlUpload,
  putValidateDocumentCashAdvance,
} from "shared/service/StrmService";
import {
  UploadOutlined,
  LoadingOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as RelatorioIcon } from "shared/assets/images/icon-relatorios.svg";
import { UploadChangeParam, UploadListProps } from "antd/es/upload";
import { camelCase } from "lodash";
import axios from "axios";

export const distroList: any[] = [
  { key: "cd_baby", name: "CD Baby", i18key: "cdBaby", steps: 4 },
  { key: "one_rpm", name: "ONErpm", i18key: "oneRpm", steps: 6 },
  { key: "soundrop", name: "Soundrop", i18key: "soundrop", steps: 4 },
  { key: "dash_go", name: "DashGo", i18key: "dashGo", steps: 5 },
  { key: "believe", name: "Believe", i18key: "believe", steps: 8 },
  { key: "altafonte", name: "Altafonte", i18key: "altafonte", steps: 4 },
  { key: "theorchard", name: "The Orchard", i18key: "theorchard", steps: 4 },
  { key: "ingrooves", name: "Ingrooves", i18key: "ingrooves", steps: 5 },
  { key: "ditto", name: "Ditto", i18key: "ditto", steps: 5 },
  { key: "distro_kid", name: "DistroKid", i18key: "distroKid", steps: 4 },
];

const acceptedFiles = [".xls", ".xlsx", ".csv", ".txt", ".tsv", ".numbers"];

const DistroControl: React.FC<any> = (props) => {
  const {
    advance,
    distro,
    suportedDistros,
    distroOptions,
    onChange,
    onDelete,
  } = props;
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>(
    distro.fileList ||
      [
        // {
        //   uid: "-5",
        //   name: "image.png",
        //   status: "error",
        //   // error: { message: "lalala" },
        // },
      ]
  );
  const [selectedDistro, setSelectedDistro] = useState<any>(
    distro?.selectedDistro
  );
  const [showInstrucoes, setShowInstrucoes] = useState<boolean>(false);
  const [validatedFileErrors, setValidatedFileErrors] = useState<any>([]);

  const renderItem: UploadListProps["itemRender"] = (
    originNode,
    file: UploadFile
  ) => {
    if (file.status === "error") {
      return React.cloneElement(originNode, {}, [
        ...React.Children.toArray(originNode.props.children),
        <Trans
          i18nKey="pages.Adiantamentos.erroValidacaoArquivoMensagem"
          values={{ mensagem: file?.error?.message }}
        />,
      ]);
    }

    return originNode;
  };

  const getSignedUrl = async (filename: string): Promise<any> => {
    const response: any = await getUrlUpload({
      filePath: advance.id,
      filename,
      contextName: 0,
    });
    return response;
  };

  const customRequest: any = async ({
    file,
    onSuccess,
    onError,
    onProgress,
  }: any) => {
    try {
      const options = {
        headers: {
          "Content-Type": "",
        },
        onUploadProgress: (event: any) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          onProgress({ percent: percent });
        },
      };
      const request = await getSignedUrl(file.name);
      await axios.put(request.url, file, options);
      onSuccess(request);
    } catch (error) {
      onError(error);
    }
  };

  const updateFileList = (
    response: any = [],
    infos: UploadChangeParam<UploadFile<any>>
  ) => {
    if (response?.length) {
      setValidatedFileErrors((prevErrors: any) => [
        ...prevErrors,
        { file: infos.file.uid, error: response[0] },
      ]);
    }
  };

  const uploadProps: UploadProps = {
    customRequest: customRequest,
    progress: {
      strokeColor: "#d9d9d91a",
      trailColor: "#3F3F3F",
      strokeWidth: 40,
    },
    onChange: async (infos) => {
      if (infos.file.status === "removed") {
        setFileList(infos.fileList);
        onChange({ ...infos, selectedDistro });
        return;
      }

      const extensao = `.${infos.file.name?.split(".").pop()}`;
      if (!extensao || !acceptedFiles.includes(extensao)) {
        message.error(t("pages.Adiantamentos.erroExtensaoArquivo"));
        return;
      }

      setFileList(infos.fileList);
      onChange({ ...infos, selectedDistro });

      if (infos.file.status === "done" && selectedDistro.name !== "other") {
        const response: any = await putValidateDocumentCashAdvance({
          distroName: selectedDistro.name,
          document: infos.file.response,
        });

        updateFileList(response, infos);
      }
    },

    beforeUpload: (file: { name: string; size: number }) => {
      const extensao = `.${file.name.split(".").pop()}`;
      const MAX_SIZE = 1 * 1024 * 1024 * 1024; // 1GB

      if (file.size > MAX_SIZE) {
        message.error(t("pages.Adiantamentos.erroArquivoMuitoGrande"));
        return false;
      }

      return extensao && acceptedFiles.includes(extensao);
    },

    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: <EyeOutlined />,
    },
    itemRender: renderItem,
    accept: acceptedFiles.join(","),
    fileList: fileList,
    multiple: true,
    iconRender: (file) =>
      file.status === "uploading" ? <LoadingOutlined /> : <RelatorioIcon />,
  };

  const getDistroNameByI18 = (name: string) => {
    return i18n.exists(`pages.Adiantamentos.${name}`)
      ? t(`pages.Adiantamentos.${name}`)
      : name;
  };
  const onSelectDistro = (value: any) => {
    const distro =
      distroList.find((distro: any) => distro.key === value) ||
      suportedDistros.find((distro: any) => distro.name === value);

    setSelectedDistro(distro);
    onChange({ selectedDistro: distro });
  };

  useEffect(() => {
    const getErrorByCode = (codeCase = "") => {
      const code = camelCase(codeCase);
      if (!code || !i18n.exists(`pages.Adiantamentos.ValidationErrors.${code}`))
        return t("pages.Adiantamentos.erroValidacaoArquivoGeral");
      return t(`pages.Adiantamentos.ValidationErrors.${code}`);
    };
    const updateErrorFiles = () => {
      const updatedFileList: any = fileList.map((file) => {
        const fileError = validatedFileErrors.find(
          (error: any) => error.file === file.uid
        );

        if (fileError) {
          return {
            ...file,
            status: "error",
            error: { message: getErrorByCode(fileError.error.code) },
            url: file.url ?? file?.response?.url,
            filekey: file?.response?.fileKey,
          };
        }

        return file;
      });
      setFileList(updatedFileList);

      // setValidatedFileErrors((prevErrors: any) =>
      //   prevErrors.filter(
      //     (error: any) =>
      //       !updatedFileList.some((file: any) => file.uid === error.file)
      //   )
      // );
    };

    if (
      !validatedFileErrors.every((error: any) =>
        fileList.some(
          (file) => file.status === "error" && file.uid === error.file
        )
      )
    ) {
      // console.log(validatedFileErrors);
      updateErrorFiles();
    }
  }, [fileList, validatedFileErrors, i18n, t]);

  if (!selectedDistro)
    return (
      <div className="distro-container">
        <div className="upload">
          <span className="distro-select-titulo">
            {" "}
            {t("pages.Adiantamentos.distribuidoras")}
          </span>
          <Select
            onChange={onSelectDistro}
            placeholder={t("pages.Adiantamentos.selecioneSuasDistribuidoras")}
          >
            {distroOptions}
          </Select>
        </div>
      </div>
    );

  return (
    <div className="distro-container">
      <div className="upload">
        <span>{getDistroNameByI18(selectedDistro.name)}</span>
        <Upload {...uploadProps} className="file-upload">
          <button className="botao-borda-roxa" style={{ flex: 1 }}>
            {t("pages.Adiantamentos.selecioneArquivos")} <UploadOutlined />
          </button>
        </Upload>
        <div>
          <div className="aceitos">
            <span> {t("pages.Adiantamentos.tipoArquivosAceitos")}</span>
            <span> {t("pages.Adiantamentos.tipoArquivosAceitosExcel")}</span>
            <span> {t("pages.Adiantamentos.tipoArquivosAceitosTexto")}</span>
            <span> {t("pages.Adiantamentos.tipoArquivosAceitosNumeros")}</span>
          </div>
          <div className="nao-aceitos">
            <span>{t("pages.Adiantamentos.tipoArquivosNaoAceitos")}</span>
            <span>{t("pages.Adiantamentos.tipoArquivosNaoAceitosTipos")}</span>
          </div>
        </div>
      </div>
      {selectedDistro.steps && (
        <div className="relatorio">
          <span
            className="clicavel"
            onClick={() => setShowInstrucoes(!showInstrucoes)}
          >
            {t("pages.Adiantamentos.comoExtrairRelatorio")}{" "}
            {showInstrucoes ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </span>
          {showInstrucoes && (
            <div className="instrucoes">
              {new Array(selectedDistro.steps).fill(null).map((_, index) => (
                <div className="instrucao">
                  <span className="numero">{index + 1}</span>
                  <p>
                    <Trans
                      i18nKey={t(
                        `pages.Adiantamentos.instrucoesDistribuidora.${selectedDistro.i18key}.${index}`,
                        {
                          interpolation: {
                            escapeValue: false,
                            useRawValueToEscape: true,
                          },
                        }
                      )}
                    />
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="distro-footer" onClick={onDelete}>
        {t("pages.Adiantamentos.excluir")}
      </div>
    </div>
  );
};

export default DistroControl;
