import { useState } from "react";
import MeuAdiantamentoHeader from "./MeuAdiantamentoHeader";
import { Divider } from "antd";
import MeuAdiantamento from "./MeuAdiantamento";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import AdiantamentoMigracao from "./migracao/AdiantamentoMigracao";
import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import AdiantamentoTakeDownPagamento from "./migracao/AdiantamentoTakeDownPagamento";
import Duvidas from "./Duvidas";

enum AdiantamentoStatus {
  Migracao = "Migracao",
  Takedown = "Takedown",
  Pagamento = "Pagamento",
}
const Stepper: React.FC<{ status: AdvanceStatus; style: any }> = (props) => {
  const { status, style } = props;
  const { t } = useTranslation();

  const getStyleByStatus = (adiantamentoStatus: AdiantamentoStatus) => {
    switch (adiantamentoStatus) {
      case AdiantamentoStatus.Migracao: {
        if (status === AdvanceStatus.CatalogMigration) {
          return "active";
        }
        return "complete";
      }
      case AdiantamentoStatus.Takedown: {
        if (
          status === AdvanceStatus.WaitingTakedown ||
          status === AdvanceStatus.WaitingTakedownConfirmation
        ) {
          return "active";
        }
        if (status === AdvanceStatus.Disbursement) {
          return "complete";
        }
        return "waiting";
      }

      case AdiantamentoStatus.Pagamento: {
        if (status === AdvanceStatus.Disbursement) {
          return "complete";
        }
        return "waiting";
      }
    }
  };

  return (
    <div className="stepper" style={style}>
      <div
        className={`step complete ${getStyleByStatus(
          AdiantamentoStatus.Migracao
        )}`}
      >
        <div className="step-circle">
          <div className="handler" />
        </div>
        <div className="step-label">{t("pages.Adiantamentos.migracao")}</div>
      </div>
      <div className={`step ${getStyleByStatus(AdiantamentoStatus.Takedown)}`}>
        <div className="step-circle">
          <div className="handler" />
        </div>
        <div className="step-label">{t("pages.Adiantamentos.takedown")}</div>
      </div>
      <div className={`step ${getStyleByStatus(AdiantamentoStatus.Pagamento)}`}>
        <div className="step-circle">
          <div className="handler" />
        </div>
        <div className="step-label">{t("pages.Adiantamentos.pagamento")}</div>
      </div>
    </div>
  );
};

const AdiantamentoMigracaoTakedownPagamento: React.FC<any> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const [showMeuAdiantamento, setShowMeuAdiantamento] =
    useState<boolean>(false);

  return (
    <div
      className="migracao-catalogo-desembolso"
      style={{ marginLeft: isMobile ? "unset" : 80 }}
    >
      <div className="flex-column-between">
        <div className="migracao">
          <MeuAdiantamentoHeader
            setShowMeuAdiantamento={setShowMeuAdiantamento}
            showMeuAdiantamento={showMeuAdiantamento}
          />
          <Divider />
          {showMeuAdiantamento ? (
            <MeuAdiantamento advance={advance} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ maxWidth: isMobile ? "unset" : 500 }}>
                <>
                  <Stepper
                    status={advance.status}
                    style={{ marginBottom: 40 }}
                  />
                  {advance.status === AdvanceStatus.CatalogMigration ? (
                    <AdiantamentoMigracao advance={advance} />
                  ) : (
                    <AdiantamentoTakeDownPagamento
                      advance={advance}
                      reloadCashAdvance={reloadCashAdvance}
                    />
                  )}
                </>
              </div>
            </div>
          )}
        </div>
        <div className="footer">
          <Duvidas />
        </div>
      </div>
    </div>
  );
};

export default AdiantamentoMigracaoTakedownPagamento;
