import { getLoggedUserDistroToken } from "shared/service/StrmService";

const getDistroLoginUrl = async () => {
  const revelatorSite = process.env.REACT_APP_REVELATOR_SITE;
  const response = await getLoggedUserDistroToken();

  if (revelatorSite && response) {
    const url = `${revelatorSite}authorize?token=${response}`;

    return url;
  }
};

export const redirectDistro = async () => {
  const url = await getDistroLoginUrl();

  if (url) window.open(url, "_blank", "noopener,noreferrer");
};
