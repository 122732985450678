import { Avatar, Checkbox, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import useOutsideAlerter from "shared/hooks/useOutsideAlerter";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const SocialList: React.FC<any> = (props) => {
  const {
    placeholder,
    checkboxLabel,
    label,
    logo,
    value,
    onChange,
    getListOptions,
    showMore,
    onSelectCallback = () => {},
  } = props;
  const [searchField, setSearchField] = useState("");
  const [showList, setShowList] = useState(false);
  const [bottomStyle, setBottomStyle] = useState<any>("auto");
  const [options, setOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(value);
  const wrapperRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [inputWidth, setInputWidth] = useState<any>("auto");
  const [offset, setOffset] = useState<number>(0);
  const { t } = useTranslation();
  useOutsideAlerter(wrapperRef, handleClickOutside);

  const openList = () => {
    const menuEl = wrapperRef.current;
    const menuRect = menuEl.getBoundingClientRect();
    const screenHeight = window.innerHeight;
    if (menuRect.bottom - screenHeight + 270 > 0) {
      setBottomStyle(108);
    } else {
      setBottomStyle("");
    }
    setShowList(true);
  };

  function handleClickOutside(event: any) {
    if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
      setShowList(false);
    }
  }

  const searchOptions = async () => {
    const list: any = await getListOptions(searchField);
    setOptions(list);
    if (list.length) openList();
  };

  const searchOptionsOffset = async (position: number = 0) => {
    const list: any = await getListOptions(searchField, position);

    setOptions((prev) => [...prev, ...list]);
    openList();
    setOffset(position);
  };

  const onKeyPress = (event: any) => {
    if (event.which === 13) {
      searchOptions();
    }
  };

  const onClickNoValue = (checked: boolean) => {
    if (checked) {
      const noDataValue = { id: "no-data" };
      onChange(noDataValue);
      setSelectedOption(noDataValue);
      onSelectCallback(false);
    } else {
      onChange();
      setSelectedOption(undefined);
    }
  };

  const onSelectValue = (option: any) => {
    const { id, name, avatarUrl } = option;
    onChange({ id, name, avatarUrl });
    setSelectedOption(option);
    setShowList(false);
    onSelectCallback(true);
  };

  const handleDeleteClick = () => {
    setSelectedOption(undefined);
  };

  const handleClickShowMore = () => {
    searchOptionsOffset(offset + 50);
  };

  useEffect(() => {
    if (showList && wrapperRef.current && inputRef.current) {
      const menuEl = wrapperRef.current;
      const menuRect = menuEl?.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      if (menuRect.bottom - screenHeight + 270 > 0) {
        setBottomStyle(108);
      } else {
        setBottomStyle("");
      }
      const inputElement = inputRef.current.input;
      if (inputElement) {
        const width = inputElement.getBoundingClientRect().width;
        setInputWidth(width);
      }
    }
  }, [showList]);

  // if (selectedOption && selectedOption.id !== "no-data")
  //   return (
  //     <div className="selected-item">
  //       <Avatar style={{ marginRight: "6px" }} src={selectedOption.avatarUrl} />
  //       <span>{selectedOption.name || selectedOption.id}</span>
  //       <DeleteOutlined className="remove" onClick={handleDeleteClick} />
  //     </div>
  //   );

  return (
    <div ref={wrapperRef} className="caixa-input">
      <div className="container">
        {selectedOption?.avatarUrl ? (
          <img
            src={selectedOption?.avatarUrl}
            className="avatar-imagem"
            alt="icon"
          />
        ) : (
          <div className="container-logo">{logo}</div>
        )}
        <div>
          <span style={{ display: "flex", gap: 9 }}>
            {logo}
            {label}
          </span>
          <span style={{ color: "#33CAE3" }}>
            {selectedOption &&
              selectedOption.id !== "no-data" &&
              (selectedOption.name || selectedOption.id)}
          </span>
        </div>
      </div>
      <DeleteOutlined className="remove" onClick={handleDeleteClick} />
      {(!selectedOption?.id || selectedOption?.id === "no-data") && (
        <>
          <Input
            ref={inputRef}
            placeholder={placeholder}
            onChange={(event) => setSearchField(event.target.value)}
            onKeyPress={onKeyPress}
            disabled={value?.id === "no-data"}
            style={{ marginTop: 14 }}
            onClick={() =>
              !showList && options.length > 0 ? openList() : setShowList(false)
            }
            suffix={
              <button
                className="botao-roxo"
                style={{ padding: 0, borderRadius: 2 }}
                onClick={() => searchOptions()}
                type="button"
              >
                {t("quiz.pages.Questionario.buscar")}
              </button>
            }
          />
          {showList && (
            <div
              className="dropdown-modal"
              style={{
                bottom: bottomStyle,
                width: inputWidth,
                top: bottomStyle ? "unset" : 145,
              }}
            >
              {options.map((option: any, index: number) => (
                <p
                  onClick={() => {
                    onSelectValue(option);
                  }}
                  key={`option-${index}`}
                >
                  <div>
                    {option.avatarUrl && (
                      <Avatar
                        style={{ marginRight: 6 }}
                        src={option.avatarUrl}
                      />
                    )}
                    {option.name}
                  </div>
                  <div className="selecionar">
                    {t("quiz.pages.Questionario.selecionar")}
                  </div>
                </p>
              ))}
              {showMore && options.length % 50 === 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="botao-roxo"
                    style={{ padding: 0, borderRadius: 2 }}
                    onClick={handleClickShowMore}
                    type="button"
                  >
                    {t("quiz.pages.Questionario.mais")}
                  </button>
                </div>
              )}
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              marginTop: 6,
            }}
          >
            <Checkbox
              onChange={({ target: { checked } }) => onClickNoValue(checked)}
              checked={value?.id === "no-data"}
            >
              <span>{checkboxLabel}</span>
            </Checkbox>
          </div>
        </>
      )}
    </div>
  );
};

export default SocialList;
