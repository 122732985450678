import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

declare global {
  interface Window {
    gtag: any;
    dataLayer: any;
  }
}

const GoogleTag: React.FC = () => {
  const [created, setCreated] = useState<boolean>(false);
  const { loggedUser } = useSelector((state: any) => state.users);

  useEffect(() => {
    // function gtag(...arg: any) {
    //   window.dataLayer.push(arg);
    // }
    if (!created && loggedUser?.login) {
      // window.dataLayer = window.dataLayer || [];

      // // gtag("event", "acesso", {
      // //   email: "loggedUser.login",
      // //   time:
      // //     loggedUser.userTeam.length > 0
      // //       ? loggedUser.userTeam[0].team.displayName
      // //       : "Sem time associado",
      // // });

      // window.dataLayer.push({
      //   email: loggedUser.login,
      //   email_user: loggedUser.login,
      //   userId: loggedUser.cognitoUsername,
      //   time:
      //     loggedUser.userTeam.length > 0
      //       ? loggedUser.userTeam[0].team.displayName
      //       : "Sem time associado",
      //   // eventModel: {
      //   //   email: loggedUser.login,
      //   //   time:
      //   //     loggedUser.userTeam.length > 0
      //   //       ? loggedUser.userTeam[0].team.displayName
      //   //       : "Sem time associado",
      //   // },
      //   event: "acesso",
      // });
      setCreated(true);
    }
  }, [loggedUser, created]);

  return null;
};

export default GoogleTag;
