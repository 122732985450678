import { Checkbox, List, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import {
  acceptInvites,
  getUserInvites,
  rejectInvites,
} from "shared/service/StrmService";
import "./Convites.scss";

const Convites: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const [userInvites, setUserInvites] = useState<any>();
  const [selectedInvites, setSelectedInvites] = useState<any>([]);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const onOkInvitesClick = async () => {
    try {
      await acceptInvites(
        selectedInvites.map((invite: any) => ({ ...invite, selected: true }))
      );
      history.push("/aceite-convite");
    } catch {}
  };

  const onCancelInvitesClick = async () => {
    try {
      await rejectInvites(userInvites);
      history.push("/");
    } catch {}
  };

  const onCheckConvite = (check: Boolean, invite: any) => {
    if (check) {
      setSelectedInvites([...selectedInvites, invite]);
    } else {
      setSelectedInvites(
        selectedInvites.filter((i: any) => i.id !== invite.id)
      );
    }
  };

  const onClickSelectAll = () => {
    if (selectedInvites.length === userInvites.length) {
      setSelectedInvites([]);
    } else {
      setSelectedInvites(userInvites);
    }
  };

  useEffect(() => {
    const getInvites = async () => {
      const response: any = await getUserInvites();
      if (response?.length > 0) {
        setUserInvites(response);
      }
    };

    getInvites();
  }, []);

  return (
    <div className="convites">
      <div className="content">
        <Typography.Title level={2} className="titulo">
          {t("pages.Convites.titulo")}
        </Typography.Title>
        <Typography.Text className="mensagem">
          {t("pages.Convites.mensagem")}
        </Typography.Text>
        <div className="container-lista">
          <List
            dataSource={userInvites}
            className="lista"
            size="small"
            header={
              <Typography.Text style={{ color: "#fff" }}>
                {t("pages.Convites.textoConvite")}
              </Typography.Text>
            }
            renderItem={(item: any) => (
              <List.Item className="item-lista">
                <Checkbox
                  onChange={(e) => onCheckConvite(e.target.checked, item)}
                  checked={selectedInvites.some(
                    (i: any) => i.team.id === item.team.id
                  )}
                  className="checkbox-amarelo"
                >
                  {item.team.displayName}
                </Checkbox>
              </List.Item>
            )}
          />
          <span className="select-all" onClick={onClickSelectAll}>
            {t("pages.Convites.selectAll")}
          </span>
        </div>
        <Checkbox
          onChange={(e) => setAcceptTerms(e.target.checked)}
          className="check-terms"
        >
          <Typography.Text>{t("pages.Convites.termsAccept")}</Typography.Text>
        </Checkbox>
        <div className="botoes">
          <button className="botao-fantasma" onClick={onCancelInvitesClick}>
            {t("pages.Convites.reject")}
          </button>
          <button
            className="botao-verde"
            disabled={!acceptTerms || selectedInvites.length === 0}
            onClick={onOkInvitesClick}
          >
            {t("pages.Convites.accept")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Convites;
