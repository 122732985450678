import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import "../checkout-diagnostico/CheckoutDiagnostico.scss";

const ConfirmacaoCheckout: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  return (
    <div className="confirmacao-checkout">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>{t("pages.CheckoutDiagnostico.ConfirmacaoTitulo")}</h1>
        <p>{t("pages.CheckoutDiagnostico.ConfirmacaoText")}</p>
        <button
          className="botao-roxo"
          onClick={() => history.push("/diagnosticos")}
        >
          {t("pages.CheckoutDiagnostico.ConfirmacaoButton")}
        </button>
      </div>
    </div>
  );
};

export default ConfirmacaoCheckout;
