import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as EstrelaIcon } from "shared/assets/images/icon-estrela.svg";

const CardDiagnosticoCompleto: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="card-outra-sessao">
      <EstrelaIcon className="icon" />
      <span className="titulo">STRM PREMIUM</span>
      <span className="divider" />
      <h1>{t("pages.EstagioArtista.diagnosticoCompleto")}</h1>
      <p className="strong-escuro">
        <Trans i18nKey={t("pages.EstagioArtista.diagnosticoCompletoTexto")} />
      </p>
      <button
        className="botao-roxo"
        onClick={() => {
          history.push("/diagnosticos");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        {t("pages.EstagioArtista.saibaMais")}
      </button>
    </div>
  );
};

export default CardDiagnosticoCompleto;
