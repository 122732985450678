import { camelCase, isPlainObject } from "lodash";

const snakeToCamelCase: any = (obj: any, maintainSpace: boolean) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeToCamelCase(v, maintainSpace));
  }
  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [key.includes(" ") && maintainSpace ? key : camelCase(key)]:
          snakeToCamelCase(obj[key], maintainSpace),
      }),
      {}
    );
  }
  return obj;
};

export default snakeToCamelCase;
