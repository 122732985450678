import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { LANG } from "shared/constants/StorageConsts";
import { UserEvent, saveUserEvent } from "event-tracker/track-user-helpers";
declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}
const { REACT_APP_OAUTH_URL, REACT_APP_APPLICATION_URL, REACT_APP_CLIENT_ID } =
  process.env;

// To federated sign in from Facebook
const Facebook: React.FC<any> = (params) => {
  const { onClick } = params;
  const { t } = useTranslation();
  const { lang } = useParams<{ lang: string }>();

  const signInAlt = () => {
    if (onClick) onClick();
    saveUserEvent({ name: UserEvent.SIGNIN_SOCIAL_FACEBOOK });
    if (lang) Cookies.set(LANG, lang);
    window.location.href = `${REACT_APP_OAUTH_URL}/authorize?identity_provider=Facebook&redirect_uri=${REACT_APP_APPLICATION_URL}&response_type=CODE&client_id=${REACT_APP_CLIENT_ID}&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile`;
  };

  return (
    <button
      onClick={signInAlt}
      type="button"
      className="button facebook social-buttons"
    >
      <FontAwesomeIcon
        icon={faFacebook}
        color="#1877F2"
        style={{ width: 26, height: 26 }}
      />
      <span style={{ marginLeft: 16 }}>
        {t("components.button.facebook.entre")}
      </span>
    </button>
  );
};

export default Facebook;
