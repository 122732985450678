import { getCapitalizedLang } from "./capitalized-lang";

export const getCountryByKey = (
  key?: string,
  countryByKeys?: any,
  i18n?: any
): string | undefined =>
  key
    ? countryByKeys &&
      i18n &&
      countryByKeys[key][`description${getCapitalizedLang(i18n)}`]
    : key;
