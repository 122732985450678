import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import * as H from "history";
import "./NewEstagioArtista.scss";
import { Artist } from "model/Artist";
import EstagioArtistsInicio from "./EstagioArtistaInicio";
import EstagioArtistaMenu from "./EstagioArtistaMenu";
import EstagioArtistaMinhaCarreira from "./EstagioArtistaMinhaCarreira";
import EstagioArtistaPlanoEstrategico from "./EstagioArtistaPlanoEstrategico";
import { isMobile } from "react-device-detect";
import { Loader } from "shared/components";
import { fetchArtistsByGenre } from "shared/service/RadarService";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUserCashAdvanceAvailable } from "explorer/reducers/adiantamentos";
import EstagioArtistaErro from "./EstagioArtistaErro";
import { UserEvent, saveUserEvent } from "event-tracker/track-user-helpers";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";
import ModalTelefone from "shared/components/modais/estagio/ModalTelefone";
import { firstAccessNotified } from "explorer/reducers/users";

export const SecaoContext = createContext<
  Dispatch<SetStateAction<"inicio" | "carreira" | "estrategico">>
>(() => {});

export interface EstagioArtistaProps {
  history: H.History<any>;
  artist?: Artist;
  artistByGenre?: any[];
}

const NewEstagioArtista: React.FC<EstagioArtistaProps> = (props) => {
  const { history } = props;
  const { userArtist, loading } = useSelector((state: any) => state.artists);
  const userState = useSelector((state: any) => state.users);
  const [artistsByGenre, setArtistsByGenre] = useState<any[]>([]);
  const [secao, setSecao] = useState<"inicio" | "carreira" | "estrategico">(
    "inicio"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getArtistByGenre = async () => {
      if (userArtist?.genresStrm?.length) {
        const response: any = await fetchArtistsByGenre(
          userArtist?.genresStrm[0]
        );
        setArtistsByGenre(response);
      }
    };
    getArtistByGenre();
  }, [userArtist?.genresStrm]);

  useEffect(() => {
    dispatch(getLoggedUserCashAdvanceAvailable(false));
  }, [dispatch]);

  useEffect(() => {
    if (
      !loading &&
      !userState.loading &&
      userArtist?.stage > 0 &&
      !userState.firstAccessNotified
    ) {
      saveUserEvent({ name: UserEvent.S4A_HOME_DIAGNOSED });
      dataLayerEvent("ScreenViewed", {
        ...quizEvents.S4A_HOME_DIAGNOSED,
        carreerStage: userArtist?.stage,
        approvedLimit: userArtist?.cashAdvanceEstimated?.amountUsd,
        alreadyCalculated:
          userArtist?.cashAdvanceEstimated?.successfullyEstimated,
        country: userArtist?.country,
        firstAccess: userState?.firstAccess,
        activeAdvanceContract: userArtist?.cashAdvanceSigned,
        activeDistributionContract: userArtist?.distributionSigned,
        userTest: userArtist?.internalTest,
      });

      dispatch(firstAccessNotified());
    }
  }, [loading, userState, userArtist, dispatch]);

  if (userArtist?.stage === 0 || loading) return <Loader.Simple />;

  if (!userArtist?.stage && !loading) return <EstagioArtistaErro />;

  return (
    // <div className={`estagio-novo ${isMobile && "mobile"}`}>
    <SecaoContext.Provider value={setSecao}>
      <ModalTelefone />
      <div className={`estagio-novo mobile ${!isMobile && "web"} `}>
        <EstagioArtistaMenu secao={secao} setSecao={setSecao} />

        {secao === "inicio" && (
          <EstagioArtistsInicio history={history} artist={userArtist} />
        )}
        {secao === "carreira" && (
          <EstagioArtistaMinhaCarreira
            history={history}
            artistByGenre={artistsByGenre}
          />
        )}
        {secao === "estrategico" && (
          <EstagioArtistaPlanoEstrategico history={history} />
        )}
      </div>
    </SecaoContext.Provider>
  );
};

export default NewEstagioArtista;
