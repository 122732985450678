import { ThemeConfig } from "antd";

export const theme: ThemeConfig = {
  token: {
    colorPrimary: "#5779ff",
    colorTextBase: "#f2f2f2",
    colorText: "#f2f2f2",
    colorBorder: "#171717",
    fontFamily: "Cabin",
    colorTextPlaceholder: "#8a8a8a",
    colorError: "#ff4f4f",
    boxShadow: "0px 0px 0px 0px #fff",
    controlHeight: 40,
  },
  components: {
    Input: {
      borderRadius: 4,
      colorText: "#f2f2f2",
      colorBorder: "#171717",
      colorBgContainer: "#292929",
    },
    Select: {
      borderRadius: 4,
      colorIcon: "#5779ff",
      colorBgContainer: "#292929",
      controlItemBgActive: "#252525",
    },
    Checkbox: {
      controlInteractiveSize: 20,
      colorWhite: "#e6e6e6",
      colorBgContainer: "#5779ff33",
      colorBorder: "#5779ff",
    },
    Form: {
      fontSize: 14,
      fontWeightStrong: 700,
      colorTextPlaceholder: "blue",
    },
    Menu: {
      radiusItem: 0,
      colorItemBgHover: "transparent",
      colorItemBgSelected: "#5779FF12",
    },
    Tooltip: {
      colorBgDefault: "#292929",
    },
    Modal: {
      colorBgElevated: "#0D0D0D",
    },
    Button: {
      colorBgContainer: "#292929",
    },
    Table: {
      borderRadius: 0,
      borderRadiusLG: 0,
      colorFillAlter: "#383737",
    },
    Steps: {
      lineWidth: 4,
      colorSplit: "#3f3db399",
      // colorPrimary: "#3F3DB3",
      // controlItemBgActive: "#3F3DB3",
    },
    Upload: {
      margin: 0,
      marginXS: 0,
    },
    DatePicker: {
      borderRadius: 4,
      colorText: "#f2f2f2",
      colorBorder: "#171717",
      colorBgContainer: "#292929",
      colorBgElevated: "#292929",
    },
    Dropdown: {
      colorBgElevated: "#1F1F1F",
      controlItemBgHover: "#1F1F1F",
      colorText: "#E6E6E6",
    },
  },
};
