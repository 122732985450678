import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import imgHenriqueJulianoWeb from "shared/assets/images/henrique-juliano-sem-texto.png";
import { specialCampaignRequestConfirmNotification } from "shared/service/StrmService";
import { isMobile } from "react-device-detect";
import { ClientSpecialCampaignApprovalEnum } from "shared/enums/ClientSpecialCampaignApprovalEnum";
import { getLoggedUserArtist } from "explorer/reducers/artistas";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialCampaign } from "shared/helpers/special-campaign-helpers";
import { SpecialCampaignEnum } from "shared/enums/SpecialCampaignEnum";
import "explorer/pages/new-estagio-artista/campanhas-especiais/CampanhasEspeciais.scss";
import "./ModalCampaignHeJResponse.scss";
import { useHistory } from "react-router-dom";

interface IModalCampaignHeJResponse {
  onCancel: () => void;
  open: boolean;
}

const ModalCampaignHeJResponse: React.FC<IModalCampaignHeJResponse> = (
  props
) => {
  const { open, onCancel } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { userArtist } = useSelector((state: any) => state.artists);

  const specialCampaign = getSpecialCampaign(
    userArtist?.specialCampaign,
    SpecialCampaignEnum.HenriqueEJuliano
  );

  const getTranslateApproval = (
    approvalVal: ClientSpecialCampaignApprovalEnum
  ): any => {
    if (!approvalVal) return {};
    const approval =
      approvalVal === ClientSpecialCampaignApprovalEnum.Approved
        ? "approved"
        : "disapproved";
    return t(`pages.CampanhasEspeciais.HenriqueJuliano.response.${approval}`);
  };

  const onClickConfirma = async () => {
    await specialCampaignRequestConfirmNotification(
      specialCampaign.specialCampaignRequestId
    );
    dispatch(getLoggedUserArtist());
    history.push("/estagio");
    onCancel();
  };

  const { textoSobreTitulo, titulo, textoDisclaimer, beneficios, botao } =
    getTranslateApproval(specialCampaign?.approval);

  return (
    <Modal
      open={open}
      footer={null}
      closable={false}
      className={`modal-henrique-juliano ${isMobile ? "mobile" : "web"}`}
    >
      <div
        style={{
          background: `url(${imgHenriqueJulianoWeb}) no-repeat`,
          backgroundSize: "cover",
          borderRadius: "8px 8px 0 0",
          minHeight: 330,
          width: "100%",
          position: "absolute",
        }}
      />

      <div className="" style={{ position: "relative" }}>
        <div className="conteudo">
          <div className="fundo-opaco" />
          <div className="wrapper-titulo">
            <span className="texto-sobre-titulo">{textoSobreTitulo}</span>
            <h1 className="titulo">{titulo}</h1>
            <span className="divider" />
          </div>
          <div className="bloco-gradiente" />
          <div className="wrapper-beneficios">
            <p className="text-disclaimer">{textoDisclaimer}</p>
            <ul>
              {beneficios?.map((text: string) => (
                <li className="circle-checkmark azul" key={`${text}_TEXT`}>
                  {text}
                </li>
              ))}
            </ul>
            <div className="buttons">
              <button className="botao-roxo" onClick={onClickConfirma}>
                {botao}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCampaignHeJResponse;
