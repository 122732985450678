import { Col, Form, FormInstance, Row } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Progress } from "antd";
import { isMobile } from "react-device-detect";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  StepContainer,
  FirstStep,
  SecondStep,
  FourthStep,
  FifthStep,
} from "./steps";
import { createUpcomingRelease } from "shared/service/StrmService";
import "./pitching.scss";
import { UpcomingRelease } from "shared/interfaces/UpcomingRelease";

interface Step {
  step: number;
  title: string;
  content: React.ReactNode;
}

const secondStepValidation = [
  "type",
  "name",
  "genres",
  "releaseOn",
  "labelName",
  "focusTrack",
];
// const thirdStepValidation = [
//   "moods",
//   "musicStyles",
//   "instrumentsUsed",
//   "isCover",
//   "recorded",
//   "isRemix",
//   "labelName",
//   "isInstrumental",
//   "language",
// ];
const fourthStepValidation = ["photosURL", "planningFilesURL"];
const fifthStepValidation = ["aboutCreation"];

const isStepValidToContinue = async (
  step: number,
  form: FormInstance<UpcomingRelease>
): Promise<boolean> => {
  const validationsByStep = new Map();
  validationsByStep.set(1, []);
  validationsByStep.set(2, secondStepValidation);
  validationsByStep.set(3, fourthStepValidation);
  validationsByStep.set(4, fifthStepValidation);

  try {
    await form.validateFields(validationsByStep.get(step));
    return true;
  } catch (error) {
    return false;
  }
};

const stepToString = (step: number) => {
  let response = "";
  switch (step) {
    case 1:
      response = "first";
      break;
    case 2:
      response = "second";
      break;
    case 3:
      response = "fourth";
      break;
    case 4:
      response = "fifth";
      break;
  }

  return response;
};

const Pitching: React.FC<any> = (props) => {
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [form] = Form.useForm<UpcomingRelease>();
  const history = useHistory();

  const steps: Step[] = [
    {
      step: 1,
      title: t("pages.Pitching.steps.first.title"),
      content: (form: FormInstance<UpcomingRelease>) => (
        <FirstStep form={form} />
      ),
    },
    {
      step: 2,
      title: t("pages.Pitching.steps.second.title"),
      content: (form: FormInstance<UpcomingRelease>) => (
        <SecondStep form={form} />
      ),
    },
    {
      step: 3,
      title: t("pages.Pitching.steps.fourth.title"),
      content: (form: FormInstance<UpcomingRelease>) => (
        <FourthStep form={form} />
      ),
    },
    {
      step: 4,
      title: t("pages.Pitching.steps.fifth.title"),
      content: (form: FormInstance<UpcomingRelease>) => (
        <FifthStep form={form} />
      ),
    },
  ];

  const getTitleProgressBar = () =>
    t(`pages.Pitching.steps.${stepToString(step)}.progressBar`);

  const submitForm = async () => {
    try {
      setLoading(true);
      await createUpcomingRelease(form.getFieldsValue());
      toast.success(t("pages.Pitching.salvoSucesso"));
      history.push("/");
    } catch {
      toast.error(t("pages.Pitching.erroAoSalvar"));
    } finally {
      setLoading(false);
    }
  };

  const isLastStep = () => step === 4;

  const nextStep = async () => {
    if (await isStepValidToContinue(step, form)) {
      if (isLastStep()) {
        await submitForm();
      } else {
        setStep(step + 1);
      }
    }
  };

  return (
    <div className={`container-pitching ${isMobile && "mobile"}`}>
      <div className={`bg ${step === 1 ? "bg-first-step" : "bg-next-steps"}`} />
      <div className="content">
        <Row justify="space-between" className="wrapper-progress-bar">
          <div className="text">{getTitleProgressBar()}</div>
          <div className="text">
            <Trans
              i18nKey={t("pages.Pitching.steps.progress", {
                currentStep: step,
              })}
            />
          </div>
          <Progress
            percent={25 * step}
            showInfo={false}
            strokeColor="#CECDFF"
          />
        </Row>
        <Form layout="vertical" form={form}>
          {steps.map((item) => (
            <div
              className={`steps-content ${item.step !== step && "hidden"}`}
              key={item.step}
            >
              <StepContainer
                title={item.title}
                StepContent={item.content}
                form={form}
              />
            </div>
          ))}
        </Form>

        <Row className="btn-move-steps">
          <Col xs={24}>
            <button
              className="botao-roxo"
              style={{ width: "100%" }}
              onClick={async () => {
                await nextStep();
              }}
              disabled={loading}
            >
              {loading && <LoadingOutlined />}
              {step < 4
                ? t("pages.Pitching.avancar")
                : t("pages.Pitching.enviar")}
              <RightOutlined />
            </button>
          </Col>
          {step > 1 && (
            <Col xs={24}>
              <button
                className="botao-branco-vazado"
                style={{ width: "100%" }}
                onClick={() => setStep(step - 1)}
                disabled={loading}
              >
                {t("pages.Pitching.voltar")}
              </button>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Pitching;
