import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  LoadingOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { isDesktop, isMobile } from "react-device-detect";
import "./RecomendacaoCollab.scss";
import { useSelector } from "react-redux";
import { Avatar, Badge, Spin, Tag } from "antd";
import ClusterChart from "shared/components/cluster-chart/ClusterChart";
import avatarProdutoresSrc from "shared/assets/images/avatar-produtores.png";
import avatarCompositoresSrc from "shared/assets/images/avatar-compositores.png";
import avatarFeatSrc from "shared/assets/images/avatar-feat.png";
import avatarHitmakerSrc from "shared/assets/images/avatar-hitmaker.png";
import avatarSniperSrc from "shared/assets/images/avatar-sniper.png";
import avatarMassificadoSrc from "shared/assets/images/avatar-massificado.png";
import avatarAscendenciaSrc from "shared/assets/images/avatar-ascendencia.png";
import avatarAtemporalSrc from "shared/assets/images/avatar-atemporal.png";
import { uniq } from "lodash";
import { getRecommendationByArtistFilter } from "shared/service/StrmService";
import { useTranslation } from "react-i18next";

function importAll(r: any): string[] {
  return r.keys().map(r) as string[];
}

const images = uniq(
  importAll(
    require.context("shared/assets/images/recomendacoes", false, /.png$/)
  )
);

interface Recommendation {
  artistIdSpotify: string;
  artistIdStrm: string | null;
  artistName: string;
  position: number;
  roleTitle: string | null;
  similarityIndex: number;
}

interface Props {
  // Define your component props here
}

enum PageStep {
  Inicio = 0,
  Recomendacao = 1,
}
enum Role {
  Produtor = "producers",
  Compositor = "writers",
  Feat = "performers",
}
enum Caracteristica {
  Hitmaker = "hitmaker",
  Sniper = "sniper",
  Massificado = "masses",
  Ascendência = "trend",
  Atemporal = "trend-max",
}

const RecomendacaoCollab: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [etapaAtual, setEtapaAtual] = useState<PageStep>(PageStep.Inicio);
  const { userArtist } = useSelector((state: any) => state.artists);
  const { genreOptions } = useSelector((state: any) => state.genres);
  const indexRef = useRef<HTMLDivElement>(null);
  const genreRef = useRef<HTMLDivElement>(null);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [currentRecommendations, setCurrentRecommendations] = useState<
    Recommendation[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [indexList, setIndexList] = useState<number>(5);
  const [filter, setFilter] = useState({
    indexes: Caracteristica.Hitmaker,
    role: Role.Produtor,
    genre: "",
  });

  const [selectedGenre, setSelectedGenre] = useState<string>("");

  const fullScreenWidth = window.innerWidth;

  const filteredOtherGenres = useMemo(() => {
    return genreOptions.filter((genre: any) => !genre.value?.includes("outro"));
  }, [genreOptions]);

  const randomImages = useMemo(() => {
    return images.sort(() => Math.random() - Math.random()).slice(0, 5);
    // eslint-disable-next-line
  }, [currentRecommendations]);

  const handleNextStep = () => {
    setEtapaAtual(PageStep.Recomendacao);
  };

  const getRecommendations = useCallback(async () => {
    if (!userArtist?.id || !selectedGenre) return;
    setLoading(true);
    const params = {
      userArtistId: userArtist.id,
      ...filter,
    };
    const response: any = await getRecommendationByArtistFilter(params);
    setRecommendations(response);
    setLoading(false);
  }, [filter, userArtist, selectedGenre]);

  const onClickRole = (role: Role) => {
    setFilter({ ...filter, role });
  };

  const onClickCaracteristica = (caracteristica: Caracteristica) => {
    setFilter({ ...filter, indexes: caracteristica });
  };

  const onClickGenre = (genre: string) => {
    setFilter({ ...filter, genre });
  };

  const handleClickNextRecommendations = () => {
    const newIndex = indexList + 5;
    if (newIndex <= recommendations.length) {
      setIndexList(newIndex);
      setCurrentRecommendations(recommendations.slice(indexList, newIndex));
    } else {
      setCurrentRecommendations(recommendations.slice(0, 5));
      setIndexList(5);
    }
  };

  const handleScrollLeft = (ref: HTMLDivElement | null) => {
    console.log(ref?.scrollLeft);
    if (ref) {
      ref.scrollTo({
        left: ref.scrollLeft - 100,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = (ref: HTMLDivElement | null) => {
    console.log(ref?.scrollLeft);
    if (ref) {
      ref.scrollTo({
        left: ref.scrollLeft + 100,
        behavior: "smooth",
      });
    }
  };

  const components = [
    ...currentRecommendations.map((recommendation, index) => (
      <div
        className="container-image"
        key={`RECOMMENDATION_${recommendation.artistIdSpotify}`}
      >
        <Badge
          count={
            <span
              className={`badge ${
                recommendation.position === 1 ? "first" : ""
              }`}
              style={{
                width: isDesktop ? 50 : 34,
                height: isDesktop ? 50 : 34,
              }}
            >
              {recommendation.position}º
            </span>
          }
        >
          <img
            src={randomImages[index]}
            alt="Profile"
            style={{
              width: isDesktop ? 70 : 50,
              height: isDesktop ? 70 : 50,
            }}
          />
        </Badge>
        <span className="label"> {recommendation.artistName}</span>
      </div>
    )),
    <div className="container-image" onClick={handleClickNextRecommendations}>
      <span
        className="ver-mais"
        style={{
          width: isDesktop ? 70 : 50,
          height: isDesktop ? 70 : 50,
        }}
      >
        +{recommendations.length - currentRecommendations.length}
      </span>

      <span className="label">{t("pages.Recomendacoes.verMais")}</span>
    </div>,
  ];

  useEffect(() => {
    setCurrentRecommendations(recommendations.slice(0, 5));
  }, [recommendations]);

  useEffect(() => {
    getRecommendations();
  }, [getRecommendations]);

  useEffect(() => {
    setSelectedGenre(
      (userArtist?.genresStrm?.length > 0 ? userArtist?.genresStrm[0] : "") ||
        ""
    );
  }, [userArtist]);

  if (etapaAtual === PageStep.Inicio)
    return (
      <div className={`recomendacao-collab ${isDesktop && "web"}`}>
        <div className="page-container">
          <div className="inicio splash">
            <div className="background" />
            <div className="content">
              <Avatar src={userArtist?.profileImage} size={100} />
              <h1 className="title"> {t("pages.Recomendacoes.title")}</h1>
              <p className="text">{t("pages.Recomendacoes.intro")}</p>
              <button className="botao-branco" onClick={handleNextStep}>
                {t("pages.Recomendacoes.inicio")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={`recomendacao-collab ${isDesktop && "web"}`}>
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 72,
            }}
            spin={false}
          />
        }
      >
        <div className={`page-container ${isDesktop && "web"}`}>
          <div className="inicio">
            <div>
              <ClusterChart
                mainImage={userArtist?.profileImage}
                components={components}
                width={isMobile ? fullScreenWidth - 100 : 500}
              />
              <div
                className="filtros"
                style={{ width: isMobile ? "calc(100% - 24px)" : 500 }}
              >
                <div className="scroll-container">
                  <div className="button-container">
                    <button
                      className={`scroll-button botao-roxo ${
                        filter.role === Role.Produtor ? "selected" : ""
                      }`}
                      onClick={() => onClickRole(Role.Produtor)}
                    >
                      <img
                        src={avatarProdutoresSrc}
                        alt="Avatar"
                        className="icon-button"
                      />
                      {t("pages.Recomendacoes.produtores")}
                    </button>
                    <button
                      className={`scroll-button botao-roxo ${
                        filter.role === Role.Compositor ? "selected" : ""
                      }`}
                      onClick={() => onClickRole(Role.Compositor)}
                    >
                      <img
                        src={avatarCompositoresSrc}
                        alt="Avatar"
                        className="icon-button"
                      />
                      {t("pages.Recomendacoes.compositores")}
                    </button>
                    <button
                      className={`scroll-button botao-roxo ${
                        filter.role === Role.Feat ? "selected" : ""
                      }`}
                      onClick={() => onClickRole(Role.Feat)}
                    >
                      <img
                        src={avatarFeatSrc}
                        alt="Avatar"
                        className="icon-button"
                      />
                      {t("pages.Recomendacoes.feat")}
                    </button>
                  </div>
                </div>
                <div className="caracteristicas-container">
                  <span className="title">
                    {t("pages.Recomendacoes.caracteristicas")}
                  </span>
                  <div style={{ display: "flex" }}>
                    {isDesktop && (
                      <ArrowLeftOutlined
                        onClick={() => handleScrollLeft(indexRef.current)}
                        style={{ padding: 4 }}
                      />
                    )}
                    <div className="scroll-container" ref={indexRef}>
                      <div className="button-container">
                        <div
                          className={`caracteristica ${
                            filter.indexes === Caracteristica.Hitmaker
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => {
                            onClickCaracteristica(Caracteristica.Hitmaker);
                          }}
                        >
                          <img
                            src={avatarHitmakerSrc}
                            alt="Avatar"
                            className="icon-button"
                          />
                          <div className="info">
                            <span className="name">
                              {t("pages.Recomendacoes.hitmaker")}
                            </span>
                            <span className="value">
                              {t("pages.Recomendacoes.hitmakerText")}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`caracteristica ${
                            filter.indexes === Caracteristica.Sniper
                              ? "selected"
                              : ""
                          }`}
                          onClick={() =>
                            onClickCaracteristica(Caracteristica.Sniper)
                          }
                        >
                          <img
                            src={avatarSniperSrc}
                            alt="Avatar"
                            className="icon-button"
                          />
                          <div className="info">
                            <span className="name">
                              {t("pages.Recomendacoes.sniper")}
                            </span>
                            <span className="value">
                              {t("pages.Recomendacoes.sniperText")}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`caracteristica ${
                            filter.indexes === Caracteristica.Massificado
                              ? "selected"
                              : ""
                          }`}
                          onClick={() =>
                            onClickCaracteristica(Caracteristica.Massificado)
                          }
                        >
                          <img
                            src={avatarMassificadoSrc}
                            alt="Avatar"
                            className="icon-button"
                          />
                          <div className="info">
                            <span className="name">
                              {t("pages.Recomendacoes.massificado")}
                            </span>
                            <span className="value">
                              {t("pages.Recomendacoes.massificadoText")}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`caracteristica ${
                            filter.indexes === Caracteristica.Ascendência
                              ? "selected"
                              : ""
                          }`}
                          onClick={() =>
                            onClickCaracteristica(Caracteristica.Ascendência)
                          }
                        >
                          <img
                            src={avatarAscendenciaSrc}
                            alt="Avatar"
                            className="icon-button"
                          />
                          <div className="info">
                            <span className="name">
                              {t("pages.Recomendacoes.tendencia")}
                            </span>
                            <span className="value">
                              {t("pages.Recomendacoes.tendenciaText")}
                            </span>
                          </div>
                        </div>
                        <div
                          className={`caracteristica ${
                            filter.indexes === Caracteristica.Atemporal
                              ? "selected"
                              : ""
                          }`}
                          onClick={() =>
                            onClickCaracteristica(Caracteristica.Atemporal)
                          }
                        >
                          <img
                            src={avatarAtemporalSrc}
                            alt="Avatar"
                            className="icon-button"
                          />
                          <div className="info">
                            <span className="name">
                              {t("pages.Recomendacoes.atemporal")}
                            </span>
                            <span className="value">
                              {t("pages.Recomendacoes.atemporalText")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isDesktop && (
                      <ArrowRightOutlined
                        onClick={() => handleScrollRight(indexRef.current)}
                        style={{ padding: 4 }}
                      />
                    )}
                  </div>
                  {filter.role === Role.Feat && userArtist?.stage >= 7 && (
                    <div className="genero-container">
                      <span className="title">
                        {t("pages.Recomendacoes.genero")}
                      </span>
                      <div style={{ display: "flex" }}>
                        {isDesktop && (
                          <ArrowLeftOutlined
                            onClick={() => handleScrollLeft(genreRef.current)}
                            style={{ padding: 4 }}
                          />
                        )}
                        <div className="scroll-container" ref={genreRef}>
                          <div className="button-container">
                            {filteredOtherGenres.map(
                              (genre: any, index: number) => (
                                <Tag
                                  key={`GENRE_${index}_${genre.value}`}
                                  className={`
                                tag-genre ${
                                  filter.genre === genre.value
                                    ? "selected"
                                    : "not-selected"
                                }`}
                                  onClick={() => onClickGenre(genre.value)}
                                >
                                  {genre.label}
                                </Tag>
                              )
                            )}
                          </div>
                        </div>
                        {isDesktop && (
                          <ArrowRightOutlined
                            onClick={() => handleScrollRight(genreRef.current)}
                            style={{ padding: 4 }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default RecomendacaoCollab;
