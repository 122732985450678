import { Col, Row, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Diagnostico } from "shared/interfaces/Diagnostico";
import {
  assignDiagToInvites,
  fetchUserDiagnostics,
} from "shared/service/StrmService";
import backgroundConvite from "shared/assets/images/banda-background.jpg";
import mulherColorida from "shared/assets/images/sing-lady-color.png";
import mulherPb from "shared/assets/images/sing-lady-bw.png";
import "./AceiteConvite.scss";
import { useSelector } from "react-redux";
import { TIPO_DIAGNOSTICO_UTILIZADO } from "shared/constants/StorageConsts";
import { TipoDiagnostico } from "shared/enums/TipoDiagnostico";

const AceiteConvite: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const [diagnosticos, setDiagnosticos] = useState<Diagnostico[]>([]);
  const loggedUser = useSelector((state: any) => state.users.loggedUser);
  const [estagio, setEstagio] = useState("iniciar");
  const [diagnosticoSelecionado, setDiagnosticoSelecionado] =
    useState<Diagnostico>();

  const formatDate = (date: string = "") => moment(date).format("DD/MM/YYYY");

  const onSelectDiagnostico = (diagnostico: Diagnostico) => {
    setDiagnosticoSelecionado(diagnostico);
  };

  const onClickSim = async () => {
    if (diagnosticoSelecionado) {
      await assignDiagToInvites(diagnosticoSelecionado.id);
      setEstagio("concluido");
    }
  };

  const onClickOk = () => {
    history.push("/");
  };

  const onCLickFazerAgora = () => {
    localStorage.setItem(TIPO_DIAGNOSTICO_UTILIZADO, TipoDiagnostico.Team);
    history.push("/novo-diagnostico");
  };

  const onClickFazerDepois = () => {
    if (loggedUser.userArtists.length > 0) history.push("/estagio");
    else history.push("/");
  };

  useEffect(() => {
    async function getUserDiagnostics() {
      const response: any = await fetchUserDiagnostics();
      if (response?.length > 0) {
        const diagnosticosValidos = response.filter(
          (diagnostico: Diagnostico) =>
            moment(diagnostico.createdOn).isBefore(moment().add(30, "days"))
        );
        if (diagnosticosValidos.length > 0)
          setDiagnosticos(diagnosticosValidos);
        else setEstagio("semDiagnosticos");
      } else setEstagio("semDiagnosticos");
    }
    getUserDiagnostics();
  }, []);

  return (
    <div className="aceite-convite">
      <div className="content">
        {estagio === "iniciar" && (
          <>
            <Typography.Title level={2} className="titulo">
              {t("pages.Convites.titulo")}
            </Typography.Title>
            <Typography.Text className="mensagem">
              {t("pages.AceiteConvite.mensagem")}
            </Typography.Text>
            {diagnosticos?.length > 0 && (
              <div className="lista">
                <Row gutter={32} justify="center" style={{ width: "100%" }}>
                  {diagnosticos.map((diagnostico) => (
                    <Col
                      key={diagnostico.url}
                      xs={24}
                      md={6}
                      onClick={() => onSelectDiagnostico(diagnostico)}
                    >
                      {diagnostico.pendingInvestorAnswer && (
                        <div className="pending-investor">
                          <Typography.Title level={4}>
                            {t("pages.Diagnosticos.pendenteInvestidor")}
                          </Typography.Title>
                        </div>
                      )}
                      <div
                        className={`card-diagnostico ${
                          diagnostico.id === diagnosticoSelecionado?.id &&
                          "selecionado"
                        } `}
                      >
                        <img
                          src={backgroundConvite}
                          className="imagem-diagnostico"
                          alt=""
                        />
                        <span className="conteudo-diagnostico">
                          {`${diagnostico.artistName} ${formatDate(
                            diagnostico.createdOn
                          )}`}
                        </span>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            className="botao-vazado"
                            onClick={() => onSelectDiagnostico(diagnostico)}
                          >
                            {t("pages.AceiteConvite.selecionar")}
                          </button>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            <div className="botoes">
              <button
                className="botao-fantasma"
                onClick={() => setEstagio("semDiagnosticos")}
              >
                {t("pages.AceiteConvite.nao")}
              </button>
              <button
                className="botao-verde"
                onClick={onClickSim}
                disabled={!diagnosticoSelecionado}
              >
                {t("pages.AceiteConvite.sim")}
              </button>
            </div>
          </>
        )}
        {estagio === "concluido" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography.Title level={2} className="concluido">
              {t("pages.AceiteConvite.concluido")}
            </Typography.Title>
            <Typography.Text className="mensagem">
              {t("pages.AceiteConvite.mensagemConcluido")}
            </Typography.Text>
            <div className="imagem">
              <img src={mulherColorida} alt="Mulher Cantanto colorido" />
            </div>
            <div className="botoes">
              <button className="botao-verde" onClick={onClickOk}>
                {t("pages.AceiteConvite.ok")}
              </button>
            </div>
          </div>
        )}
        {estagio === "semDiagnosticos" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography.Title level={2} className="quase-concluido">
              {t("pages.AceiteConvite.quaseConcluido")}
            </Typography.Title>
            <Typography.Text className="mensagem">
              {t("pages.AceiteConvite.mensagemQuaseConcluido")}
            </Typography.Text>
            <div className="imagem">
              <img src={mulherPb} alt="Mulher Cantanto Preto e branco" />
            </div>
            <div className="botoes">
              <button className="botao-fantasma" onClick={onClickFazerDepois}>
                {t("pages.AceiteConvite.fazerDepois")}
              </button>
              <button className="botao-verde" onClick={onCLickFazerAgora}>
                {t("pages.AceiteConvite.fazerAgora")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AceiteConvite;
