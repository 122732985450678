import { ButtonHTMLAttributes } from "react";
import BackgroundButton from "shared/assets/images/botao-transparente.png";
import "./Button.scss";

const DefaultImage: React.FC<ButtonHTMLAttributes<any>> = (props: any) => {
  const { children, ...rest } = props;
  return (
    <button {...rest} className="button default-image">
      <img src={BackgroundButton} alt="" />
      <span> {children}</span>
    </button>
  );
};

export default DefaultImage;
