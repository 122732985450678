import { UserEvent } from "event-tracker/track-user-helpers";

interface QuizEvents {
  S4A_QUIZ_GENERAL_DATA: any;
  S4A_QUIZ_PLATFORMS_CONNECT: any;
  S4A_QUIZ_ANALYSIS_LOADING: any;
  QUIZ_SEARCH_ERROR_SPOTIFY: any;
  QUIZ_SEARCH_ERROR_YOUTUBE: any;
  QUIZ_SEARCH_ERROR_INSTAGRAM: any;
  QUIZ_SEARCH_SPOTIFY: any;
  QUIZ_SEARCH_YOUTUBE: any;
  QUIZ_SEARCH_INSTAGRAM: any;
  QUIZ_SPOTIFY_SELECTED: any;
  QUIZ_YOUTUBE_SELECTED: any;
  QUIZ_INSTAGRAM_SELECTED: any;
  QUIZ_SPOTIFY_NO_VALUE: any;
  QUIZ_YOUTUBE_NO_VALUE: any;
  QUIZ_INSTAGRAM_NO_VALUE: any;

  S4A_SIGN_UP: any;
  S4A_SIGN_UP_VERIFICATION: any;
  S4A_LOGIN: any;
  S4A_HOME_DIAGNOSED: any;
  S4A_HOME_UNDIAGNOSED: any;

  S4A_DISTRO_FORM: any;
  S4A_DISTRO_SIGN: any;

  S4A_ADVANCE_INDEX: any;
  S4A_ADVANCE_CONFIRMATION: any;
  S4A_ADVANCE_WAITING_MERGE: any;
}

const quizEvents: QuizEvents = {
  [UserEvent.S4A_QUIZ_GENERAL_DATA]: {
    screenName: "S4A_QUIZ_GENERAL_DATA",
    businessContext: "QUIZ",
    platformContext: "S4A",
  },
  [UserEvent.S4A_QUIZ_PLATFORMS_CONNECT]: {
    screenName: "S4A_QUIZ_PLATFORMS_CONNECT",
    businessContext: "QUIZ",
    platformContext: "S4A",
  },
  [UserEvent.S4A_QUIZ_ANALYSIS_LOADING]: {
    screenName: "S4A_QUIZ_ANALYSIS_LOADING",
    businessContext: "QUIZ",
    platformContext: "S4A",
  },
  [UserEvent.QUIZ_SEARCH_ERROR_SPOTIFY]: {},
  [UserEvent.QUIZ_SEARCH_ERROR_YOUTUBE]: {},
  [UserEvent.QUIZ_SEARCH_ERROR_INSTAGRAM]: {},
  [UserEvent.QUIZ_SEARCH_SPOTIFY]: {},
  [UserEvent.QUIZ_SEARCH_YOUTUBE]: {},
  [UserEvent.QUIZ_SEARCH_INSTAGRAM]: {},
  [UserEvent.QUIZ_SPOTIFY_SELECTED]: {},
  [UserEvent.QUIZ_YOUTUBE_SELECTED]: {},
  [UserEvent.QUIZ_INSTAGRAM_SELECTED]: {},
  [UserEvent.QUIZ_SPOTIFY_NO_VALUE]: {},
  [UserEvent.QUIZ_YOUTUBE_NO_VALUE]: {},
  [UserEvent.QUIZ_INSTAGRAM_NO_VALUE]: {},

  [UserEvent.S4A_SIGN_UP]: {
    screenName: "S4A_SIGN_UP",
    businessContext: "SIGN_UP",
    platformContext: "S4A",
  },
  [UserEvent.S4A_SIGN_UP_VERIFICATION]: {
    screenName: "S4A_SIGN_UP_VERIFICATION",
    businessContext: "SIGN_UP",
    platformContext: "S4A",
  },
  [UserEvent.S4A_LOGIN]: {
    screenName: "S4A_LOGIN",
    businessContext: "LOGIN",
    platformContext: "S4A",
  },
  [UserEvent.S4A_HOME_DIAGNOSED]: {
    screenName: "S4A_HOME_DIAGNOSED",
    businessContext: "HOME",
    platformContext: "S4A",
  },
  [UserEvent.S4A_HOME_UNDIAGNOSED]: {
    screenName: "S4A_HOME_UNDIAGNOSED",
    businessContext: "HOME",
    platformContext: "S4A",
  },

  [UserEvent.S4A_DISTRO_FORM]: {
    screenName: "S4A_DISTRO_FORM",
    businessContext: "DISTRO",
    platformContext: "S4A",
  },

  [UserEvent.S4A_DISTRO_SIGN]: {
    screenName: "S4A_DISTRO_SIGN",
    businessContext: "DISTRO",
    platformContext: "S4A",
  },

  [UserEvent.S4A_ADVANCE_INDEX]: {
    screenName: "S4A_ADVANCE_INDEX",
    businessContext: "ADVANCE",
    platformContext: "S4A",
  },
  [UserEvent.S4A_ADVANCE_CONFIRMATION]: {
    screenName: "S4A_ADVANCE_CONFIRMATION",
    businessContext: "ADVANCE",
    platformContext: "S4A",
  },
  [UserEvent.S4A_ADVANCE_WAITING_MERGE]: {
    screenName: "S4A_ADVANCE_WAITING_MERGE",
    businessContext: "ADVANCE",
    platformContext: "S4A",
  },
};
export default quizEvents;
