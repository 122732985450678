import { Checkbox, Col, Form, Row } from "antd";
import { Trans } from "react-i18next";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Release } from "model/Release";
import { toast } from "react-toastify";
import { InfoCircleOutlined } from "@ant-design/icons";

export const findAllConnectedIsRcs = (
  release: Release,
  remainingReleases: Release[],
  checkedReleases: Release[] = []
): string[] => {
  if (checkedReleases.includes(release)) {
    return [];
  }

  const currentIsRcs = release.tracks?.map((track) => track?.isRc || "") || [];
  checkedReleases.push(release);

  const connectedReleases = remainingReleases.filter((r) =>
    r.tracks?.some((track) => currentIsRcs.includes(track.isRc || ""))
  );

  const sharedIsRcs = connectedReleases.flatMap((r) =>
    findAllConnectedIsRcs(r, remainingReleases, checkedReleases)
  );

  return Array.from(new Set([...currentIsRcs, ...sharedIsRcs]));
};

export const updateSelectedForConnectedReleases = (
  data: any,
  isRcList: string[],
  selected: boolean
): any => {
  const updateReleaseList = (releases: Release[]): Release[] => {
    return releases.map((release) => {
      const isSelected = release.tracks?.some((track) =>
        isRcList.includes(track.isRc || "")
      );

      return {
        ...release,
        selected:
          isSelected && release.isMigratable ? selected : release.selected,
      };
    });
  };

  return {
    album: updateReleaseList(data.album),
    single: updateReleaseList(data.single),
  };
};

const showToastOutrosLancamentos = () => {
  toast.info(
    <div className="toast-info">
      <Trans i18nKey="pages.Adiantamentos.outrosLancamentosSelecionados" />
    </div>
  );
};

const FieldRelease: React.FC<any> = (props) => {
  const { release, field, type } = props;
  const form = Form.useFormInstance();
  const migratable = release.isMigratable;

  const onCheckboxClick = (event: CheckboxChangeEvent) => {
    const selected = event.target.checked;
    const data = form.getFieldsValue(true);
    const allReleases: Release[] = [...data.album, ...data.single];
    const connectedIsRcs: string[] = findAllConnectedIsRcs(
      release,
      allReleases
    );
    const result = updateSelectedForConnectedReleases(
      data,
      connectedIsRcs,
      selected
    );
    if (selected) {
      const otherSelectedCount = allReleases.filter(
        (release: any) => release.selected
      ).length;

      const newSelectedCount = [...result.album, ...result.single].filter(
        (release: any) => release.selected
      ).length;
      if (otherSelectedCount !== newSelectedCount) showToastOutrosLancamentos();
    }
    form.setFieldsValue(result);
  };

  return (
    <div
      key={release.releaseId}
      style={{ margin: "0 16px" }}
      className={`card-album ${migratable === false ? "non-migratable" : ""}`}
    >
      <Row gutter={16} className="card-album-row">
        <Col className="album-imagem">
          <img src={release.releaseImageUrl} alt="" />
        </Col>
        <Col
          xs={13}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 4,
            color: "8e8e8e",
          }}
        >
          <span style={{ color: "#8e8e8e" }}>
            {type} | {release.labelName}
          </span>
          <span style={{ marginBottom: 4, fontSize: 20, fontWeight: 600 }}>
            {release.releaseName}
          </span>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
          <Form.Item
            {...field}
            noStyle
            name={[field.name, "selected"]}
            valuePropName="checked"
          >
            <Checkbox onChange={onCheckboxClick} disabled={!migratable} />
          </Form.Item>
        </Col>
      </Row>
      {migratable === false && (
        <div className="explicacao">
          <InfoCircleOutlined style={{ marginRight: 8, color: "#00BDDC" }} />

          <Trans i18nKey="pages.Adiantamentos.lancamentoNaoMigravel" />
        </div>
      )}
    </div>
  );
};

export default FieldRelease;
