import { ReactComponent as IconCheck } from "shared/assets/images/icon-google-colored.svg";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { LANG } from "shared/constants/StorageConsts";
import { UserEvent, saveUserEvent } from "event-tracker/track-user-helpers";

const { REACT_APP_OAUTH_URL, REACT_APP_APPLICATION_URL, REACT_APP_CLIENT_ID } =
  process.env;

const Google: React.FC<any> = (params) => {
  const { onClick } = params;
  const { t } = useTranslation();
  const { lang } = useParams<{ lang: string }>();

  const signInAlt = () => {
    if (onClick) onClick();
    saveUserEvent({ name: UserEvent.SIGNIN_SOCIAL_GOOGLE });
    if (lang) Cookies.set(LANG, lang);
    window.location.href = `${REACT_APP_OAUTH_URL}/authorize?identity_provider=Google&redirect_uri=${REACT_APP_APPLICATION_URL}&response_type=CODE&client_id=${REACT_APP_CLIENT_ID}&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile`;
  };

  return (
    <button onClick={signInAlt} className="button google social-buttons">
      <IconCheck style={{ width: 26, height: 26 }} />
      <span style={{ marginLeft: 16 }}>
        {t("components.button.google.entre", "Entre com o Google")}
      </span>
    </button>
  );
};

export default Google;

//https://localhost:3000/login?response_type=code&client_id=292103268242-ihs355sbn3u4bv2m539o8i2gciqug0g2.apps.googleusercontent.com&redirect_uri=https://www.example.com
