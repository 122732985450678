import { Form, Row, Col, Select, Input, Radio, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Trans, useTranslation } from "react-i18next";
import { LinkOutlined } from "@ant-design/icons";
import { ReactComponent as NotaIcon } from "shared/assets/images/nota-icon.svg";
import { ReleaseTypeEnum } from "shared/enums/ReleaseTypeEnum";
import { releaseTypeFieldName } from "../interfaces";
import { monthsData } from "shared/enums/MonthsEnum";

interface ReleaseDataStepProps {
  count: [number, number];
  name: string[];
  title: string;
  isVisible: boolean;
}

const ReleaseDataStep: React.FC<ReleaseDataStepProps> = ({
  count,
  name,
  isVisible,
  title,
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const [currentItem, total] = count;

  const renderTracks = () => {
    const tracksCountCurrent = form.getFieldValue([...name, "tracksCount"]);

    const tracks = [];
    for (let i = 0; i < tracksCountCurrent; i++) {
      tracks.push(
        <div className="wrapper-input-track" key={i}>
          <div className="label">
            <NotaIcon />
          </div>
          <div className="wrapper-input" style={{ flexGrow: 1 }}>
            <Form.Item
              name={[...name, "tracks", i, "name"]}
              rules={[
                {
                  required: isVisible,
                  message: "",
                },
              ]}
            >
              <Input
                className="input-number-with-buttons"
                style={{
                  backgroundColor: "#1A1A1A",
                }}
              />
            </Form.Item>
          </div>
          <div className="label">
            <Form.Item
              name={[...name, "tracks", i, "isFocus"]}
              valuePropName="checked"
              style={{ marginBottom: "0px" }}
            >
              <Radio
                className="custom-radio"
                onChange={() => onTrackFocusChange(i)}
              />
            </Form.Item>
          </div>
        </div>
      );
    }
    return tracks;
  };

  const onTrackFocusChange = (index: number) => {
    const values = form.getFieldValue([...name, "tracks"]);

    values.forEach((track: any, indexTrack: number) => {
      track.isFocus = index === indexTrack;
    });

    form.setFieldValue([...name, "tracks"], values);
  };

  const isSingle = name.includes(releaseTypeFieldName[ReleaseTypeEnum.Single]);

  return (
    <Row justify="center" gutter={[24, 24]}>
      <Col span={24} className="header">
        <p className="title">
          <Trans
            i18nKey={t(title, {
              current: currentItem,
              total: total,
            })}
          />
        </p>
      </Col>
      <Col span={24}>
        <Form.Item
          name={[...name, "releaseForecastMonth"]}
          label={t("pages.ReleasesSchedule.steps.release.previsaoLancamento")}
          rules={[
            {
              required: isVisible,
              message: t(
                "pages.ReleasesSchedule.steps.release.previsaoLancamentoRequired"
              ),
            },
          ]}
        >
          <Select
            options={monthsData.map((x) => {
              return {
                ...x,
                label: t(x.label),
              };
            })}
            showArrow
          />
        </Form.Item>
        <Form.Item
          name={[...name, "name"]}
          label={t("pages.ReleasesSchedule.steps.release.nome")}
          rules={[
            {
              required: isVisible,
              message: t("pages.ReleasesSchedule.steps.release.nomeRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={[...name, "context"]}
          label={t("pages.ReleasesSchedule.steps.release.contexto")}
          rules={[
            {
              required: isVisible,
              message: t(
                "pages.ReleasesSchedule.steps.release.contextoRequired"
              ),
            },
          ]}
        >
          <TextArea rows={3} maxLength={4000} />
        </Form.Item>
        <Form.Item
          name={[...name, "genre"]}
          label={t("pages.ReleasesSchedule.steps.release.genero")}
          rules={[
            {
              required: isVisible,
              message: t("pages.ReleasesSchedule.steps.release.generoRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {!isSingle && (
          <Row>
            <Col span={24}>
              <Row justify="space-between" className="upper-label-tracks">
                <div>{t("pages.ReleasesSchedule.steps.release.nomeFaixa")}</div>
                <div>{t("pages.ReleasesSchedule.steps.release.faixaFoco")}</div>
              </Row>
              {renderTracks()}
            </Col>
          </Row>
        )}
        <Row className="row-optional">
          <Col span={24}>
            <p>{t("pages.ReleasesSchedule.steps.release.opcionais")}</p>
          </Col>
        </Row>
        <Form.Item
          name={[...name, "urlMusic"]}
          label={t("pages.ReleasesSchedule.steps.release.linkMusica")}
          rules={[
            {
              type: "url",
              message: t("pages.ReleasesSchedule.steps.release.urlInvalida"),
            },
          ]}
        >
          <Input addonBefore={<LinkOutlined />} />
        </Form.Item>
        <Form.Item
          name={[...name, "label"]}
          label={t("pages.ReleasesSchedule.steps.release.selo")}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("pages.ReleasesSchedule.steps.release.clipe")}
          name={[...name, "existsVideoClipe"]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name={[...name, "urlPhotos"]}
          label={t("pages.ReleasesSchedule.steps.release.urlFotos")}
          rules={[
            {
              type: "url",
              message: t("pages.ReleasesSchedule.steps.release.urlInvalida"),
            },
          ]}
        >
          <Input
            addonBefore={<LinkOutlined />}
            placeholder={t(
              "pages.ReleasesSchedule.steps.release.linkDocumento"
            )}
          />
        </Form.Item>
        <Form.Item
          name={[...name, "urlCover"]}
          label={t("pages.ReleasesSchedule.steps.release.urlCapa")}
          rules={[
            {
              type: "url",
              message: t("pages.ReleasesSchedule.steps.release.urlInvalida"),
            },
          ]}
        >
          <Input
            addonBefore={<LinkOutlined />}
            placeholder={t(
              "pages.ReleasesSchedule.steps.release.linkDocumento"
            )}
          />
        </Form.Item>
        <Form.Item
          name={[...name, "urlMarketingPlaning"]}
          label={t("pages.ReleasesSchedule.steps.release.urlMarketing")}
          rules={[
            {
              type: "url",
              message: t("pages.ReleasesSchedule.steps.release.urlInvalida"),
            },
          ]}
        >
          <Input
            addonBefore={<LinkOutlined />}
            placeholder={t(
              "pages.ReleasesSchedule.steps.release.linkDocumento"
            )}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ReleaseDataStep;
