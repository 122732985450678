import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AdvanceStatus } from "shared/enums/AdvanceStatus";
import {
  acceptCashAdvanceProposal,
  changeStatusCashAdvanceProcess,
} from "shared/service/StrmService";
import MeuAdiantamento from "./MeuAdiantamento";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";

const AdiantamentoAlteracao: React.FC<any> = (props) => {
  const { advance, reloadCashAdvance } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const onClickRecusaContrato = async () => {
    await changeStatusCashAdvanceProcess(advance.id, AdvanceStatus.Canceled);
    toast.success(t("pages.Adiantamentos.cancelarAdiantamentoSucesso"), {
      autoClose: 10000,
    });
    history.push("/");
  };

  const onClickAceitaContrato = async () => {
    await acceptCashAdvanceProposal(advance.id);
    reloadCashAdvance();
  };

  return (
    <div
      className="flex-column-between"
      style={{
        minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
        paddingTop: isMobile ? 0 : 60,
      }}
    >
      <div>
        <div className="confirma-adiantamento-intro">
          <h1>{t("pages.Adiantamentos.valoresAlteradosTitulo")}</h1>
          <p style={{ maxWidth: isMobile ? "unset" : 550, marginBottom: 48 }}>
            <Trans i18nKey={t("pages.Adiantamentos.valoresAlteradosTexto")} />
          </p>
        </div>
        <MeuAdiantamento advance={advance} />
      </div>
      <div>
        <div className="footer black">
          <button className="botao-borda-roxa " onClick={onClickRecusaContrato}>
            {t("pages.Adiantamentos.voltar")}
          </button>
          <button
            className="botao-roxo continuar"
            onClick={onClickAceitaContrato}
          >
            {t("pages.Adiantamentos.continuar")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdiantamentoAlteracao;
