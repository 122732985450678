import { useTranslation } from "react-i18next";

const Duvidas: React.FC<any> = (props) => {
  const { t } = useTranslation();
  return (
    <span className="duvidas">
      {t("pages.Adiantamentos.aindaComDuvidas")}
      {` `}
      <a href="mailto:suporte@strm.com.br">
        {t("pages.Adiantamentos.faleConsultores")}
      </a>
    </span>
  );
};

export default Duvidas;
