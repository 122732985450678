import { useTranslation } from "react-i18next";

const EstagioArtistaErro: React.FC<any> = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: "100vh",
        marginTop: "30vh",
        display: "flex",
        justifyContent: "center",
        color: "white",
      }}
    >
      <h1>{t("pages.EstagioArtista.tentarNovamenteEmbreve")}</h1>
    </div>
  );
};

export default EstagioArtistaErro;
