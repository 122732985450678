import { resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import { Form, Input, Typography, Button as AntButton } from "antd";
import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import { LanguageChange } from "shared/components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import LoadingButton from "shared/components/button/LoadingButton";

const ResetSenha: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [etapa, setEtapa] = useState("primeira");
  const [loading, setLoading] = useState<boolean>(false);

  const onFinishPrimeiraEtapa = async (formAtual: any) => {
    setLoading(true);
    const values = await formAtual.validateFields();
    try {
      await resetPassword({ username: values.email });
      setEtapa("segunda");
    } catch (error: any) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const onFinishSegundaEtapa = async (formAtual: any, username: string) => {
    setLoading(true);
    const { codigo, password } = await formAtual.validateFields();
    try {
      await confirmResetPassword({
        username,
        confirmationCode: codigo,
        newPassword: password,
      });
      history.push("/login");
      toast.success(t("pages.ResetSenha.sucesso"));
    } catch (error: any) {
      if (error.code === "CodeMismatchException")
        toast.error(t("pages.ResetSenha.codigoInvalido"));
      else toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <div
      className="conteudo-centralizado login"
      style={{
        height: "100vh",
        backgroundColor: "#1a191a",
        padding: "14px 26px",
      }}
    >
      <div className="direction">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="logo-region">
            <Logo className="logo" style={{ width: isMobile ? 80 : 100 }} />
            <span style={{ fontSize: isMobile ? 14 : 28 }}>for artists</span>
          </div>
          <LanguageChange.SelectLanguageChange />
        </div>
        <Form.Provider
          onFormFinish={async (name, { forms }) => {
            const { esqueciPrimeiraEtapaForm, esqueciSegundaEtapaForm } = forms;
            if (name === "esqueciPrimeiraEtapaForm") {
              onFinishPrimeiraEtapa(esqueciPrimeiraEtapaForm);
            }
            if (name === "esqueciSegundaEtapaForm") {
              onFinishSegundaEtapa(
                esqueciSegundaEtapaForm,
                esqueciPrimeiraEtapaForm.getFieldValue("email")
              );
            }
          }}
        >
          <Form
            form={form}
            name="esqueciPrimeiraEtapaForm"
            hidden={etapa !== "primeira"}
          >
            <div className="content" style={{ marginTop: 154 }}>
              <div className="text">
                <Typography.Title style={{ color: "#F2F2F2", fontSize: 20 }}>
                  {t("pages.ResetSenha.titulo")}
                </Typography.Title>
                <Typography.Paragraph
                  style={{
                    color: "#F2F2F2",
                    fontSize: 13,
                    marginTop: ".5rem",
                    marginBottom: 0,
                  }}
                >
                  {t("pages.ResetSenha.avisoLoginExterno1")}
                </Typography.Paragraph>
                <Typography.Paragraph
                  style={{
                    color: "#F2F2F2",
                    fontSize: 13,
                  }}
                >
                  {t("pages.ResetSenha.avisoLoginExterno2")}
                </Typography.Paragraph>
              </div>
              <div className="input">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("pages.ResetSenha.emailRequired"),
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder={t("pages.ResetSenha.email")}
                  />
                </Form.Item>
              </div>

              <div className="enter-button">
                <LoadingButton className="botao-roxo" loading={loading}>
                  {t("pages.ResetSenha.submit")}
                </LoadingButton>
              </div>
            </div>
          </Form>
          {etapa === "segunda" && (
            <Form form={form} name="esqueciSegundaEtapaForm">
              <div className="content" style={{ marginTop: 154 }}>
                <div className="text">
                  <Typography.Title style={{ color: "#F2F2F2", fontSize: 20 }}>
                    {t("pages.ResetSenha.titulo")}
                  </Typography.Title>
                </div>
                <div className="input">
                  <Form.Item
                    name="codigo"
                    rules={[
                      {
                        required: true,
                        message: t("pages.ResetSenha.codigoRequired"),
                      },
                    ]}
                  >
                    <Input placeholder={t("pages.ResetSenha.codigo")} />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t("pages.ResetSenha.senhaRequired"),
                      },
                    ]}
                  >
                    <Input.Password placeholder={t("pages.ResetSenha.senha")} />
                  </Form.Item>
                  <Form.Item
                    name="confirmacaoSenha"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: t("pages.ResetSenha.confirmacaoSenhaRequired"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(t("pages.ResetSenha.senhaNaoCombina"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder={t("pages.ResetSenha.confirmacaoSenha")}
                    />
                  </Form.Item>
                </div>

                <div className="enter-button">
                  <LoadingButton className="botao-roxo" loading={loading}>
                    {t("pages.ResetSenha.submit")}
                  </LoadingButton>
                </div>
              </div>
            </Form>
          )}
        </Form.Provider>
        <div className="esqueci-senha">
          <AntButton
            type="link"
            htmlType="button"
            onClick={() => history.goBack()}
            className="button-reset-senha"
          >
            {t("pages.ResetSenha.voltar")}
          </AntButton>
        </div>
      </div>
    </div>
  );
};

export default ResetSenha;
