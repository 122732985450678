import { BROWSER_ID } from "shared/constants/StorageConsts";
import { v4 as uuidv4 } from "uuid";

export const loadUserId = (storageBrowserId: string = "") => {
  const browserId = sessionStorage.getItem(BROWSER_ID);

  return (
    browserId ||
    sessionStorage.setItem(BROWSER_ID, storageBrowserId || uuidv4())
  );
};

export const getBrowserId: () => string = () => {
  return sessionStorage.getItem(BROWSER_ID) || "";
};
