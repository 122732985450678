export enum QuizDisabledReasonEnum {
  CashAdvanceInProgress = 0,
  SpecialCampaignRequestInProgress = 1,
  MaximumChangeReach = 2,
}

export const getKeyTranslation = (val: QuizDisabledReasonEnum) => {
  let response = null;
  switch (val) {
    case QuizDisabledReasonEnum.CashAdvanceInProgress:
      response = "pages.EstagioArtista.refazerDiagnosticoBlockAdvance";
      break;

    case QuizDisabledReasonEnum.SpecialCampaignRequestInProgress:
      response =
        "pages.EstagioArtista.refazerDiagnosticoBlockSpecialCamapignRequest";

      break;

    case QuizDisabledReasonEnum.MaximumChangeReach:
      response = "pages.EstagioArtista.refazerDiagnosticoBlockLimite";
      break;
  }
  return response;
};
