import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface NotFoundLoaderProps {
  loading: boolean;
}
const NotFoundLoader: React.FC<NotFoundLoaderProps> = (props) => {
  const { loading } = props;
  return loading ? (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      size="small"
    />
  ) : null;
};

export default NotFoundLoader;
