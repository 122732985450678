const listaGeneros = [
  {
    code: "outro-afrobeats",
    pt: "Afrobeats",
    en: "Afrobeats",
    es: "Afrobeats",
  },
  {
    code: "outro-alternativo-indie",
    pt: "Alternativo indie",
    en: "Alternative indie",
    es: "Alternativo indie",
  },
  {
    code: "axe",
    en: "Axe",
    pt: "Axé",
    es: "Axé",
  },
  {
    code: "outro-bachata",
    pt: "Bachata",
    en: "Bachata",
    es: "Bachata",
  },
  {
    code: "outro-blues",
    pt: "Blues",
    en: "Blues",
    es: "Blues",
  },
  {
    code: "outro-bolero",
    pt: "Bolero",
    en: "Bolero",
    es: "Bolero",
  },
  {
    code: "brega",
    en: "Brega",
    pt: "Brega",
    es: "Brega",
  },
  {
    code: "outro-c-pop",
    pt: "C-Pop",
    en: "C-Pop",
    es: "C-Pop",
  },
  {
    code: "outro-country",
    pt: "Country",
    en: "Country",
    es: "Country",
  },
  {
    code: "outro-cumbia",
    en: "Cumbia",
    pt: "Cumbia",
    es: "Cumbia",
  },
  {
    code: "outro-dembow",
    en: "Dembow",
    pt: "Dembow",
    es: "Dembow",
  },
  {
    code: "eletronico",
    en: "Electronic-dance",
    pt: "Eletrônico-dance",
    es: "Eletrônico-dance",
  },
  {
    code: "outro-folk",
    en: "Folk",
    pt: "Folk",
    es: "Folk",
  },
  {
    code: "forro",
    en: "Forro",
    pt: "Forró",
    es: "Forró",
  },
  {
    code: "outro-funk",
    pt: "Funk",
    en: "Funk",
    es: "Funk",
  },
  {
    code: "funk",
    en: "Brazilian funk",
    pt: "Funk Brasileiro",
    es: "Funk Brasileño",
  },
  {
    code: "gospel",
    en: "Religious music",
    pt: "Música religiosa",
    es: "Musica religiosa",
  },
  {
    code: "hiphop",
    en: "Hip hop",
    pt: "Hip hop",
    es: "Hip hop",
  },
  {
    code: "infantil",
    en: "Children's music",
    pt: "Infantil",
    es: "Infantil",
  },
  {
    code: "outro-j-pop",
    pt: "J-Pop",
    en: "J-Pop",
    es: "J-Pop",
  },
  {
    code: "jazz",
    en: "Jazz",
    pt: "Jazz",
    es: "Jazz",
  },
  {
    code: "outro-kpop",
    en: "K-Pop",
    pt: "K-Pop",
    es: "K-Pop",
  },
  {
    code: "outro-lofi",
    en: "Lo-fi",
    pt: "Lo-fi",
    es: "Lo-fi",
  },
  {
    code: "metal",
    en: "Metal",
    pt: "Metal",
    es: "Metal",
  },
  {
    code: "mpb",
    en: "Mpb",
    pt: "Mpb",
    es: "Mpb",
  },
  {
    code: "outro-musica-classica",
    pt: "Música clássica",
    en: "Classical music",
    es: "Música Clasica",
  },
  {
    code: "outro-musica-regional",
    pt: "Música regional",
    en: "Regional music",
    es: "Música regional",
  },
  {
    code: "outro",
    pt: "Outro",
    en: "Other",
    es: "Otro",
  },
  {
    code: "pop",
    pt: "Pop",
    en: "Pop",
    es: "Pop",
  },
  {
    code: "outro-r&b",
    pt: "R&B",
    en: "R&B",
    es: "R&B",
  },
  {
    code: "outro-ranchero",
    pt: "Ranchero",
    en: "Ranchero",
    es: "Ranchero",
  },
  {
    code: "reggae",
    pt: "Reggae",
    en: "Reggae",
    es: "Reggae",
  },
  {
    code: "outro-reggaeton",
    pt: "Reggaeton",
    en: "Reggaeton",
    es: "Reggaeton",
  },
  {
    code: "rock",
    pt: "Rock",
    en: "Rock",
    es: "Rock",
  },
  {
    code: "outro-salsa",
    pt: "Salsa",
    en: "Salsa",
    es: "Salsa",
  },
  {
    code: "samba",
    pt: "Samba",
    en: "Samba",
    es: "Samba",
  },
  {
    code: "sertanejo",
    pt: "Sertanejo",
    en: "Sertanejo",
    es: "Sertanejo",
  },
  {
    code: "outro-soul",
    pt: "Soul",
    en: "Soul",
    es: "Soul",
  },
  {
    code: "outro-spiritual",
    pt: "Spiritual",
    en: "Spiritual",
    es: "Spiritual",
  },
  {
    code: "outro-tango",
    pt: "Tango",
    en: "Tango",
    es: "Tango",
  },
  {
    code: "outro-world-music",
    pt: "World Music",
    en: "World Music",
    es: "World Music",
  },
];
export default listaGeneros;
