import { Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getI18nGenre } from "shared/helpers/i18nGenre";

const GenreTagList = ({ genreList }: { genreList?: string[] }) => {
  const { i18n } = useTranslation();
  const { genres } = useSelector((state: any) => state.genres);

  if (!genreList || genreList?.length === 0) return null;

  return (
    <>
      {genreList?.map((genre: any) => (
        <Tag
          color="transparent"
          style={{
            border: `1px solid #7692FF`,
            color: "#7692FF",
            backgroundColor: "#2F60FF1A",
            textTransform: "capitalize",
          }}
          key={Math.random()}
        >
          {getI18nGenre(genre, i18n, genres)}
        </Tag>
      ))}
    </>
  );
};

export default GenreTagList;
