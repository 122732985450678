export const BRLFormat = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export const BRLFormatNoSign = (value: number) => {
  return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
    value
  );
};

export const ptBRFormat = (value: number) => {
  return new Intl.NumberFormat("pt-BR").format(value);
};

export const moneyFormat = (value: number, currency: string) => {
  return new Intl.NumberFormat(currency === "BRL" ? "pt-BR" : "en-US", {
    style: "currency",
    currency: currency,
  }).format(value);
};

export const moneyFormatObject = (value: any = { currency: "BRL" }) => {
  const currency = value?.currency || "BRL";
  return new Intl.NumberFormat(value?.currency === "BRL" ? "pt-BR" : "en-US", {
    style: "currency",
    currency: currency,
  }).format(value.amount);
};

export const formatNoSign = (value: number, currency: string = "BRL") => {
  return new Intl.NumberFormat(currency === "BRL" ? "pt-BR" : "en-US", {
    minimumFractionDigits: 2,
  }).format(value);
};

export const formatNoSignObject = (value: any = { currency: "BRL" }) => {
  return new Intl.NumberFormat(value?.currency === "BRL" ? "pt-BR" : "en-US", {
    minimumFractionDigits: 2,
  }).format(value.amount);
};

export const signByCurrency = (currency: string) =>
  currency === "USD" ? "$" : "R$";
