import { Form, Input } from "antd";
import { StepProps } from "./StepProps";
import { useTranslation } from "react-i18next";

const FourthStep: React.FC<StepProps> = () => {
  const { t } = useTranslation();

  const LabelWithInstruction = (props: any) => {
    const { label, instruction } = props;
    return (
      <div className="label-with-instruction">
        <p className="label">{label}</p>
        <p className="instruction">{instruction}</p>
      </div>
    );
  };

  return (
    <div>
      <Form.Item
        name="videoClipeUrl"
        label={
          <LabelWithInstruction
            label={t("pages.Pitching.steps.fourth.videoClipeUrl")}
            instruction={t("pages.Pitching.steps.fourth.videoClipeUrlIns")}
          />
        }
        rules={[
          {
            type: "url",
            message: t("pages.Pitching.steps.fourth.urlInvalida"),
          },
        ]}
      >
        <Input
          placeholder={t(
            "pages.Pitching.steps.fourth.videoClipeUrlPlaceHolder"
          )}
        />
      </Form.Item>
      <Form.Item
        name="photosURL"
        label={
          <LabelWithInstruction
            label={t("pages.Pitching.steps.fourth.photosURL")}
            instruction={t("pages.Pitching.steps.fourth.photosURLIns")}
          />
        }
        rules={[
          {
            required: true,
            message: t("pages.Pitching.steps.fourth.photosURLRequired"),
          },
          {
            type: "url",
            message: t("pages.Pitching.steps.fourth.urlInvalida"),
          },
        ]}
      >
        <Input
          placeholder={t("pages.Pitching.steps.fourth.photosURLPlaceholder")}
        />
      </Form.Item>
      <Form.Item
        name="planningFilesURL"
        label={
          <LabelWithInstruction
            label={t("pages.Pitching.steps.fourth.planningFilesURL")}
            instruction={t("pages.Pitching.steps.fourth.planningFilesURLIns")}
          />
        }
        rules={[
          {
            required: true,
            message: t(
              "pages.Pitching.steps.fourth.planningFilesURLInsRequired"
            ),
          },
          {
            type: "url",
            message: t("pages.Pitching.steps.fourth.urlInvalida"),
          },
        ]}
      >
        <Input
          placeholder={t(
            "pages.Pitching.steps.fourth.planningFilesURLPlaceholder"
          )}
        />
      </Form.Item>
    </div>
  );
};

export default FourthStep;
