export const removeDiacritics = (str: string): string => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const stringIncludes = (strA: string, strB: string): boolean => {
  const normalizedStrA = removeDiacritics(strA).toLowerCase();
  const normalizedStrB = removeDiacritics(strB).toLowerCase();

  return normalizedStrA.includes(normalizedStrB);
};
