import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as WalletIcon } from "shared/assets/images/icon-wallet.svg";

const CardAdiantamento: React.FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loggedUser } = useSelector((state: any) => state.users);
  const { cashAdvanceAvailable } = useSelector((state: any) => state.advances);

  if (cashAdvanceAvailable || loggedUser?.isCashAdvanceAvailable)
    return (
      <div className="card-outra-sessao">
        <WalletIcon className="icon amarelo" />
        <span className="titulo">
          {t("pages.EstagioArtista.cardAdiantamentoTitulo")}
        </span>
        <span className="divider" />
        <h1>{t("pages.EstagioArtista.cardAdiantamentoTitulo")}</h1>
        <p className="strong-escuro">
          <Trans i18nKey={t("pages.EstagioArtista.cardAdiantamentoTexto")} />
        </p>
        <button
          className="botao-roxo"
          onClick={() => {
            history.push("/adiantamentos");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          {t("pages.EstagioArtista.saibaMais")}
        </button>
      </div>
    );
  return null;
};

export default CardAdiantamento;
