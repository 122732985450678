import { signOut } from "aws-amplify/auth";
import { QUIZ_INFO, TOKEN } from "shared/constants/StorageConsts";
import { saveQuiz } from "shared/service/StrmService";
import * as Sentry from "@sentry/react";
import { getRefPageLocalStorage } from "./ref-page";

export const autenticado = () => {
  return localStorage.getItem(TOKEN) != null;
};

export const logout = async (keepSession: boolean = true) => {
  try {
    await signOut({ global: true });
  } finally {
    if (keepSession) {
      sessionStorage.setItem("returnURL", window.location.pathname);
    }
    localStorage.clear();
    window.location.href = "/login";
  }
};

export const hasClaim = (claim: string, loggedUser: any) => {
  return loggedUser?.claims
    ?.map((cl: string) => cl.toUpperCase())
    .includes(claim.toUpperCase());
};

export const hasQuizPending = () => {
  return sessionStorage.getItem(QUIZ_INFO) !== null;
};

export const handlePendingQuiz = async (callback: any) => {
  if (hasQuizPending()) {
    const quiz = JSON.parse(sessionStorage.getItem(QUIZ_INFO) || "{}");
    try {
      if (await saveQuiz(quiz)) {
        sessionStorage.removeItem(QUIZ_INFO);
        callback(true);
      } else {
        callback();
      }
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTransactionName("Fluxo login quiz");
        scope.setTag("campanha", getRefPageLocalStorage());
        scope.setLevel("fatal");
        scope.setExtras({
          quiz,
        });
        Sentry.captureException(error);
        throw error;
      });
    }
    return true;
  } else {
    return false;
  }
};
