import { PageHeader } from "@ant-design/pro-layout";
import { Col, Collapse, message, Modal, Row, Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { subscribeNewsletter } from "shared/service/StrmService";
import "./Diagnosticos.scss";
import { PlayCircleFilled } from "@ant-design/icons";
import diagnosticoJornadaCarreiraExemploImg from "shared/assets/images/diagnostico-jornada-carreira-exemplo.jpg";
import diagnosticoDadosPublicosExemploImg from "shared/assets/images/diagnostico-dados-publicos-exemplo.jpg";
import diagnosticoDadosPrivadoExemploImg from "shared/assets/images/diagnostico-dados-privados-exemplo.jpg";
import diagnosticoConclusaoExemploImg from "shared/assets/images/diagnostico-conclusao-exemplo.jpg";
import CarouselExemploDiagnostico from "./CarouselExemploDiagnostico";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useWindowWidth } from "shared/hooks/UseWindowWidth";
import { WhatsAppContactConsultor } from "shared/helpers/whatsapp-contact";

const { success } = Modal;

const { REACT_APP_DIAGNOSE_EXAMPLE } = process.env;
const ApresentacaoDiagnostico: React.FC<any> = (props) => {
  const { showPageHeader } = props;
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();

  const signupSubscription = async () => {
    const response = await subscribeNewsletter();
    if (response) message.success(t("pages.EstagioArtista.newsletterSuccess"));
    else if (response) message.error(t("pages.EstagioArtista.newsletterError"));
  };

  const showModalVideo = () => {
    success({
      width: 800,
      closable: true,
      icon: null,
      okButtonProps: {
        className: "botao-roxo",
      },
      content: (
        <iframe
          className="embedded-video-16-9"
          src="https://www.youtube.com/embed/pIXGRHuWhog"
          allowFullScreen
          title="video"
          style={{ width: "100%", border: 0, height: 400 }}
        />
      ),
    });
  };

  return (
    <div className={`previa-diagnostico ${isMobile ? "mobile" : ""}`}>
      <div className="video-botoes">
        {showPageHeader && !isMobile && (
          <PageHeader
            title={t("pages.Diagnosticos.titulo")}
            subTitle={t("pages.Diagnosticos.subTitulo")}
          />
        )}
        <div className="content">
          <div className="texts">
            <button className="play-button" onClick={showModalVideo}>
              <PlayCircleFilled />
            </button>
            <h1>
              {t("pages.Diagnosticos.PreviaDiagnostico.artistasMonitorados")}
            </h1>
          </div>
          <div className="botoes">
            <button className="botao-roxo">
              <Link
                to={{
                  pathname: "/checkout-diagnostico",
                  state: { buy: true },
                }}
              >
                {t(
                  "pages.Diagnosticos.PreviaDiagnostico.fazerDiagnosticoCompleto"
                )}
              </Link>
            </button>
            <button className="botao-borda-roxa">
              <a
                target="_blank"
                href={WhatsAppContactConsultor}
                rel="noopener noreferrer"
              >
                {t("pages.Diagnosticos.PreviaDiagnostico.falarComEspecialista")}
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row-sessoes-diagnostico default-padding">
          <Typography.Title className="title">
            {t(
              "pages.Diagnosticos.PreviaDiagnostico.conhecaQuatroSessoesTitulo"
            )}
          </Typography.Title>
          <p className="texto-sessoes-diagnostico">
            {t(
              "pages.Diagnosticos.PreviaDiagnostico.conhecaQuatroSessoesDescricao"
            )}
          </p>
        </div>
        <Row
          className="default-padding"
          style={{
            marginTop: 24,
            marginBottom: 64,
            display: "flex",
            alignItems: "center",
            gap: 22,
          }}
        >
          <button className="botao-roxo">
            <Link
              style={{ color: "white" }}
              to={{ pathname: "/checkout-diagnostico", state: { buy: true } }}
            >
              {t("pages.Diagnosticos.PreviaDiagnostico.comprarCreditos")}
            </Link>
          </button>
          <a
            className="botao-exemplo"
            href={REACT_APP_DIAGNOSE_EXAMPLE}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("pages.Diagnosticos.exemplo")}
          </a>
        </Row>
        <Row gutter={[0, 55]} style={{ margin: "64px 0" }}>
          <Col xs={24}>
            <Typography.Title className="title-explanation default-padding">
              {t("pages.Diagnosticos.PreviaDiagnostico.jornadaCarreira")}
            </Typography.Title>
            <div className="blur-img">
              <div className="modal-inside-blur-img">
                <p className="title-modal">
                  {t("pages.Diagnosticos.PreviaDiagnostico.entendaQualEstagio")}
                </p>
                <p className="message-modal">
                  {t(
                    "pages.Diagnosticos.PreviaDiagnostico.quaisSaoSeusPrincipaisDesafios"
                  )}
                </p>
                <div className="buttons">
                  <ButtonDiagnosticoCompleto t={t} />
                  <button className="botao-borda-roxa">
                    <a
                      target="_blank"
                      href={WhatsAppContactConsultor}
                      rel="noopener noreferrer"
                    >
                      {t(
                        "pages.Diagnosticos.PreviaDiagnostico.falarComEspecialista"
                      )}
                    </a>
                  </button>
                </div>
              </div>
              <img
                alt=""
                src={diagnosticoJornadaCarreiraExemploImg}
                className="img-desktop"
              />
            </div>
          </Col>

          <Col xs={24}>
            <Typography.Title className="title-explanation default-padding">
              {t(
                "pages.Diagnosticos.PreviaDiagnostico.diagnósticoDadosPublicos"
              )}
            </Typography.Title>
            <div className="blur-img">
              <div className="modal-inside-blur-img">
                <p className="title-modal">
                  {t(
                    "pages.Diagnosticos.PreviaDiagnostico.diagnósticoDadosPublicosModalTitulo"
                  )}
                </p>
                <p className="message-modal">
                  {t(
                    "pages.Diagnosticos.PreviaDiagnostico.diagnósticoDadosPublicosModalMensagem"
                  )}
                </p>
                <div className="buttons">
                  <ButtonDiagnosticoCompleto t={t} />
                  <button className="botao-borda-roxa">
                    <a
                      target="_blank"
                      href={WhatsAppContactConsultor}
                      rel="noopener noreferrer"
                    >
                      {t(
                        "pages.Diagnosticos.PreviaDiagnostico.falarComEspecialista"
                      )}
                    </a>
                  </button>
                </div>
              </div>
              <img
                alt=""
                src={diagnosticoDadosPublicosExemploImg}
                className="img-desktop"
              />
            </div>
          </Col>

          <Col xs={24}>
            <Typography.Title className="title-explanation default-padding">
              {t(
                "pages.Diagnosticos.PreviaDiagnostico.diagnósticoDadosPrivados"
              )}
            </Typography.Title>
            <div className="blur-img">
              <div className="modal-inside-blur-img">
                <p className="title-modal">
                  {t(
                    "pages.Diagnosticos.PreviaDiagnostico.diagnósticoDadosPrivadosModalTitulo"
                  )}
                </p>
                <p className="message-modal">
                  {t(
                    "pages.Diagnosticos.PreviaDiagnostico.diagnósticoDadosPrivadosModalMensagem"
                  )}
                </p>
                <div className="buttons">
                  <ButtonDiagnosticoCompleto t={t} />
                  <button className="botao-borda-roxa">
                    <a
                      target="_blank"
                      href={WhatsAppContactConsultor}
                      rel="noopener noreferrer"
                    >
                      {t(
                        "pages.Diagnosticos.PreviaDiagnostico.falarComEspecialista"
                      )}
                    </a>
                  </button>
                </div>
              </div>
              <img
                alt=""
                src={diagnosticoDadosPrivadoExemploImg}
                className="img-desktop"
              />
            </div>
          </Col>

          <Col xs={24}>
            <Typography.Title className="title-explanation default-padding">
              {t("pages.Diagnosticos.PreviaDiagnostico.conclusao")}
            </Typography.Title>
            <div className="blur-img">
              <div className="modal-inside-blur-img">
                <p className="title-modal">
                  {t(
                    "pages.Diagnosticos.PreviaDiagnostico.conclusaoModalTitulo"
                  )}
                </p>
                <p className="message-modal">
                  {t(
                    "pages.Diagnosticos.PreviaDiagnostico.conclusaoModalMensagem"
                  )}
                </p>
                <div className="buttons">
                  <ButtonDiagnosticoCompleto t={t} />
                  <button className="botao-borda-roxa">
                    <a
                      target="_blank"
                      href={WhatsAppContactConsultor}
                      rel="noopener noreferrer"
                    >
                      {t(
                        "pages.Diagnosticos.PreviaDiagnostico.falarComEspecialista"
                      )}
                    </a>
                  </button>
                </div>
              </div>
              <img
                alt=""
                src={diagnosticoConclusaoExemploImg}
                className="img-desktop"
              />
            </div>
          </Col>
        </Row>
        <Row className="row-example-diagnostic default-padding">
          <Col>
            <Typography.Title>
              {t(
                "pages.Diagnosticos.PreviaDiagnostico.vejaComoRecebeSeuDiagnostico"
              )}
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24}>
            <div>
              <CarouselExemploDiagnostico />
            </div>
          </Col>
        </Row>
        <Row
          justify="center"
          style={{ marginTop: "3rem", marginBottom: "3rem" }}
          className="default-padding"
        >
          <Col
            xs={24}
            style={{
              marginTop: "30px",
            }}
          >
            <Typography.Title style={{ fontSize: 20 }}>
              {t("pages.Diagnosticos.PreviaDiagnostico.aindaTemDuvidas")}
            </Typography.Title>
          </Col>
          <Col xs={24}>
            <p className="texto-duvidas">
              {t(
                "pages.Diagnosticos.PreviaDiagnostico.aindaTemDuvidasDescricao"
              )}
            </p>
          </Col>
          <Col xs={24}>
            <button className="botao-borda-roxa">
              <a
                target="_blank"
                href={WhatsAppContactConsultor}
                rel="noopener noreferrer"
              >
                {t("pages.Diagnosticos.PreviaDiagnostico.falarComEspecialista")}
              </a>
            </button>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24}>
            <Collapse
              bordered={false}
              className="faq"
              expandIcon={() => <FontAwesomeIcon icon={faChevronDown} />}
              expandIconPosition="end"
            >
              {Array(10)
                .fill(1)
                .map((_, i) => (
                  <Collapse.Panel
                    header={
                      <>
                        <span style={{ color: "#5779FF" }}>{i + 1}</span>{" "}
                        {t(`pages.Diagnosticos.FAQ.pergunta${i + 1}`)}
                      </>
                    }
                    key={i}
                  >
                    <p>
                      <Trans
                        i18nKey={t(`pages.Diagnosticos.FAQ.resposta${i + 1}`)}
                      />
                    </p>
                  </Collapse.Panel>
                ))}
            </Collapse>
          </Col>
        </Row>
        <Row justify="center" className="default-padding">
          <Col xs={24}>
            <Typography.Title
              style={{
                marginTop: "3rem",
              }}
            >
              {t(
                "pages.Diagnosticos.PreviaDiagnostico.entendaComoInterpretarDiagnostico"
              )}
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="center" className="row-video">
          <Col xs={24} className="borda-bottom-colorida">
            <iframe
              className="embedded-video-16-9"
              src="https://www.youtube.com/embed/ukLu7BPLJ6s"
              allowFullScreen
              title="youtube video"
            />
          </Col>
        </Row>
        <Row className="row-receber-atualizacoes">
          <Col xs={24}>
            <Typography.Title>
              {t("pages.Diagnosticos.PreviaDiagnostico.gostouDoConteudo")}
            </Typography.Title>
          </Col>
          <Col xs={24}>
            <p>
              {t(
                "pages.Diagnosticos.PreviaDiagnostico.gostouDoConteudoDescricao"
              )}
            </p>
          </Col>
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            <button className="botao-roxo" onClick={signupSubscription}>
              {t(
                "pages.Diagnosticos.PreviaDiagnostico.queroReceberAtualizacoes"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ButtonDiagnosticoCompleto: React.FC<any> = ({ t }) => (
  <button className="botao-roxo">
    <Link
      to={{
        pathname: "/checkout-diagnostico",
        state: { buy: true },
      }}
    >
      {t("pages.Diagnosticos.PreviaDiagnostico.fazerDiagnosticoCompleto")}
    </Link>
  </button>
);

export default ApresentacaoDiagnostico;
