import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { camelCase } from "lodash";
import { Goal } from "model/Goals";
import { useTranslation } from "react-i18next";
import { ReactComponent as GrowingIcon } from "shared/assets/images/growing-icon.svg";
import { ReactComponent as DecreasingIcon } from "shared/assets/images/decreasing-icon.svg";
import { ReactComponent as StableIcon } from "shared/assets/images/stable-icon.svg";
import { MilestoneRatio } from "shared/enums/MilestoneRatio";

interface MetaProps extends Goal {}

const Meta: React.FC<MetaProps> = (props) => {
  const { milestone, name, currentValue, ratioSelected, completed } = props;
  const { t } = useTranslation();

  const percentage = Math.min((currentValue / milestone) * 100, 100);

  const getIconByRatio = () => {
    if (!ratioSelected) return null;
    if (completed) return <GrowingIcon />;
    if (ratioSelected === MilestoneRatio.Ascending) return <GrowingIcon />;
    if (ratioSelected === MilestoneRatio.Descending) return <DecreasingIcon />;
    if (ratioSelected === MilestoneRatio.Stable) return <StableIcon />;
  };

  const getRatioTranslation = () => {
    const translate = (name: string) => t(`pages.Metas.${name}`);
    if (completed) return translate("concluido");
    if (ratioSelected === MilestoneRatio.Ascending)
      return translate("crescendo");
    if (ratioSelected === MilestoneRatio.Descending)
      return translate("decrescendo");
    if (ratioSelected === MilestoneRatio.Stable) return translate("estavel");
  };

  const getColorByRatio = () => {
    if (!ratioSelected) return "";
    if (completed) return "#00D88A";
    if (ratioSelected === MilestoneRatio.Ascending) return "#00D88A";
    if (ratioSelected === MilestoneRatio.Descending) return "#FF4F4F";
    if (ratioSelected === MilestoneRatio.Stable) return "#F2CF51";
  };

  return (
    <div className="meta-card">
      <div>
        <span
          className="goal-name"
          style={{
            fontSize: 12,
            color: "#CFCFCF",
            display: "block",
            marginBottom: 8,
          }}
        >
          {t(`pages.Metas.${camelCase(name)}`)}
        </span>
        {completed && (
          <FontAwesomeIcon
            icon={faCheck}
            style={{ color: "#00D88A", marginRight: 4 }}
          />
        )}
        <span style={{ color: "#F2F2F2", fontSize: 16, fontWeight: 700 }}>{` ${
          currentValue ? Math.floor(currentValue).toLocaleString() : "-"
        }`}</span>

        <span style={{ color: "#B8B8B8" }}>{` /${Math.floor(
          milestone
        ).toLocaleString()}`}</span>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <div
            style={{
              height: 6,
              width: `${percentage}%`,
              backgroundColor: completed ? "#00D88A" : "#F2F2F2",
              borderRadius: completed ? "3px" : "3px 0px 0px 3px",
            }}
          />
          <div
            style={{
              height: 6,
              width: `${100 - percentage}%`,
              backgroundColor: "#292929",
              borderRadius: "0px 3px 3px 0px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 12,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {ratioSelected && (
          <>
            <div>{getIconByRatio()}</div>
            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <span>{t("pages.Metas.evolucao")}</span>
              <span
                style={{
                  color: getColorByRatio(),
                  fontSize: "1rem",
                }}
              >
                {getRatioTranslation()}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Meta;
