import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGenres } from "shared/service/StrmService";
import listaGeneros from "./lista-generos";
import revelatorGenres from "shared/helpers/revelator-genres";

export const getGeneros = createAsyncThunk("generos/get-genero", async () => {
  return await fetchGenres();
});

export const genresSlice = createSlice({
  name: "genres",
  initialState: {
    genres: listaGeneros,
    revelatorGenres: revelatorGenres,
    loading: true,
    genreOptions: [],
  },
  reducers: {
    setGenreOptionsI18n: (state, action) => {
      state.genreOptions = state.genres.map((genre: any) => ({
        label: genre[`${action.payload}`],
        value: genre.code,
      })) as any;
    },
  },
  extraReducers: {
    [getGeneros.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.genres = action.payload;
        state.loading = false;
      }
    },
  },
});

export const { setGenreOptionsI18n } = genresSlice.actions;

export default genresSlice.reducer;
