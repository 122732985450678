import { useRef } from "react";
import { Avatar } from "antd";
import { EstagioArtistaProps } from "./NewEstagioArtista";
import { useSelector } from "react-redux";
import { getCapitalizedLang } from "shared/helpers/capitalized-lang";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as IconCheck } from "shared/assets/images/icon-check.svg";
import { Slider } from "shared/components";
import CardMinhaJornada from "./cards/CardMinhaCarreira";
import CardPlanoEstrategico from "./cards/CardPlanoEstrategico";
import CardDiagnosticoCompleto from "./cards/CardDiagnosticoCompleto";
import { Link, useHistory } from "react-router-dom";
import { formatNoSignObject, signByCurrency } from "shared/helpers/BRLFormat";
import { isMobile } from "react-device-detect";
import { ReactComponent as EstrelaIcon } from "shared/assets/images/icon-estrela.svg";
import { ReactComponent as DistroIcon } from "shared/assets/images/icon-distro-colorido.svg";
import { ReactComponent as SpotifyIcon } from "shared/assets/images/icon-spotify.svg";
import { ReactComponent as AppleMusicIcon } from "shared/assets/images/icon-apple-music.svg";
import { ReactComponent as AmazonMusicIcon } from "shared/assets/images/icon-amazon-music.svg";
import { ReactComponent as YoutubeIcon } from "shared/assets/images/icon-youtube.svg";
import { ReactComponent as DeezerIcon } from "shared/assets/images/icon-deezer.svg";
import { ReactComponent as TidalIcon } from "shared/assets/images/icon-tidal.svg";
import { ReactComponent as SpikeArrow } from "shared/assets/images/spike-arrow.svg";
import CardAdiantamento from "./cards/CardAdiantamento";
import CardDistro from "./cards/CardDistro";
import {
  WhatsAppContactAdvance,
  WhatsAppContactDistro,
} from "shared/helpers/whatsapp-contact";
import { CashAdvanceAvailableEnum } from "shared/enums/CashAdvanceAvailable";
import GenreTagList from "shared/components/genre-tag-list";
import CardCTAHenriqueJuliano from "./campanhas-especiais/ctas/CardCTAHenriqueJuliano";
import { canAskAdvance } from "explorer/reducers/adiantamentos";
import ButtonRemakeQuiz from "shared/components/button-remake-quiz/ButtonRemakeQuiz";

const EstagioArtistsInicio: React.FC<EstagioArtistaProps> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { userArtist } = useSelector((state: any) => state.artists);
  const { countryByKeys } = useSelector((state: any) => state.countries);
  const { loggedUser } = useSelector((state: any) => state.users);
  const { cashAdvanceAvailable } = useSelector((state: any) => state.advances);
  const aprofundeDiagnostico = useRef<HTMLDivElement>(null);

  const permiteAssinarDistroStrm = () =>
    userArtist?.distributionAvailable && !userArtist?.distributionSigned;

  let { titulo, explicacao, posicao, principalObjetivo } = t(
    `pages.EstagioArtista.estagio.${userArtist?.stage || 0}`
  ) as any;

  const goRouteScrollTop = (route: string) => {
    history.push(route);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCountryByKey = (key?: string) => {
    if (key && countryByKeys) {
      const country = countryByKeys[key];
      if (country) {
        const lang = getCapitalizedLang(i18n);
        return country[`description${lang}`];
      }
    }
    return key;
  };

  const getEstagioPercentage = () => {
    if (userArtist?.stage === 10) return 100;
    return (
      (userArtist?.stagePercentual || 1) / 10 +
      ((userArtist?.stage || 1) - 1) * (100 / (10 - 1))
    );
  };

  function getCurrentDateTime() {
    const currentDate = new Date(userArtist?.updatedOn ?? 0);

    const days = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().slice(-2);

    return {
      days,
      hours,
      minutes,
      seconds,
      month,
      year,
    };
  }

  const { days, month, year } = getCurrentDateTime();

  const mensagemSemAdiantamento = () => {
    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotAvailable)
      return t("pages.Adiantamentos.naoCumpreRequisitosMensagemSimples");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NeedRetry)
      return t("pages.Adiantamentos.tentarNovamenteEmbreve");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotFound)
      return t("pages.Adiantamentos.spotifyNaoEncontrado");
  };

  const CardCTAADiantamento = () => {
    if (
      canAskAdvance(cashAdvanceAvailable) ||
      loggedUser?.isCashAdvanceAvailable
    )
      return (
        <div className="caixa-adiantamento padding">
          <div className="background" />
          <div className="container-linhas">
            <div className="linha linha-paragrafo">
              <p className="branco">
                <Trans
                  i18nKey="pages.Adiantamentos.adiante5Anos"
                  components={{ span: <span className="cor-destaque" /> }}
                />
              </p>
            </div>
            <div className="linha linha-paragrafo">
              <p className="valor">
                <b>
                  {signByCurrency(userArtist?.cashAdvanceEstimated?.currency)}
                </b>
                {formatNoSignObject({
                  ...userArtist?.cashAdvanceEstimated,
                  amount:
                    userArtist?.cashAdvanceEstimated?.amount /
                    userArtist?.cashAdvanceEstimated?.trancheFactor,
                })}
                {userArtist?.cashAdvanceEstimated?.trancheFactor > 1 && (
                  <>
                    {t("pages.Adiantamentos.ate")}
                    <b>
                      {signByCurrency(
                        userArtist?.cashAdvanceEstimated?.currency
                      )}
                    </b>
                    {formatNoSignObject(userArtist?.cashAdvanceEstimated)}
                  </>
                )}
                <span className="disclaimer-dot">*</span>
              </p>
            </div>
            <div className={`linha actions`}>
              <button
                className="botao-roxo botao-adiantamento-desktop"
                onClick={() => goRouteScrollTop("/adiantamentos")}
              >
                {t("pages.Adiantamentos.queroSolicitar")}
              </button>
              <button className="botao-link botao-tirar-duvidas">
                <a
                  target="_blank"
                  href={WhatsAppContactAdvance}
                  rel="noopener noreferrer"
                >
                  {t("pages.Adiantamentos.tirarDuvidas")}
                </a>
              </button>
            </div>

            <div className={`linha disclaimer`}>
              <p className="roxo">
                {t("pages.Adiantamentos.valoresSujeitoAlteracoes")}
              </p>
            </div>
          </div>
        </div>
      );

    return (
      <div className="caixa-adiantamento padding" style={{ maxWidth: "607px" }}>
        <div className="background" />
        <div className="container-linhas">
          <div className="linha">
            <p className="branco">
              {t("pages.EstagioArtista.adiantamentoNaoLiberadoTitulo")}
            </p>
          </div>
          <div className="linha">
            <p className="roxo">
              <Trans i18nKey={mensagemSemAdiantamento()} />
            </p>
          </div>
          <div className={`linha actions`}>
            <button
              className="botao-roxo"
              onClick={() => goRouteScrollTop("/adiantamentos")}
            >
              {t("pages.EstagioArtista.saibaMais")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const CardCTADistroMobile = () => (
    <div className="caixa-distro">
      <span className="branco">
        <DistroIcon /> {t("pages.EstagioArtista.distoLiberadoTitulo")}
      </span>
      <div className="botoes-icones">
        <div className="icones">
          <AmazonMusicIcon />
          <SpotifyIcon />
          <YoutubeIcon />
          <TidalIcon />
          <AppleMusicIcon />
          <DeezerIcon />
        </div>
      </div>
      <div className="content-text">
        <SpikeArrow
          style={{
            position: "absolute",
            opacity: 0.5,
            top: -116,
            right: -29,
            width: "100%",
          }}
        />
        <ul>
          <li className="circle-checkmark azul">
            {t("pages.EstagioArtista.distroAcessoAoLabels")}
          </li>
          <li className="circle-checkmark azul">
            {t("pages.EstagioArtista.distroIsencaoTaxaUpload")}
          </li>
          <li className="circle-checkmark azul">
            {t("pages.EstagioArtista.distrosMigracaoCatalogo")}
          </li>
        </ul>
        <div className="background" />
      </div>
      <div
        style={{
          margin: "24px 60px",
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        <button className="botao-roxo" onClick={onOkModalDistro}>
          {t("pages.EstagioArtista.distribuaComStrm")}
        </button>
        <button className="botao-borda-roxa">
          <a
            target="_blank"
            href={WhatsAppContactDistro}
            rel="noopener noreferrer"
          >
            {t("pages.EstagioArtista.saibaMais")}
          </a>
        </button>
      </div>
    </div>
  );

  const CardCTADistro = () => (
    <div className="caixa-distro" style={{ display: "flex" }}>
      <div
        style={{ display: "flex", flexDirection: "column", gap: 20, flex: 1 }}
      >
        <span className="branco">
          <DistroIcon /> {t("pages.EstagioArtista.distoLiberadoTitulo")}
        </span>
        <div className="content-text">
          <ul style={{ margin: 20 }}>
            <li className="circle-checkmark azul">
              {t("pages.EstagioArtista.distroAcessoAoLabels")}
            </li>
            <li className="circle-checkmark azul">
              {t("pages.EstagioArtista.distroIsencaoTaxaUpload")}
            </li>
            <li className="circle-checkmark azul">
              {t("pages.EstagioArtista.distrosMigracaoCatalogo")}
            </li>
          </ul>
          <div className="background" />
        </div>
        <div
          style={{
            display: "flex",
            gap: 12,
          }}
        >
          <button className="botao-roxo" onClick={onOkModalDistro}>
            {t("pages.EstagioArtista.distribuaComStrm")}
          </button>
          <button className="botao-borda-roxa">
            <a
              target="_blank"
              href={WhatsAppContactDistro}
              rel="noopener noreferrer"
            >
              {t("pages.EstagioArtista.saibaMais")}
            </a>
          </button>
        </div>
      </div>
      <div style={{ flex: 1.2 }}>
        <div
          className="botoes-icones"
          style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
        >
          <SpikeArrow style={{ position: "absolute", opacity: 0.5 }} />
          <div className="icones web">
            <AmazonMusicIcon />
            <SpotifyIcon />
            <YoutubeIcon />
            <TidalIcon />
            <AppleMusicIcon />
            <DeezerIcon />
          </div>
        </div>
      </div>
    </div>
  );

  const onOkModalDistro = async () => {
    history.push("/distribua");
  };

  return (
    <div className="inicio">
      <div className="intro">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="dados-artista">
            <Avatar src={userArtist?.profileImage} size={isMobile ? 85 : 180} />
            <div className="informacoes">
              <h1>{userArtist?.name}</h1>
              <span className="pais">
                {getCountryByKey(userArtist?.country)}
              </span>
              <span className="genero">{t(`pages.EstagioArtista.genero`)}</span>
              <GenreTagList genreList={userArtist.genresStrm} />
            </div>
          </div>
          {!isMobile && <CardCTAADiantamento />}
        </div>
        <div className="estagio-inicio">
          <span className="label">
            {t(`pages.EstagioArtista.estagioArtista`)}
          </span>
          <div className="box-estagio">
            <div>
              <div className="numero-titulo">
                <span className="numero">{userArtist?.stage}</span>
                <span className="titulo">{titulo}</span>
              </div>
            </div>
          </div>
          <div style={{ margin: "32px 0" }}>
            <Slider
              marks={Array.from(
                { length: 10 },
                (_, i) => i * (100 / (10 - 1))
              ).reduce((acc, cur) => ({ ...acc, [cur]: " " }), {})}
              value={getEstagioPercentage()}
              handleRender={() => (
                <>
                  <div
                    className="custom-handle rc-slider-handle"
                    style={{
                      left: `${getEstagioPercentage()}%`,
                      color: "black",
                      transform: "translateX(-50%)",
                    }}
                  >
                    {userArtist?.stage}
                    {(userArtist?.stage || 1) < 10 && (
                      <span className="porcentagem">{`${userArtist?.stagePercentual?.toFixed(
                        0
                      )}%`}</span>
                    )}
                  </div>
                </>
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: "1 1" }}>
              <p className="strong-roxo explicacao">
                <Trans i18nKey={explicacao} />
              </p>
              <div style={{ display: "flex", gap: "1rem" }}>
                {!isMobile && (
                  <>
                    <Link to="/metas">
                      <button className="botao-branco">
                        {t("pages.Metas.btnMetasProximoEstagio")}
                      </button>
                    </Link>
                    <button
                      className="botao-borda-roxa"
                      style={{ alignSelf: "center" }}
                      onClick={() => goRouteScrollTop("/diagnosticos")}
                    >
                      <EstrelaIcon style={{ marginBottom: 4 }} />
                      {t("pages.EstagioArtista.facaDiagnosticoCompleto")}
                    </button>
                  </>
                )}
              </div>
            </div>
            {isMobile && (
              <div>
                <Link to="/metas">
                  <button className="botao-branco">
                    {t("pages.Metas.btnMetasProximoEstagio")}
                  </button>
                </Link>
              </div>
            )}
            <div className="atualizacao">
              <div className="content">
                <span>{t("pages.EstagioArtista.dadosAtualizados")}</span>

                <div className="proxima-atualizacao">
                  <div>
                    <div className="caixa">{days}</div>
                    <div className="label">{t("pages.EstagioArtista.dia")}</div>
                  </div>
                  <div>
                    <div className="caixa" style={{ width: 10 }}>
                      /
                    </div>
                    <div className="label"></div>
                  </div>
                  <div>
                    <div className="caixa">{month}</div>
                    <div className="label">{t("pages.EstagioArtista.mes")}</div>
                  </div>
                  <div>
                    <div className="caixa" style={{ width: 10 }}>
                      /
                    </div>
                    <div className="label"></div>
                  </div>
                  <div>
                    <div className="caixa">{year}</div>
                    <div className="label">{t("pages.EstagioArtista.ano")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && <span className="divider" />}

        {isMobile && <CardCTAADiantamento />}

        <div className="estagio-atual">
          <span className="label">
            {t("pages.EstagioArtista.estagioAtual")}
          </span>
          <div className="list">
            {posicao?.map((item: any, index: number) => (
              <div key={`${index}_ATUAL`} className="item">
                <IconCheck />
                <span style={{ display: "flex", flex: 1 }}>{item}</span>
              </div>
            ))}
          </div>
          {isMobile && (
            <button
              className="botao-roxo"
              style={{ alignSelf: "center" }}
              onClick={() => goRouteScrollTop("/diagnosticos")}
            >
              <EstrelaIcon style={{ marginBottom: 4 }} />
              {t("pages.EstagioArtista.facaDiagnosticoCompleto")}
            </button>
          )}
        </div>
        {!isMobile && <CardCTAHenriqueJuliano />}
        {!isMobile && permiteAssinarDistroStrm() && <CardCTADistro />}
      </div>
      <div className="principal-objetivo">
        <div className="background">
          <div className="conteudo">
            <h1 style={{ lineHeight: 1 }}>
              {t("pages.EstagioArtista.principalObjetivo")}
            </h1>
            <p>
              <Trans i18nKey={principalObjetivo} />
            </p>
            <button
              className="botao-branco"
              onClick={() => {
                aprofundeDiagnostico.current?.scrollIntoView({
                  behavior: "smooth",
                });
                if (isMobile)
                  setTimeout(() => {
                    window.scrollBy({ top: -50, left: 0, behavior: "smooth" });
                  }, 600);
              }}
            >
              {t("pages.EstagioArtista.saibaMais")}
            </button>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="aprofunde-diagnostico">
          <CardCTAHenriqueJuliano mobile />
        </div>
      )}
      {isMobile && permiteAssinarDistroStrm() && (
        <div className="aprofunde-diagnostico">
          <CardCTADistroMobile />
        </div>
      )}
      <div className="aprofunde-diagnostico" ref={aprofundeDiagnostico}>
        <h1 className="title-aprofunde-diagnostico">
          {t("pages.EstagioArtista.aprofundeDiagnostico")}
        </h1>
        <div className="cards">
          <CardMinhaJornada />
          <CardPlanoEstrategico />
          <CardDistro />
          <CardAdiantamento />
          <CardDiagnosticoCompleto />
        </div>
        {isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 32,
            }}
          >
            <ButtonRemakeQuiz />
          </div>
        )}
      </div>
    </div>
  );
};

export default EstagioArtistsInicio;
