import { InstagramOutlined } from "@ant-design/icons";
import "./Button.scss";
import { serialize } from "shared/helpers/serialization";
const { REACT_APP_INSTAGRAM_CLIENT_ID, REACT_APP_APPLICATION_URL } =
  process.env;
const Instagram: React.FC<any> = (props) => {
  const { classSelected, state } = props;
  const signInAlt = () => {
    window.location.href = `https://api.instagram.com/oauth/authorize/?client_id=${REACT_APP_INSTAGRAM_CLIENT_ID}&redirect_uri=${REACT_APP_APPLICATION_URL}/quiz&response_type=code&scope=user_profile&state=${serialize(
      state
    )}`;
    //window.location.href = `https://12323teststrm.auth.us-east-1.amazoncognito.com/login?client_id=5euhf7fuk6lfdr21rr9msl6rja&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://explorer-staging.strmmusic.com`
  };

  return (
    <div className={`social-logo ${classSelected}`} onClick={signInAlt}>
      <InstagramOutlined className="teste" />
    </div>
  );
};

export default Instagram;
