import { Col, Form, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";

interface AboutArtistStepProps {
  isVisible: boolean;
}
const AboutArtistStep: React.FC<AboutArtistStepProps> = ({
  isVisible,
}: any) => {
  const { t } = useTranslation();

  return (
    <Row justify="center" gutter={[24, 0]}>
      <Col span={24} className="header">
        <p className="title required-icon">
          {t("pages.ReleasesSchedule.steps.aboutArtist.title")}
        </p>
        <p className="subtitle">
          {t("pages.ReleasesSchedule.steps.aboutArtist.subtitle")}
        </p>
      </Col>
      <Col span={24}>
        <Form.Item
          name="aboutCreation"
          rules={[
            {
              required: isVisible,
              message: t(
                "pages.ReleasesSchedule.steps.aboutArtist.campoRequired"
              ),
            },
          ]}
        >
          <TextArea
            maxLength={4000}
            rows={7}
            placeholder={t("pages.ReleasesSchedule.steps.aboutArtist.resposta")}
          ></TextArea>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AboutArtistStep;
