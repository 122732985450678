import {
  confirmSignUp,
  resendSignUpCode,
  autoSignIn,
  fetchAuthSession,
  ResendSignUpCodeInput,
} from "@aws-amplify/auth";
import { Button, Form, Input, Typography } from "antd";
import { ReactComponent as Logo } from "shared/assets/images/logo-strm.svg";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { useDispatch } from "react-redux";
import { cadastroRealizado, loginReduced } from "explorer/reducers/auth";
import { LanguageChange } from "shared/components";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import LoadingButton from "shared/components/button/LoadingButton";
import { useEffect, useState } from "react";
import { UserEvent, saveUserEvent } from "event-tracker/track-user-helpers";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";
import { DISTRO_PARAM, TOKEN } from "shared/constants/StorageConsts";
import { getLoggedUser, setLoggedUserLanguage } from "explorer/reducers/users";
import { getLoggedUserArtist } from "explorer/reducers/artistas";
import { getCountries } from "explorer/reducers/countries";
import { hasQuizPending } from "shared/helpers/auth-functions";

const VerificacaoLogin: React.FC<RouteComponentProps> = (props) => {
  const { history, location } = props;
  const [form] = Form.useForm();
  const usuario: any = location.state;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async () => {
    saveUserEvent({ name: UserEvent.VERIFICATION_SEND });
    dataLayerEvent("ClickButton", {
      ...quizEvents.S4A_SIGN_UP_VERIFICATION,
      buttonName: "VERIFY_CODE",
    });

    setLoading(true);
    try {
      const values = await form.validateFields();
      const { isSignUpComplete } = await confirmSignUp({
        username: usuario.login,
        confirmationCode: values.codigo,
      });

      if (isSignUpComplete) {
        let autoSignedInSuccessed = false;
        try {
          const { isSignedIn } = await autoSignIn();
          autoSignedInSuccessed = isSignedIn;
        } catch (error: any) {
          toast.info(t("pages.VerificacaoLogin.autoLoginFalho"));
          dispatch(cadastroRealizado());
          history.push("/login");
        }
        if (autoSignedInSuccessed) {
          dispatch(cadastroRealizado());
          const { idToken } = (await fetchAuthSession()).tokens ?? {};

          localStorage.setItem(TOKEN, idToken?.toString() ?? "");
          dispatch(setLoggedUserLanguage(i18n.language));
          dispatch(getLoggedUser());
          dispatch(getLoggedUserArtist());
          dispatch(loginReduced());
          dispatch(getCountries());
          const distroFlow = sessionStorage.getItem(DISTRO_PARAM) === "true";
          if (hasQuizPending()) return history.push("/quiz");
          const returnURL = sessionStorage.getItem("returnURL");
          sessionStorage.removeItem("returnURL");
          if (distroFlow) {
            history.push("/distribua");
          } else if (returnURL) history.push(returnURL);
          else history.push("/", { login: true });
        }
      } else {
        toast.info(t("pages.VerificacaoLogin.autoLoginFalho"));
        dispatch(cadastroRealizado());
        history.push("/login");
      }
    } catch (error: any) {
      saveUserEvent({ name: UserEvent.VERIFICATION_ERROR });
      dataLayerEvent("ErrorDialog", {
        ...quizEvents.S4A_SIGN_UP_VERIFICATION,
        errorName: "INVALID_CODE",
      });
      if (error.code === "CodeMismatchException")
        toast.error(t("pages.VerificacaoLogin.codigoInvalido"), {
          autoClose: 10000,
        });
      else toast.error(error.message, { autoClose: 10000 });
    }
    setLoading(false);
  };

  const onClickReenvia = async () => {
    saveUserEvent({ name: UserEvent.VERIFICATION_RESEND });
    dataLayerEvent("ClickButton", {
      ...quizEvents.S4A_SIGN_UP_VERIFICATION,
      buttonName: "RESEND_CODE",
    });
    try {
      const param: ResendSignUpCodeInput = {
        username: usuario.login,
      };
      await resendSignUpCode(param);
    } catch (error: any) {
      console.log(error);
    }
    toast.info(t("pages.VerificacaoLogin.mensagemReenvio"), {
      autoClose: 10000,
    });
  };

  useEffect(() => {
    saveUserEvent({ name: UserEvent.VERIFICATION_OPEN });
    dataLayerEvent("ScreenViewed", {
      ...quizEvents.S4A_SIGN_UP_VERIFICATION,
    });
  }, []);

  return (
    <div
      className="conteudo-centralizado login"
      style={{
        height: "100vh",
        backgroundColor: "#1a191a",
        padding: "14px 26px",
      }}
    >
      <div className="direction">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="logo-region">
            <Logo className="logo" style={{ width: isMobile ? 80 : 100 }} />
            <span style={{ fontSize: isMobile ? 14 : 28 }}>for artists</span>
          </div>
          <LanguageChange.SelectLanguageChange />
        </div>
        <Form form={form} onFinish={onFinish}>
          <div className="content" style={{ marginTop: 154 }}>
            <div className="text">
              <Typography.Title style={{ color: "#F2F2F2", fontSize: 20 }}>
                {t("pages.VerificacaoLogin.titulo")}
              </Typography.Title>
              <Typography.Text style={{ color: "#FFFFFF" }}>
                {t("pages.VerificacaoLogin.verificacaoMensagem")}
              </Typography.Text>
            </div>
            <div className="input">
              <Form.Item
                name="codigo"
                rules={[
                  {
                    required: true,
                    message: t("pages.VerificacaoLogin.codigoObrigatorio"),
                  },
                ]}
              >
                <Input placeholder={t("pages.VerificacaoLogin.codigo")} />
              </Form.Item>
            </div>

            <div className="enter-button">
              <LoadingButton
                className="botao-roxo"
                style={{ marginBottom: "16px" }}
                loading={loading}
              >
                {t("pages.VerificacaoLogin.submit")}
              </LoadingButton>
            </div>
            <div className="esqueci-senha">
              <Button
                type="link"
                htmlType="button"
                onClick={onClickReenvia}
                className="button-reset-senha"
                style={{ margin: 0 }}
              >
                {t("pages.VerificacaoLogin.reenvioCodigo")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default VerificacaoLogin;
