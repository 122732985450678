import { Trans, useTranslation } from "react-i18next";
import { EstagioArtistaProps } from "./NewEstagioArtista";
import { ReactComponent as PlanoEstrategicoGrafico } from "shared/assets/images/plano-grafico.svg";
import { ReactComponent as MoneyBagIcon } from "shared/assets/images/icon-money-bag.svg";
import { ReactComponent as ContractIcon } from "shared/assets/images/icon-contract.svg";
import { ReactComponent as PinkArrow } from "shared/assets/images/pink-arrow.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import CardDiagnosticoCompleto from "./cards/CardDiagnosticoCompleto";
import CardMinhaCarreira from "./cards/CardMinhaCarreira";
import { formatNoSignObject, signByCurrency } from "shared/helpers/BRLFormat";
import { Link, useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import CardAdiantamento from "./cards/CardAdiantamento";
import CardDistro from "./cards/CardDistro";
import { useSelector } from "react-redux";
import { CashAdvanceAvailableEnum } from "shared/enums/CashAdvanceAvailable";
import { WhatsAppContactAdvance } from "shared/helpers/whatsapp-contact";

const EstagioArtistaPlanoEstrategico: React.FC<EstagioArtistaProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loggedUser } = useSelector((state: any) => state.users);
  const { userArtist } = useSelector((state: any) => state.artists);
  const { cashAdvanceAvailable } = useSelector((state: any) => state.advances);
  let {
    planoEstrategicoIntroInicial,
    planoEstrategicoIntroContent,
    desafiosCruciaisTexto,
    desafiosCruciais,
    investimentoPassos,
    investimentoTitulo,
    investimentoTexto,
    cuidadosInvestimentos,
    cuidadosInvestimentoTitulo,
    cuidadosInvestimentoTexto,
  } = t(`pages.EstagioArtista.estagio.${userArtist?.stage || 0}`) as any;

  const goRouteScrollTop = (route: string) => {
    history.push(route);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const mensagemSemAdiantamento = () => {
    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotAvailable)
      return t("pages.Adiantamentos.naoCumpreRequisitosMensagemSimples");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NeedRetry)
      return t("pages.Adiantamentos.tentarNovamenteEmbreve");

    if (cashAdvanceAvailable === CashAdvanceAvailableEnum.NotFound)
      return t("pages.Adiantamentos.spotifyNaoEncontrado");
  };

  return (
    <div className="plano-estrategico">
      <div
        style={{
          background:
            "linear-gradient(180deg, #0D0D0D 0%, #5779FF 100%, #5A57FF 100%)",
          padding: isMobile ? 32 : 104,
        }}
      >
        <span className="page-header padding-default">
          {t("pages.EstagioArtista.visaoGeral")}
        </span>
        <div className="title-exportar padding-default">
          <h1> {t("pages.EstagioArtista.planoEstrategico")} </h1>
          {/* <span className="exportar">
            <IconDownload />
          </span> */}
        </div>
        <span className="divider padding-default" />
        <div className="intro padding-default">
          <div
            style={{
              display: "flex",
              justifyContent: isMobile ? "flex-start" : "flex-end",
            }}
          >
            <PlanoEstrategicoGrafico
              style={{
                height: isMobile ? 47 : 120,
                width: isMobile ? 112 : 280,
              }}
            />
          </div>
          <div>
            <p className="inicial">
              <Trans i18nKey={planoEstrategicoIntroInicial} />
            </p>
            <p style={{ fontSize: 16 }}>
              <Trans i18nKey={planoEstrategicoIntroContent} />
            </p>
            <Link to="/metas">
              <button
                className="botao-branco"
                style={{ marginTop: "2rem", marginBottom: 64 }}
              >
                {t("pages.Metas.btnMetasProximoEstagio")}
              </button>
            </Link>
          </div>
        </div>
        <span className="divider padding-default" />
        <div className="desafios-cruciais padding-default">
          <h3>{t("pages.EstagioArtista.desafiosCruciaisLabel")} </h3>
          <p
            style={{
              marginBottom: 64,
              fontSize: 16,
              maxWidth: isMobile ? "" : "50%",
            }}
          >
            {desafiosCruciaisTexto}
          </p>

          {desafiosCruciais?.map((desafio: string, index: number) => (
            <div className="card-desafio" key={`CARD_DESAFIO_${index}`}>
              <div className="header">
                <span className="numero">{index + 1}</span>
                {t(`pages.EstagioArtista.desafiosCruciais.${desafio}.title`)}
              </div>
              <div className="body">
                {t(`pages.EstagioArtista.desafiosCruciais.${desafio}.text`)}
              </div>
            </div>
          ))}
        </div>
        <div className="diagnostico-cta padding-default">
          <PinkArrow className="svg-bg" />
          <div className="content">
            <h1>{t(`pages.EstagioArtista.saibaMaisDiagnostico`)}</h1>
            <p>{t(`pages.EstagioArtista.saibaMaisDiagnosticoCTA`)}</p>
            <div className="botoes">
              <button
                className="botao-branco"
                onClick={() => goRouteScrollTop("/checkout-diagnostico")}
              >
                {t(`pages.EstagioArtista.comprar`)}
              </button>
              <button
                className="botao-borda-roxa"
                onClick={() => goRouteScrollTop("/diagnosticos")}
              >
                {t(`pages.EstagioArtista.saibaMais`)}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: isMobile ? 32 : 104 }}>
        <div className="investimento padding-default">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <div className="start">
                <div className="content">
                  <span style={{ textTransform: "uppercase" }}>
                    {t(`pages.EstagioArtista.investimento`)}
                  </span>
                  <h1>{investimentoTitulo}</h1>
                  <p>{investimentoTexto}</p>
                </div>
                {isMobile && (
                  <div className="icone-box">
                    <div className="icone">
                      <MoneyBagIcon />
                    </div>
                  </div>
                )}
              </div>
              {investimentoPassos?.map((passo: string, index: number) => (
                <div
                  className="card-passo"
                  key={`CARD_PASSO_INVESTIMENTO_${index}`}
                >
                  <div className="titulo">
                    <span className="circulo" />
                    <h3>
                      {t(
                        `pages.EstagioArtista.passosInvestimento.${passo}.title`
                      )}
                    </h3>
                  </div>
                  <p>
                    <Trans
                      i18nKey={t(
                        `pages.EstagioArtista.passosInvestimento.${passo}.text`
                      )}
                    />
                  </p>
                </div>
              ))}
            </div>
            {!isMobile && (
              <div className="icone-box">
                <div className="icone">
                  <MoneyBagIcon />
                </div>
              </div>
            )}
          </div>
          {cuidadosInvestimentos && (
            <>
              <span className="divider" />
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <div className="start">
                    <div className="content">
                      <span style={{ textTransform: "uppercase" }}>
                        {t("pages.EstagioArtista.cuidados")}
                      </span>
                      <h1>{cuidadosInvestimentoTitulo}</h1>
                      <p>{cuidadosInvestimentoTexto}</p>
                    </div>
                    {isMobile && (
                      <div className="icone-box">
                        <div className="icone">
                          <ContractIcon />
                        </div>
                      </div>
                    )}
                  </div>
                  {cuidadosInvestimentos?.map(
                    (cuidado: string, index: number) => (
                      <div
                        className="card-passo"
                        key={`CARD_CUIDADOS_${index}`}
                      >
                        <div className="titulo">
                          <span className="circulo" />
                          <h3>
                            {t(
                              `pages.EstagioArtista.cuidadosInvestimento.${cuidado}.title`
                            )}
                          </h3>
                        </div>
                        <p>
                          <Trans
                            i18nKey={t(
                              `pages.EstagioArtista.cuidadosInvestimento.${cuidado}.text`
                            )}
                          />
                        </p>
                      </div>
                    )
                  )}
                </div>
                {!isMobile && (
                  <div className="icone-box">
                    <div className="icone">
                      <ContractIcon />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="padding-default" style={{ marginBottom: 32 }}>
          <div className="caixa-adiantamento">
            <div className="content">
              {loggedUser?.isCashAdvanceAvailable ||
              cashAdvanceAvailable === CashAdvanceAvailableEnum.Available ||
              cashAdvanceAvailable ===
                CashAdvanceAvailableEnum.GettingReleasesMetadata ||
              (cashAdvanceAvailable !== CashAdvanceAvailableEnum.NotAvailable &&
                cashAdvanceAvailable !==
                  CashAdvanceAvailableEnum.WaitingListAvailable) ? (
                <>
                  <div className="background" />
                  <p className="branco linha-paragrafo">
                    <Trans
                      i18nKey="pages.Adiantamentos.adiante5Anos"
                      components={{ span: <span className="destaque" /> }}
                    />
                  </p>
                  <div className="container-blocos">
                    <div className="bloco">
                      <div className="linha linha-paragrafo">
                        <p className="valor">
                          <b>
                            {signByCurrency(
                              userArtist?.cashAdvanceEstimated?.currency
                            )}
                          </b>
                          {formatNoSignObject({
                            ...userArtist?.cashAdvanceEstimated,
                            amount:
                              userArtist?.cashAdvanceEstimated?.amount /
                              userArtist?.cashAdvanceEstimated?.trancheFactor,
                          })}
                          {userArtist?.cashAdvanceEstimated?.trancheFactor >
                            1 && (
                            <>
                              {t("pages.Adiantamentos.ate")}
                              <b>
                                {signByCurrency(
                                  userArtist?.cashAdvanceEstimated?.currency
                                )}
                              </b>
                              {formatNoSignObject(
                                userArtist?.cashAdvanceEstimated
                              )}
                            </>
                          )}
                          <span className="disclaimer-dot">*</span>
                        </p>
                      </div>
                      <div className={`linha actions`}>
                        <button
                          className="botao-branco"
                          onClick={() => goRouteScrollTop("/adiantamentos")}
                        >
                          {t("pages.Adiantamentos.queroSolicitar")}
                        </button>
                        <button className="botao-link botao-tirar-duvidas">
                          <a
                            target="_blank"
                            href={WhatsAppContactAdvance}
                            rel="noopener noreferrer"
                          >
                            {t("pages.Adiantamentos.tirarDuvidas")}
                          </a>
                        </button>
                      </div>
                      <div className={`linha disclaimer`}>
                        <p>
                          {t("pages.Adiantamentos.valoresSujeitoAlteracoes")}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="background" />
                  <span className="branco" style={{ lineHeight: 1 }}>
                    {t("pages.EstagioArtista.adiantamentoNaoLiberadoTitulo")}
                  </span>
                  <p className="roxo">
                    <Trans i18nKey={mensagemSemAdiantamento()} />
                  </p>
                  <button
                    className="botao-branco"
                    onClick={() => history.push("/adiantamentos")}
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    {t("pages.EstagioArtista.saibaMais")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="aprofunde-diagnostico">
        <div style={{ marginBottom: 32 }}>
          <span>
            {t("pages.EstagioArtista.continueLendo")}{" "}
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </div>
        <div className="cards">
          <CardMinhaCarreira />
          <CardDistro />
          <CardAdiantamento />
          <CardDiagnosticoCompleto />
        </div>
      </div>
    </div>
  );
};

export default EstagioArtistaPlanoEstrategico;
