import { Layout } from "antd";
import MenuLateral from "shared/components/menu-lateral";
import "./Layout.scss";

const { Content } = Layout;
const LayoutAutenticado: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout className="layout-customizado">
      <Layout>
        <MenuLateral />
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutAutenticado;
