import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getArtistUserLogged } from "shared/service/StrmService";

export const getLoggedUserArtist = createAsyncThunk(
  "users/GetUserArtist",
  async () => {
    const loggedUserArtist: any = await getArtistUserLogged();
    return loggedUserArtist;
  }
);

export const artistasSlice = createSlice({
  name: "artists",
  initialState: {
    loading: true,
    userArtist: {},
  },
  reducers: {},
  extraReducers: {
    [getLoggedUserArtist.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.userArtist = action.payload;
      }
      state.loading = false;
    },
    [getLoggedUserArtist.pending.type]: (state) => {
      state.loading = true;
    },
  },
});

export default artistasSlice.reducer;
