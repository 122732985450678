import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { TOKEN } from "shared/constants/StorageConsts";
import { MilestoneRatioPeriod } from "shared/enums/MilestoneRatioPeriod";
import { logout } from "shared/helpers/auth-functions";
import snakeToCamelCase from "shared/helpers/snakeToCamelCase";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_RADAR_ENDPOINT,
  timeout: 180000,
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers["Authorization"] = `Bearer ${localStorage.getItem(TOKEN)}`;
  return config;
});

instance.interceptors.response.use(
  (response: AxiosResponse<any>) => snakeToCamelCase(response.data, true),
  (error: any) => {
    switch (error.response.status) {
      case 401:
      case 403:
        logout();
    }
  }
);

export const fetchSuportedDistros = () =>
  instance.get("filter/supported-distros");

export const fetchArtistsByGenre = (genre: string) =>
  instance.get(`Artist/artist-quantities-by-stage/${genre}`);

export const fetchArtistMilestones = (
  artistId: string,
  milestoneRatioPeriod: MilestoneRatioPeriod
) =>
  instance.get(
    `ArtistMilestone/milestones/${artistId}/${milestoneRatioPeriod}`
  );
