import { Suspense, useEffect } from "react";
import "./App.scss";
import "translation";
import { BrowserRouter } from "react-router-dom";
import Routes from "Routes";
import { Amplify } from "aws-amplify";
import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import { poolData } from "shared/components/user-pool/AwsConfig";
import { useDispatch } from "react-redux";
import { getLoggedUser } from "explorer/reducers/users";
import { autenticado } from "shared/helpers/auth-functions";
import { loadUserId } from "shared/helpers/browser-id";
import NewUserNotification from "shared/components/new-user-notification/NewUserNotification";
import { getCountries } from "explorer/reducers/countries";
import GoogleTag from "shared/components/google-tag";
import Personify from "shared/components/personify/Personify";
import { ConfigProvider } from "antd";
import { theme } from "shared/layout/theme";
import { ToastContainer } from "react-toastify";
import ptBR from "antd/locale/pt_BR";
import { getLoggedUserArtist } from "explorer/reducers/artistas";

Amplify.configure(poolData);
loadUserId();
const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_SAMPLE_RATE,
  REACT_APP_SENTRY_ENVIORNMENT,
} = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    // new Integrations.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  // replaysSessionSampleRate: Number(REACT_APP_SENTRY_SAMPLE_RATE || 1.0),
  // replaysOnErrorSampleRate: Number(REACT_APP_SENTRY_SAMPLE_RATE || 1.0),
  environment: REACT_APP_SENTRY_ENVIORNMENT,
  tracesSampleRate: Number(REACT_APP_SENTRY_SAMPLE_RATE || 1.0),
});

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (autenticado()) {
      dispatch(getLoggedUser());
      dispatch(getLoggedUserArtist());
    }
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <Suspense fallback="loading">
      <ConfigProvider theme={theme} locale={ptBR}>
        <BrowserRouter>
          <ToastContainer theme="dark" style={{ zIndex: 9999999 }} />
          <Personify />
          <GoogleTag />
          <NewUserNotification />
          <Routes />
        </BrowserRouter>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
