import { useTranslation } from "react-i18next";
import { ReactComponent as Wallet } from "shared/assets/images/icon-wallet.svg";

const MeuAdiantamentoHeader: React.FC<any> = (props) => {
  const { setShowMeuAdiantamento, showMeuAdiantamento } = props;

  const { t } = useTranslation();
  return (
    <div
      className="header clicavel"
      onClick={() => setShowMeuAdiantamento(!showMeuAdiantamento)}
    >
      <Wallet className="svg-stroke-roxo" />
      <span> {t("pages.Adiantamentos.meuAdiantamento")}</span>
    </div>
  );
};

export default MeuAdiantamentoHeader;
