export enum UserTypeEnum {
  Normal = 0,
  AdminStrm = 1,
  CreditTeam = 2,
}

export const userTypeList = [
  { label: "Normal", value: UserTypeEnum.Normal },
  { label: "Admin Strm", value: UserTypeEnum.AdminStrm },
  { label: "Time de crédito", value: UserTypeEnum.CreditTeam },
];
