import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginRealizado: false,
    novoUsuario: false,
    notificado: false,
  },
  reducers: {
    loginReduced: (state) => {
      state.loginRealizado = true;
    },

    cadastroRealizado: (state) => {
      state.novoUsuario = true;
    },

    notificado: (state) => {
      state.notificado = true;
    },
  },
});

export const { loginReduced, cadastroRealizado } = authSlice.actions;

export default authSlice.reducer;
