import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as DistroIcon } from "shared/assets/images/icon-distro.svg";

const CardDistro: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { userArtist } = useSelector((state: any) => state.artists);
  const onOkModalDistro = async () => {
    history.push("/distribua");
  };

  if (!userArtist?.distributionAvailable || userArtist?.distributionSigned)
    return null;

  return (
    <div className="card-outra-sessao">
      <DistroIcon className="icon amarelo" />
      <span className="titulo">
        {t("pages.EstagioArtista.distoLiberadoTituloSimples")}
      </span>
      <span className="divider" />
      <h1>{t("pages.EstagioArtista.distoLiberadoTitulo")}</h1>
      <p className="strong-escuro">
        <Trans i18nKey={t("pages.EstagioArtista.distribuicaoTexto")} />
      </p>
      <button className="botao-roxo" onClick={onOkModalDistro}>
        {t("pages.EstagioArtista.distribuaComStrm")}
      </button>
    </div>
  );
};

export default CardDistro;
