import { useSelector } from "react-redux";
import { logout } from "shared/helpers/auth-functions";
import { depersonifyUser } from "shared/service/StrmService";

const Personify: React.FC<any> = (props) => {
  const { loggedUser } = useSelector((state: any) => state.users);
  const ondepersonifyClick = async () => {
    const response = await depersonifyUser();
    if (response) logout();
  };
  if (loggedUser?.emailPersonified)
    return (
      <div>
        {`Personificando ${loggedUser?.emailPersonified} `}
        <button onClick={ondepersonifyClick}>Despersonificar</button>
      </div>
    );
  return null;
};

export default Personify;
