import { Checkbox, Divider, Form, Input, Select } from "antd";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MaskInput } from "shared/components";
import { filterOptionDiacritics } from "shared/components/single-multiselect/SingleMultiselect";
import { pixKeyTypeList } from "shared/enums/PixKeyTypeEnum";
import { formCNPJValidator } from "shared/helpers/cnpj-validator";
import { formCPFValidator } from "shared/helpers/cpf-validator";
import useFetchData from "shared/hooks/useFetchData";
import { ReactComponent as Logo } from "shared/assets/images/contrato-icon.svg";
import {
  fecthBankList,
  getAddressByCep,
  getDistributionContractSpecialCampaign,
  postSignDistributionContract,
  postSignDistributionSpecialCampaignContract,
} from "shared/service/StrmService";
import "./Distribua.scss";
import "../adiantamentos/Adiantamentos.scss";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SpecialCampaignEnum } from "shared/enums/SpecialCampaignEnum";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";

enum TipoPessoa {
  Fisica = 0,
  Juridica = 1,
}

enum StepPage {
  Form = 0,
  Terms = 1,
  Contract = 2,
}

const Distribua: React.FC<any> = (props) => {
  const { location } = props;
  const state = location.state;
  const { specialCampaign } = state || {};
  const campanhaEspecialHenriqueJuliano =
    specialCampaign === SpecialCampaignEnum.HenriqueEJuliano;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [termsOfUseHtml, setTermsOfUseHtml] = useState<string | null>(null);
  const [step, setStep] = useState<StepPage>(StepPage.Form);
  const [tipoPessoa, setTipoPessoa] = useState<TipoPessoa>(TipoPessoa.Fisica);
  const [contratoParaAssinar, setContratoParaAssinar] = useState<string>("");
  const [checkBoxCheckeds, setCheckBoxCheckeds] = useState<any>({
    readAndUnderstood: false,
    acceptTerms: false,
  });
  const history = useHistory();
  const { loggedUser } = useSelector((state: any) => state.users);
  const { userArtist } = useSelector((state: any) => state.artists);

  const [bankOptions, bankLoading] = useFetchData(
    fecthBankList,
    [],
    useCallback(
      (data) =>
        data.map((item: any) => ({
          ...item,
          label: `${item?.value} - ${item?.label}`,
        })),
      []
    )
  );

  const getContratoAtual = useCallback(async () => {
    const result: any = await getDistributionContractSpecialCampaign();

    if (result?.response) {
      setContratoParaAssinar(result?.response.signUrl);
      setStep(StepPage.Contract);
    }
  }, []);

  const onClickAssinar = async () => {
    if (contratoParaAssinar) {
      const newWindow = window.open(
        contratoParaAssinar,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    } else {
      toast.error(t("pages.Adiantamentos.erroAssinarContrato"));
    }
  };

  const onClickHome = () => {
    history.push("/");
  };

  const onFinish = async () => {
    if (campanhaEspecialHenriqueJuliano) {
      const values = form.getFieldsValue(true);

      const request = {
        ...values,
        specialCampaign,
      };

      try {
        await postSignDistributionSpecialCampaignContract(request);
        await getContratoAtual();
        setStep(StepPage.Contract);
      } catch {
        toast.error(t("pages.Distribua.erroCriacaoContrato"));
      }
    } else {
      setStep(StepPage.Terms);
      fetch(
        tipoPessoa === TipoPessoa.Fisica
          ? "simplificado_adesao_pf_template_strm distro.html"
          : "simplificado_adesao_pj_template_strm distro.html"
      )
        .then((response) => response.text())
        .then((html) => {
          setTermsOfUseHtml(html);
        })
        .catch((error) => {
          console.error("There was an error loading the terms of use:", error);
        });
    }
  };

  const onClickAceitar = async () => {
    dataLayerEvent("ClickButton", {
      ...quizEvents.S4A_DISTRO_SIGN,
      buttonName: "SIGN_CONTRACT",
      approvedLimit: userArtist?.cashAdvanceEstimated?.amountUsd,
      alreadyCalculated:
        userArtist?.cashAdvanceEstimated?.successfullyEstimated,
      carreerStage: userArtist.stage,
    });
    const values = form.getFieldsValue(true);
    const request = {
      userArtistId: userArtist.id,
      ...values,
    };
    const response = await postSignDistributionContract(request);
    if (response) {
      toast.success(t("pages.Distribua.mensagemSucesso"));
      history.push("/estagio?distro=assinado");
    }
  };

  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    const { name = "", checked } = event.target;
    setCheckBoxCheckeds((prevState: any) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    getContratoAtual();
  }, [getContratoAtual]);

  if (step === StepPage.Contract) {
    return (
      <div className={`adiantamentos ${!isMobile && "web"}`}>
        <div
          className="flex-column-between"
          style={{
            minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
            paddingLeft: isMobile ? 0 : 94,
          }}
        >
          <div
            className="migracao"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="confirma-adiantamento-intro">
              <Logo />
              <h1>{t("pages.Adiantamentos.contratoEnviadoTitulo")}</h1>
              <p style={{ maxWidth: isMobile ? "unset" : 650 }}>
                <Trans
                  i18nKey={t("pages.Adiantamentos.contratoEnviadoTexto")}
                />
              </p>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
                gap: 12,
              }}
            >
              <button className="botao-azul" onClick={onClickAssinar}>
                {t("pages.Adiantamentos.assinar")}
              </button>
              <button className="botao-borda-roxa" onClick={onClickHome}>
                {t("pages.Distribua.assinadoVoltar")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (step === StepPage.Terms)
    return (
      <div className={`adiantamentos ${!isMobile && "web"}`}>
        <div
          className="contrato-assinatura distribua"
          style={{
            marginLeft: isMobile ? "unset" : 80,
            minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
          }}
        >
          <div className="flex-column-between">
            <div className="migracao envio-relatorio">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    maxWidth: isMobile ? "unset" : 650,
                    backgroundColor: "#fff",
                    color: "#000",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: termsOfUseHtml || "Loading...",
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", gap: 16 }}>
              <Form.Item label={t("pages.Distribua.liCompreendi")}>
                <Checkbox
                  name="readAndUnderstood"
                  checked={checkBoxCheckeds.readAndUnderstood}
                  onChange={onCheckboxChange}
                />
              </Form.Item>
              <Form.Item label={t("pages.Distribua.aceitoTermos")}>
                <Checkbox
                  name="acceptTerms"
                  checked={checkBoxCheckeds.acceptTerms}
                  onChange={onCheckboxChange}
                />
              </Form.Item>
            </div>
            <div className="footer black" style={{ paddingLeft: 8 }}>
              <button
                className="botao-roxo"
                onClick={() => setStep(StepPage.Form)}
              >
                {t("pages.Adiantamentos.recusar")}
              </button>
              <button
                disabled={
                  !(
                    checkBoxCheckeds.readAndUnderstood &&
                    checkBoxCheckeds.acceptTerms
                  )
                }
                className="botao-roxo"
                onClick={onClickAceitar}
              >
                {t("pages.Adiantamentos.aceitar")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={`adiantamentos ${!isMobile && "web"}`}>
      <div
        className="contrato-assinatura distribua"
        style={{
          marginLeft: isMobile ? "unset" : 80,
          minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
        }}
      >
        <div className="flex-column-between">
          <div className="migracao envio-relatorio">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ maxWidth: isMobile ? "unset" : 650 }}>
                <div className="confirma-adiantamento-intro">
                  <h1>{t("pages.Distribua.parabensTitulo")}</h1>
                  <p>
                    <Trans
                      i18nKey={t("pages.Distribua.parabensTexto", {
                        estagio: userArtist.stage,
                      })}
                    />
                  </p>
                </div>
                <Divider />

                <Form
                  form={form}
                  onFinish={onFinish}
                  className="contrato"
                  layout="vertical"
                  initialValues={{
                    fiscalType: TipoPessoa.Fisica,
                    email: loggedUser.login,
                  }}
                >
                  <h2>
                    {t("pages.Adiantamentos.confirmaAdiantamentoTituloForm")}
                  </h2>
                  <span className="sub-titulo">
                    {t("pages.Adiantamentos.pessoaFisicaJuridica")}
                  </span>
                  <Form.Item
                    name="fiscalType"
                    label={t("pages.Adiantamentos.tipoPessoa")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.tipoPessoaRequired"),
                      },
                    ]}
                  >
                    <Select onChange={(value) => setTipoPessoa(value)}>
                      <Select.Option value={TipoPessoa.Fisica}>
                        {t("pages.Adiantamentos.pessoaFisica")}
                      </Select.Option>
                      <Select.Option value={TipoPessoa.Juridica}>
                        {t("pages.Adiantamentos.pessoaJuridica")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {tipoPessoa === TipoPessoa.Juridica && (
                    <>
                      <PessoaJuridicaForm />
                    </>
                  )}
                  <PessoaFisicaForm
                    titulo={
                      tipoPessoa === TipoPessoa.Fisica
                        ? t("pages.Adiantamentos.confirmaAdiantamentoFisica")
                        : t(
                            "pages.Adiantamentos.confirmaAdiantamentoFisicaRepresentante"
                          )
                    }
                  />
                  <span className="sub-titulo">
                    {t("pages.Adiantamentos.dadosBancarios")}
                  </span>
                  <Form.Item
                    name="pixKeyType"
                    label={t("pages.Adiantamentos.tipoChavePix")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.tipoChavePixRequired"),
                      },
                    ]}
                  >
                    <Select
                      options={pixKeyTypeList.map((item) => ({
                        ...item,
                        label: t(
                          `pages.Adiantamentos.${item.label.toLocaleLowerCase()}`
                        ),
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    name="pixKey"
                    label={t("pages.Adiantamentos.chavePix")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.chavePixRequired"),
                      },
                    ]}
                  >
                    <Input maxLength={100} />
                  </Form.Item>
                  <Form.Item
                    name="bankCode"
                    label={t("pages.Adiantamentos.codigoBanco")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.codigoBancoRequired"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      options={bankOptions}
                      loading={bankLoading}
                      filterOption={filterOptionDiacritics}
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name="bankAgency"
                    label={t("pages.Adiantamentos.agenciaBanco")}
                    rules={[
                      {
                        required: true,
                        message: t("pages.Adiantamentos.agenciaBancoRequired"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>{" "}
                  <Form.Item
                    name="bankAccountNumber"
                    label={t("pages.Adiantamentos.numeroContaBanco")}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "pages.Adiantamentos.numeroContaBancoRequired"
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>

          <div className="footer black" style={{ paddingLeft: 8 }}>
            <button className="botao-roxo" onClick={() => history.push("/")}>
              {t("pages.Adiantamentos.voltar")}
            </button>
            <button
              // disabled={disabledSave}
              className="botao-roxo continuar"
              onClick={() => {
                form.submit();
                dataLayerEvent("ClickButton", {
                  ...quizEvents.S4A_DISTRO_FORM,
                  buttonName: "NEXT",
                });
              }}
            >
              {t("pages.Adiantamentos.continuar")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PessoaFisicaForm: React.FC<any> = (props) => {
  const { titulo } = props;
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [loadingCEP, setLoadingCEP] = useState<boolean>(false);

  const fillAddressByCep = async (cep: string) => {
    try {
      if (cep) {
        setLoadingCEP(true);
        const response: any = await getAddressByCep(cep);
        form.setFieldsValue({
          street: response.street,
          state: response.uf,
          city: response.city,
        });
      }
    } finally {
      setLoadingCEP(false);
    }
  };
  return (
    <>
      <span className="sub-titulo">{titulo}</span>
      <Form.Item
        name="name"
        label={t("pages.Adiantamentos.nomeCompleto")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.nomeCompletoRequired"),
          },
          {
            pattern: /^\S+(\s+\S+)+$/,
            message: t("pages.Adiantamentos.nomeSobrenomeValidation"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.nomeCompletoPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="email"
        label={t("pages.Adiantamentos.email")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.emailRequired"),
          },
        ]}
      >
        <Input
          placeholder={t("pages.Adiantamentos.emailPlaceholder")}
          disabled
        />
      </Form.Item>
      <Form.Item
        name="cpf"
        label={t("pages.Adiantamentos.cpf")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cpfRequired"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return formCPFValidator(value, getFieldValue, "cpf", t);
            },
          }),
        ]}
      >
        <MaskInput
          mask="999.999.999-99"
          placeholder={t("pages.Adiantamentos.cpfPlaceholder")}
        />
      </Form.Item>
      <Form.Item
        name="rg"
        label={t("pages.Adiantamentos.rg")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.rgRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.rgPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="rgPlaceExpedition"
        label={t("pages.Adiantamentos.rgPlaceExpedition")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.rgPlaceExpeditionRequired"),
          },
        ]}
      >
        <Input
          placeholder={t("pages.Adiantamentos.rgPlaceExpeditionPlaceholder")}
        />
      </Form.Item>
      <span className="sub-titulo">
        {t("pages.Adiantamentos.confirmaAdiantamentoEndereco")}
      </span>
      <Form.Item
        name="cep"
        label={t("pages.Adiantamentos.cep")}
        hasFeedback={loadingCEP}
        validateStatus={loadingCEP ? "validating" : undefined}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cepRequired"),
          },
        ]}
      >
        <Input
          onChange={debounce(
            (event) => fillAddressByCep(event.target.value),
            2000
          )}
          placeholder={t("pages.Adiantamentos.cepPlaceholder")}
          maxLength={8}
        />
      </Form.Item>
      <Form.Item
        name="street"
        label={t("pages.Adiantamentos.rua")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.ruaRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.ruaPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="streetNumber"
        label={t("pages.Adiantamentos.numero")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.numeroRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.numeroPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="addressComplement"
        label={t("pages.Adiantamentos.complemento")}
      >
        <Input placeholder={t("pages.Adiantamentos.complementoPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="state"
        label={t("pages.Adiantamentos.estado")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.estadoRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.estadoPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="city"
        label={t("pages.Adiantamentos.cidade")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cidadeRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.cidadePlaceholder")} />
      </Form.Item>
    </>
  );
};

const PessoaJuridicaForm: React.FC<any> = () => {
  const [loadingCEP, setLoadingCEP] = useState<boolean>(false);
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const fillAddressByCep = async (cep: string) => {
    try {
      if (cep) {
        setLoadingCEP(true);

        const response: any = await getAddressByCep(cep);
        form.setFieldsValue({
          companyStreet: response.street,
          companyState: response.uf,
          companyCity: response.city,
        });
      }
    } finally {
      setLoadingCEP(false);
    }
  };
  return (
    <>
      <span className="sub-titulo">
        {t("pages.Adiantamentos.confirmaAdiantamentoJuridica")}
      </span>
      <Form.Item
        name="businessName"
        label={t("pages.Adiantamentos.razaoSocial")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.razaoSocialRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.razaoSocialPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="cnpj"
        label={t("pages.Adiantamentos.cnpj")}
        // extra={t("pages.Adiantamentos.cpfExtra")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cnpjRequired"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return formCNPJValidator(value, getFieldValue, "cnpj", t);
            },
          }),
        ]}
      >
        <MaskInput
          mask="99.999.999/9999-99"
          placeholder={t("pages.Adiantamentos.cnpjPlaceholder")}
        />
      </Form.Item>
      <span className="sub-titulo">
        {t("pages.Adiantamentos.confirmaAdiantamentoEndereco")}
      </span>
      <Form.Item
        name="companyCEP"
        label={t("pages.Adiantamentos.cep")}
        hasFeedback={loadingCEP}
        validateStatus={loadingCEP ? "validating" : undefined}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cepRequired"),
          },
        ]}
      >
        <Input
          onChange={debounce(
            (event) => fillAddressByCep(event.target.value),
            2000
          )}
          placeholder={t("pages.Adiantamentos.cepPlaceholder")}
          maxLength={8}
        />
      </Form.Item>
      <Form.Item
        name="companyStreet"
        label={t("pages.Adiantamentos.rua")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.ruaRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.ruaPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="companyStreetNumber"
        label={t("pages.Adiantamentos.numero")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.numeroRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.numeroPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="companyAddressComplement"
        label={t("pages.Adiantamentos.complemento")}
      >
        <Input placeholder={t("pages.Adiantamentos.complementoPlaceholder")} />
      </Form.Item>

      <Form.Item
        name="companyState"
        label={t("pages.Adiantamentos.estado")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.estadoRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.estadoPlaceholder")} />
      </Form.Item>
      <Form.Item
        name="companyCity"
        label={t("pages.Adiantamentos.cidade")}
        rules={[
          {
            required: true,
            message: t("pages.Adiantamentos.cidadeRequired"),
          },
        ]}
      >
        <Input placeholder={t("pages.Adiantamentos.cidadePlaceholder")} />
      </Form.Item>
    </>
  );
};
export default Distribua;
