import { Loader } from "shared/components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import "./Home.scss";
import { getUserInvites } from "shared/service/StrmService";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";

const Home: React.FC<RouteComponentProps> = (props) => {
  const {
    history,
    location: { state = {} },
  } = props;
  const { login = false }: any = state;
  const [loadingInvite, setLoadingInvite] = useState<boolean>(false);
  const { t } = useTranslation();
  const { loggedUser, loading } = useSelector((state: any) => state.users);
  const { userArtists } = loggedUser;

  useEffect(() => {
    const getInvites = async () => {
      setLoadingInvite(true);
      const response: any = await getUserInvites();
      if (response?.length > 0) {
        history.push("/convites");
        return;
      }
      if (userArtists?.length > 0) {
        history.push("/estagio");
        return;
      }
    };
    if (!loading && !loadingInvite) getInvites();
  }, [userArtists, login, history, loading, loadingInvite]);

  useEffect(() => {
    if (!loading && !userArtists?.length) {
      dataLayerEvent("ScreenViewed", {
        ...quizEvents.S4A_HOME_UNDIAGNOSED,
      });
    }
  }, [loading, userArtists]);

  if (!loggedUser || loading || userArtists?.length) return <Loader.Simple />;

  return (
    <div className="home">
      <div className="direction">
        <div className="content">
          <h1>{t("Home.welcome")}</h1>
          <h3>{t("Home.subtext")}</h3>
          <span className="message">{t("Home.intro")}</span>
          <button
            className="botao-roxo"
            onClick={() => {
              dataLayerEvent("ClickButton", {
                ...quizEvents.S4A_HOME_UNDIAGNOSED,
                buttonName: "RUN_DIAGNOSE",
              });
              history.push("/quiz");
            }}
          >
            {t("Home.start")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
