import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCountries } from "shared/service/StrmService";

export const getCountries = createAsyncThunk(
  "countries/get-countries",
  async () => {
    return await fetchCountries();
  }
);

const getCapitalizedLang = (language: string) =>
  language.charAt(0).toUpperCase() + language.slice(1);

export const paisesSlice = createSlice({
  name: "countries",
  initialState: {
    countries: [],
    countryOptions: [],
    countryByKeys: {},
    loading: true,
  },
  reducers: {
    setCountryOptionsI18n: (state, action) => {
      state.countryOptions = state.countries
        .map((country: any) => ({
          label: country[`description${getCapitalizedLang(action.payload)}`],
          value: country.code,
        }))
        .sort((a: any, b: any) => (a.value === "BR" ? -1 : 0)) as any;
    },
  },
  extraReducers: {
    [getCountries.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.countries = action.payload;
        state.countryByKeys = action.payload.reduce((r: any, e: any) => {
          r[e.code] = { ...e };
          return r;
        }, {});
        state.loading = false;
      }
    },
  },
});

export const { setCountryOptionsI18n } = paisesSlice.actions;

export default paisesSlice.reducer;

export const countriesLatamRestrictions = [
  "AR",
  "BO",
  "BR",
  "CL",
  "CR",
  "CU",
  "CO",
  "SV",
  "EC",
  "GT",
  "HT",
  "HN",
  "MX",
  "NI",
  "PA",
  "PY",
  "PE",
  "DO",
  "UY",
  "VE",
];
