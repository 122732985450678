import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { StepProps } from "./StepProps";
import { useTranslation } from "react-i18next";

const FifthStep: React.FC<StepProps> = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        name="aboutCreation"
        label={t("pages.Pitching.steps.fifth.aboutCreation")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea
          rows={8}
          maxLength={4000}
          placeholder={t("pages.Pitching.steps.fifth.resposta")}
        />
      </Form.Item>
    </div>
  );
};

export default FifthStep;
