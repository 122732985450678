interface StepComponentProps {
  StepContent: any;
  isVisible: boolean;
}

const StepContainer: React.FC<StepComponentProps> = ({
  StepContent,
  isVisible,
}) => {
  return StepContent(isVisible);
};

export default StepContainer;
