import { Layout } from "antd";
import MenuLateral from "shared/components/menu-lateral";
import { autenticado } from "shared/helpers/auth-functions";
import "./Layout.scss";

const { Content } = Layout;
const LayoutBranco: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout className="layout-branco">
      {autenticado() && <MenuLateral />}
      <Content>{children}</Content>
    </Layout>
  );
};

export default LayoutBranco;
