import { Modal, Progress } from "antd";
import { useTranslation } from "react-i18next";
import imgHenriqueJulianoWeb from "shared/assets/images/henrique-juliano-sem-texto.png";
import "explorer/pages/new-estagio-artista/campanhas-especiais/CampanhasEspeciais.scss";
import { useSelector } from "react-redux";

interface ModalCampaignHeJNotEligibleProps {
  open: boolean;
  onCancel: () => void;
}

enum MotivoNaoElegivel {
  Estagio = 0,
  Adiantamento = 1,
  Genero = 2,
}

const ModalCampaignHeJNotEligible: React.FC<
  ModalCampaignHeJNotEligibleProps
> = (props) => {
  const { open, onCancel } = props;
  const { t } = useTranslation();
  const { userArtist } = useSelector((state: any) => state.artists);
  const { titulo } = t(
    `pages.EstagioArtista.estagio.${userArtist?.stage || 0}`
  ) as any;

  const motivoNaoElegivel: () => MotivoNaoElegivel = () => {
    if (
      !userArtist?.genresStrm.includes("sertanejo") &&
      !userArtist?.genresStrm.includes("forro") &&
      !userArtist?.genresStrm.includes("brega")
    )
      return MotivoNaoElegivel.Genero;
    if (userArtist?.stage < 5) return MotivoNaoElegivel.Estagio;
    if (userArtist?.cashAdvanceSigned) return MotivoNaoElegivel.Adiantamento;
    return MotivoNaoElegivel.Genero;
  };

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      width={700}
      title={null}
      className="modal-henrique-juliano"
    >
      <>
        <div
          style={{
            background: `url(${imgHenriqueJulianoWeb}) no-repeat`,
            backgroundSize: "cover",
            borderRadius: "8px 8px 0 0",
            minHeight: 330,
            width: "100%",
            position: "absolute",
          }}
        />
        <div style={{ position: "relative" }}>
          <div className="conteudo">
            <div style={{ background: "#0D0D0D", opacity: 0.7 }} />
            <div
              style={{
                paddingTop: 112,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 48px",
              }}
            >
              {motivoNaoElegivel() === MotivoNaoElegivel.Estagio && (
                <div>
                  <Progress
                    type="circle"
                    percent={userArtist?.stagePercentual}
                    width={110}
                    strokeColor="#00D88A"
                    trailColor="#004129"
                    format={(value) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        <span style={{ fontSize: 36, color: "#f2f2f2" }}>
                          {userArtist?.stage}
                        </span>
                        <span
                          style={{ fontSize: 14, width: 80, color: "#f2f2f2" }}
                        >
                          {titulo}
                        </span>
                        <span style={{ fontSize: 11, color: "#00D88A" }}>
                          {value}%
                        </span>
                      </div>
                    )}
                  />
                </div>
              )}
              <span className="chamada">
                {motivoNaoElegivel() === MotivoNaoElegivel.Estagio &&
                  t("pages.CampanhasEspeciais.HenriqueJuliano.quaseLa")}
                {motivoNaoElegivel() === MotivoNaoElegivel.Adiantamento &&
                  t(
                    "pages.CampanhasEspeciais.HenriqueJuliano.adiantamentoVigente"
                  )}
                {motivoNaoElegivel() === MotivoNaoElegivel.Genero &&
                  t(
                    "pages.CampanhasEspeciais.HenriqueJuliano.artistasSertanejos"
                  )}
              </span>
              <h1 className="titulo">
                {motivoNaoElegivel() === MotivoNaoElegivel.Estagio &&
                  t(
                    "pages.CampanhasEspeciais.HenriqueJuliano.naoElegivelEstagioTitulo"
                  )}
                {motivoNaoElegivel() === MotivoNaoElegivel.Adiantamento &&
                  t(
                    "pages.CampanhasEspeciais.HenriqueJuliano.naoElegivelAdiantamentoTitulo"
                  )}
                {motivoNaoElegivel() === MotivoNaoElegivel.Genero &&
                  t(
                    "pages.CampanhasEspeciais.HenriqueJuliano.naoElegivelGeneroTitulo"
                  )}
              </h1>

              <span className="divider" />
            </div>

            <p
              style={{
                padding: "0 48px",
                textAlign: "center",
              }}
            >
              {motivoNaoElegivel() === MotivoNaoElegivel.Estagio &&
                t(
                  "pages.CampanhasEspeciais.HenriqueJuliano.naoElegivelEstagioTexto"
                )}
              {motivoNaoElegivel() === MotivoNaoElegivel.Adiantamento &&
                t(
                  "pages.CampanhasEspeciais.HenriqueJuliano.naoElegivelAdiantamentoTexto"
                )}
              {motivoNaoElegivel() === MotivoNaoElegivel.Genero &&
                t(
                  "pages.CampanhasEspeciais.HenriqueJuliano.naoElegivelGeneroTexto"
                )}
            </p>
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(25, 25, 25, 0.00) 0%, #1A1A1A 100%)",
                height: 170,
                width: "100%",
                marginTop: -130,
              }}
            />
            <div style={{ background: "#1A1A1A", paddingTop: 12 }}>
              <ul>
                <li className="circle-checkmark azul">
                  {t("pages.CampanhasEspeciais.HenriqueJuliano.strmHelp1")}
                </li>
                <li className="circle-checkmark azul">
                  {t("pages.CampanhasEspeciais.HenriqueJuliano.strmHelp2")}
                </li>
                <li className="circle-checkmark azul">
                  {t("pages.CampanhasEspeciais.HenriqueJuliano.strmHelp3")}
                </li>
                <li className="circle-checkmark azul">
                  {t("pages.CampanhasEspeciais.HenriqueJuliano.strmHelp4")}
                </li>
              </ul>
              <div className="buttons">
                <button className="botao-roxo" onClick={onCancel}>
                  {t("pages.CampanhasEspeciais.continuarEvoluindo")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ModalCampaignHeJNotEligible;
