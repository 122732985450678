import { theme } from "antd";
import InputMask from "react-input-mask";
const { useToken } = theme;

const MaskInput: React.FC<any> = (props) => {
  const { token }: any = useToken();
  const invalid = props["aria-invalid"] === "true";

  return (
    <InputMask
      autoComplete="off"
      {...props}
      className={`ant-input ${token._hashId} ${
        invalid && "ant-input-status-error"
      }`}
    />
  );
};

export default MaskInput;
