export enum PixKeyTypeEnum {
  Document = 0,
  Phone = 1,
  Email = 2,
  Random = 3,
}

export const pixKeyTypeList = [
  {
    label: "Documento",
    value: PixKeyTypeEnum.Document,
  },
  {
    label: "Phone",
    value: PixKeyTypeEnum.Phone,
  },
  {
    label: "Email",
    value: PixKeyTypeEnum.Email,
  },
  {
    label: "Random",
    value: PixKeyTypeEnum.Random,
  },
];
