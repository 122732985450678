import { useTranslation } from "react-i18next";
import "./LanguageChange.scss";
import Flags from "country-flag-icons/react/3x2";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCountryOptionsI18n } from "explorer/reducers/countries";
import { setGenreOptionsI18n } from "explorer/reducers/generos";
import { setLoggedUserLanguage } from "explorer/reducers/users";
import { autenticado } from "shared/helpers/auth-functions";
import { LinguagemEnum } from "shared/enums/LinguagemEnum";

const LanguageChange: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((state: any) => state.users);
  const { countries } = useSelector((state: any) => state.countries);

  const setCountryOptions = useCallback(async () => {
    if (countries) {
      dispatch(setCountryOptionsI18n(i18n.language));
    }
  }, [countries, dispatch, i18n]);

  const setGenreOptions = useCallback(async () => {
    dispatch(setGenreOptionsI18n(i18n.language));
  }, [dispatch, i18n]);

  const onChangeLanguage = (lang: string) => {
    if (loggedUser?.currentLanguage && autenticado()) {
      dispatch(setLoggedUserLanguage(lang));
    }
    i18n.changeLanguage(lang);
    setGenreOptions();
    setCountryOptions();
  };

  useEffect(() => {
    setGenreOptions();
    setCountryOptions();
  }, [setGenreOptions, setCountryOptions]);

  return (
    <div className="change-language">
      <Flags.US onClick={() => onChangeLanguage(LinguagemEnum.Ingles)} />
      <Flags.BR onClick={() => onChangeLanguage(LinguagemEnum.Portugues)} />
      <Flags.ES onClick={() => onChangeLanguage(LinguagemEnum.Espanhol)} />
    </div>
  );
};

export default LanguageChange;
