import { RightOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as DocumentWithClock } from "shared/assets/images/document-with-clock.svg";

const SuccessfulStep: React.FC = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const navigateToHome = () => {
    history.push("/");
  };

  return (
    <div className={`container-releases-schedule ${isMobile && "mobile"}`}>
      <div className="content">
        <Row justify="center" gutter={[24, 0]} className="successful-step">
          <Col>
            <p>
              <DocumentWithClock />
            </p>
            <p className="title">
              {t("pages.ReleasesSchedule.steps.success.title")}
            </p>
            <p className="subtitle">
              {t("pages.ReleasesSchedule.steps.success.subtitle")}
            </p>
          </Col>
          <Col>
            <button className="botao-roxo" onClick={navigateToHome}>
              {t("pages.ReleasesSchedule.steps.success.homeLink")}{" "}
              <RightOutlined />
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SuccessfulStep;
