import ModalCampaignHeJEligible from "./estagio/campanhas-especiais/henrique-juliano/ModalCampaignHeJEligible";
import ModalCampaignHeJNotEligible from "./estagio/campanhas-especiais/henrique-juliano/ModalCampaignHeJNotEligible";
import ModalCampaignHeJRequest from "./estagio/campanhas-especiais/henrique-juliano/ModalCampaignHeJRequest";
import ModalCampaignHeJResponse from "./estagio/campanhas-especiais/henrique-juliano/ModalCampaignHeJResponse";

const Modais = {
  ModalCampaignHeJEligible,
  ModalCampaignHeJNotEligible,
  ModalCampaignHeJRequest,
  ModalCampaignHeJResponse,
};

export default Modais;
