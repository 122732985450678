import { Row, Radio, Divider, Col } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import FormAlterarSenha from "./FormAlterarSenha";
import "./MeusDados.scss";
import { hasClaim } from "shared/helpers/auth-functions";
import DetalhesConta from "./DetalhesConta";
import HistoricoCompras from "./HistoricoCompras";
import { isMobile } from "react-device-detect";

const MeusDados: React.FC<RouteComponentProps> = () => {
  const loggedUser = useSelector((state: any) => state.users.loggedUser);
  const [selectedTab, setSelectedTab] = useState<string>("details");
  const googleUser = hasClaim("us-east-1_qb4NuMldu_Google", loggedUser);
  const facebookUser = hasClaim("us-east-1_qb4NuMldu_Facebook", loggedUser);
  const { t } = useTranslation();

  const onChangeTab = (e: any) => {
    setSelectedTab(e.target.value);
  };

  return (
    <div
      className="meus-dados"
      style={{ padding: isMobile ? "16px" : "32px 100px" }}
    >
      <Row justify="center">
        <Col span={24}>
          <Row>
            <Radio.Group onChange={onChangeTab} value={selectedTab}>
              <Radio.Button value="details">
                {t("pages.MeusDados.details")}
              </Radio.Button>
              <Radio.Button
                value="changePassword"
                style={{ display: googleUser || facebookUser ? "none" : "" }}
              >
                {t("pages.MeusDados.changePassword")}
              </Radio.Button>
              <Radio.Button value="shopHistory">
                {t("pages.MeusDados.shopHistory")}
              </Radio.Button>
            </Radio.Group>
          </Row>

          <Divider style={{ backgroundColor: "#5c5c5c" }} />
          {selectedTab === "details" && <DetalhesConta />}
          {selectedTab === "changePassword" && <FormAlterarSenha />}
          {selectedTab === "shopHistory" && <HistoricoCompras />}
        </Col>
      </Row>
    </div>
  );
};
export default MeusDados;
