import React from "react";
import { isMobile } from "react-device-detect";
import bgGirlMobile from "shared/assets/images/girl-waiting-list-mobile.png";
import bgGirl from "shared/assets/images/girl-waiting-list.png";
import { ReactComponent as Clock } from "shared/assets/images/clock-eight.svg";
import { Progress } from "antd";
import { Trans, useTranslation } from "react-i18next";

const AdiantamentoListaEspera: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex-column-between lista-espera"
      style={{
        minHeight: isMobile ? "calc(100vh - 64px)" : "100vh",
        paddingLeft: isMobile ? 0 : 94,
        alignItems: "center",
        backgroundColor: "#1a1a1a",
      }}
    >
      <div
        style={{
          background: `url(${isMobile ? bgGirlMobile : bgGirl}) no-repeat`,
          backgroundSize: "cover",
          borderRadius: "8px 8px 0 0",
          height: "90vh",
          width: "100%",
          position: "absolute",
          backgroundPosition: "0  0",
          marginTop: isMobile ? 0 : 4,
        }}
      />
      <div
        className="migracao"
        style={{
          padding: "24px 40px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxWidth: isMobile ? "unset" : 650,
          zIndex: 1,
        }}
      >
        <div style={{ marginTop: isMobile ? 60 : 120 }}>
          <Progress
            type="circle"
            percent={60}
            width={100}
            strokeColor="#5A57FF"
            trailColor="#1677ff40"
            format={() => (
              <Clock
                style={{
                  width: 60,
                  height: 60,
                  transform: "translate(3px, 1px)",
                }}
              />
            )}
          />
          <span className="chamada">{t("pages.Adiantamentos.quaseLa")}</span>
          <h1 className="titulo">
            {t("pages.Adiantamentos.obrigadoPeloInteresse")}
          </h1>
          <p className="texto">
            <Trans i18nKey={t("pages.Adiantamentos.adiantamentoListaEspera")} />
          </p>
          <p className="texto">
            <Trans
              i18nKey={t(
                "pages.Adiantamentos.adiantamentoListaEsperaNotificaremos"
              )}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdiantamentoListaEspera;
