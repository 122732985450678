import RcSlider from "rc-slider";
import "rc-slider/assets/index.css";
import { HandlesProps } from "rc-slider/lib/Handles";
import { MarkObj } from "rc-slider/lib/Marks";
import { AriaValueFormat } from "rc-slider/lib/interface";
import "./Slider.scss";

interface SliderProps<ValueType = number | number[]> {
  prefixCls?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  keyboard?: boolean;
  autoFocus?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
  range?: boolean;
  count?: number;
  min?: number;
  max?: number;
  step?: number | null;
  value?: ValueType;
  defaultValue?: ValueType;
  onChange?: (value: ValueType) => void;
  /** @deprecated It's always better to use `onChange` instead */
  onBeforeChange?: (value: ValueType) => void;
  /** @deprecated It's always better to use `onChange` instead */
  onAfterChange?: (value: ValueType) => void;
  allowCross?: boolean;
  pushable?: boolean | number;
  /** range only */
  draggableTrack?: boolean;
  reverse?: boolean;
  vertical?: boolean;
  included?: boolean;
  startPoint?: number;
  trackStyle?: React.CSSProperties | React.CSSProperties[];
  handleStyle?: React.CSSProperties | React.CSSProperties[];
  railStyle?: React.CSSProperties;
  dotStyle?: React.CSSProperties | ((dotValue: number) => React.CSSProperties);
  activeDotStyle?:
    | React.CSSProperties
    | ((dotValue: number) => React.CSSProperties);
  marks?: Record<string | number, React.ReactNode | MarkObj>;
  dots?: boolean;
  handleRender?: HandlesProps["handleRender"];
  tabIndex?: number | number[];
  ariaLabelForHandle?: string | string[];
  ariaLabelledByForHandle?: string | string[];
  ariaValueTextFormatterForHandle?: AriaValueFormat | AriaValueFormat[];
}

const Slider: React.FC<SliderProps> = (props) => {
  const { ...rest } = props;
  return (
    <RcSlider
      className="slider"
      {...rest}
      // included={false}
      // min={0}
      // max={100}
      // value={50}
      // trackStyle={{ backgroundColor: "blue" }} // Exemplo de estilo do track
      // railStyle={{ backgroundColor: "lightgray" }} // Exemplo de estilo do rail
      // handleStyle={{ backgroundColor: "blue", borderColor: "blue" }} // Exemplo de estilo do handle
    />
  );
};

export default Slider;
