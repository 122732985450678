//local storage keys
export const TOKEN = "token";
export const BROWSER_ID = "browser_id";
export const USER = "usuario";
export const TIPO_DIAGNOSTICO_UTILIZADO = "TIPO_DIAGNOSTICO_UTILIZADO";
export const LANG = "LANG";

//session storage keys
export const QUIZ_INFO = "QUIZ_INFO";

export const NOVO_USUARIO = "NOVO_USUARIO";

export const REFERENCE_PAGE = "REFERENCE_PAGE";

export const DISTRO_PARAM = "DISTRO_PARAM";
