import { PageHeader } from "@ant-design/pro-layout";
import { Col, Form, Input, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MaskInput } from "shared/components";
import { validatePhone } from "shared/helpers/phone-helpers";
import { saveLoggedUser } from "shared/service/StrmService";

const DetalhesConta: React.FC<any> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const loggedUser = useSelector((state: any) => state.users.loggedUser);
  const { countryOptions } = useSelector((state: any) => state.countries);
  const country = loggedUser.countryCode;
  const onFinish = async () => {
    await form.validateFields();
    setLoading(true);
    const {
      actions,
      activeSubscriptionTeam,
      mercadoPagoTransactions,
      userTeam,
      ...rest
    }: any = form.getFieldsValue(true);
    try {
      await saveLoggedUser(rest);
      toast.success(t("pages.MeusDados.sucesso"));
    } catch (error: any) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [loggedUser, form]);

  return (
    <div>
      <PageHeader title={t("pages.MeusDados.titulo")} style={{ padding: 0 }} />
      <Typography.Title level={5} style={{ color: "#fff" }}>
        {t("pages.MeusDados.creditosRemanescentes", {
          creditos: loggedUser.remainingCredits,
        })}
      </Typography.Title>

      <Form
        form={form}
        initialValues={loggedUser}
        onFinish={onFinish}
        style={{ color: "#fff" }}
        layout="vertical"
      >
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item
              name="name"
              label={t("pages.MeusDados.fullName")}
              rules={[
                {
                  required: true,
                  message: t("pages.MeusDados.nomeObrigatorio"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item name="login" label={t("pages.MeusDados.email")}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item
              name="phone"
              label={t("pages.MeusDados.phone")}
              rules={[
                {
                  validator(_, value) {
                    return validatePhone(
                      country,
                      value,
                      t("pages.MeusDados.telefoneInvalido")
                    );
                  },
                },
              ]}
            >
              <MaskInput mask="(99) 9999-99999" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item name="countryCode" label={t("pages.MeusDados.country")}>
              <Select options={countryOptions} allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item name="state" label={t("pages.MeusDados.state")}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24}>
            <Form.Item name="city" label={t("pages.MeusDados.city")}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12} offset={6}>
            <button
              className="botao-roxo"
              style={{ width: "100%" }}
              disabled={loading}
            >
              {t("pages.MeusDados.alterar")}
            </button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DetalhesConta;
