import { Divider } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { Collapse } from "shared/components";
import { ReactComponent as ClockIcon } from "shared/assets/images/clock-eight.svg";
import { ReactComponent as UploadIcon } from "shared/assets/images/upload-icon.svg";
import { ReactComponent as RefreshIcon } from "shared/assets/images/refresh-icon.svg";
import { ReactComponent as MergeIcon } from "shared/assets/images/merge-icon.svg";
import moment from "moment";
import { CatalogMigrationStepEnum } from "shared/enums/CatalogMigrationStepEnum";
import { useCallback, useEffect, useState } from "react";
import { dataLayerEvent } from "event-tracker/gtm-events";
import quizEvents from "event-tracker/quiz-events";
import { useSelector } from "react-redux";
import { CashAdvance } from "shared/interfaces/CashAdvance";
import {
  getCatalogMigrationByLoggedUser,
  putMarkContractSignatureFirstView,
} from "shared/service/StrmService";
import { CatalogMigrationStatusEnum } from "shared/enums/CatalogMigrationStatusEnum";

interface Migration {
  status: CatalogMigrationStatusEnum;
  step: CatalogMigrationStepEnum;
  createdOn: string;
  startedOn: string;
}

const AdiantamentoMigracao: React.FC<{
  advance: CashAdvance;
}> = (props) => {
  const { advance } = props;
  const { t } = useTranslation();
  const { userArtist } = useSelector((state: any) => state.artists);
  const [migration, setMigration] = useState<Migration>();

  const getMigration = useCallback(async () => {
    const { response, error }: any = await getCatalogMigrationByLoggedUser();

    if (!error) {
      setMigration(response);
    }
  }, []);

  const getHeghtByStatus = (status: CatalogMigrationStepEnum) => {
    const currentStatus = migration?.step;
    switch (status) {
      case CatalogMigrationStepEnum.Upload: {
        if (currentStatus === CatalogMigrationStepEnum.Upload) {
          return "50%";
        }
        return "100%";
      }
      case CatalogMigrationStepEnum.Distribution: {
        if (currentStatus === CatalogMigrationStepEnum.Distribution) {
          return "50%";
        }
        if (currentStatus === CatalogMigrationStepEnum.Upload) {
          return "0%";
        }
        return "100%";
      }
      case CatalogMigrationStepEnum.Merge: {
        if (
          currentStatus === CatalogMigrationStepEnum.Distribution ||
          currentStatus === CatalogMigrationStepEnum.Upload
        ) {
          return "0%";
        }

        return "50%";
      }
    }
  };

  useEffect(() => {
    if (userArtist.stage && advance?.totalAmount) {
      dataLayerEvent("ScreenViewed", {
        ...quizEvents.S4A_ADVANCE_WAITING_MERGE,
        carreerStage: userArtist?.stage,
        approvedLimit: userArtist?.cashAdvanceEstimated?.amountUsd,
        alreadyCalculated:
          userArtist?.cashAdvanceEstimated?.successfullyEstimated,
        country: userArtist?.country,
        proposalValue: advance?.totalAmountUsd,
        userTest: userArtist?.internalTest,
        firstView: !advance?.firstViewContractSignatureOn,
        signatureTime: advance?.signatureDate,
      });

      if (advance.id) putMarkContractSignatureFirstView(advance.id);
    }
  }, [userArtist, advance]);

  useEffect(() => {
    getMigration();
  }, [getMigration]);

  return (
    <>
      <div style={{ padding: "0 20px" }}>
        <h1 className="titulo-strong-roxo" style={{ textAlign: "left" }}>
          <Trans i18nKey="pages.Adiantamentos.adiantamentoMigracaoTitulo" />
        </h1>
        <Divider />
        <p style={{ color: "#B8B8B8" }}>
          <Trans i18nKey="pages.Adiantamentos.adiantamentoMigracaoText" />
        </p>
        <div className="prazo-passo-migracao">
          <span className="inicio">
            {t("pages.Adiantamentos.etapaIniciada")}{" "}
            <b>{moment(migration?.createdOn).format("DD/MM/YYYY")}</b>
          </span>

          <ClockIcon />

          <span>{t("pages.Adiantamentos.prazoTotalEstimadoPasso")}</span>
          <span className="roxo">
            {t("pages.Adiantamentos.prazoEstimadoPassoDias", {
              days: 15,
            })}
          </span>
        </div>
      </div>
      <div className="passos-migracao">
        <div
          className={`passo ${
            migration?.step !== CatalogMigrationStepEnum.Upload && "completo"
          }`}
        >
          <div className="progress-container">
            <div className="icon-container">
              <div className="icon">
                <UploadIcon />
              </div>
            </div>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  height: getHeghtByStatus(CatalogMigrationStepEnum.Upload),
                }}
              ></div>
            </div>
          </div>
          <div className="passo-container">
            <span className="passo-posicao">
              {t("pages.Adiantamentos.passo", { step: 1 })}
            </span>
            <Collapse
              header={<h1 className="titulo-passo">Upload</h1>}
              content={
                <p>
                  <Trans i18nKey={t("pages.Adiantamentos.uploadPasso")} />
                </p>
              }
            />
            <Divider />
            <div className="caixa-etapa-prazo">
              {migration?.step === CatalogMigrationStepEnum.Upload && (
                <div>
                  <span>{t("pages.Adiantamentos.etapaIniciada")}</span>
                  <span>
                    {moment(migration?.startedOn).format("DD/MM/YYYY")}
                  </span>
                </div>
              )}
              <div>
                <span>{t("pages.Adiantamentos.prazoEstimadoPasso")}</span>
                <span className="roxo">
                  {t("pages.Adiantamentos.prazoEstimadoPassoDias", {
                    days: 5,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`passo ${
            migration?.step === CatalogMigrationStepEnum.Merge && "completo"
          }`}
        >
          <div className="progress-container">
            <div className="icon-container">
              <div className="icon">
                <RefreshIcon />
              </div>
            </div>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  height: getHeghtByStatus(
                    CatalogMigrationStepEnum.Distribution
                  ),
                }}
              />
            </div>
          </div>
          <div className="passo-container">
            <span className="passo-posicao">
              {t("pages.Adiantamentos.passo", { step: 2 })}
            </span>
            <Collapse
              header={
                <h1 className="titulo-passo">
                  {t("pages.Adiantamentos.distro")}
                </h1>
              }
              content={
                <p>
                  <Trans i18nKey={t("pages.Adiantamentos.distroPasso")} />
                </p>
              }
            />
            <Divider />
            <div className="caixa-etapa-prazo">
              {migration?.step === CatalogMigrationStepEnum.Distribution && (
                <div>
                  <span>{t("pages.Adiantamentos.etapaIniciada")}</span>
                  <span>
                    {moment(migration?.startedOn).format("DD/MM/YYYY")}
                  </span>
                </div>
              )}
              <div>
                <span>{t("pages.Adiantamentos.prazoEstimadoPasso")}</span>
                <span className="roxo">
                  {t("pages.Adiantamentos.prazoEstimadoPassoDias", {
                    days: 5,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="passo">
          <div className="progress-container">
            <div className="icon-container">
              <div className="icon">
                <MergeIcon />
              </div>
            </div>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  height: getHeghtByStatus(CatalogMigrationStepEnum.Merge),
                }}
              />
            </div>
          </div>
          <div className="passo-container">
            <span className="passo-posicao">
              {t("pages.Adiantamentos.passo", { step: 3 })}
            </span>
            <Collapse
              header={<h1 className="titulo-passo">Merge</h1>}
              content={
                <p>
                  <Trans i18nKey={t("pages.Adiantamentos.mergePasso")} />
                </p>
              }
            />
            <Divider />

            <div className="caixa-etapa-prazo">
              {migration?.step === CatalogMigrationStepEnum.Merge && (
                <div>
                  <span>{t("pages.Adiantamentos.etapaIniciada")}</span>
                  <span>
                    {moment(migration?.startedOn).format("DD/MM/YYYY")}
                  </span>
                </div>
              )}
              <div>
                <span>{t("pages.Adiantamentos.prazoEstimadoPasso")}</span>
                <span className="roxo">
                  {t("pages.Adiantamentos.prazoEstimadoPassoDias", {
                    days: 5,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdiantamentoMigracao;
